import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { CustomToolbar } from "../../components/utils/CustomFilter";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { MenuProps } from "../../components/utils/MenuProps";
import { useSelector } from "react-redux";
import { validarRoles } from "../../components/utils/ValidarRoles";
import { Cargando } from "../../components/utils/Cargando";

export const Producto = () => {
  const [cargando, setCargando] = useState(false);

  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;
  const [open, setOpen] = useState(false);
  const [codigo, setCodigo] = useState("");
  const [nombre, setNombre] = useState("");
  const [marca, setMarca] = useState("");
  const [id, setId] = useState("");
  const [modelo, setModelo] = useState(null);
  const [unidad, setUnidad] = useState(null);
  const [serie, setSerie] = useState("");
  const [tipo, setTipo] = useState("");
  const [minimo, setMinimo] = useState(0);

  const [tipo2, setTipo2] = useState("");
  const [real, setReal] = useState("");

  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [unidades, setUnidades] = useState([]);

  const [lista, setLista] = useState(null);

  const [open2, setOpen2] = useState(false);

  useEffect(() => {
    cargar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function limpiar() {
    setCodigo("");
    setNombre("");
    setModelo(null);
    setUnidad("");
    setMarca(null);
    setTipo2("");
    setReal("");
    setMinimo(0);
    setTipo("");
    setOpen(false);
    setOpen2(false);
    setSerie("");
    setId("");
  }
  function handleOpen(row) {
    setCodigo(row.codigo);
    setNombre(row.nombre_simple);
    setTipo2(row.tipo2);
    setReal(row.unidad_real);
    setMarca(row.marca ?? null);
    setModelo(row.modelo ?? null);
    setUnidad(row.unidad?.id ?? "");
    setMinimo(row.stock_min);
    setTipo(row.tipo);
    setSerie(row.usa_serie ? "SI" : "NO");
    setId(row.id);
    setOpen(true);
  }

  function handleOpen2(row) {
    setId(row.id);
    setOpen2(true);
  }

  function crear() {
    setCargando(true)

    let body = JSON.stringify({
      codigo: codigo,
      nombre:
        nombre +
        (marca !== null ? " " + marca.marca : "") +
        (modelo !== null ? " " + modelo.modelo : ""),
      nombre_simple: nombre,
      marca: marca?.id ?? null,
      modelo: modelo?.id ?? null,
      unidad: unidad,
      stock_min: minimo,
      tipo2: tipo2,
      unidad_real: tipo2 === "COMPUESTO" ? real : 1,
      tipo: tipo,
      usa_serie: serie === "SI",
    });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/producto/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(result);
        setOpen(false);
        Swal.fire({ icon: "success", text: "Producto agregado " });
        cargar();
      })
      .catch((error) => {
        setCargando(false)

        setOpen(false);
        Swal.fire({ icon: "error", text: "Error: " + (typeof error === "string" ? error : JSON.stringify(error)) });
      });
  }

  function editar() {
    setCargando(true)

    if (id === "") {
      crear();
      return;
    }
    let body = JSON.stringify({
      codigo: codigo,
      nombre:
        nombre +
        (marca !== null ? " " + marca.marca : "") +
        (modelo !== null ? " " + modelo.modelo : ""),
      marca: marca?.id ?? null,
      modelo: modelo?.id ?? null,
      nombre_simple: nombre,
      unidad: unidad,
      stock_min: minimo,
      tipo: tipo,
      usa_serie: serie === "SI",
    });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/producto/" + id + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setOpen(false);
        Swal.fire({ icon: "success", text: "Producto actualizado " });
        cargar();
        //aconsole.log(result);
      })
      .catch((error) => {
        setOpen(false);
        setCargando(false)

        Swal.fire({ icon: "error", text: "Error: " + (typeof error === "string" ? error : JSON.stringify(error)) });
      });
  }

  function eliminar() {
    setCargando(true)

    if (id === "") {
      return;
    }
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/producto/" + id + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setOpen2(false);
        Swal.fire({ icon: "success", text: "Producto eliminado " });
        cargar();
      })
      .catch((error) => {
        setOpen2(false);
        setCargando(false)
        Swal.fire({ icon: "error", text: "Error: " + (typeof error === "string" ? error : JSON.stringify(error)) });
      });
  }

  function cargar() {
    limpiar();
    setCargando(true)
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://backterra.econtel.com.ec/api/v1/producto/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setLista(JSON.parse(result));
        setCargando(false)

      })
      .catch((error) => {
        setCargando(false)

        Swal.fire({ icon: "error", text: "Error: " + (typeof error === "string" ? error : JSON.stringify(error)) });
      });
    fetch(
      "https://backterra.econtel.com.ec/api/v1/marca/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setMarcas(JSON.parse(result));
      })
      .catch((error) => {
        Swal.fire({ icon: "error", text: "Error: " + (typeof error === "string" ? error : JSON.stringify(error)) });
      });
    fetch(
      "https://backterra.econtel.com.ec/api/v1/modelo/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setModelos(JSON.parse(result));
      })
      .catch((error) => {
        Swal.fire({ icon: "error", text: "Error: " + (typeof error === "string" ? error : JSON.stringify(error)) });
      });
    fetch(
      "https://backterra.econtel.com.ec/api/v1/unidad/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setUnidades(JSON.parse(result));
      })
      .catch((error) => {
        Swal.fire({ icon: "error", text: "Error: " + (typeof error === "string" ? error : JSON.stringify(error)) });
      });
  }

  const columns = [
    {
      field: "codigo",
      headerClassName: "super-app-theme--header",
      headerName: "Código",
      flex: 1,
    },
    {
      field: "nombre",
      headerClassName: "super-app-theme--header",
      headerName: "Nombre",
      flex: 1.5,
    },
    {
      field: "marca",
      headerClassName: "super-app-theme--header",
      headerName: "Marca",
      flex: 1,
      valueGetter: (params) => {
        return params.row.marca?.marca ?? "";
      },
    },
    {
      field: "modelo",
      headerClassName: "super-app-theme--header",
      headerName: "Modelo",
      flex: 1,
      valueGetter: (params) => {
        return params.row.modelo?.modelo ?? "";
      },
    },
    {
      field: "unidad_real",
      headerClassName: "super-app-theme--header",
      headerName: "Unidad Real",
      flex: 1,
      valueGetter: (params) => {
        return params.row.unidad?.unidad
          ? params.row.unidad_real + " " + params.row.unidad?.unidad
          : params.row.unidad_real;
      },
    },
    // {
    //   field: "unidad",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "Unidad",
    //   flex: 1,
    //   valueGetter: (params) => {
    //     return params.row.unidad?.unidad ?? "";
    //   },
    // },
    {
      field: "stock_min",
      headerClassName: "super-app-theme--header",
      headerName: "Stock Mínimo",
      flex: 1,
    },
    {
      field: "tipo",
      headerClassName: "super-app-theme--header",
      headerName: "Tipo",
      flex: 1,
    },
    {
      field: "usa_serie",
      headerClassName: "super-app-theme--header",
      headerName: "Usa Serie",
      flex: 1,
      valueGetter: (params) => {
        return params.row.usa_serie ? "SI" : "NO";
      },
    },
    {
      field: "1",
      headerClassName: "super-app-theme--header",
      headerName: "Acciones",
      flex: 1,
      renderCell: (params) => (
        <Stack direction={"row"} spacing={2}>
          {validarRoles("EDITAR PRODUCTO", userInfo) && (
            <IconButton onClick={() => handleOpen(params.row)}>
              <EditIcon color="warning" />
            </IconButton>
          )}
          {validarRoles("ELIMINAR PRODUCTO", userInfo) && (
            <IconButton onClick={() => handleOpen2(params.row)}>
              <DeleteIcon color="error" />
            </IconButton>
          )}
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Box px={2} py={2}>
        <Paper elevation={2} sx={{ padding: "10px" }}>
          <Typography variant="h5">
            {" "}
            <strong>Productos</strong>{" "}
          </Typography>
          <br />

          {validarRoles("CREAR PRODUCTO", userInfo) && (
            <Button
              color="success"
              variant="contained"
              onClick={() => setOpen(true)}
            >
              Agregar
            </Button>
          )}

          <br />
          <br />
          <Paper elevation={2} sx={{ overflowX: "scroll", padding: "10px" }}>
            <Box
              sx={{
                height: 600,
                minWidth: 1000,
                "& .super-app-theme--header": {
                  backgroundColor: "#428bd4",
                  color: "white",
                  fontSize: "16px",
                },
              }}
            >
              <DataGrid  disableColumnMenu
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
                rows={lista ?? []}
                loading={lista === null}
                columns={
                  validarRoles("ELIMINAR PRODUCTO", userInfo) ||
                    validarRoles("EDITAR PRODUCTO", userInfo)
                    ? columns
                    : columns.filter((val) => val.field !== "1")
                }
                getRowId={(row) => row.id}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                slots={{ toolbar: CustomToolbar }}
              />
            </Box>
          </Paper>
        </Paper>
      </Box>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          limpiar();
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          {" "}
          <strong>{id !== "" ? "Editar " : "Crear "} Producto</strong>{" "}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Código *</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={codigo}
                  onChange={(e) => setCodigo(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Nombre *</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Unidad *</strong>{" "}
                </Typography>
                <Select
                  fullWidth
                  size="small"
                  MenuProps={MenuProps}
                  value={unidad}
                  onChange={(e) => {
                    setUnidad(e.target.value);
                  }}
                >
                  {unidades
                    ? unidades.map((val) => (
                        <MenuItem key={val.id} value={val.id}>
                          {val.unidad}
                        </MenuItem>
                      ))
                    : ""}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Tipo de Unidad *</strong>{" "}
                </Typography>
                <Select
                  fullWidth
                  disabled={id !== ""}
                  size="small"
                  value={tipo2}
                  onChange={(e) => setTipo2(e.target.value)}
                >
                  <MenuItem key={"SIMPLE"} value="SIMPLE">
                    {" "}
                    SIMPLE
                  </MenuItem>
                  <MenuItem key={"COMPUESTO"} value="COMPUESTO">
                    {" "}
                    COMPUESTO
                  </MenuItem>
                </Select>
              </Box>
            </Grid>
            {tipo2 === "COMPUESTO" && (
              <Grid item xs={12} lg={6}>
                <Box px={2}>
                  <Typography>
                    {" "}
                    <strong>Unidad Real*</strong>{" "}
                  </Typography>
                  <TextField
                    fullWidth
                    disabled={id !== ""}
                    size="small"
                    value={real}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={(e) => setReal(e.target.value)}
                  />
                </Box>
              </Grid>
            )}
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Stock Mínimo *</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={minimo}
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={(e) => setMinimo(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Marca </strong>{" "}
                </Typography>
                <Autocomplete
                  size="small"
                  fullWidth
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disablePortal
                  value={marca}
                  options={marcas ?? []}
                  getOptionLabel={(option) => option.marca}
                  onChange={(e, t) => {
                    //aconsole.log(t);
                    setMarca(t);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Modelo </strong>{" "}
                </Typography>
                <Autocomplete
                  size="small"
                  fullWidth
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disablePortal
                  value={modelo}
                  options={modelos ?? []}
                  getOptionLabel={(option) => option.modelo}
                  onChange={(e, t) => {
                    //aconsole.log(t);
                    setModelo(t);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Usa Serie *</strong>{" "}
                </Typography>
                <Select
                  fullWidth
                  disabled={id !== ""}
                  size="small"
                  value={serie}
                  onChange={(e) => setSerie(e.target.value)}
                >
                  <MenuItem key={"SI"} value="SI">
                    {" "}
                    SI
                  </MenuItem>
                  <MenuItem key={"NO"} value="NO">
                    {" "}
                    NO
                  </MenuItem>
                </Select>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Tipo *</strong>{" "}
                </Typography>
                <Select
                  fullWidth
                  size="small"
                  value={tipo}
                  onChange={(e) => setTipo(e.target.value)}
                >
                  <MenuItem key={"Herramienta"} value="Herramienta">
                    {" "}
                    Herramienta
                  </MenuItem>
                  <MenuItem key={"Material"} value="Material">
                    {" "}
                    Material
                  </MenuItem>
                </Select>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Nombre Producto Final</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={
                    nombre +
                    (marca !== null ? " " + marca.marca : "") +
                    (modelo !== null ? " " + modelo.modelo : "")
                  }
                  disabled
                />
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <br />
                <Button
                  color="success"
                  variant="contained"
                  disabled={
                    nombre.trim().length === 0 ||
                    codigo.trim().length === 0 ||
                    unidad === "" ||
                    tipo2.trim().length === 0 ||
                    serie.trim().length === 0 ||
                    (tipo2 === "COMPUESTO" && real === "") ||
                    tipo.trim().length === 0
                  }
                  onClick={() => editar()}
                >
                  Guardar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen(false);
              limpiar();
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open2}>
        <DialogTitle>
          <Stack
            spacing={2}
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            ¿ESTÁ SEGURO DE ELIMINAR?
          </Stack>
        </DialogTitle>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              eliminar();
            }}
          >
            Borrar
          </Button>
          <Button
            color="info"
            variant="contained"
            onClick={() => {
              setOpen2(false);
              limpiar();
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Cargando open2={cargando} />

    </>
  );
};
