import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  TextField,
  Stack,
  Typography,
  Autocomplete,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Swal from "sweetalert2";
import { CustomToolbar } from "../../components/utils/CustomFilter";
import { useSelector } from "react-redux";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  getDownloadURL,
  uploadBytes,
  ref as ref_storage,
} from "firebase/storage";
import moment from "moment";
import { storage } from "../../firebaseConfig";
import { validarRoles } from "../../components/utils/ValidarRoles";
import { Cargando } from "../../components/utils/Cargando";
import { FiltroFechas } from "../../components/utils/FiltroFechas";
import { endOfDay, startOfDay, subDays } from "date-fns";

export const Gasto = () => {
  const [cargando, setCargando] = useState(false);

  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [monto, setMonto] = useState("");
  const [cuadrilla, setCuadrilla] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [imagen, setImagen] = useState(null);
  const [view, setView] = useState(null);
  const [motivo, setMotivo] = useState(null);
  const [lista, setLista] = useState(null);

  const [cuadrillas, setCuadrillas] = useState([]);
  const [motivos, setMotivos] = useState([]);

  const [open2, setOpen2] = useState(false);

  const [value, setValue] = useState([
    startOfDay(subDays(new Date(), 6)),
    endOfDay(new Date()),
  ]);


  useEffect(() => {
    cargar();
    cargarMotivos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeimagen = (e) => {
    const file = e.target.files[e.target.files.length - 1];
    setImagen(file);
    setView(URL.createObjectURL(file));
  };

  function limpiar() {
    setId("");
    setImagen(null);
    setView(null);
    setDescripcion("");
    setMonto("");
    setMotivo(null);
    setCuadrilla("");
  }

  function handleOpen2(row) {
    setId(row.id);
    setOpen2(true);
  }
  function handleOpen(row) {
    setId(row.id);
    setOpen(true);
    setMotivo(row.motivo_gasto);
    setView(row.foto_comprobante);
    setDescripcion(row.descripcion);
    setMonto(row.monto_gasolina);
    setCuadrilla(row.id_cuadrilla);
  }

  const columns = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      flex: 1,
      valueGetter: (params) => {
        return "GASTO-"+ params.row.id ?? "";
      },
    },
    {
      field: "id_cuadrilla",
      headerClassName: "super-app-theme--header",
      headerName: "Cuadrilla",
      flex: 1,
      valueGetter: (params) => {
        return params.row.id_cuadrilla?.nombre ?? "";
      },
    },
    {
      field: "id_tecnico",
      headerClassName: "super-app-theme--header",
      headerName: "Técnico",
      flex: 1,
      valueGetter: (params) => {
        return params.row.id_tecnico?.nombre ?? "";
      },
    },
    {
      field: "monto_gasolina",
      headerClassName: "super-app-theme--header",
      headerName: "Monto",
      flex: 1,
    },
    {
      field: "motivo_gasto",
      headerClassName: "super-app-theme--header",
      headerName: "Motivo",
      flex: 1,
      valueGetter: (params) => {
        return params.row.motivo_gasto?.motivo ?? "";
      },
    },
    {
      field: "fecha",
      headerClassName: "super-app-theme--header",
      headerName: "Fecha",
      flex: 1,
      valueGetter: (params) => {
        return moment(params.row.created_at).format("DD/MMMM/yyyy HH:mm") ?? "";
      },
    },
    {
      field: "1",
      headerClassName: "super-app-theme--header",
      headerName: "Acciones",
      flex: 1,
      renderCell: (params) => (
        <Stack direction={"row"} spacing={2}>
          <IconButton onClick={() => handleOpen(params.row)}>
            <RemoveRedEyeIcon color="warning" />
          </IconButton>
          {validarRoles("ELIMINAR GASTOS", userInfo) && (
            <IconButton onClick={() => handleOpen2(params.row)}>
              <DeleteIcon color="error" />
            </IconButton>
          )}
        </Stack>
      ),
    },
  ];

  async function uploadImage() {
    setCargando(true);
    try {
      const storageRef = ref_storage(
        storage,
        `${userInfo.id}/${cuadrilla}/Foto Comprobante:  ${moment().format(
          "DD/MMMM/yyyy HH:mm:ss "
        )} ${imagen.name}`
      );
      await uploadBytes(storageRef, imagen).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          crear(downloadURL);
        });
      });
    } catch (error) {
      setCargando(false);
      Swal.fire({
        icon: "error",
        text:
          "Error: " +
          (typeof error === "string" ? error : JSON.stringify(error)),
      });
    }
    setImagen(null);
    setView(null);
  }

  function crear(link) {
    setCargando(true);
    let body = JSON.stringify({
      foto_comprobante: link,
      monto_gasolina: monto,
      descripcion: descripcion,
      id_tecnico: userInfo.id,
      motivo_gasto: motivo.id,
      id_cuadrilla: cuadrilla?.id ?? null,
    });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/combustibles/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(result);
        setOpen(false);
        Swal.fire({ icon: "success", text: "Registro agregado " });
        cargar();
      })
      .catch((error) => {
        setOpen(false);
        setCargando(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function eliminar() {
    setCargando(true);
    if (id === "") {
      return;
    }
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/combustibles/" +
        id +
        "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setOpen2(false);
        Swal.fire({ icon: "success", text: "Registro eliminado" });
        cargar();
      })
      .catch((error) => {
        setOpen2(false);
        setCargando(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function cargarMotivos() {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://backterra.econtel.com.ec/api/v1/motivo_gastos/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setMotivos(JSON.parse(result));
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function cargar(inicio, fin) {
    setCargando(true);
    limpiar();
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    let url = "https://backterra.econtel.com.ec/api/v1/combustibles/"
    if(inicio && fin){
      url = "https://backterra.econtel.com.ec/api/v1/combustibles/?inicio="
      +inicio+"&fin="+fin;
    }else{
      url = "https://backterra.econtel.com.ec/api/v1/combustibles/?inicio="
      +value[0].toISOString()+"&fin="+value[1].toISOString();
    }
    fetch(
      url,
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setLista(JSON.parse(result));
        setCargando(false);
      })
      .catch((error) => {
        setCargando(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });

    fetch(
      "https://backterra.econtel.com.ec/api/v1/bodega/?tipo=CUADRILLA",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setCuadrillas(JSON.parse(result));
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  return (
    <>
      <Box px={2} py={2}>
        <Paper elevation={2} sx={{ padding: "10px" }}>
          <Typography variant="h5">
            {" "}
            <strong>Gastos</strong>{" "}
          </Typography>
          <br />
          <FiltroFechas metodo={cargar} value={value} setValue={setValue} />

          {validarRoles("CREAR GASTOS", userInfo) && (
            <Button
              color="success"
              variant="contained"
              onClick={() => setOpen(true)}
            >
              Agregar
            </Button>
          )}

          <br />
          <br />
          <Paper elevation={2} sx={{ overflowX: "scroll", padding: "10px" }}>
            <Box
              sx={{
                height: 600,
                minWidth: 1000,
                "& .super-app-theme--header": {
                  backgroundColor: "#428bd4",
                  color: "white",
                  fontSize: "16px",
                },
              }}
            >
              <DataGrid
                disableColumnMenu
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
                rows={lista ?? []}
                loading={lista === null}
                columns={columns}
                getRowId={(row) => row.id}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                slots={{ toolbar: CustomToolbar }}
              />
            </Box>
          </Paper>
        </Paper>
      </Box>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          limpiar();
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          {" "}
          <strong> {id !== "" ? "Ver " : "Crear "} Registro</strong>{" "}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Monto *</strong>{" "}
                </Typography>
                <TextField
                  disabled={id !== ""}
                  fullWidth
                  size="small"
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  value={monto}
                  onChange={(e) => setMonto(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Motivo *</strong>{" "}
                </Typography>
                <Autocomplete
                  size="small"
                  disabled={id !== ""}
                  fullWidth
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disablePortal
                  value={motivo}
                  options={motivos ?? []}
                  getOptionLabel={(option) => option.motivo}
                  onChange={(e, t) => {
                    //aconsole.log(t);
                    setMotivo(t);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Cuadrilla *</strong>{" "}
                </Typography>
                <Autocomplete
                  ListboxProps={{ style: { maxHeight: 150 } }}
                  size="small"
                  disabled={id !== ""}
                  fullWidth
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disablePortal
                  value={cuadrilla}
                  options={cuadrillas}
                  getOptionLabel={(option) => option.nombre ?? ""}
                  onChange={(e, t) => {
                    setCuadrilla(t);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Descripción *</strong>{" "}
                </Typography>
                <TextField
                  disabled={id !== ""}
                  fullWidth
                  size="small"
                  multiline
                  rows={2}
                  value={descripcion}
                  onChange={(e) => setDescripcion(e.target.value)}
                />
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Stack direction={"row"} spacing={2}>
                  <Stack direction={"column"} spacing={2}>
                    {id === "" && (
                      <Button
                        component="label"
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                      >
                        Cargar Comprobante
                        <input hidden type="file" onChange={changeimagen} />
                      </Button>
                    )}
                  </Stack>
                  {view && (
                    <img
                      src={view}
                      alt={imagen?.name ?? "imagen"}
                      height={110}
                    />
                  )}
                </Stack>
              </Box>
            </Grid>

            {id === "" && (
              <Grid item xs={12} lg={6}>
                <Box px={2}>
                  <br />
                  <Button
                    color="success"
                    variant="contained"
                    disabled={
                      imagen === null ||
                      monto === "" ||
                      motivo === null ||
                      descripcion === "" ||
                      cuadrilla === ""
                    }
                    onClick={() => uploadImage()}
                  >
                    Guardar
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen(false);
              limpiar();
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open2}>
        <DialogTitle>
          <Stack
            spacing={2}
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            ¿ESTÁ SEGURO DE ELIMINAR?
          </Stack>
        </DialogTitle>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              eliminar();
            }}
          >
            Borrar
          </Button>
          <Button
            color="info"
            variant="contained"
            onClick={() => {
              setOpen2(false);
              limpiar();
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Cargando open2={cargando} />
    </>
  );
};
