import { Box, Paper, Typography } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { CustomToolbar } from "../../../components/utils/CustomFilter";

export const StockTecnico = () => {
  const [lista, setLista] = useState(null);
  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;

  useEffect(() => {
    cargar();
  }, []);

  function cargar() {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/bodega/?encargado_id=" +
        userInfo.id,
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then(async (result) => {
        let tecnicos = JSON.parse(result).filter(
          (val) => val.tipo === "TECNICO"
        );
        let lista2 = [];
        for (const el of tecnicos) {
          //aconsole.log(el);
          await fetch(
            "https://backterra.econtel.com.ec/api/v1/stockby/?bodega=" + el.id,
            requestOptions
          )
            .then(async (response) => {
              if (response.ok) {
                return response.text();
              }
              let mensaje = await response.json();
              throw {
                ...mensaje,
              };
            })
            .then((result) => {
              //aconsole.log(JSON.parse(result));
              lista2 = lista2.concat(JSON.parse(result).filter((val)=> val.producto !== null));
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                text: "Error: " + (typeof error === "string" ? error : JSON.stringify(error)),
              });
            });
        }

        setLista(lista2);
      })
      .catch((error) => {
        Swal.fire({ icon: "error", text: "Error: " + (typeof error === "string" ? error : JSON.stringify(error)) });
      });
  }

  const columns = [
    {
      field: "nombre_bodega",
      headerClassName: "super-app-theme--header",
      headerName: "Técnico",
      flex: 1,
      valueGetter: (params) => {
        return params.row.bodega?.nombre ?? "";
      },
    },
    {
      field: "nombre_producto",
      headerClassName: "super-app-theme--header",
      headerName: "Producto",
      flex: 1,
      valueGetter: (params) => {
        return params.row.producto?.nombre ?? "";
      },
    },
    {
      field: "cantidad",
      headerClassName: "super-app-theme--header",
      headerName: "Cantidad",
      flex: 1,
    },
  ];

  return (
    <Box px={2} py={2}>
      <Paper elevation={2} sx={{ padding: "10px" }}>
        <Typography variant="h5">
          {" "}
          <strong>Stock Técnico</strong>{" "}
        </Typography>
        <br />
        <br />
        <Paper elevation={2} sx={{ overflowX: "scroll", padding: "10px" }}>
          <Box
            sx={{
              height: 600,
              minWidth: 1000,
              "& .super-app-theme--header": {
                backgroundColor: "#428bd4",
                color: "white",
                fontSize: "16px",
              },
            }}
          >
            <DataGrid  disableColumnMenu
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}
              rows={lista ?? []}
              loading={lista === null}
              columns={columns}
              getRowId={(row) => row.id}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              slots={{ toolbar: CustomToolbar }}
            />
          </Box>
        </Paper>
      </Paper>
    </Box>
  );
};
