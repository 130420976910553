import React, { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Typography } from "@mui/material";
import { Ingresos } from "./Ingresos";
import { Egresos } from "./Egresos";
import { Transferencias } from "./Transferencias";

export const GenerarMov = () => {
  const [tab, setTab] = useState("0");
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <br />
      <Box px={2}>
        <Typography variant="h5">
          {" "}
          <strong>Realizar Movimientos</strong>{" "}
        </Typography>
        <TabContext value={tab}>
          <Box sx={{ borderColor: "divider" }}>
            <TabList onChange={handleChange} variant="scrollable">
              <Tab label="Ingreso" sx={{ fontWeight: "bold" }} value={"0"} />
              <Tab label="Egreso" sx={{ fontWeight: "bold" }} value={"1"} />
              <Tab
                label="Transferencia"
                sx={{ fontWeight: "bold" }}
                value={"2"}
              />
            </TabList>
          </Box>
          <TabPanel value={"0"}>
            <Ingresos />
          </TabPanel>
          <TabPanel value={"1"}>
            <Egresos />
          </TabPanel>
          <TabPanel value={"2"}>
            <Transferencias />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};
