import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  TextField,
  Stack,
  Typography,
  Autocomplete,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { CustomToolbar } from "../../components/utils/CustomFilter";
import { useSelector } from "react-redux";
import { validarRoles } from "../../components/utils/ValidarRoles";
import { Cargando } from "../../components/utils/Cargando";
import moment from "moment";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { FiltroFechas } from "../../components/utils/FiltroFechas";

export const Rutas = () => {
  const [cargando, setCargando] = useState(false);

  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");

  const [bodega, setBodega] = useState(null);

  const [lista, setLista] = useState(null);

  const [cuadrillas, setCuadrillas] = useState([]);
  const [actividades, setActividades] = useState([]);

  const [seleccionado, setSeleccionado] = useState([]);

  const [open2, setOpen2] = useState(false);

  const [value, setValue] = useState([
    startOfDay(subDays(new Date(), 6)),
    endOfDay(new Date()),
  ]);

  useEffect(() => {
    cargar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function limpiar() {
    setId("");
    setBodega(null);
    setSeleccionado([]);
  }

  function handleOpen2(row) {
    setId(row.id);
    setOpen2(true);
  }
  function handleOpen(row) {
    setId(row.id);
    if (row.cuadrilla) {
      let res = cuadrillas.filter((val) => val.id === row.cuadrilla.id);
      setBodega(res[0] ?? null);
    } else {
      setBodega(null);
    }
    setSeleccionado(row.actividades ?? []);
    setOpen(true);
  }

  const columns = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "Ruta",
      flex: 1,
      valueGetter: (params) => {
        return "RUTA-" + params.row.id;
      },
    },
    {
      field: "cuadrilla",
      headerClassName: "super-app-theme--header",
      headerName: "Cuadrilla",
      flex: 1,
      valueGetter: (params) => {
        return params.row.cuadrilla?.nombre ?? "";
      },
    },
    {
      field: "estado",
      headerClassName: "super-app-theme--header",
      headerName: "Estado",
      flex: 1,
    },
    {
      field: "actividades",
      headerClassName: "super-app-theme--header",
      headerName: "Actividades",
      flex: 1,
      valueGetter: (params) => {
        if (params.row.actividades) {
          return [
            ...params.row.actividades.map((val) => {
              return "ACTIVIDAD-" + val.id;
            }),
          ].join(", ");
        } else {
          return "";
        }
      },
    },
    {
      field: "created_at",
      headerClassName: "super-app-theme--header",
      headerName: "Creación",
      flex: 1,
      valueGetter: (params) => {
        return params.row.created_at
          ? moment(params.row.created_at).format("DD/MMMM/yyyy HH:mm")
          : "";
      },
    },
    {
      field: "1",
      headerClassName: "super-app-theme--header",
      headerName: "Acciones",
      flex: 1,
      renderCell: (params) => (
        <Stack direction={"row"} spacing={2}>
          {validarRoles("EDITAR RUTAS", userInfo) &&
            params.row.estado === "PENDIENTE" && (
              <IconButton onClick={() => handleOpen(params.row)}>
                <EditIcon color="warning" />
              </IconButton>
            )}
          {validarRoles("ELIMINAR RUTAS", userInfo) && (
            <IconButton onClick={() => handleOpen2(params.row)}>
              <DeleteIcon color="error" />
            </IconButton>
          )}
        </Stack>
      ),
    },
  ];

  function crear() {
    setCargando(true);

    let body = JSON.stringify({
      cuadrilla: bodega.id,
      estado: "PENDIENTE",
      actividades: [
        ...seleccionado.map((val) => {
          return val.id;
        }),
      ],
    });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/rutas/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(result);
        setOpen(false);
        Swal.fire({ icon: "success", text: "Ruta agregada " });
        cargar();
      })
      .catch((error) => {
        setCargando(false);

        setOpen(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }
  function editar() {
    setCargando(true);

    if (id === "") {
      crear();
      return;
    }
    let body = JSON.stringify({
      cuadrilla: bodega.id,
      actividades: [
        ...seleccionado.map((val) => {
          return val.id;
        }),
      ],
    });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/rutas/" + id + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setOpen(false);
        Swal.fire({ icon: "success", text: "Ruta actualizada " });
        cargar();
      })
      .catch((error) => {
        setCargando(false);

        setOpen(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function eliminar() {
    setCargando(true);

    if (id === "") {
      return;
    }
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/rutas/" + id + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setOpen2(false);
        Swal.fire({ icon: "success", text: "Ruta eliminada " });
        cargar();
      })
      .catch((error) => {
        setCargando(false);

        setOpen2(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function cargar(inicio, fin) {
    limpiar();
    setCargando(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    let url = "https://backterra.econtel.com.ec/api/v1/rutas/"
    if(inicio && fin){
      url = "https://backterra.econtel.com.ec/api/v1/rutas/?inicio="
      +inicio+"&fin="+fin;
    }else{
      url = "https://backterra.econtel.com.ec/api/v1/rutas/?inicio="
      +value[0].toISOString()+"&fin="+value[1].toISOString();
    }
    fetch(
      url,
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setLista(
          JSON.parse(result).sort((a, b) => {
            return b.id - a.id;
          })
        );
        setCargando(false);
      })
      .catch((error) => {
        setCargando(false);

        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
    let url2 =
      "https://backterra.econtel.com.ec/api/v1/actividades/estado/INGRESADO/";
    if (inicio && fin) {
      url2 =
        "https://backterra.econtel.com.ec/api/v1/actividades/estado/INGRESADO/?inicio=" +
        inicio +
        "&fin=" +
        fin;
    } else {
      url2 =
        "https://backterra.econtel.com.ec/api/v1/actividades/estado/INGRESADO/?inicio=" +
        value[0].toISOString() +
        "&fin=" +
        value[1].toISOString();
    }
    fetch(url2, requestOptions)
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setActividades(JSON.parse(result));
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
    fetch(
      "https://backterra.econtel.com.ec/api/api/cuadrillas",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setCuadrillas(JSON.parse(result));
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  return (
    <>
      <Box px={2} py={2}>
        <Paper elevation={2} sx={{ padding: "10px" }}>
          <Typography variant="h5">
            {" "}
            <strong>Rutas</strong>{" "}
          </Typography>
          <br />
          <FiltroFechas metodo={cargar} value={value} setValue={setValue} />

          {validarRoles("CREAR RUTAS", userInfo) && (
            <Button
              color="success"
              variant="contained"
              onClick={() => setOpen(true)}
            >
              Agregar
            </Button>
          )}

          <br />
          <br />
          <Paper elevation={2} sx={{ overflowX: "scroll", padding: "10px" }}>
            <Box
              sx={{
                height: 600,
                minWidth: 1000,
                "& .super-app-theme--header": {
                  backgroundColor: "#428bd4",
                  color: "white",
                  fontSize: "16px",
                },
              }}
            >
              <DataGrid
                disableColumnMenu
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
                rows={lista ?? []}
                loading={lista === null}
                columns={
                  validarRoles("ELIMINAR RUTAS", userInfo) ||
                    validarRoles("EDITAR RUTAS", userInfo)
                    ? columns
                    : columns.filter((val) => val.field !== "1")
                }
                getRowId={(row) => row.id}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                slots={{ toolbar: CustomToolbar }}
              />
            </Box>
          </Paper>
        </Paper>
      </Box>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          limpiar();
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {" "}
          <strong>{id !== "" ? "Editar " : "Crear "} Ruta</strong>{" "}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Cuadrilla *</strong>{" "}
                </Typography>
                <Autocomplete
                  size="small"
                  fullWidth
                  disabled={id !== ""}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disablePortal
                  value={bodega}
                  options={cuadrillas}
                  // getOptionLabel={(option) => option.nombre ?? ""}
                  getOptionLabel={(option) =>
                    option.id ? option.nombre + ": " + option.usuarios : ""
                  }
                  onChange={(e, t) => {
                    setBodega(t);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Actividades</strong>{" "}
                </Typography>
                <Autocomplete
                  size="small"
                  fullWidth
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id || option.id === Number(value)
                  }
                  disablePortal
                  value={seleccionado}
                  options={actividades}
                  multiple
                  getOptionLabel={(option) =>
                    option.id ? "ACTIVIDAD-" + option.id : ""
                  }
                  onChange={(e, t) => {
                    //aconsole.log(t);
                    setSeleccionado(t);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <br />
                <Button
                  color="success"
                  variant="contained"
                  disabled={bodega === null}
                  onClick={() => editar()}
                >
                  Guardar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen(false);
              limpiar();
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open2}>
        <DialogTitle>
          <Stack
            spacing={2}
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            ¿ESTÁ SEGURO DE ELIMINAR?
          </Stack>
        </DialogTitle>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              eliminar();
            }}
          >
            Borrar
          </Button>
          <Button
            color="info"
            variant="contained"
            onClick={() => {
              setOpen2(false);
              limpiar();
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Cargando open2={cargando} />
    </>
  );
};
