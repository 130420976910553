import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { CustomToolbar } from "../../components/utils/CustomFilter";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { MenuProps } from "../../components/utils/MenuProps";
import { useSelector } from "react-redux";
import { Cargando } from "../../components/utils/Cargando";
import EditIcon from "@mui/icons-material/Edit";

import moment from "moment";
import { validarRoles } from "../../components/utils/ValidarRoles";
import { FiltroFechas } from "../../components/utils/FiltroFechas";
import { endOfDay, startOfDay, subDays } from "date-fns";

export const Solicitudes = () => {
  const [cargando, setCargando] = useState(false);
  const [filtro, setFiltro] = useState("APROBADO");
  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;
  const [open, setOpen] = useState(false);
  const [cliente, setCliente] = useState("");
  const [id, setId] = useState("");
  const [tipo, setTipo] = useState("");

  const [lista, setLista] = useState(null);

  const [open2, setOpen2] = useState(false);

  const [cedula, setCedula] = useState("");
  const [direccion, setDireccion] = useState("");
  const [latitud, setLatitud] = useState("");
  const [longitud, setLongitud] = useState("");
  const [telefono, setTelefono] = useState("");
  const [celular, setCelular] = useState("");
  const [correo, setCorreo] = useState("");
  const [plan, setPlan] = useState("");
  const [horarios, setHorarios] = useState("");
  const [latitudAnterior, setLatitudAnterior] = useState("");
  const [longitudAnterior, setLongitudAnterior] = useState("");
  const [direccionAnterior, setDireccionAnterior] = useState("");
  const [potencia, setPotencia] = useState("");
  const [tecnologia, setTecnologia] = useState("");
  const [nombre, setNombre] = useState("");
  const [observacion, setObservacion] = useState("");

  const [pisos, setPisos] = useState("");
  const [gestion, setGestion] = useState("");
  const [equipos, setEquipos] = useState("");

  const [edit, setEdit] = useState(false);

  const [value, setValue] = useState([
    startOfDay(subDays(new Date(), 6)),
    endOfDay(new Date()),
  ]);

  useEffect(() => {
    cargar(filtro, null, null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function limpiar() {
    setCliente("");
    setOpen(false);
    setEdit(false);

    setTipo("");
    setId("");

    setPisos("");
    setGestion("");
    setEquipos("");

    setCedula("");
    setDireccion("");
    setLatitud("");
    setLongitud("");
    setTelefono("");
    setCelular("");
    setCorreo("");
    setPlan("");
    setHorarios("");
    setLatitudAnterior("");
    setLongitudAnterior("");
    setDireccionAnterior("");
    setPotencia("");
    setTecnologia("");
    setNombre("");
    setObservacion("");
  }

  function handleOpen3(row) {
    setCliente(row.idcliente);
    setTipo(row.tipo);
    setId(row.id);

    if (row.tipo === "INSTALACION") {
      setCedula(row.cedula);
      setDireccion(row.direccion);
      setLatitud(row.latitud);
      setLongitud(row.longitud);
      setTelefono(row.telefono);
      setCelular(row.celular);
      setCorreo(row.correo_electronico);
      setPlan(row.plan_contratar);
      setHorarios(row.horarios_disponibles);
      setNombre(row.nombres_cliente);
      setDireccion(row.direccion);
      setPisos(row.casa_npisos);
    } else if (row.tipo === "CAMBIO DOMICILIO") {
      setCedula(row.cedula);
      setDireccion(row.direccion);
      setLatitud(row.latitud);
      setLongitud(row.longitud);
      setLatitudAnterior(row.latitud_anterior);
      setLongitudAnterior(row.longitud_anterior);
      setTelefono(row.telefono);
      setCelular(row.celular);
      setEquipos(row.cliente_tiene_equipo);
      setHorarios(row.horarios_disponibles);
      setNombre(row.nombres_cliente);
      setDireccionAnterior(row.direccion_anterior);
    } else if (row.tipo === "MIGRACION") {
      setCedula(row.cedula);
      setDireccion(row.direccion);
      setLatitud(row.latitud);
      setLongitud(row.longitud);
      setTelefono(row.telefono);
      setCelular(row.celular);
      setHorarios(row.horarios_disponibles);
      setNombre(row.nombres_cliente);
    } else if (
      row.tipo === "OTRO" ||
      row.tipo === "CAMBIO DE TITULAR" ||
      row.tipo === "RETIRO"
    ) {
      setCedula(row.cedula);
      setDireccion(row.direccion);
      setLatitud(row.latitud);
      setLongitud(row.longitud);
      setTelefono(row.telefono);
      setCelular(row.celular);
      setNombre(row.nombres_cliente);
      setGestion(row.gestion_operador);
    } else {
      setCedula(row.cedula);
      setDireccion(row.direccion);
      setLatitud(row.latitud);
      setLongitud(row.longitud);
      setTelefono(row.telefono);
      setCelular(row.celular);
      setNombre(row.nombres_cliente);
      setTecnologia(row.tecnologia);
      setPotencia(row.senal_potencia);
      setGestion(row.gestion_operador);
    }
    setObservacion(row.observacion_diagnostico);
    setEdit(true);
  }

  function handleOpen(row) {
    setCliente(row.idcliente);
    setTipo(row.tipo);
    setId(row.id);

    if (row.tipo === "INSTALACION") {
      setCedula(row.cedula);
      setDireccion(row.direccion);
      setLatitud(row.latitud);
      setLongitud(row.longitud);
      setTelefono(row.telefono);
      setCelular(row.celular);
      setCorreo(row.correo_electronico);
      setPlan(row.plan_contratar);
      setHorarios(row.horarios_disponibles);
      setNombre(row.nombres_cliente);
      setDireccion(row.direccion);
      setPisos(row.casa_npisos);
    } else if (row.tipo === "CAMBIO DOMICILIO") {
      setCedula(row.cedula);
      setDireccion(row.direccion);
      setLatitud(row.latitud);
      setLongitud(row.longitud);
      setLatitudAnterior(row.latitud_anterior);
      setLongitudAnterior(row.longitud_anterior);
      setTelefono(row.telefono);
      setCelular(row.celular);
      setEquipos(row.cliente_tiene_equipo);
      setHorarios(row.horarios_disponibles);
      setNombre(row.nombres_cliente);
      setDireccionAnterior(row.direccion_anterior);
    } else if (row.tipo === "MIGRACION") {
      setCedula(row.cedula);
      setDireccion(row.direccion);
      setLatitud(row.latitud);
      setLongitud(row.longitud);
      setTelefono(row.telefono);
      setCelular(row.celular);
      setHorarios(row.horarios_disponibles);
      setNombre(row.nombres_cliente);
    } else if (
      row.tipo === "OTRO" ||
      row.tipo === "CAMBIO DE TITULAR" ||
      row.tipo === "RETIRO"
    ) {
      setCedula(row.cedula);
      setDireccion(row.direccion);
      setLatitud(row.latitud);
      setLongitud(row.longitud);
      setTelefono(row.telefono);
      setCelular(row.celular);
      setNombre(row.nombres_cliente);
      setGestion(row.gestion_operador);
    } else {
      setCedula(row.cedula);
      setDireccion(row.direccion);
      setLatitud(row.latitud);
      setLongitud(row.longitud);
      setTelefono(row.telefono);
      setCelular(row.celular);
      setNombre(row.nombres_cliente);
      setTecnologia(row.tecnologia);
      setPotencia(row.senal_potencia);
      setGestion(row.gestion_operador);
    }
    setObservacion(row.observacion_diagnostico);
    setOpen(true);
  }

  function handleOpen2(row) {
    setId(row.id);
    setOpen2(true);
  }

  function crear(link) {
    setCargando(true);
    let body = JSON.stringify({
      idcliente: cliente,
      tipo: tipo,
      descripcion: "POR REVISAR",
    });
    if (tipo === "INSTALACION") {
      body = JSON.stringify({
        idcliente: cliente,
        cedula: cedula,
        direccion: direccion,
        tipo: tipo,
        latitud: latitud,
        longitud: longitud,
        telefono: telefono,
        celular: celular,
        nombres_cliente: nombre,
        plan_contratar: plan,
        horarios_disponibles: horarios,
        correo_electronico: correo ?? "",
        descripcion: "POR REVISAR",
        estado: "PENDIENTE",
        casa_npisos: pisos,
        usuario_solicitud: userInfo.id,
        observacion_diagnostico: observacion,
        foto_casa: link ?? "",
      });
    } else if (tipo === "CAMBIO DOMICILIO") {
      body = JSON.stringify({
        idcliente: cliente,
        cedula: cedula,
        direccion: direccion,
        tipo: tipo,
        latitud: latitud,
        longitud: longitud,
        latitud_anterior: latitudAnterior,
        longitud_anterior: longitudAnterior,
        telefono: telefono,
        celular: celular,
        cliente_tiene_equipo: equipos,
        nombres_cliente: nombre,
        horarios_disponibles: horarios,
        direccion_anterior: direccionAnterior,
        estado: "PENDIENTE",
        descripcion: "POR REVISAR",
        usuario_solicitud: userInfo.id,
        observacion_diagnostico: observacion,
        foto_casa: link ?? "",
      });
    } else if (tipo === "MIGRACION") {
      body = JSON.stringify({
        idcliente: cliente,
        cedula: cedula,
        direccion: direccion,
        tipo: tipo,
        latitud: latitud,
        longitud: longitud,
        telefono: telefono,
        celular: celular,
        nombres_cliente: nombre,
        horarios_disponibles: horarios,
        estado: "PENDIENTE",
        descripcion: "POR REVISAR",
        usuario_solicitud: userInfo.id,
        observacion_diagnostico: observacion,
      });
    } else if (
      tipo === "OTRO" ||
      tipo === "CAMBIO DE TITULAR" ||
      tipo === "RETIRO"
    ) {
      body = JSON.stringify({
        idcliente: cliente,
        cedula: cedula,
        direccion: direccion,
        tipo: tipo,
        latitud: latitud,
        longitud: longitud,
        telefono: telefono,
        celular: celular,
        nombres_cliente: nombre,
        estado: "PENDIENTE",
        descripcion: "POR REVISAR",
        usuario_solicitud: userInfo.id,
        gestion_operador: gestion,
        observacion_diagnostico: observacion,
      });
    } else {
      body = JSON.stringify({
        idcliente: cliente,
        cedula: cedula,
        direccion: direccion,
        tipo: tipo,
        latitud: latitud,
        longitud: longitud,
        telefono: telefono,
        celular: celular,
        nombres_cliente: nombre,
        senal_potencia: potencia,
        tecnologia: tecnologia,
        observacion_diagnostico: observacion,
        estado: "PENDIENTE",
        descripcion: "POR REVISAR",
        usuario_solicitud: userInfo.id,
        gestion_operador: gestion,
      });
    }
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/solicitud/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setOpen(false);
        Swal.fire({ icon: "success", text: "Solicitud agregada " });
        cargar(filtro, null, null);
      })
      .catch((error) => {
        setOpen(false);
        setCargando(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function editar() {
    
    setCargando(true);
    let body = {
      observacion_diagnostico: observacion,
    };
    if (tipo === "INSTALACION" || tipo === "CAMBIO DOMICILIO" || tipo === "MIGRACION") {
      body = {
        observacion_diagnostico: observacion,
        horarios_disponibles: horarios,
      };
    } 
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: JSON.stringify(body),
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/solicitud/"+id+"/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setEdit(false);
        Swal.fire({ icon: "success", text: "Solicitud actualizada" });
        cargar(filtro, null, null);
      })
      .catch((error) => {
        setEdit(false);
        setCargando(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function eliminar() {
    setCargando(true);
    if (id === "") {
      return;
    }
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/solicitud/" + id + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setOpen2(false);
        Swal.fire({ icon: "success", text: "Solicitud eliminada " });
        cargar(filtro, null, null);
      })
      .catch((error) => {
        setCargando(false);
        setOpen2(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function cargarClientes(cedula) {
    setCargando(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/api/clientes/?cedula=" +
        cedula,
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        let datos = JSON.parse(result);
        if (datos.length > 0) {
          let a = datos[0];
          setCorreo(a.email);
          setCliente(a.id_servicio);
          setTelefono(a.telefono);
          setPlan(a.plan_internet?.nombre ?? "");
          setNombre(a.nombre);
          if (tipo === "CAMBIO DOMICILIO") {
            setDireccionAnterior(a.direccion);
          } else {
            setDireccion(a.direccion);
          }
          if (a.coordenadas) {
            let c = a.coordenadas.split(",");
            //aconsole.log(c);
            if (c.length > 1) {
              if (tipo === "CAMBIO DOMICILIO") {
                setLatitudAnterior(c[0].trim());
                setLongitudAnterior(c[1].trim());
              } else {
                setLatitud(c[0].trim());
                setLongitud(c[1].trim());
              }
            }
          }
        } else {
          setDireccion("");
          setCorreo("");
          setCliente("");
          setTelefono("");
          setPlan("");
          setLongitud("");
          setLatitud("");
          setNombre("");
        }
        setCargando(false);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
        setCargando(false);
      });
  }

  function cargar2(inicio, fin) {
    cargar(filtro, inicio, fin);
  }

  function cargar(opcion, inicio, fin) {
    limpiar();
    setCargando(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    let url =
      "https://backterra.econtel.com.ec/api/api/solicitudes?estado=" +
      opcion;
    if (inicio && fin) {
      url =
        "https://backterra.econtel.com.ec/api/api/solicitudes?estado=" +
        opcion +
        "&inicio=" +
        inicio +
        "&fin=" +
        fin;
    } else {
      url =
        "https://backterra.econtel.com.ec/api/api/solicitudes?estado=" +
        opcion +
        "&inicio=" +
        value[0].toISOString() +
        "&fin=" +
        value[1].toISOString();
    }
    fetch(url, requestOptions)
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setLista(
          JSON.parse(result).sort((a, b) => {
            return b.id - a.id;
          })
        );
        setCargando(false);
      })
      .catch((error) => {
        setCargando(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  const columns = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "Solicitud",
      flex: 1,
      valueGetter: (params) => {
        return "SOLICITUD-" + params.row.id;
      },
    },
    {
      field: "actividades",
      headerClassName: "super-app-theme--header",
      headerName: "Actividades",
      flex: 1,
      valueGetter: (params) => {
        return params.row.actividades
          ? [
              ...params.row.actividades.split(";").map((val) => {
                return "ACTIVIDAD-" + val;
              }),
            ].join(", ")
          : "";
      },
    },
    {
      field: "nombres_cliente",
      headerClassName: "super-app-theme--header",
      headerName: "Cliente",
      flex: 1.5,
      valueGetter: (params) => {
        return params.row.nombres_cliente
          ? params.row.nombres_cliente + " - " + params.row.cedula
          : "";
      },
    },
    {
      field: "tipo",
      headerClassName: "super-app-theme--header",
      headerName: "Tipo",
      flex: 1,
    },
    {
      field: "estado",
      headerClassName: "super-app-theme--header",
      headerName: "Estado",
      flex: 1,
    },

    {
      field: "descripcion",
      headerClassName: "super-app-theme--header",
      headerName: "Motivo Rechazo",
      flex: 1,
      valueGetter: (params) => {
        return params.row.estado === "RECHAZADO" ? params.row.descripcion : "";
      },
    },
    {
      field: "usuario_solicitud",
      headerClassName: "super-app-theme--header",
      headerName: "Creación",
      flex: 1,
      valueGetter: (params) => {
        return params.row.creado_por ?? "";
      },
    },
    {
      field: "usuario_revision",
      headerClassName: "super-app-theme--header",
      headerName: "Revisión",
      flex: 1,
      valueGetter: (params) => {
        return params.row.revisado_por ?? "";
      },
    },
    {
      field: "usuario_agendo",
      headerClassName: "super-app-theme--header",
      headerName: "Agendamiento",
      flex: 1,
      valueGetter: (params) => {
        return params.row.agendado_por ?? "";
      },
    },
    {
      field: "fecha",
      headerClassName: "super-app-theme--header",
      headerName: "Fecha",
      flex: 1,
      valueGetter: (params) => {
        return (
          moment(
            new Date(params.row.created_at).getTime() - 5 * 60 * 60 * 1000
          ).format("DD/MMMM/yyyy HH:mm") ?? ""
        );
      },
    },
    {
      field: "1",
      headerClassName: "super-app-theme--header",
      headerName: "Acciones",
      width: 160,
      renderCell: (params) => (
        <Stack direction={"row"} spacing={2}>
          <IconButton onClick={() => handleOpen(params.row)}>
            <VisibilityIcon color="info" />
          </IconButton>
          {validarRoles("EDITAR SOLICITUDES", userInfo) && (
            <IconButton onClick={() => handleOpen3(params.row)}>
              <EditIcon color="warning" />
            </IconButton>
          )}
          {validarRoles("ELIMINAR SOLICITUDES", userInfo) && (
            <IconButton onClick={() => handleOpen2(params.row)}>
              <DeleteIcon color="error" />
            </IconButton>
          )}
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Box px={2} py={2}>
        <Paper elevation={2} sx={{ padding: "10px" }}>
          <Typography variant="h5">
            {" "}
            <strong>Solicitudes</strong>{" "}
          </Typography>
          <br />
          <FiltroFechas metodo={cargar2} value={value} setValue={setValue} />

          {validarRoles("CREAR SOLICITUDES", userInfo) && (
            <Button
              color="success"
              variant="contained"
              onClick={() => setOpen(true)}
            >
              Agregar
            </Button>
          )}
          <Stack direction={"row"} spacing={2} pt={2}>
            <Typography>
              {" "}
              <strong>ESTADO:</strong>{" "}
            </Typography>
            <Select
              size="small"
              MenuProps={MenuProps}
              value={filtro}
              onChange={(e) => {
                setFiltro(e.target.value);
                cargar(e.target.value, null, null);
              }}
            >
              <MenuItem key={0} value={"TODOS"}>
                {" "}
                {"TODOS"}
              </MenuItem>
              <MenuItem key={1} value={"PENDIENTE"}>
                {" "}
                {"PENDIENTE"}
              </MenuItem>
              <MenuItem key={2} value={"APROBADO"}>
                {" "}
                {"APROBADO"}
              </MenuItem>
              <MenuItem key={3} value={"RECHAZADO"}>
                {" "}
                {"RECHAZADO"}
              </MenuItem>
              <MenuItem key={4} value={"TERMINADO"}>
                {" "}
                {"TERMINADO"}
              </MenuItem>
            </Select>
          </Stack>
          <br />
          <Paper elevation={2} sx={{ overflowX: "scroll", padding: "10px" }}>
            <Box
              sx={{
                height: 600,
                minWidth: 1000,
                "& .super-app-theme--header": {
                  backgroundColor: "#428bd4",
                  color: "white",
                  fontSize: "16px",
                },
              }}
            >
              <DataGrid
                disableColumnMenu
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
                rows={lista ?? []}
                loading={lista === null}
                columns={columns}
                getRowId={(row) => row.id}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                slots={{ toolbar: CustomToolbar }}
              />
            </Box>
          </Paper>
        </Paper>
      </Box>

      <Dialog
        open={open || edit}
        onClose={() => {
          setOpen(false);
          setEdit(false);
          limpiar();
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          {" "}
          <strong>{id !== "" ? "Ver " : "Crear "} Solicitud</strong>{" "}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Tipo Solicitud *</strong>{" "}
                </Typography>
                <Select
                  fullWidth
                  size="small"
                  disabled={id !== ""}
                  MenuProps={MenuProps}
                  value={tipo}
                  onChange={(e) => {
                    setTipo(e.target.value);
                  }}
                >
                  <MenuItem key={1} value={"INSTALACION"}>
                    {" "}
                    {"INSTALACION"}{" "}
                  </MenuItem>
                  <MenuItem key={2} value={"CAMBIO DOMICILIO"}>
                    {" "}
                    {"CAMBIO DOMICILIO"}{" "}
                  </MenuItem>
                  <MenuItem key={3} value={"VISITA TECNICA"}>
                    {" "}
                    {"VISITA TECNICA"}{" "}
                  </MenuItem>
                  <MenuItem key={4} value={"MIGRACION"}>
                    {" "}
                    {"MIGRACION"}{" "}
                  </MenuItem>
                  <MenuItem key={5} value={"RETIRO"}>
                    {" "}
                    {"RETIRO"}{" "}
                  </MenuItem>
                  <MenuItem key={6} value={"CAMBIO DE TITULAR"}>
                    {" "}
                    {"CAMBIO DE TITULAR"}{" "}
                  </MenuItem>
                  <MenuItem key={7} value={"OTRO"}>
                    {" "}
                    {"OTRO"}{" "}
                  </MenuItem>
                </Select>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Identificación Cliente *</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  disabled={cliente !== "" || id !== ""}
                  value={cedula}
                  onChange={(e) => {
                    setCedula(e.target.value);
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={2}>
              <Box px={2}>
                <br />
                <Button
                  color="info"
                  disabled={cedula.length < 8 || id !== "" || cliente !== ""}
                  variant="contained"
                  onClick={() => cargarClientes(cedula)}
                >
                  BUSCAR
                </Button>
              </Box>
            </Grid>
            {tipo === "INSTALACION" && (
              <>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Nombre Completo *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Dirección *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={direccion}
                      onChange={(e) => setDireccion(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Latitud *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={latitud}
                      onChange={(e) => setLatitud(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Longitud *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={longitud}
                      onChange={(e) => setLongitud(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Teléfono *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={telefono}
                      onChange={(e) => setTelefono(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Celular *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== ""}
                      size="small"
                      value={celular}
                      onChange={(e) => setCelular(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Correo </strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={correo}
                      onChange={(e) => setCorreo(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Plan *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={plan}
                      onChange={(e) => setPlan(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Horarios disponibles *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      disabled={id !== "" && !edit}
                      multiline
                      rows={3}
                      value={horarios}
                      onChange={(e) => setHorarios(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Observación</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== "" && !edit}
                      size="small"
                      multiline
                      rows={3}
                      value={observacion}
                      onChange={(e) => setObservacion(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Casas #Pisos *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== ""}
                      size="small"
                      value={pisos}
                      onChange={(e) => setPisos(e.target.value)}
                    />
                  </Box>
                </Grid>
                {id === "" && (
                  <Grid item xs={12} lg={6}>
                    <Box px={2}>
                      <br />
                      <Button
                        color="success"
                        variant="contained"
                        disabled={
                          cedula === "" ||
                          tipo === "" ||
                          nombre === "" ||
                          latitud === "" ||
                          longitud === "" ||
                          direccion === "" ||
                          celular === "" ||
                          telefono === "" ||
                          // correo === "" ||
                          plan === "" ||
                          pisos === "" ||
                          // imagen === null ||
                          horarios === ""
                        }
                        onClick={() => {
                          crear();
                        }}
                      >
                        Guardar Instalación
                      </Button>
                    </Box>
                  </Grid>
                )}
                {id !== "" && edit && (
                  <Grid item xs={12} lg={6}>
                    <Box px={2}>
                      <br />
                      <Button
                        color="warning"
                        disabled={horarios === ""}
                        variant="contained"
                        onClick={() => {
                          editar();
                        }}
                      >
                        Actualizar
                      </Button>
                    </Box>
                  </Grid>
                )}
              </>
            )}
            {tipo === "CAMBIO DOMICILIO" && (
              <>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Nombre Completo *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== ""}
                      size="small"
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Cliente tiene Equipos*</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== ""}
                      size="small"
                      value={equipos}
                      onChange={(e) => setEquipos(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Dirección Nueva*</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={direccion}
                      onChange={(e) => setDireccion(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Dirección Anterior*</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== ""}
                      size="small"
                      value={direccionAnterior}
                      onChange={(e) => setDireccionAnterior(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Latitud Nueva*</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={latitud}
                      onChange={(e) => setLatitud(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Longitud Nueva*</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={longitud}
                      onChange={(e) => setLongitud(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Latitud Anterior*</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={latitudAnterior}
                      onChange={(e) => setLatitudAnterior(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Longitud Anterior*</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== ""}
                      size="small"
                      value={longitudAnterior}
                      onChange={(e) => setLongitudAnterior(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Teléfono *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={telefono}
                      onChange={(e) => setTelefono(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Celular *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={celular}
                      onChange={(e) => setCelular(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Horarios disponibles *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== "" && !edit}
                      size="small"
                      multiline
                      rows={3}
                      value={horarios}
                      onChange={(e) => setHorarios(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Observación</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      multiline
                      disabled={id !== "" && !edit}
                      rows={3}
                      value={observacion}
                      onChange={(e) => setObservacion(e.target.value)}
                    />
                  </Box>
                </Grid>
                {id === "" && (
                  <Grid item xs={12} lg={6}>
                    <Box px={2}>
                      <br />
                      <Button
                        color="success"
                        variant="contained"
                        disabled={
                          cedula === "" ||
                          tipo === "" ||
                          nombre === "" ||
                          latitud === "" ||
                          longitud === "" ||
                          direccion === "" ||
                          celular === "" ||
                          telefono === "" ||
                          latitudAnterior === "" ||
                          longitudAnterior === "" ||
                          direccionAnterior === "" ||
                          // imagen === null ||
                          equipos === "" ||
                          horarios === ""
                        }
                        onClick={() => {
                          crear();
                        }}
                      >
                        Guardar Cambio Domicilio
                      </Button>
                    </Box>
                  </Grid>
                )}
                {id !== "" && edit && (
                  <Grid item xs={12} lg={6}>
                    <Box px={2}>
                      <br />
                      <Button
                        color="warning"
                        variant="contained"
                        disabled={horarios === ""}
                        onClick={() => {
                          editar();
                        }}
                      >
                        Actualizar
                      </Button>
                    </Box>
                  </Grid>
                )}
              </>
            )}
            {tipo === "MIGRACION" && (
              <>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Nombre Completo *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== ""}
                      size="small"
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Dirección *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={direccion}
                      onChange={(e) => setDireccion(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Latitud *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={latitud}
                      onChange={(e) => setLatitud(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Longitud *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== ""}
                      size="small"
                      value={longitud}
                      onChange={(e) => setLongitud(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Teléfono *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={telefono}
                      onChange={(e) => setTelefono(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Celular *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== ""}
                      size="small"
                      value={celular}
                      onChange={(e) => setCelular(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Horarios disponibles *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== "" && !edit}
                      fullWidth
                      size="small"
                      multiline
                      rows={3}
                      value={horarios}
                      onChange={(e) => setHorarios(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Observación</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== "" && !edit}
                      size="small"
                      multiline
                      rows={3}
                      value={observacion}
                      onChange={(e) => setObservacion(e.target.value)}
                    />
                  </Box>
                </Grid>
                {id === "" && (
                  <Grid item xs={12} lg={6}>
                    <Box px={2}>
                      <br />
                      <Button
                        color="success"
                        variant="contained"
                        disabled={
                          cedula === "" ||
                          tipo === "" ||
                          nombre === "" ||
                          latitud === "" ||
                          longitud === "" ||
                          direccion === "" ||
                          celular === "" ||
                          telefono === "" ||
                          horarios === ""
                        }
                        onClick={() => crear()}
                      >
                        Guardar Migración
                      </Button>
                    </Box>
                  </Grid>
                )}
                {id !== "" && edit && (
                  <Grid item xs={12} lg={6}>
                    <Box px={2}>
                      <br />
                      <Button
                        color="warning"
                        variant="contained"
                        disabled={horarios === ""}
                        onClick={() => {
                          editar();
                        }}
                      >
                        Actualizar
                      </Button>
                    </Box>
                  </Grid>
                )}
              </>
            )}
            {tipo === "VISITA TECNICA" && (
              <>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Nombre Completo *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Dirección *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={direccion}
                      onChange={(e) => setDireccion(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Latitud *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={latitud}
                      onChange={(e) => setLatitud(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Longitud *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={longitud}
                      onChange={(e) => setLongitud(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Teléfono *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== ""}
                      size="small"
                      value={telefono}
                      onChange={(e) => setTelefono(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Celular *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={celular}
                      onChange={(e) => setCelular(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Señal o Potencia *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={potencia}
                      onChange={(e) => setPotencia(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Tecnología *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={tecnologia}
                      onChange={(e) => setTecnologia(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Gestión Operador *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== ""}
                      size="small"
                      multiline
                      rows={3}
                      value={gestion}
                      onChange={(e) => setGestion(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Observación</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== "" && !edit}
                      size="small"
                      multiline
                      rows={3}
                      value={observacion}
                      onChange={(e) => setObservacion(e.target.value)}
                    />
                  </Box>
                </Grid>
                {id === "" && (
                  <Grid item xs={12} lg={6}>
                    <Box px={2}>
                      <br />
                      <Button
                        color="success"
                        variant="contained"
                        disabled={
                          cedula === "" ||
                          tipo === "" ||
                          nombre === "" ||
                          latitud === "" ||
                          longitud === "" ||
                          direccion === "" ||
                          celular === "" ||
                          telefono === "" ||
                          tecnologia === "" ||
                          gestion === "" ||
                          potencia === ""
                        }
                        onClick={() => crear()}
                      >
                        Guardar Visita Técnica
                      </Button>
                    </Box>
                  </Grid>
                )}
                {id !== "" && edit && (
                  <Grid item xs={12} lg={6}>
                    <Box px={2}>
                      <br />
                      <Button
                        color="warning"
                        variant="contained"
                        onClick={() => {
                          editar();
                        }}
                      >
                        Actualizar
                      </Button>
                    </Box>
                  </Grid>
                )}
              </>
            )}
            {tipo === "RETIRO" && (
              <>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Nombre Completo *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== ""}
                      size="small"
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Dirección *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== ""}
                      size="small"
                      value={direccion}
                      onChange={(e) => setDireccion(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Latitud *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== ""}
                      size="small"
                      value={latitud}
                      onChange={(e) => setLatitud(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Longitud *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={longitud}
                      onChange={(e) => setLongitud(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Teléfono *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== ""}
                      size="small"
                      value={telefono}
                      onChange={(e) => setTelefono(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Celular *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={celular}
                      onChange={(e) => setCelular(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Gestión Operador *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== ""}
                      size="small"
                      multiline
                      rows={3}
                      value={gestion}
                      onChange={(e) => setGestion(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Observación</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== "" && !edit}
                      size="small"
                      multiline
                      rows={3}
                      value={observacion}
                      onChange={(e) => setObservacion(e.target.value)}
                    />
                  </Box>
                </Grid>
                {id === "" && (
                  <Grid item xs={12} lg={6}>
                    <Box px={2}>
                      <br />
                      <Button
                        color="success"
                        variant="contained"
                        disabled={
                          cedula === "" ||
                          tipo === "" ||
                          nombre === "" ||
                          latitud === "" ||
                          longitud === "" ||
                          direccion === "" ||
                          celular === "" ||
                          telefono === "" ||
                          observacion === "" ||
                          gestion === ""
                        }
                        onClick={() => crear()}
                      >
                        Guardar Retiro
                      </Button>
                    </Box>
                  </Grid>
                )}
                {id !== "" && edit && (
                  <Grid item xs={12} lg={6}>
                    <Box px={2}>
                      <br />
                      <Button
                        color="warning"
                        disabled={observacion === ""}
                        variant="contained"
                        onClick={() => {
                          editar();
                        }}
                      >
                        Actualizar
                      </Button>
                    </Box>
                  </Grid>
                )}
              </>
            )}
            {tipo === "CAMBIO DE TITULAR" && (
              <>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Nombre Completo *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Dirección *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={direccion}
                      onChange={(e) => setDireccion(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Latitud *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={latitud}
                      onChange={(e) => setLatitud(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Longitud *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={longitud}
                      onChange={(e) => setLongitud(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Teléfono *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={telefono}
                      onChange={(e) => setTelefono(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Celular *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== ""}
                      fullWidth
                      size="small"
                      value={celular}
                      onChange={(e) => setCelular(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Gestión Operador *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== ""}
                      size="small"
                      multiline
                      rows={3}
                      value={gestion}
                      onChange={(e) => setGestion(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Observación</strong>{" "}
                    </Typography>
                    <TextField
                      disabled={id !== "" && !edit}
                      fullWidth
                      size="small"
                      multiline
                      rows={3}
                      value={observacion}
                      onChange={(e) => setObservacion(e.target.value)}
                    />
                  </Box>
                </Grid>
                {id === "" && (
                  <Grid item xs={12} lg={6}>
                    <Box px={2}>
                      <br />
                      <Button
                        color="success"
                        variant="contained"
                        disabled={
                          cedula === "" ||
                          tipo === "" ||
                          nombre === "" ||
                          latitud === "" ||
                          longitud === "" ||
                          direccion === "" ||
                          celular === "" ||
                          telefono === "" ||
                          observacion === "" ||
                          gestion === ""
                        }
                        onClick={() => crear()}
                      >
                        Guardar Cambio de Titular
                      </Button>
                    </Box>
                  </Grid>
                )}
                {id !== "" && edit && (
                  <Grid item xs={12} lg={6}>
                    <Box px={2}>
                      <br />
                      <Button
                        color="warning"
                        variant="contained"
                        disabled={observacion === ""}
                        onClick={() => {
                          editar();
                        }}
                      >
                        Actualizar
                      </Button>
                    </Box>
                  </Grid>
                )}
              </>
            )}
            {tipo === "OTRO" && (
              <>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Nombre Completo *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== ""}
                      size="small"
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Dirección *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== ""}
                      size="small"
                      value={direccion}
                      onChange={(e) => setDireccion(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Latitud *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== ""}
                      size="small"
                      value={latitud}
                      onChange={(e) => setLatitud(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Longitud *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      disabled={id !== ""}
                      value={longitud}
                      onChange={(e) => setLongitud(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Teléfono *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== ""}
                      size="small"
                      value={telefono}
                      onChange={(e) => setTelefono(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Celular *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== ""}
                      size="small"
                      value={celular}
                      onChange={(e) => setCelular(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Gestión Operador *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== ""}
                      size="small"
                      multiline
                      rows={3}
                      value={gestion}
                      onChange={(e) => setGestion(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Observación</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled={id !== "" && !edit}
                      size="small"
                      multiline
                      rows={3}
                      value={observacion}
                      onChange={(e) => setObservacion(e.target.value)}
                    />
                  </Box>
                </Grid>
                {id === "" && (
                  <Grid item xs={12} lg={6}>
                    <Box px={2}>
                      <br />
                      <Button
                        color="success"
                        variant="contained"
                        disabled={
                          cedula === "" ||
                          tipo === "" ||
                          nombre === "" ||
                          latitud === "" ||
                          longitud === "" ||
                          direccion === "" ||
                          celular === "" ||
                          telefono === "" ||
                          observacion === "" ||
                          gestion === ""
                        }
                        onClick={() => crear()}
                      >
                        Guardar Otro
                      </Button>
                    </Box>
                  </Grid>
                )}
                {id !== "" && edit && (
                  <Grid item xs={12} lg={6}>
                    <Box px={2}>
                      <br />
                      <Button
                        color="warning"
                        variant="contained"
                        disabled={observacion === ""}
                        onClick={() => {
                          editar();
                        }}
                      >
                        Actualizar
                      </Button>
                    </Box>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen(false);
              setEdit(false);
              limpiar();
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open2}>
        <DialogTitle>
          <Stack
            spacing={2}
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            ¿ESTÁ SEGURO DE ELIMINAR?
          </Stack>
        </DialogTitle>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              eliminar();
            }}
          >
            Borrar
          </Button>
          <Button
            color="info"
            variant="contained"
            onClick={() => {
              setOpen2(false);
              limpiar();
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Cargando open2={cargando} />
    </>
  );
};
