import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Paper } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useState, useEffect } from "react";
import logo from "../../assets/logo.png";
import fondo from "../../assets/fondo.jpg";
import { useSelector } from "react-redux";
import { CargandoBack } from "../../components/utils/Cargando";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebaseConfig";

export const Login = () => {
  const navigate = useNavigate();

  const userAuth = useSelector((state) => state.userAuth.userInfo);
  useEffect(() => {
    if (userAuth?.correo) {
      navigate("/");
    }
  }, [userAuth, navigate]);

  function Copyright(props) {
    return (
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {"Copyright © "}
        <Link color="inherit">Terranet</Link> {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

  const defaultTheme = createTheme();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [load, setLoad] = useState(false);

  const handleSubmit = async (e) => {
    try {
      setLoad(true);
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: "Las credenciales no pertenecen a ningún usuario",
      });
    }
    setLoad(false);
  };

  return (
    <div>
      <ThemeProvider theme={defaultTheme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: `url(${fondo})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Login
              </Typography>
              <br />
              <img src={logo} alt="logo" width={300} />
              <form>
                <Box sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    fullWidth
                    label="Usuario"
                    id="user"
                    name="user"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    autoComplete="user"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <TextField
                    margin="normal"
                    fullWidth
                    label="Contraseña"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="password"
                    id="password"
                    name="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />

                  <Button
                    fullWidth
                    disabled={
                      email.trim().length === 0 || password.trim().length === 0
                    }
                    onClick={() => handleSubmit()}
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Ingresar
                  </Button>
                  <Copyright sx={{ mt: 5 }} />
                </Box>
              </form>
            </Box>
          </Grid>
          <CargandoBack open={load} />
        </Grid>
      </ThemeProvider>
      {/* )} */}
    </div>
  );
};
