import {
    Collapse,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
  } from "@mui/material";
  import React, { useState } from "react";
  import { Link } from "react-router-dom";
  import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';  
  import { ExpandLess, ExpandMore } from "@mui/icons-material";
  import { validarRoles } from "../../components/utils/ValidarRoles";
  
  export const ListVehiculo = ({ info }) => {
    const [open, setOpen] = useState(false);
    const handleClick = () => {
      setOpen(!open);
    };
  
    return (
      <>
        <ListItemButton component={Link} onClick={handleClick}>
          <ListItemIcon>
            <DirectionsCarIcon />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ fontWeight: "bold" }}
            primary="Vehículo"
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding dense>
            {(validarRoles("LEER VEHICULOS CUADRILLA", info) || validarRoles("LEER VEHICULOS PARTICULARES", info)) && (
              <ListItemButton
                component={Link}
                to={"vehiculo/vehiculo"}
                sx={{ pl: 8 }}
              >
                <ListItemText primary="Vehículos" />
              </ListItemButton>
            )}
            {validarRoles("LEER REVISIONES", info) && (
              <ListItemButton
                component={Link}
                to={"vehiculo/revision"}
                sx={{ pl: 8 }}
              >
                <ListItemText primary="Revisiones" />
              </ListItemButton>
            )}
  
            {validarRoles("LEER TIPO REVISION", info) && (
              <ListItemButton
                component={Link}
                to={"vehiculo/tipo"}
                sx={{ pl: 8 }}
              >
                <ListItemText primary="Tipo Revisión" />
              </ListItemButton>
            )}
          </List>
        </Collapse>
      </>
    );
  };
  