import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  TextField,
  Stack,
  Typography,
  Autocomplete,
  Tab,
  DialogContent,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { CustomToolbar } from "../../components/utils/CustomFilter";
import { useSelector } from "react-redux";
import { validarRoles } from "../../components/utils/ValidarRoles";
import { Cargando } from "../../components/utils/Cargando";
import moment from "moment";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate, useParams } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { FiltroFechas } from "../../components/utils/FiltroFechas";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { Divider } from "rsuite";

export const VehiculoDetalle = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [cargando, setCargando] = useState(false);

  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;

  const [descripcion, setDescripcion] = useState("");
  const [placa, setPlaca] = useState("");
  const [modelo, setModelo] = useState("");
  const [nombre, setNombre] = useState("");
  const [km, setKm] = useState("");
  const [cuadrilla, setCuadrilla] = useState(null);

  const [cuadrillas, setCuadrillas] = useState([]);

  const [open2, setOpen2] = useState(false);
  const [open, setOpen] = useState(false);

  const [lista, setLista] = useState(null);
  const [alertas, setAlertas] = useState(null);

  const [value, setValue] = useState([
    startOfDay(subDays(new Date(), 6)),
    endOfDay(new Date()),
  ]);

  const [detalle, setDetalle] = useState({});

  useEffect(() => {
    cargar();
  }, []);

  function limpiar() {
    setModelo("");
    setNombre("");
    setCuadrilla(null);
    setPlaca("");
    setDescripcion("");
    setKm("");
    setDetalle({});
  }

  function handleOpen(row) {
    setDetalle(row);
    setOpen(true);
  }

  const columns = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      flex: 1,
      valueGetter: (params) => {
        return "REVISION-" + params.row.id ?? "";
      },
    },
    {
      field: "vehiculo",
      headerClassName: "super-app-theme--header",
      headerName: "Vehículo",
      flex: 1,
      valueGetter: (params) => {
        return params.row.vehiculo?.nombre ?? "";
      },
    },
    {
      field: "km",
      headerClassName: "super-app-theme--header",
      headerName: "Km",
      flex: 0.5,
    },
    {
      field: "tipo_revision",
      headerClassName: "super-app-theme--header",
      headerName: "Tipo",
      flex: 1,
      valueGetter: (params) => {
        return params.row.tipo_revision?.tipo;
      },
    },
    {
      field: "usuario",
      headerClassName: "super-app-theme--header",
      headerName: "Usuario",
      flex: 1,
      valueGetter: (params) => {
        return params.row.usuario?.nombre ?? "";
      },
    },
    {
      field: "fecha",
      headerClassName: "super-app-theme--header",
      headerName: "Fecha",
      flex: 1,
      valueGetter: (params) => {
        return moment(params.row.created_at).format("DD/MMMM/yyyy HH:mm") ?? "";
      },
    },
    {
      field: "1",
      headerClassName: "super-app-theme--header",
      headerName: "Acciones",
      flex: 1,
      renderCell: (params) => (
        <Stack direction={"row"} spacing={2}>
          <IconButton onClick={() => handleOpen(params.row)}>
            <RemoveRedEyeIcon color="warning" />
          </IconButton>
        </Stack>
      ),
    },
  ];

  function cargar2(inicio, fin) {
    setCargando(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    let url = "https://backterra.econtel.com.ec/api/v1/revisiones/";
    if (inicio && fin) {
      url =
        "https://backterra.econtel.com.ec/api/v1/revisiones/?vehiculo=" +
        id +
        "&inicio=" +
        inicio +
        "&fin=" +
        fin;
    } else {
      url =
        "https://backterra.econtel.com.ec/api/v1/revisiones/?vehiculo=" +
        id +
        "&inicio=" +
        value[0].toISOString() +
        "&fin=" +
        value[1].toISOString();
    }
    fetch(url, requestOptions)
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setLista(
          JSON.parse(result).sort((a, b) => {
            return b.id - a.id;
          })
        );
        setCargando(false);
      })
      .catch((error) => {
        setCargando(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function editar() {
    setCargando(true);
    let body = JSON.stringify({
      modelo: modelo,
      nombre: nombre,
      id_cuadrilla: cuadrilla?.id ?? null,
      placa: placa,
      descripcion: descripcion,
      km: km,
      id_usuario: userInfo.id,
    });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/vehiculos/" + id + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        Swal.fire({ icon: "success", text: "Vehículo actualizado " });
        cargar();
      })
      .catch((error) => {
        setCargando(false);

        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function eliminar() {
    setCargando(true);

    if (id === "") {
      return;
    }
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/vehiculos/" + id + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setOpen2(false);
        Swal.fire({ icon: "success", text: "Vehículo eliminado " });
        navigate("/vehiculo/vehiculo/");
      })
      .catch((error) => {
        setOpen2(false);
        setCargando(false);

        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function cargar() {
    limpiar();
    setCargando(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/vehiculos/" + id + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        let row = JSON.parse(result);
        if (
          validarRoles("LEER VEHICULOS PARTICULARES", userInfo) &&
          row.cuadrilla === null
        ) {
          //
        } else if (
          validarRoles("LEER VEHICULOS CUADRILLA", userInfo) &&
          row.cuadrilla !== null
        ) {
          //
        } else {
          Swal.fire({
            icon: "error",
            text: "No tiene permisos para visualizar este registro",
          });
          navigate("/vehiculo/vehiculo");
          return;
        }
        setModelo(row.modelo);
        setNombre(row.nombre);
        setCuadrilla(row.cuadrilla);
        setPlaca(row.placa);
        setDescripcion(row.descripcion);
        setKm(row.km);
        setCargando(false);
      })
      .catch((error) => {
        setCargando(false);

        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });

    fetch(
      "https://backterra.econtel.com.ec/api/api/alertas?vehiculo=" + id + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setAlertas(JSON.parse(result));
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
    fetch(
      "https://backterra.econtel.com.ec/api/v1/bodega/?tipo=CUADRILLA",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setCuadrillas(JSON.parse(result));
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  const [tab, setTab] = useState("0");
  const handleChange = (event, newValue) => {
    if (newValue === "1") {
      cargar2();
    } else {
      setLista(null);
    }
    setTab(newValue);
  };

  return (
    <>
      <Box px={2} py={2}>
        <Paper elevation={2} sx={{ padding: "10px" }}>
          <Stack direction={"row"} spacing={2}>
            <Typography variant="h5">
              <strong>Vehículo ID:{id}</strong>{" "}
            </Typography>
            <Button
              size="small"
              variant="outlined"
              onClick={() => navigate("/vehiculo/vehiculo/")}
            >
              Regresar
            </Button>
          </Stack>
          <TabContext value={tab}>
            <Box sx={{ borderColor: "divider" }}>
              <TabList onChange={handleChange} variant="scrollable">
                <Tab
                  label="Información"
                  sx={{ fontWeight: "bold" }}
                  value={"0"}
                />
                <Tab
                  label="Revisiones"
                  sx={{ fontWeight: "bold" }}
                  value={"1"}
                />
              </TabList>
            </Box>

            <TabPanel value={"0"}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography align="center" variant="h6">
                    <strong>Revisiones Programadas</strong>
                  </Typography>
                </Grid>
                {alertas?.length > 0
                  ? alertas.map((val) => (
                      <Grid item xs={12} md={3} key={val.id}>
                        <Paper
                          elevation={3}
                          sx={{ padding: "10px", margin: "5px" }}
                        >
                          <Typography
                            align="center"
                            color={
                              Number(val.km_futura) > Number(km)
                                ? "orange"
                                : "red"
                            }
                          >
                            <strong>{val.tipo?.toUpperCase() ?? ""}</strong>
                          </Typography>
                          <Typography>
                            Revisión pendiente a realizar a los:{" "}
                            <strong>{val.km_futura} km</strong>
                          </Typography>
                        </Paper>
                      </Grid>
                    ))
                  : ""}
              </Grid>
              <Divider />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography align="center" variant="h6">
                    <strong>Datos del Vehículo</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Nombre *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Cuadrilla </strong>{" "}
                    </Typography>
                    <Autocomplete
                      size="small"
                      fullWidth
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      disablePortal
                      value={cuadrilla}
                      options={cuadrillas ?? []}
                      getOptionLabel={(option) => option.nombre}
                      onChange={(e, t) => {
                        //aconsole.log(t);
                        setCuadrilla(t);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Km *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                      value={km}
                      onChange={(e) => {
                        setKm(e.target.value);
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Placa</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      value={placa}
                      onChange={(e) => {
                        setPlaca(e.target.value);
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Modelo</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      value={modelo}
                      onChange={(e) => {
                        setModelo(e.target.value);
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Descripción</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      multiline
                      rows={2}
                      value={descripcion}
                      onChange={(e) => {
                        setDescripcion(e.target.value);
                      }}
                    />
                  </Box>
                </Grid>
                {(validarRoles("EDITAR VEHICULOS", userInfo) ||
                  validarRoles("ELIMINAR VEHICULOS", userInfo)) && (
                  <Grid item xs={12} lg={6}>
                    <Box px={2}>
                      <br />
                      <Stack direction={"row"} spacing={2}>
                        {validarRoles("EDITAR VEHICULOS", userInfo) && (
                          <Button
                            color="success"
                            variant="contained"
                            disabled={
                              nombre?.trim().length === 0 ||
                              km?.trim().length === 0
                            }
                            onClick={() => editar()}
                          >
                            Actualizar
                          </Button>
                        )}
                        {validarRoles("ELIMINAR VEHICULOS", userInfo) && (
                          <Button
                            color="error"
                            variant="contained"
                            onClick={() => setOpen2(true)}
                          >
                            Borrar
                          </Button>
                        )}
                      </Stack>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </TabPanel>
            <TabPanel value={"1"}>
              <FiltroFechas
                metodo={cargar2}
                value={value}
                setValue={setValue}
              />
              <br />
              <Paper
                elevation={2}
                sx={{ overflowX: "scroll", padding: "10px" }}
              >
                <Box
                  sx={{
                    height: 400,
                    minWidth: 1000,
                    "& .super-app-theme--header": {
                      backgroundColor: "#428bd4",
                      color: "white",
                      fontSize: "16px",
                    },
                  }}
                >
                  <DataGrid
                    disableColumnMenu
                    getRowHeight={() => "auto"}
                    getEstimatedRowHeight={() => 200}
                    rows={lista ?? []}
                    loading={lista === null}
                    columns={columns}
                    getRowId={(row) => row.id}
                    localeText={
                      esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    slots={{ toolbar: CustomToolbar }}
                  />
                </Box>
              </Paper>
            </TabPanel>
          </TabContext>
        </Paper>
      </Box>

      <Dialog open={open2}>
        <DialogTitle>
          <Stack
            spacing={2}
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            ¿ESTÁ SEGURO DE ELIMINAR?
          </Stack>
        </DialogTitle>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              eliminar();
            }}
          >
            Borrar
          </Button>
          <Button
            color="info"
            variant="contained"
            onClick={() => {
              setOpen2(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          {" "}
          <strong> Ver Revisión</strong>{" "}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Tipo *</strong>{" "}
                </Typography>
                <TextField
                  disabled
                  fullWidth
                  size="small"
                  value={detalle?.tipo_revision?.tipo ?? ""}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Vehículo *</strong>{" "}
                </Typography>
                <TextField
                  disabled
                  fullWidth
                  size="small"
                  value={detalle?.vehiculo?.nombre ?? ""}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Kilometraje Actual *</strong>{" "}
                </Typography>
                <TextField
                  disabled
                  fullWidth
                  size="small"
                  value={detalle?.km ?? ""}
                />
              </Box>
            </Grid>
            {detalle?.tipo_revision?.programable === "SI" && (
              <Grid item xs={12} lg={6}>
                <Box px={2}>
                  <Typography>
                    {" "}
                    <strong>Próxima revisión Km *</strong>{" "}
                  </Typography>
                  <TextField
                    disabled
                    fullWidth
                    size="small"
                    value={detalle?.km_futura ?? ""}
                  />
                </Box>
              </Grid>
            )}
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Descripción *</strong>{" "}
                </Typography>
                <TextField
                  disabled
                  multiline
                  rows={2}
                  fullWidth
                  size="small"
                  value={detalle?.descripcion ?? ""}
                />
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Stack direction={"row"} spacing={2}>
                  {detalle?.foto && (
                    <img
                      src={detalle?.foto ?? ""}
                      alt={"imagen"}
                      height={110}
                    />
                  )}
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Cargando open2={cargando} />
    </>
  );
};
