import React, { useState } from 'react'
import { Box, Menu, MenuItem, IconButton, Avatar, ListItemIcon, Typography } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es'
import { logout } from '../../redux/actions/AuthAction';
moment.locale('es')

export const MenuAccount = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch()
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
    }
    const logoutHandler = () => {
        dispatch(logout())
    }
   

    return (
        <>
            <IconButton
                onClick={handleClick}
            >
                <Avatar />
            </IconButton>
            <Menu
                id="basic-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{ marginTop: "10px" }}
                elevation={1}
            >
                <MenuItem>
                    <ListItemIcon>
                        <Avatar />
                    </ListItemIcon>
                    <Box pl={2}>
                        {userInfo &&
                            <>
                                <Typography>{userInfo.nombre}</Typography>
                                <Typography style={{ width: 200, textOverflow: 'ellipsis', overflow: 'hidden' }}> {userInfo.correo}</Typography>
                            </>}
                    </Box>
                </MenuItem>
                <MenuItem onClick={logoutHandler}>
                    <ListItemIcon>
                        <ExitToAppIcon />
                    </ListItemIcon>
                    Salir
                </MenuItem>
            </Menu>
        </>
    )
}