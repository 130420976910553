import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Grid,
  Stack,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { CustomToolbar } from "../../components/utils/CustomFilter";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import { validarRoles } from "../../components/utils/ValidarRoles";
import { useSelector } from "react-redux";
import { Cargando } from "../../components/utils/Cargando";
import EditIcon from "@mui/icons-material/Edit";
import Inventory2Icon from "@mui/icons-material/Inventory2";

export const Series = () => {
  const [cargando, setCargando] = useState(false);

  const [lista, setLista] = useState(null);
  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;
  const [open, setOpen] = useState(false);

  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [nombre, setNombre] = useState("");
  const [id, setId] = useState("");

  const [tipo, setTipo] = useState("SIMPLE");



  const [productos, setProductos] = useState([]);

  const [producto, setProducto] = useState(null);

  const [objeto, setObjeto] = useState(null);

  useEffect(() => {
    cargar();
  }, []);

  function cargar() {
    limpiar()
    setCargando(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/api/series_ult_miv/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then(async (result) => {
        //aconsole.log(JSON.parse(result));
        setLista(JSON.parse(result));
        setCargando(false);
      })
      .catch((error) => {
        setCargando(false);

        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });

      fetch(
        "https://backterra.econtel.com.ec/api/v1/producto/",
        requestOptions
      )
        .then(async (response) => {
          if (response.ok) {
            return response.text();
          }
          let mensaje = await response.json();
          throw {
            ...mensaje,
          };
        })
        .then(async (result) => {
          //aconsole.log(JSON.parse(result));
          setProductos(JSON.parse(result));
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            text:
              "Error: " +
              (typeof error === "string" ? error : JSON.stringify(error)),
          });
        });
  }

  const columns = [
    {
      field: "serie",
      headerClassName: "super-app-theme--header",
      headerName: "Serie",
      flex: 1,
    },
    {
      field: "id_actividad",
      headerClassName: "super-app-theme--header",
      headerName: "Actividad",
      flex: 1,
      valueGetter: (params) => {
        return params.row.id_actividad
          ? "ACTIVIDAD-" + params.row.id_actividad
          : "";
      },
    },
    {
      field: "nombre_producto",
      headerClassName: "super-app-theme--header",
      headerName: "Producto",
      flex: 1,
    },
    {
      field: "nombre_bodega",
      headerClassName: "super-app-theme--header",
      headerName: "Bodega",
      flex: 1,
    },
    {
      field: "nombre_proveedor",
      headerClassName: "super-app-theme--header",
      headerName: "Proveedor",
      flex: 1,
    },
    {
      field: "movimiento",
      headerClassName: "super-app-theme--header",
      headerName: "Últ. movimiento",
      flex: 1,
      valueGetter: (params) => {
        return params.row.movimiento ? "MOV-" + params.row.movimiento : "";
      },
    },
    {
      field: "stock",
      headerClassName: "super-app-theme--header",
      headerName: "Stock",
      flex: 1,
      valueGetter: (params) => {
        return params.row.tipo === "COMPUESTO" ? params.row.stock : "";
      },
    },
    {
      field: "tipo",
      headerClassName: "super-app-theme--header",
      headerName: "Estado",
      flex: 1,
    },
    {
      field: "garantia",
      headerClassName: "super-app-theme--header",
      headerName: "Garantía",
      flex: 1,
    },
    {
      field: "1",
      headerClassName: "super-app-theme--header",
      headerName: "Acciones",
      width: 150,
      renderCell: (params) => (
        <Stack direction={"row"} spacing={1}>
          {validarRoles("INACTIVAR SERIES", userInfo) && (
            <IconButton
              onClick={() => handleOpen(params.row)}
              disabled={
                params.row.estado !== "ACTIVO" || params.row.tipo === "SIMPLE"
              }
            >
              <DisabledByDefaultIcon
                color={
                  params.row.estado !== "ACTIVO" || params.row.tipo === "SIMPLE"
                    ? "gray"
                    : "error"
                }
              />
            </IconButton>
          )}
          {validarRoles("EDITAR SERIES", userInfo) && (
            <>
              <IconButton onClick={() => {
                  setId(params.row.id)
                  setNombre(params.row.serie)
                  setOpen2(true)
                }}>
                <EditIcon color={"warning"} />
              </IconButton>
              <IconButton
                onClick={() => {
                  setId(params.row.id)
                  setTipo(params.row.tipo)
                  setProducto(productos.filter((val) => val.id === params.row.producto_id)[0] ?? null)
                  setOpen3(true)
                }}
                disabled={params.row.nombre_bodega !== null}
              >
                <Inventory2Icon
                  color={params.row.nombre_bodega !== null ? "gray" : "info"}
                />
              </IconButton>
            </>
          )}
        </Stack>
      ),
    },
  ];

  function handleOpen(row) {
    setObjeto(row);
    setOpen(true);
  }

  function limpiar() {
    setOpen(false);
    setTipo("SIMPLE")
    setOpen2(false);
    setOpen3(false);
    setObjeto(null);
    setNombre("");
    setProducto(null)
    setId("");
  }

  function editar() {
    setCargando(true);
    let body = JSON.stringify({
      serie: nombre,
    });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/serie2/" + id + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setOpen2(false);
        Swal.fire({ icon: "success", text: "Serie actualizada " });
        cargar();
      })
      .catch((error) => {
        setOpen2(false);
        setCargando(false);

        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }


  function nuevoProducto() {
    setCargando(true);
    let body = JSON.stringify({
       producto: producto?.id ?? null,
    });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/serie2/" + id + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setOpen3(false);
        Swal.fire({ icon: "success", text: "Serie actualizada " });
        cargar();
      })
      .catch((error) => {
        setOpen3(false);
        setCargando(false);

        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  async function inactivar() {
    setCargando(true);

    let body = JSON.stringify({
      estado: "INACTIVO",
      bodega: null,
    });

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/serie/" +
        objeto.id +
        "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        limpiar();
        Swal.fire({ icon: "success", text: "Serie Inactivada " });
        cargar();
      })
      .catch((error) => {
        setCargando(false);

        limpiar();
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  return (
    <Box px={2} py={2}>
      <Paper elevation={2} sx={{ padding: "10px" }}>
        <Typography variant="h5">
          {" "}
          <strong>Series</strong>{" "}
        </Typography>
        <br />
        <br />
        <Paper elevation={2} sx={{ overflowX: "scroll", padding: "10px" }}>
          <Box
            sx={{
              height: 600,
              minWidth: 1000,
              "& .super-app-theme--header": {
                backgroundColor: "#428bd4",
                color: "white",
                fontSize: "16px",
              },
            }}
          >
            <DataGrid
              disableColumnMenu
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}
              rows={lista ?? []}
              loading={lista === null}
              columns={
                validarRoles("INACTIVAR SERIES", userInfo) ||
                validarRoles("EDITAR SERIES", userInfo)
                  ? columns
                  : columns.filter((val) => val.field !== "1")
              }
              getRowId={(row) => row.id}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              slots={{ toolbar: CustomToolbar }}
            />
          </Box>
        </Paper>
      </Paper>

      <Dialog open={open}>
        <DialogTitle>
          <Stack
            spacing={2}
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            ¿ESTÁ SEGURO DE INACTIVAR?
          </Stack>
        </DialogTitle>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            disabled={objeto?.id === undefined}
            onClick={() => {
              inactivar();
            }}
          >
            Inactivar
          </Button>
          <Button
            color="info"
            variant="contained"
            onClick={() => {
              setOpen(false);
              limpiar();
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open2}
        onClose={() => {
          setOpen2(false);
          limpiar();
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>Editar Serie</strong>{" "}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Nombre *</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                />
              </Box>
              <br/>
            </Grid>
            <Grid item xs={12}>
              <Box px={2}>
                <br />
                <Button
                  color="success"
                  variant="contained"
                  disabled={nombre?.trim().length === 0}
                  onClick={() => editar()}
                >
                  Guardar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen2(false);
              limpiar();
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={open3}
        onClose={() => {
          setOpen3(false);
          limpiar();
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>Editar Serie</strong>{" "}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Producto *</strong>{" "}
                </Typography>
                <Autocomplete
                  ListboxProps={{ style: { maxHeight: 150 } }}
                  size="small"
                  fullWidth
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disablePortal
                  value={producto}
                  options={productos ? productos.filter((val)=> val.tipo2 === tipo) : []}
                  getOptionLabel={(option) => option.nombre}
                  onChange={(e, t) => {
                    setProducto(t);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <br/>
              <br/>
            </Grid>
            <Grid item xs={12}>
              <Box px={2}>
                <br />
                <Button
                  color="success"
                  variant="contained"
                  disabled={producto === null}
                  onClick={() => nuevoProducto()}
                >
                  Guardar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen3(false);
              limpiar();
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Cargando open2={cargando} />
    </Box>
  );
};
