import React, { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Typography } from "@mui/material";
import { Bodega } from "./Bodega";
import { Tecnico } from "./Tecnico";
import { Cuadrilla } from "./Cuadrilla";

export const BodegaPage = () => {
  const [tab, setTab] = useState("0");
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
        <br/>
       <Box px={2}>
       <Typography variant="h5">
          {" "}
          <strong>Bodega</strong>{" "}
        </Typography>
        <TabContext value={tab}>
          <Box sx={{ borderColor: "divider" }}>
            <TabList onChange={handleChange} variant="scrollable">
              <Tab label="Bodega/Nodo" sx={{ fontWeight: "bold" }} value={"0"} />
              <Tab label="Técnico" sx={{ fontWeight: "bold" }} value={"1"} />
              <Tab label="Cuadrilla" sx={{ fontWeight: "bold" }} value={"2"} />
            </TabList>
          </Box>

          <TabPanel value={"0"}>
            <Bodega />
          </TabPanel>
          <TabPanel value={"1"}>
            <Tecnico />
          </TabPanel>
          <TabPanel value={"2"}>
            <Cuadrilla />
          </TabPanel>
        </TabContext>{" "}
       </Box> 
    </>
  );
};
