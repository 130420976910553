import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { CustomToolbar } from "../../../components/utils/CustomFilter";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import { MenuProps } from "../../../components/utils/MenuProps";
import { useSelector } from "react-redux";
import { validarRoles } from "../../../components/utils/ValidarRoles";
import { Cargando } from "../../../components/utils/Cargando";

export const Bodega = () => {
  const [cargando, setCargando] = useState(false);
  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [codigo, setCodigo] = useState("");
  const [nombre, setNombre] = useState("");
  const [ubicacion, setUbicacion] = useState("");
  const [id, setId] = useState("");
  const [tipo, setTipo] = useState("");
  const [responsable, setResponsable] = useState([]);

  const [usuarios, setUsuarios] = useState([]);

  const [lista, setLista] = useState(null);

  useEffect(() => {
    cargar();
  }, []);

  function limpiar() {
    setCodigo("");
    setNombre("");
    setUbicacion("");
    setResponsable([]);
    setId("");
    setTipo("");
  }
  function handleOpen(row) {
    setCodigo(row.codigo);
    setNombre(row.nombre);
    setUbicacion(row.ubicacion);
    setResponsable([
      ...row.personas_encargadas.map((val) => {
        return usuarios.filter((val2) => val2.id === val)[0];
      }),
    ]);
    setId(row.id);
    setTipo(row.tipo);
    setOpen(true);
  }

  function handleOpen2(row) {
    setId(row.id);
    setOpen2(true);
  }

  function crear() {
    setCargando(true);
    let body = JSON.stringify({
      codigo: codigo,
      nombre: nombre,
      ubicacion: ubicacion,
      personas_encargadas: [
        ...responsable.map((val) => {
          return val.id;
        }),
      ],
      tipo: tipo,
    });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: body,
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/bodega/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setOpen(false);
        Swal.fire({ icon: "success", text: "Bodega agregada " });
        limpiar();
        cargar();
      })
      .catch((error) => {
        setCargando(false);
        setOpen(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function editar() {
    setCargando(true);

    if (id === "") {
      crear();
      return;
    }
    let body = JSON.stringify({
      codigo: codigo,
      nombre: nombre,
      ubicacion: ubicacion,
      personas_encargadas: [
        ...responsable.map((val) => {
          return val.id;
        }),
      ],
      tipo: tipo,
    });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/bodega/" + id + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(result);
        setOpen(false);
        Swal.fire({ icon: "success", text: "Bodega actualizada " });
        limpiar();
        cargar();
      })
      .catch((error) => {
        setOpen(false);
        setCargando(false);

        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function eliminar() {
    setCargando(true);

    if (id === "") {
      return;
    }
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/bodega/" + id + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setOpen2(false);
        Swal.fire({ icon: "success", text: "Bodega eliminada " });
        limpiar();
        cargar();
      })
      .catch((error) => {
        limpiar();
        setOpen2(false);
        setCargando(false);

        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function cargar() {
    setCargando(true);

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/bodega/?tipo=BODEGA",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        let lista1 = JSON.parse(result);
        fetch(
          "https://backterra.econtel.com.ec/api/v1/bodega/?tipo=NODO",
          requestOptions
        )
          .then(async (response) => {
            if (response.ok) {
              return response.text();
            }
            let mensaje = await response.json();
            throw {
              ...mensaje,
            };
          })
          .then((result) => {
            //aconsole.log(JSON.parse(result));
            setLista(lista1.concat(JSON.parse(result)));
            setCargando(false);
          })
          .catch((error) => {
            setCargando(false);

            Swal.fire({
              icon: "error",
              text:
                "Error: " +
                (typeof error === "string" ? error : JSON.stringify(error)),
            });
          });
      })
      .catch((error) => {
        setCargando(false);

        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });

    fetch(
      "https://backterra.econtel.com.ec/api/v1/usuario/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setUsuarios(JSON.parse(result));
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }
  const columns = [
    {
      field: "codigo",
      headerClassName: "super-app-theme--header",
      headerName: "Código",
      flex: 1,
    },
    {
      field: "nombre",
      headerClassName: "super-app-theme--header",
      headerName: "Nombre",
      flex: 1,
    },
    {
      field: "ubicacion",
      headerClassName: "super-app-theme--header",
      headerName: "Ubicación",
      flex: 1,
    },
    {
      field: "tipo",
      headerClassName: "super-app-theme--header",
      headerName: "Tipo",
      flex: 1,
    },
    {
      field: "personas_encargadas",
      headerClassName: "super-app-theme--header",
      headerName: "Encargados",
      flex: 1,
      valueGetter: (params) => {
        if (usuarios.length !== 0) {
          return [
            ...params.row.personas_encargadas.map((val) => {
              return usuarios.filter((val2) => val2.id === val)[0].nombre;
            }),
          ].toString();
        } else {
          return "";
        }
      },
    },
    {
      field: "1",
      headerClassName: "super-app-theme--header",
      headerName: "Acciones",
      flex: 1,
      renderCell: (params) => (
        <Stack direction={"row"} spacing={2}>
          {validarRoles("EDITAR BODEGA", userInfo) && (
            <IconButton onClick={() => handleOpen(params.row)}>
              <EditIcon color="warning" />
            </IconButton>
          )}
          {validarRoles("ELIMINAR BODEGA", userInfo) && (
            <IconButton onClick={() => handleOpen2(params.row)}>
              <DeleteIcon color="error" />
            </IconButton>
          )}
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Box py={2}>
        <Paper elevation={2} sx={{ padding: "10px" }}>
          <Typography variant="h5">
            {" "}
            <strong>Bodegas</strong>{" "}
          </Typography>
          <br />
          {validarRoles("CREAR BODEGA", userInfo) && (
            <Button
              color="success"
              variant="contained"
              onClick={() => setOpen(true)}
            >
              Agregar
            </Button>
          )}

          <br />
          <br />
          <Paper elevation={2} sx={{ overflowX: "scroll", padding: "10px" }}>
            <Box
              sx={{
                height: 600,
                minWidth: 1000,
                "& .super-app-theme--header": {
                  backgroundColor: "#428bd4",
                  color: "white",
                  fontSize: "16px",
                },
              }}
            >
              <DataGrid  disableColumnMenu
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
                rows={lista ?? []}
                loading={lista === null}
                columns={
                  validarRoles("ELIMINAR BODEGA", userInfo) ||
                    validarRoles("EDITAR BODEGA", userInfo)
                    ? columns
                    : columns.filter((val) => val.field !== "1")
                }
                getRowId={(row) => row.id}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                slots={{ toolbar: CustomToolbar }}
              />
            </Box>
          </Paper>
        </Paper>
      </Box>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          limpiar();
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {" "}
          <strong>{id !== "" ? "Editar " : "Crear "} Bodega</strong>{" "}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Código *</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={codigo}
                  onChange={(e) => setCodigo(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Nombre *</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Ubicación *</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={ubicacion}
                  onChange={(e) => setUbicacion(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Tipo *</strong>{" "}
                </Typography>
                <Select
                  fullWidth
                  size="small"
                  value={tipo}
                  onChange={(e) => setTipo(e.target.value)}
                >
                  <MenuItem key={"BODEGA"} value="BODEGA">
                    {" "}
                    BODEGA
                  </MenuItem>
                  <MenuItem key={"NODO"} value="NODO">
                    {" "}
                    NODO
                  </MenuItem>
                </Select>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Personas Encargadas *</strong>{" "}
                </Typography>
                <Select
                  fullWidth
                  size="small"
                  multiple
                  MenuProps={MenuProps}
                  value={responsable}
                  onChange={(e) => {
                    setResponsable(e.target.value);
                  }}
                >
                  {usuarios
                    ? usuarios.map((val) => (
                        <MenuItem key={val.id} value={val}>
                          {val.nombre}
                        </MenuItem>
                      ))
                    : ""}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <br />
                <Button
                  color="success"
                  variant="contained"
                  disabled={
                    nombre.length === 0 ||
                    codigo.length === 0 ||
                    tipo.length === 0 ||
                    ubicacion.length === 0 ||
                    responsable.length === 0
                  }
                  onClick={() => editar()}
                >
                  Guardar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen(false);
              limpiar();
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open2}>
        <DialogTitle>
          <Stack
            spacing={2}
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            ¿ESTÁ SEGURO DE ELIMINAR?
          </Stack>
        </DialogTitle>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              eliminar();
            }}
          >
            Borrar
          </Button>
          <Button
            color="info"
            variant="contained"
            onClick={() => {
              setOpen2(false);
              limpiar();
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Cargando open2={cargando} />
    </>
  );
};
