import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { validarRoles } from "../../components/utils/ValidarRoles";

export const ListTecnico = ({ info }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton component={Link} onClick={handleClick}>
        <ListItemIcon>
          <EngineeringIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{ fontWeight: "bold" }}
          primary="Técnicos"
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding dense>
          {validarRoles("LEER STOCK TECNICO", info) && (
            <ListItemButton
              component={Link}
              to={"tecnico/tecnico"}
              sx={{ pl: 8 }}
            >
              <ListItemText primary="Stock Técnico" />
            </ListItemButton>
          )}

          {validarRoles("LEER STOCK CUADRILLA", info) && (
            <ListItemButton
              component={Link}
              to={"tecnico/cuadrilla"}
              sx={{ pl: 8 }}
            >
              <ListItemText primary="Stock Cuadrilla" />
            </ListItemButton>
          )}

          {validarRoles("VER RUTAS ASIGNADAS", info) && (
            <ListItemButton
              component={Link}
              to={"tecnico/rutas"}
              sx={{ pl: 8 }}
            >
              <ListItemText primary="Ruta y Actividades Asignadas" />
            </ListItemButton>
          )}
        </List>
      </Collapse>
    </>
  );
};
