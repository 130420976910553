import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  TextField,
  Stack,
  Typography,
  Autocomplete,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { CustomToolbar } from "../../components/utils/CustomFilter";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import DirectionsIcon from "@mui/icons-material/Directions";
import { useSelector } from "react-redux";
import { DateTimePicker } from "@mui/x-date-pickers";
import { validarRoles } from "../../components/utils/ValidarRoles";
import { Cargando } from "../../components/utils/Cargando";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { FiltroFechas } from "../../components/utils/FiltroFechas";

export const Actividades = () => {
  const [cargando, setCargando] = useState(false);

  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;
  const [open, setOpen] = useState(false);
  const [descripcion, setDescripcion] = useState("");
  const [solicitud, setSolicitud] = useState(null);
  const [fecha, setFecha] = useState(new Date());
  const [id, setId] = useState("");

  const [ruta, setRuta] = useState(null);

  const [lista, setLista] = useState(null);

  const [rutas, setRutas] = useState([]);
  const [solicitudes, setSolicitudes] = useState([]);

  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const [value, setValue] = useState([
    startOfDay(subDays(new Date(), 6)),
    endOfDay(new Date()),
  ]);

  useEffect(() => {
    cargar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function limpiar() {
    setDescripcion("");
    setFecha(new Date());
    setId("");
    setSolicitud(null);
    setRuta(null);
  }

  function handleOpen2(row) {
    setId(row.id);
    setOpen2(true);
    setSolicitud(row.solicitud);
  }

  function handleOpen3(row) {
    setId(row.id);
    setRuta(row.ruta ? { id: row.ruta } : null);
    setOpen3(true);
  }

  const columns = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "Actividad",
      flex: 1,
      valueGetter: (params) => {
        return "ACTIVIDAD-" + params.row.id;
      },
    },
    {
      field: "estado",
      headerClassName: "super-app-theme--header",
      headerName: "Estado",
      flex: 1,
    },
    {
      field: "fecha",
      headerClassName: "super-app-theme--header",
      headerName: "Fecha",
      flex: 1,
      valueGetter: (params) => {
        return params.row.fecha
          ? moment(params.row.fecha).format("DD/MMMM/yyyy HH:mm")
          : "";
      },
    },
    {
      field: "ruta",
      headerClassName: "super-app-theme--header",
      headerName: "Ruta",
      flex: 1,
      valueGetter: (params) => {
        return params.row.ruta ? "RUTA-" + params.row.ruta : "";
      },
    },
    {
      field: "solicitud",
      headerClassName: "super-app-theme--header",
      headerName: "Solicitud",
      flex: 1,
      valueGetter: (params) => {
        return params.row.solicitud
          ? "SOLICITUD-" + params.row.solicitud.id
          : "";
      },
    },
    {
      field: "cliente",
      headerClassName: "super-app-theme--header",
      headerName: "Cliente",
      flex: 1.5,
      valueGetter: (params) => {
        return params.row.solicitud
          ? params.row.solicitud.nombres_cliente +
              " - " +
              params.row.solicitud.cedula
          : "";
      },
    },
    {
      field: "usuario_mod",
      headerClassName: "super-app-theme--header",
      headerName: "U. Modificación",
      flex: 1,
      valueGetter: (params) => {
        return params.row.usuario_mod?.nombre ?? "";
      },
    },
    {
      field: "created_at",
      headerClassName: "super-app-theme--header",
      headerName: "Creación",
      flex: 1,
      valueGetter: (params) => {
        return params.row.created_at
          ? moment(params.row.created_at).format("DD/MMMM/yyyy HH:mm")
          : "";
      },
    },
    {
      field: "1",
      headerClassName: "super-app-theme--header",
      headerName: "Acciones",
      width: 110,
      renderCell: (params) => (
        <Stack direction={"row"} spacing={2}>
          {validarRoles("EDITAR ACTIVIDADES", userInfo) &&
            params.row.estado === "INGRESADO" && (
              <IconButton onClick={() => handleOpen3(params.row)}>
                <DirectionsIcon color="info" />
              </IconButton>
            )}
          {validarRoles("ELIMINAR ACTIVIDADES", userInfo) && (
            <IconButton onClick={() => handleOpen2(params.row)}>
              <DeleteIcon color="error" />
            </IconButton>
          )}
        </Stack>
      ),
    },
  ];

  async function solicitudAdd(id2) {
    let body = JSON.stringify({
      usuario_agendo: userInfo.id,
    });

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    await fetch(
      "https://backterra.econtel.com.ec/api/v1/solicitud/" + id2 + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {})
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  async function solicitudDel(id2) {
    let body = JSON.stringify({
      usuario_agendo: null,
    });

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    await fetch(
      "https://backterra.econtel.com.ec/api/v1/solicitud/" + id2 + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {})
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  async function crear() {
    setCargando(true);
    let body = JSON.stringify({
      descripcion: descripcion,
      fecha: moment(fecha).format("YYYY-MM-DD HH:mm"),
      estado: "INGRESADO",
      usuario_mod: userInfo.id,
      solicitud: solicitud?.id ?? null,
    });
    if (solicitud?.id) {
      await solicitudAdd(solicitud?.id);
    }

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/actividades/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setOpen(false);
        Swal.fire({ icon: "success", text: "Actividad agregada " });
        cargar();
      })
      .catch((error) => {
        setCargando(false);
        setOpen(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }
  function editar() {
    setCargando(true);
    if (id === "") {
      crear();
      return;
    }
    let body = JSON.stringify({
      descripcion: descripcion,
      fecha: moment(fecha).format("YYYY-MM-DD HH:mm"),
      usuario_mod: userInfo.id,
      solicitud: solicitud?.id ?? null,
    });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/actividades/" + id + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setOpen(false);
        Swal.fire({ icon: "success", text: "Actividad actualizada " });
        //aconsole.log(result);
        cargar();
      })
      .catch((error) => {
        setCargando(false);
        setOpen(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function asignar() {
    setCargando(true);
    let body = JSON.stringify({
      // actividades: [...ruta.actividades.map((val)=>{return val.id})].concat([id]),
      ruta: ruta.id,
    });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/actividades/" + id + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setOpen3(false);
        Swal.fire({ icon: "success", text: "Ruta actualizada " });
        cargar();
      })
      .catch((error) => {
        setCargando(false);
        setOpen3(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  async function eliminar() {
    setCargando(true);
    if (id === "") {
      return;
    }
    if (solicitud?.id) {
      await solicitudDel(solicitud?.id);
    }
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/actividades/" + id + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setOpen2(false);
        Swal.fire({ icon: "success", text: "Actividad eliminada " });
        cargar();
      })
      .catch((error) => {
        setCargando(false);
        setOpen2(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function cargar(inicio, fin) {
    setCargando(true);
    limpiar();
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    let url = "https://backterra.econtel.com.ec/api/v1/actividades/";
    if (inicio && fin) {
      url =
        "https://backterra.econtel.com.ec/api/v1/actividades/?inicio=" +
        inicio +
        "&fin=" +
        fin;
    } else {
      url =
        "https://backterra.econtel.com.ec/api/v1/actividades/?inicio=" +
        value[0].toISOString() +
        "&fin=" +
        value[1].toISOString();
    }
    fetch(url, requestOptions)
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setLista(
          JSON.parse(result).sort((a, b) => {
            return b.id - a.id;
          })
        );
        setCargando(false);
      })
      .catch((error) => {
        setCargando(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });

    let url3 =
      "https://backterra.econtel.com.ec/api/v1/rutasby/?estado2=TERMINADO";
    if (inicio && fin) {
      url3 =
        "https://backterra.econtel.com.ec/api/v1/rutasby/?estado2=TERMINADO&inicio=" +
        inicio +
        "&fin=" +
        fin;
    } else {
      url3 =
        "https://backterra.econtel.com.ec/api/v1/rutasby/?estado2=TERMINADO&inicio=" +
        value[0].toISOString() +
        "&fin=" +
        value[1].toISOString();
    }
    fetch(url3, requestOptions)
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setRutas(
          JSON.parse(result).filter((val) => val.estado !== "TERMINADO")
        );
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
    let url2 =
      "https://backterra.econtel.com.ec/api/v1/solicitud/estado/APROBADO/";
    if (inicio && fin) {
      url2 =
        "https://backterra.econtel.com.ec/api/v1/solicitud/estado/APROBADO/?inicio=" +
        inicio +
        "&fin=" +
        fin;
    } else {
      url2 =
        "https://backterra.econtel.com.ec/api/v1/solicitud/estado/APROBADO/?inicio=" +
        value[0].toISOString() +
        "&fin=" +
        value[1].toISOString();
    }
    fetch(url2, requestOptions)
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setSolicitudes(
          JSON.parse(result)
            .filter((val) => val.estado === "APROBADO")
            .sort((a, b) => {
              return b.id - a.id;
            })
        );
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  return (
    <>
      <Box px={2} py={2}>
        <Paper elevation={2} sx={{ padding: "10px" }}>
          <Typography variant="h5">
            {" "}
            <strong>Actividades</strong>{" "}
          </Typography>
          <br />
          <FiltroFechas metodo={cargar} value={value} setValue={setValue} />

          {validarRoles("CREAR ACTIVIDADES", userInfo) && (
            <Button
              color="success"
              variant="contained"
              onClick={() => setOpen(true)}
            >
              Agregar
            </Button>
          )}

          <br />
          <br />
          <Paper elevation={2} sx={{ overflowX: "scroll", padding: "10px" }}>
            <Box
              sx={{
                height: 600,
                minWidth: 1000,
                "& .super-app-theme--header": {
                  backgroundColor: "#428bd4",
                  color: "white",
                  fontSize: "16px",
                },
              }}
            >
              <DataGrid
                disableColumnMenu
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
                rows={lista ?? []}
                loading={lista === null}
                columns={
                  validarRoles("ELIMINAR ACTIVIDADES", userInfo) ||
                  validarRoles("EDITAR ACTIVIDADES", userInfo)
                    ? columns
                    : columns.filter((val) => val.field !== "1")
                }
                getRowId={(row) => row.id}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                slots={{ toolbar: CustomToolbar }}
              />
            </Box>
          </Paper>
        </Paper>
      </Box>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          limpiar();
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {" "}
          <strong>{id !== "" ? "Editar " : "Crear "} Actividad</strong>{" "}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Solicitud</strong>{" "}
                </Typography>
                <Autocomplete
                  ListboxProps={{ style: { maxHeight: 150 } }}
                  size="small"
                  fullWidth
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disablePortal
                  value={solicitud}
                  options={solicitudes}
                  getOptionLabel={(option) =>
                    option.id ? "SOLICITUD-" + option.id : ""
                  }
                  onChange={(e, t) => {
                    setSolicitud(t);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Descripción *</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={descripcion}
                  onChange={(e) => setDescripcion(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Fecha *</strong>{" "}
                </Typography>
                <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    slotProps={{
                      textField: { size: "small", fullWidth: true },
                    }}
                    fullWidth
                    value={fecha}
                    onChange={(newValue) => setFecha(newValue)}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <br />
                <Button
                  color="success"
                  variant="contained"
                  disabled={descripcion.length === 0}
                  onClick={() => editar()}
                >
                  Guardar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen(false);
              limpiar();
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open3}
        onClose={() => {
          setOpen3(false);
          limpiar();
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <strong>Asignar Ruta a Actividad</strong>{" "}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Ruta *</strong>{" "}
                </Typography>
                <Autocomplete
                  ListboxProps={{ style: { maxHeight: 150 } }}
                  size="small"
                  fullWidth
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disablePortal
                  value={ruta}
                  options={rutas}
                  getOptionLabel={(option) =>
                    option.id
                      ? "RUTA-" +
                        option.id +
                        (option.cuadrilla?.id
                          ? " / " + option.cuadrilla?.nombre
                          : "")
                      : ""
                  }
                  onChange={(e, t) => {
                    setRuta(t);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <br />
                <Button
                  color="success"
                  variant="contained"
                  disabled={ruta === null}
                  onClick={() => asignar()}
                >
                  Guardar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen3(false);
              limpiar();
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open2}>
        <DialogTitle>
          <Stack
            spacing={2}
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            ¿ESTÁ SEGURO DE ELIMINAR?
          </Stack>
        </DialogTitle>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              eliminar();
            }}
          >
            Borrar
          </Button>
          <Button
            color="info"
            variant="contained"
            onClick={() => {
              setOpen2(false);
              limpiar();
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Cargando open2={cargando} />
    </>
  );
};
