import React from 'react'
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from 'react-redux';


export default function PrivateRoute({ allowedRoles }) {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const location = useLocation();
    
    return (
        (userAuth?.roles2?.find(role => allowedRoles?.includes(role)) || userAuth?.roles2?.includes("ADMIN"))
            ? <Outlet />
            : <>{userAuth?.correo
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />}</>
    )
}
