import { Backdrop, CircularProgress, Dialog, DialogTitle, Grid, Stack } from '@mui/material'
import React from 'react'

export const Cargando= ({ open2 }) => {
    return (
        <Dialog open={open2} >
            <DialogTitle>
                <Stack spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress align="center" />
                    <strong><p align="center" style={{ fontSize: '14px' }}>CARGANDO...</p></strong>
                </Stack>
            </DialogTitle>
        </Dialog>
    )
}


export const CargandoPage = () => {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid item xs={3}>
                <CircularProgress />
            </Grid>
        </Grid>
    )
}

export const CargandoBack = ({ open = false }) => {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}