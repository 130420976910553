import React, { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Typography } from "@mui/material";
import { Movimientos } from "./Movimientos";
import { MovimientosSerie } from "./MovimientosSerie";
import { useSelector } from "react-redux";

export const MovimientosPage = () => {
  const [tab, setTab] = useState("0");
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <br />
      <Box px={2}>
        <Typography variant="h5">
          {" "}
          <strong>Historial Movimientos</strong>{" "}
        </Typography>
        <TabContext value={tab}>
          <Box sx={{ borderColor: "divider" }}>
            <TabList onChange={handleChange} variant="scrollable">
              <Tab label="Historial" sx={{ fontWeight: "bold" }} value={"0"} />
              <Tab
                label="Historial por Serie"
                sx={{ fontWeight: "bold" }}
                value={"1"}
              />
            </TabList>
          </Box>
          <TabPanel value={"0"}>
            <Movimientos />
          </TabPanel>
          <TabPanel value={"1"}>
            <MovimientosSerie />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};
