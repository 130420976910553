import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Paper,
    TextField,
    Stack,
    Typography,
  } from "@mui/material";
  import { DataGrid, esES } from "@mui/x-data-grid";
  import React, { useState, useEffect } from "react";
  import EditIcon from "@mui/icons-material/Edit";
  import DeleteIcon from "@mui/icons-material/Delete";
  import Swal from "sweetalert2";
  import { CustomToolbar } from "../../components/utils/CustomFilter";
  import { useSelector } from "react-redux";
  import { validarRoles } from "../../components/utils/ValidarRoles";
  import { Cargando } from "../../components/utils/Cargando";
  
  export const TipoGasto = () => {
    const [cargando, setCargando] = useState(false);
  
    const userAuth = useSelector((state) => state.userAuth);
    const { userInfo } = userAuth;
    const [open, setOpen] = useState(false);
    const [modelo, setModelo] = useState("");
    const [id, setId] = useState("");
  
    const [lista, setLista] = useState(null);
  
    const [open2, setOpen2] = useState(false);
  
    useEffect(() => {
      cargar();
    }, []);
  
    function limpiar() {
      setModelo("");
      setId("");
    }
  
    function handleOpen2(row) {
      setId(row.id);
      setOpen2(true);
    }
    function handleOpen(row) {
      setId(row.id);
      setModelo(row.motivo);
      setOpen(true);
    }
  
    const columns = [
      {
        field: "motivo",
        headerClassName: "super-app-theme--header",
        headerName: "Motivo",
        flex: 1,
      },
      {
        field: "1",
        headerClassName: "super-app-theme--header",
        headerName: "Acciones",
        flex: 1,
        renderCell: (params) => (
          <Stack direction={"row"} spacing={2}>
            {validarRoles("EDITAR MOTIVOS GASTOS", userInfo) && (
              <IconButton onClick={() => handleOpen(params.row)}>
                <EditIcon color="warning" />
              </IconButton>
            )}
            {validarRoles("ELIMINAR MOTIVOS GASTOS", userInfo) && (
              <IconButton onClick={() => handleOpen2(params.row)}>
                <DeleteIcon color="error" />
              </IconButton>
            )}
          </Stack>
        ),
      },
    ];
  
    function crear() {
      setCargando(true);
  
      let body = JSON.stringify({
        motivo: modelo,
      });
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("ngrok-skip-browser-warning", "asc");
  myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: body,
        redirect: "follow",
      };
      fetch(
        "https://backterra.econtel.com.ec/api/v1/motivo_gastos/",
        requestOptions
      )
        .then(async (response) => {
          if (response.ok) {
            return response.text();
          }
          let mensaje = await response.json();
          throw {
            ...mensaje,
          };
        })
        .then((result) => {
          //aconsole.log(result);
          setOpen(false);
          Swal.fire({ icon: "success", text: "Motivo agregado " });
          cargar();
        })
        .catch((error) => {
          setOpen(false);
          setCargando(false);
  
          Swal.fire({
            icon: "error",
            text:
              "Error: " +
              (typeof error === "string" ? error : JSON.stringify(error)),
          });
        });
    }
  
    function editar() {
      setCargando(true);
  
      if (id === "") {
        crear();
        return;
      }
      let body = JSON.stringify({
        motivo: modelo,
      });
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("ngrok-skip-browser-warning", "asc");
  myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
      let requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: body,
        redirect: "follow",
      };
      fetch(
        "https://backterra.econtel.com.ec/api/v1/motivo_gastos/" +
          id +
          "/",
        requestOptions
      )
        .then(async (response) => {
          if (response.ok) {
            return response.text();
          }
          let mensaje = await response.json();
          throw {
            ...mensaje,
          };
        })
        .then((result) => {
          setOpen(false);
          Swal.fire({ icon: "success", text: "Motivo actualizado " });
          cargar();
        })
        .catch((error) => {
          setOpen(false);
          setCargando(false);
  
          Swal.fire({
            icon: "error",
            text:
              "Error: " +
              (typeof error === "string" ? error : JSON.stringify(error)),
          });
        });
    }
  
    function eliminar() {
      setCargando(true);
  
      if (id === "") {
        return;
      }
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("ngrok-skip-browser-warning", "asc");
  myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
      let requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(
        "https://backterra.econtel.com.ec/api/v1/motivo_gastos/" +
          id +
          "/",
        requestOptions
      )
        .then(async (response) => {
          if (response.ok) {
            return response.text();
          }
          let mensaje = await response.json();
          throw {
            ...mensaje,
          };
        })
        .then((result) => {
          setOpen2(false);
          Swal.fire({ icon: "success", text: "Motivo eliminado " });
          cargar();
        })
        .catch((error) => {
          setOpen2(false);
          setCargando(false);
  
          Swal.fire({
            icon: "error",
            text:
              "Error: " +
              (typeof error === "string" ? error : JSON.stringify(error)),
          });
        });
    }
  
    function cargar() {
      limpiar();
      setCargando(true);
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("ngrok-skip-browser-warning", "asc");
  myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(
        "https://backterra.econtel.com.ec/api/v1/motivo_gastos/",
        requestOptions
      )
        .then(async (response) => {
          if (response.ok) {
            return response.text();
          }
          let mensaje = await response.json();
          throw {
            ...mensaje,
          };
        })
        .then((result) => {
          //aconsole.log(JSON.parse(result));
          setLista(JSON.parse(result));
          setCargando(false);
        })
        .catch((error) => {
          setCargando(false);
  
          Swal.fire({
            icon: "error",
            text:
              "Error: " +
              (typeof error === "string" ? error : JSON.stringify(error)),
          });
        });
    }
  
    return (
      <>
        <Box px={2} py={2}>
          <Paper elevation={2} sx={{ padding: "10px" }}>
            <Typography variant="h5">
              {" "}
              <strong>Motivo Gasto</strong>{" "}
            </Typography>
            <br />
  
            {validarRoles("CREAR MOTIVOS GASTOS", userInfo) && (
              <Button
                color="success"
                variant="contained"
                onClick={() => setOpen(true)}
              >
                Agregar
              </Button>
            )}
  
            <br />
            <br />
            <Paper elevation={2} sx={{ overflowX: "scroll", padding: "10px" }}>
              <Box
                sx={{
                  height: 600,
                  minWidth: 1000,
                  "& .super-app-theme--header": {
                    backgroundColor: "#428bd4",
                    color: "white",
                    fontSize: "16px",
                  },
                }}
              >
                <DataGrid  disableColumnMenu
                  getRowHeight={() => "auto"}
                  getEstimatedRowHeight={() => 200}
                  rows={lista ?? []}
                  loading={lista === null}
                  columns={
                    validarRoles("ELIMINAR MOTIVOS GASTOS", userInfo) ||
                      validarRoles("EDITAR MOTIVOS GASTOS", userInfo)
                      ? columns
                      : columns.filter((val) => val.field !== "1")
                  }
                  getRowId={(row) => row.id}
                  localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                  slots={{ toolbar: CustomToolbar }}
                />
              </Box>
            </Paper>
          </Paper>
        </Box>
  
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
            limpiar();
          }}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            {" "}
            <strong>
              {id !== "" ? "Editar " : "Crear "} Motivo Gasto
            </strong>{" "}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Box px={2}>
                  <Typography>
                    {" "}
                    <strong>Motivo *</strong>{" "}
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    value={modelo}
                    onChange={(e) => setModelo(e.target.value)}
                  />
                </Box>
              </Grid>
  
              <Grid item xs={12} lg={6}>
                <Box px={2}>
                  <br />
                  <Button
                    color="success"
                    variant="contained"
                    disabled={modelo.trim().length === 0}
                    onClick={() => editar()}
                  >
                    Guardar
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                setOpen(false);
                limpiar();
              }}
            >
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
  
        <Dialog open={open2}>
          <DialogTitle>
            <Stack
              spacing={2}
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              ¿ESTÁ SEGURO DE ELIMINAR?
            </Stack>
          </DialogTitle>
          <DialogActions>
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                eliminar();
              }}
            >
              Borrar
            </Button>
            <Button
              color="info"
              variant="contained"
              onClick={() => {
                setOpen2(false);
                limpiar();
              }}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
        <Cargando open2={cargando} />
      </>
    );
  };
  