import { Box, Grid, Stack, Paper, Button, Typography } from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { validarRoles } from "../../components/utils/ValidarRoles";

export const Home = () => {
  const [lista, setLista] = useState([]);
  const [ocultadas, setOcultadas] = useState([]);
  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;

  useEffect(() => {
    if (validarRoles("NOTIFICACIONES STOCK MINIMO", userInfo)) {
      cargar();
      let ale = [];
      if (localStorage.getItem("alertas")) {
        ale = JSON.parse(localStorage.getItem("alertas")).lista;
      }
      setOcultadas(ale);
    }
  }, []);

  function ocultar(row) {
    //aconsole.log(row);
    let ale = [];
    if (localStorage.getItem("alertas")) {
      ale = JSON.parse(localStorage.getItem("alertas")).lista;
    }
    ale = ale.concat([row.id]);
    localStorage.setItem(
      "alertas",
      JSON.stringify({
        lista: ale,
      })
    );
    //aconsole.log(ale);
    setOcultadas(ale);
  }

  function ocultarTodo() {
    let ale = [];
    if (localStorage.getItem("alertas")) {
      ale = JSON.parse(localStorage.getItem("alertas")).lista;
    }
    ale = [
      ...lista.map((val) => {
        return val.id;
      }),
    ];
    localStorage.setItem(
      "alertas",
      JSON.stringify({
        lista: ale,
      })
    );
    setOcultadas(ale);
  }

  function mostrar() {
    localStorage.removeItem("alertas");
    setOcultadas([]);
  }

  function cargar() {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://backterra.econtel.com.ec/api/v1/stock/below-minimum/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setLista(JSON.parse(result));
        //aconsole.log(JSON.parse(result));
      })
      .catch((error) => {
        Swal.fire({ icon: "error", text: "Error: " + (typeof error === "string" ? error : JSON.stringify(error)) });
      });
  }

  return (
    <Box px={2} py={2}>
      <Paper elevation={2} sx={{ padding: "10px" }}>
        <Typography variant="h5">
          {" "}
          <strong>HOME</strong>{" "}
        </Typography>
        <br />
        {validarRoles("NOTIFICACIONES STOCK MINIMO", userInfo) && (
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Stack spacing={2} direction={"row"}>
                <Button
                  variant="contained"
                  color="success"
                  disabled={lista.length === 0}
                  onClick={() => mostrar()}
                >
                  Mostrar Alertas
                </Button>
                <Button
                  variant="contained"
                  disabled={lista.length === 0}
                  color="error"
                  onClick={() => ocultarTodo()}
                >
                  Ocultar Alertas
                </Button>
              </Stack>
            </Grid>
            {lista
              ? [...lista.filter((val) => !ocultadas.includes(val.id))].map(
                  (val) => (
                    <Grid item xs={12} lg={3} key={val.id}>
                      <Paper elevation={3} sx={{ padding: "10px" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <ReportProblemIcon
                            color={"warning"}
                            fontSize="large"
                          />
                          <CancelIcon
                            color={"error"}
                            fontSize="large"
                            sx={{ marginTop: "-25px", marginRight: "-20px" }}
                            onClick={() => ocultar(val)}
                          />
                        </div>
                        <Typography color={"red"}>
                          {" "}
                          <strong>ALERTA</strong>{" "}
                        </Typography>
                        <Typography>
                          El stock del producto{" "}
                          <strong>{val.producto?.nombre ?? ""}</strong> en la
                          bodega/nodo{" "}
                          <strong>{val.bodega?.nombre ?? ""}</strong> esta por
                          debajo de su stock mínimo.
                        </Typography>
                      </Paper>
                    </Grid>
                  )
                )
              : ""}
          </Grid>
        )}
      </Paper>
    </Box>
  );
};
