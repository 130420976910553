import { Box, Paper, Typography } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { CustomToolbar } from "../../components/utils/CustomFilter";
import Swal from "sweetalert2";

export const Clientes = () => {
  const [lista, setLista] = useState(null);

  useEffect(() => {
    cargar();
  }, []);

  const columns = [
    {
      field: "id_servicio",
      headerClassName: "super-app-theme--header",
      headerName: "Id Servicio",
      flex: 1,
    },
    {
      field: "nombre",
      headerClassName: "super-app-theme--header",
      headerName: "Nombre",
      flex: 1,
    },
    {
      field: "cedula",
      headerClassName: "super-app-theme--header",
      headerName: "Cédula",
      flex: 1,
    },
    {
      field: "usuario",
      headerClassName: "super-app-theme--header",
      headerName: "Usuario",
      flex: 1,
    },
    {
      field: "plan_internet",
      headerClassName: "super-app-theme--header",
      headerName: "Plan Internet",
      flex: 1,
      valueGetter: (params) => {
        return params.row.plan_internet?.nombre ?? "";
      },
    },
    {
      field: "router",
      headerClassName: "super-app-theme--header",
      headerName: "Router",
      flex: 1,
      valueGetter: (params) => {
        return params.row.router?.nombre ?? "";
      },
    },
    {
      field: "ip",
      headerClassName: "super-app-theme--header",
      headerName: "Ip",
      flex: 1,
    },
    {
      field: "sn_onu",
      headerClassName: "super-app-theme--header",
      headerName: "Serie",
      flex: 1,
    },
  ];

  function cargar() {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch("https://backterra.econtel.com.ec/api/api/clientes/", requestOptions)
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json()
        throw {
          ...mensaje
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setLista(JSON.parse(result).sort((a, b) =>  { return b.id_servicio - a.id_servicio}));
      })
      .catch((error) => {
        Swal.fire({ icon: "error", text: "Error: " + (typeof error === "string" ? error : JSON.stringify(error)) });
      });
  }

  return (
    <Box px={2} py={2}>
      <Paper elevation={2} sx={{ padding: "10px" }}>
        <Typography variant="h5">
          {" "}
          <strong>Clientes</strong>{" "}
        </Typography>
        <br />
        <br />
        <Paper elevation={2} sx={{ overflowX: "scroll", padding: "10px" }}>
          <Box
            sx={{
              height: 600,
              minWidth: 1000,
              "& .super-app-theme--header": {
                backgroundColor: "#428bd4",
                color: "white",
                fontSize: "16px",
              },
            }}
          >
            <DataGrid  disableColumnMenu
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}
              rows={lista ?? []}
              columns={columns}
              loading={lista === null}
              getRowId={(row) => row.id_servicio}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              slots={{ toolbar: CustomToolbar }}
            />
          </Box>
        </Paper>
      </Paper>
    </Box>
  );
};
