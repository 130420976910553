import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  TextField,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { CustomToolbar } from "../../components/utils/CustomFilter";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { useSelector } from "react-redux";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Cargando } from "../../components/utils/Cargando";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { FiltroFechas } from "../../components/utils/FiltroFechas";

export const Reagendar = () => {
  const [cargando, setCargando] = useState(false);

  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;
  const [open, setOpen] = useState(false);
  const [descripcion, setDescripcion] = useState("");
  const [solicitud, setSolicitud] = useState(null);
  const [fecha, setFecha] = useState(new Date());
  const [id, setId] = useState("");

  const [lista, setLista] = useState(null);

  const [value, setValue] = useState([
    startOfDay(subDays(new Date(), 6)),
    endOfDay(new Date()),
  ]);

  useEffect(() => {
    cargar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function limpiar() {
    setDescripcion("");
    setFecha(new Date());
    setId("");
    setSolicitud(null);
  }

  function handleOpen3(row) {
    setId(row.id);
    setSolicitud(row.solicitud);
    setOpen(true);
    setDescripcion(row.descripcion);
  }

  const columns = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "Actividad",
      flex: 1,
      valueGetter: (params) => {
        return "ACTIVIDAD-" + params.row.id;
      },
    },
    {
      field: "estado",
      headerClassName: "super-app-theme--header",
      headerName: "Estado",
      flex: 1,
    },
    {
      field: "fecha",
      headerClassName: "super-app-theme--header",
      headerName: "Fecha",
      flex: 1,
      valueGetter: (params) => {
        return params.row.fecha
          ? moment(params.row.fecha).format("DD/MMMM/yyyy HH:mm")
          : "";
      },
    },
    {
      field: "solicitud",
      headerClassName: "super-app-theme--header",
      headerName: "Solicitud",
      flex: 1,
      valueGetter: (params) => {
        return params.row.solicitud
          ? "SOLICITUD-" + params.row.solicitud.id
          : "";
      },
    },
    {
      field: "cliente",
      headerClassName: "super-app-theme--header",
      headerName: "Cliente",
      flex: 1.5,
      valueGetter: (params) => {
        return params.row.solicitud
          ? (params.row.solicitud.nombres_cliente +" - "+ params.row.solicitud.cedula)
          : "";
      },
    },
    {
      field: "motivo_cancelado",
      headerClassName: "super-app-theme--header",
      headerName: "Motivo",
      flex: 1,
    },
    {
      field: "usuario_mod",
      headerClassName: "super-app-theme--header",
      headerName: "U. Modificación",
      flex: 1,
      valueGetter: (params) => {
        return params.row.usuario_mod?.nombre ?? "";
      },
    },
    {
      field: "id_reagendado",
      headerClassName: "super-app-theme--header",
      headerName: "Actividad Nueva",
      flex: 1,
      valueGetter: (params) => {
        return params.row.id_reagendado
          ? "ACTIVIDAD-" + params.row.id_reagendado
          : "";
      },
    },
    {
      field: "1",
      headerClassName: "super-app-theme--header",
      headerName: "Reagendar",
      flex: 1,
      renderCell: (params) => (
        <Stack direction={"row"} spacing={2}>
          <IconButton
            onClick={() => handleOpen3(params.row)}
            disabled={params.row.id_reagendado !== null}
          >
            <EditIcon
              color={params.row.id_reagendado !== null ? "gray" : "warning"}
            />
          </IconButton>
        </Stack>
      ),
    },
  ];

  async function solicitudAdd(id2) {
    let body = JSON.stringify({
      usuario_agendo: userInfo.id,
    });

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    await fetch(
      "https://backterra.econtel.com.ec/api/v1/solicitud/" + id2 + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {})
      .catch((error) => {
        Swal.fire({ icon: "error", text: "Error: " + (typeof error === "string" ? error : JSON.stringify(error)) });
      });
  }

  async function actividadAdd(nuevo, viejo) {
    let body = JSON.stringify({
      id_reagendado: nuevo,
    });

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    await fetch(
      "https://backterra.econtel.com.ec/api/v1/actividades/" + viejo + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {})
      .catch((error) => {
        Swal.fire({ icon: "error", text: "Error: " + (typeof error === "string" ? error : JSON.stringify(error)) });
      });
  }

  async function crear() {
    setCargando(true);
    let body = JSON.stringify({
      descripcion: descripcion,
      fecha: moment(fecha).format("YYYY-MM-DD HH:mm"),
      estado: "INGRESADO",
      usuario_mod: userInfo.id,
      solicitud: solicitud?.id ?? null,
    });
    if (solicitud?.id) {
      await solicitudAdd(solicitud?.id);
    }

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/actividades/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then(async (result) => {
        let res = JSON.parse(result);
        await actividadAdd(res.id, id);
        setOpen(false);
        Swal.fire({ icon: "success", text: "Actividad agregada " });
        cargar();
      })
      .catch((error) => {
        setOpen(false);
        setCargando(false);
        Swal.fire({ icon: "error", text: "Error: " + (typeof error === "string" ? error : JSON.stringify(error)) });
      });
  }
  function editar() {
    crear();
  }

  function cargar(inicio, fin) {
    limpiar();
    setCargando(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    let url = "https://backterra.econtel.com.ec/api/v1/actividades/estado/CANCELADO/"
    if(inicio && fin){
      url = "https://backterra.econtel.com.ec/api/v1/actividades/estado/CANCELADO/?inicio="
      +inicio+"&fin="+fin;
    }else{
      url = "https://backterra.econtel.com.ec/api/v1/actividades/estado/CANCELADO/?inicio="
      +value[0].toISOString()+"&fin="+value[1].toISOString();
    }
    fetch(
      url,
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setLista(JSON.parse(result).sort((a, b) =>  { return b.id - a.id}))
        setCargando(false);
      })
      .catch((error) => {
        setCargando(false);
        Swal.fire({ icon: "error", text: "Error: " + (typeof error === "string" ? error : JSON.stringify(error)) });
      });
  }

  return (
    <>
      <Box px={2} py={2}>
        <Paper elevation={2} sx={{ padding: "10px" }}>
          <Typography variant="h5">
            {" "}
            <strong>Actividades Canceladas</strong>{" "}
          </Typography>
          <br />
          <FiltroFechas metodo={cargar} value={value} setValue={setValue} />

          <br />
          <Paper elevation={2} sx={{ overflowX: "scroll", padding: "10px" }}>
            <Box
              sx={{
                height: 600,
                minWidth: 1000,
                "& .super-app-theme--header": {
                  backgroundColor: "#428bd4",
                  color: "white",
                  fontSize: "16px",
                },
              }}
            >
              <DataGrid  disableColumnMenu
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
                rows={lista ?? []}
                loading={lista === null}
                columns={columns}
                getRowId={(row) => row.id}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                slots={{ toolbar: CustomToolbar }}
              />
            </Box>
          </Paper>
        </Paper>
      </Box>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          limpiar();
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {" "}
          <strong>{"Crear "} Nueva Actividad</strong>{" "}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Actividad Anterior</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  disabled
                  value={"ACTIVIDAD-" + id}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Solicitud Anterior</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  disabled
                  size="small"
                  value={solicitud ? "SOLICITUD-" + solicitud.id : ""}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Descripción *</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={descripcion}
                  onChange={(e) => setDescripcion(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Fecha *</strong>{" "}
                </Typography>
                <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    slotProps={{
                      textField: { size: "small", fullWidth: true },
                    }}
                    fullWidth
                    value={fecha}
                    onChange={(newValue) => setFecha(newValue)}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <br />
                <Button
                  color="success"
                  variant="contained"
                  disabled={descripcion.length === 0}
                  onClick={() => editar()}
                >
                  Guardar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen(false);
              limpiar();
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Cargando open2={cargando} />

    </>
  );
};
