import React from 'react'
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from 'react-redux';
export function PublicRoute() {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const location = useLocation();

    return (
        userAuth?.correo
            ? <Outlet />
            : <Navigate to="/login" state={{ from: location }} replace />
    )
}


export function PublicRoute2() {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const location = useLocation();
    return (
        !userAuth?.correo
            ? <Outlet />
            : <Navigate to="/" state={{ from: location }} replace />
    )
}