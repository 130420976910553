import GroupIcon from "@mui/icons-material/Group";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { validarRoles } from "../../components/utils/ValidarRoles";

export const ListUsuarios = ({ info }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton component={Link} onClick={handleClick}>
        <ListItemIcon>
          <GroupIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{ fontWeight: "bold" }}
          primary="Usuarios"
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding dense>
          {validarRoles("LEER USUARIO", info) && (
            <ListItemButton
              component={Link}
              to={"usuarios/admin"}
              sx={{ pl: 8 }}
            >
              <ListItemText primary="Administrar Usuarios" />
            </ListItemButton>
          )}
          {validarRoles("LEER ROLES", info) && (
            <ListItemButton
              component={Link}
              to={"usuarios/roles"}
              sx={{ pl: 8 }}
            >
              <ListItemText primary="Administrar Roles" />
            </ListItemButton>
          )}
        </List>
      </Collapse>
    </>
  );
};
