import { Box, Paper, Tab, Dialog,
  DialogActions,
  DialogContent, IconButton, 
  DialogTitle, Button, Typography, MenuItem, Select } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { CustomToolbar } from "../../../components/utils/CustomFilter";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import moment from "moment";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { Stack } from "rsuite";
import { MenuProps } from "../../../components/utils/MenuProps";


export const StockCuadrilla = () => {
  const [lista, setLista] = useState(null);
  const [lista2, setLista2] = useState(null);
  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;

  const [view, setView] = useState(null);
  const [open, setOpen] = useState(false);

  const [filtro, setFiltro] = useState("Todos");

  const [value, setValue] = useState([
    startOfDay(subDays(new Date(), 1)),
    endOfDay(new Date()),
  ]);

  useEffect(() => {
    cargar();
  }, []);

  function cargar() {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/bodega/?encargado_id=" +
        userInfo.id,
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then(async (result) => {
        let tecnicos = JSON.parse(result).filter(
          (val) => val.tipo === "CUADRILLA"
        );
        let lista2 = [];
        for (const el of tecnicos) {
          //aconsole.log(el);
          await fetch(
            "https://backterra.econtel.com.ec/api/v1/stockby/?bodega=" +
              el.id,
            requestOptions
          )
            .then(async (response) => {
              if (response.ok) {
                return response.text();
              }
              let mensaje = await response.json();
              throw {
                ...mensaje,
              };
            })
            .then((result) => {
              //aconsole.log(JSON.parse(result));
              lista2 = lista2.concat(
                JSON.parse(result).filter((val) => val.producto !== null)
              );
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                text:
                  "Error: " +
                  (typeof error === "string" ? error : JSON.stringify(error)),
              });
            });
        }
        setLista(lista2);
        let url = "https://backterra.econtel.com.ec/api/v1/movimiento?"+
        "inicio=" + value[0].toISOString() + "&fin=" + value[1].toISOString();
        await fetch(
          url,
          requestOptions
        )
          .then(async (response) => {
            if (response.ok) {
              return response.text();
            }
            let mensaje = await response.json();
            throw {
              ...mensaje,
            };
          })
          .then((result) => {
            let ids = [...tecnicos.map((val)=> { return val.id})]
            let resp = JSON.parse(result).filter((val)=> val.bodega?.id ? ids.includes(val.bodega?.id) : false)
            setLista2(resp.sort((a, b) =>  { return b.id - a.id}))
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              text:
                "Error: " +
                (typeof error === "string" ? error : JSON.stringify(error)),
            });
          });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  const columns = [
    {
      field: "nombre_bodega",
      headerClassName: "super-app-theme--header",
      headerName: "Cuadrilla",
      flex: 1,
      valueGetter: (params) => {
        return params.row.bodega?.nombre ?? "";
      },
    },
    {
      field: "nombre_producto",
      headerClassName: "super-app-theme--header",
      headerName: "Producto",
      flex: 1,
      valueGetter: (params) => {
        return params.row.producto?.nombre ?? "";
      },
    },
    {
      field: "tipo_producto",
      headerClassName: "super-app-theme--header",
      headerName: "Tipo",
      flex: 1,
      valueGetter: (params) => {
        return params.row.producto?.tipo ?? "";
      },
    },
    {
      field: "cantidad",
      headerClassName: "super-app-theme--header",
      headerName: "Cantidad",
      flex: 1,
    },
    // {
    //   field: "1",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "Editar",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <IconButton onClick={() => handleOpen(params.row)}>
    //       <EditIcon color="warning" />
    //     </IconButton>
    //   ),
    // },
  ];

  const columns2 = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "Movimiento",
      flex: 1,
      valueGetter: (params) => {
        return "MOV-" + params.row.id;
      },
    },
    {
      field: "bodega",
      headerClassName: "super-app-theme--header",
      headerName: "Bodega",
      flex: 1,
      valueGetter: (params) => {
        return params.row.bodega?.nombre ?? "";
      },
    },
    {
      field: "producto",
      headerClassName: "super-app-theme--header",
      headerName: "Producto",
      flex: 1,
      valueGetter: (params) => {
        return params.row.producto?.nombre ?? "";
      },
    },
    {
      field: "tipomovimiento",
      headerClassName: "super-app-theme--header",
      headerName: "Tipo",
      flex: 1,
    },
    {
      field: "id_actividad",
      headerClassName: "super-app-theme--header",
      headerName: "Actividad",
      flex: 1,
      valueGetter: (params) => {
        return params.row.id_actividad
          ? "ACTIVIDAD-" + params.row.id_actividad
          : "";
      },
    },
    {
      field: "proveedor",
      headerClassName: "super-app-theme--header",
      headerName: "Proveedor",
      flex: 1,
      valueGetter: (params) => {
        return params.row.proveedor?.nombre ?? "";
      },
    },
    {
      field: "cantidad",
      headerClassName: "super-app-theme--header",
      headerName: "Cantidad",
      flex: 1,
    },
    {
      field: "motivo",
      headerClassName: "super-app-theme--header",
      headerName: "Motivo",
      flex: 1,
      valueGetter: (params) => {
        return params.row.motivo_movimiento?.motivo ?? "";
      },
    },
    {
      field: "descripcion",
      headerClassName: "super-app-theme--header",
      headerName: "Descripción",
      flex: 1,
    },
    {
      field: "series",
      headerClassName: "super-app-theme--header",
      headerName: "Series",
      flex: 1,
      valueGetter: (params) => {
        if (params.row.series) {
          return [
            ...params.row.series.map((val) => {
              return val.serie;
            }),
          ].join(", ");
        } else {
          return "";
        }
      },
    },
    {
      field: "usuario",
      headerClassName: "super-app-theme--header",
      headerName: "Usuario",
      flex: 1,
      valueGetter: (params) => {
        return params.row.usuario?.nombre ?? "";
      },
    },
    {
      field: "fecha",
      headerClassName: "super-app-theme--header",
      headerName: "Fecha",
      flex: 1,
      valueGetter: (params) => {
        return moment(params.row.created_at).format("DD/MMMM/yyyy HH:mm") ?? "";
      },
      
    },
    {
      field: "foto",
      headerClassName: "super-app-theme--header",
      headerName: "Foto",
      width: 70,
      renderCell: (params) => (
        <>
          {params.row.foto && (
            <IconButton
              onClick={() => {
                setView(params.row.foto);
                setOpen(true);
              }}
            >
              <RemoveRedEyeIcon color="warning" />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  const [tab, setTab] = useState("0");
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box px={2} py={2}>
      <Paper elevation={2} sx={{ padding: "10px" }}>
        <Typography variant="h5">
          {" "}
          <strong>Stock Cuadrilla</strong>{" "}
        </Typography>
        <br />
        <TabContext value={tab}>
          <Box sx={{ borderColor: "divider" }}>
            <TabList onChange={handleChange} variant="scrollable">
              <Tab label="Stock" sx={{ fontWeight: "bold" }} value={"0"} />
              <Tab
                label="Movimientos"
                sx={{ fontWeight: "bold" }}
                value={"1"}
              />
            </TabList>
          </Box>
          <TabPanel value={"0"}>
          <Stack direction={"row"} spacing={2} pt={2}>
            <Typography>
              {" "}
              <strong>Tipo Inventario:</strong>{" "}
            </Typography>
            <Select
              size="small"
              MenuProps={MenuProps}
              value={filtro}
              onChange={(e) => {
                setFiltro(e.target.value);
              }}
            >
              <MenuItem key={0} value={"Todos"}>
                {"Todos"}
              </MenuItem>
              <MenuItem key={1} value={"Herramienta"}>
                {"Herramienta"}
              </MenuItem>
              <MenuItem key={2} value={"Material"}>
                {"Material"}
              </MenuItem>
            </Select>
          </Stack>
          <br />
            <Paper elevation={2} sx={{ overflowX: "scroll", padding: "10px" }}>
              <Box
                sx={{
                  height: 600,
                  minWidth: 1000,
                  "& .super-app-theme--header": {
                    backgroundColor: "#428bd4",
                    color: "white",
                    fontSize: "16px",
                  },
                }}
              >
                <DataGrid
                  disableColumnMenu
                  getRowHeight={() => "auto"}
                  rows={lista ?
                    lista.filter((val)=> filtro !== "Todos" ? val.producto?.tipo === filtro : true)
                    : []}
                  loading={lista === null}
                  columns={columns}
                  getRowId={(row) => row.id}
                  localeText={
                    esES.components.MuiDataGrid.defaultProps.localeText
                  }
                  slots={{ toolbar: CustomToolbar }}
                />
              </Box>
            </Paper>
          </TabPanel>
          <TabPanel value={"1"}>
            <Paper elevation={2} sx={{ overflowX: "scroll", padding: "10px" }}>
              <Box
                sx={{
                  height: 600,
                  minWidth: 1000,
                  "& .super-app-theme--header": {
                    backgroundColor: "#428bd4",
                    color: "white",
                    fontSize: "16px",
                  },
                }}
              >
                <DataGrid
                  disableColumnMenu
                  getRowHeight={() => "auto"}
                  getEstimatedRowHeight={() => 200}
                  rows={lista2 ?? []}
                  loading={lista2 === null}
                  columns={columns2}
                  getRowId={(row) => row.id}
                  //
                  //
                  localeText={
                    esES.components.MuiDataGrid.defaultProps.localeText
                  }
                  slots={{ toolbar: CustomToolbar }}
                />
              </Box>
            </Paper>
          </TabPanel>
        </TabContext>
      </Paper>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setView(null);
        }}
        maxWidth="lg"
      >
        <DialogTitle>
          <strong>Foto Transferencia</strong>
        </DialogTitle>
        <DialogContent>
          <Box px={2}>
            {view && <img src={view} alt={"imagen"} height={200} />}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen(false);
              setView(null);
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
