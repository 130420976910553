import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Stack,
  Typography,
  Autocomplete,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import React, { useState, useEffect, useRef } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { CustomToolbar } from "../../../components/utils/CustomFilter";
import { useSelector } from "react-redux";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { read, utils, writeFile } from "xlsx";
import { Cargando } from "../../../components/utils/Cargando";
import { MenuProps } from "../../../components/utils/MenuProps";

export const Ingresos = () => {
  const [cargando, setCargando] = useState(false);

  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [cantidad, setCantidad] = useState("");
  const [producto, setProducto] = useState("");
  const [proveedor, setProveedor] = useState("");
  const [bodega, setBodega] = useState("");
  const [id, setId] = useState("");

  const [garantia, setGarantia] = useState("NO");

  const [descripcion, setDescripcion] = useState("");
  const [motivo, setMotivo] = useState("");

  const [lista, setLista] = useState([]);

  const [seleccionado, setSeleccionado] = useState([]);

  const [proveedores, setProveedores] = useState([]);
  const [bodegas, setBodegas] = useState([]);
  const [productos, setProductos] = useState([]);
  const [motivos, setMotivos] = useState([]);
  const [series, setSeries] = useState([]);

  const [nuevaSerie, setNuevaSerie] = useState("");

  const [numero, setNumero] = useState(2);

  function handleOpen3(row) {
    setSeleccionado(seleccionado.filter((val) => val.serie !== row.serie));
  }

  useEffect(() => {
    cargarBodegas();
    cargarProductos();
    cargarMotivos();
    cargarProveedores();
  }, []);

  const handleImport = ($event) => {
    setCargando(true);
    const files = $event.target.files;
    //aconsole.log(files.length);
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          let datos = [];
          let ser = [
            ...series.map((val) => {
              return val.serie;
            }),
          ];
          for (const el of rows) {
            if (
              !ser.includes((el.SERIE + "").toUpperCase()) &&
              lista.filter(
                (val) =>
                  val.producto.id === producto.id &&
                  val.series.includes(nuevaSerie)
              ).length === 0
            ) {
              datos.push((el.SERIE + "").toUpperCase());
            }
          }
          datos = Array.from(new Set(datos));
          setSeleccionado([
            ...datos.map((val) => {
              return { serie: val };
            }),
          ]);
          setCargando(false);

          Swal.fire({
            icon: "success",
            text: "SERIES NUEVAS DETECTADAS: " + datos.length,
          });
        }
        event.target.value = null;
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleExport = () => {
    let data = [
      { SERIE: "EjemploSerie" },
      { SERIE: "EjemploSerie2" },
      { SERIE: "EjemploSerie3" },
    ];
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    let lista = [];
    for (const el of Object.keys(data)) {
      lista.push({ wch: 20 });
    }
    ws["!cols"] = lista;
    utils.sheet_add_json(ws, data, { origin: "A1" });
    utils.book_append_sheet(wb, ws, "Hoja");
    writeFile(wb, "Formato.xlsx");
  };

  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  function cargarProveedores() {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://backterra.econtel.com.ec/api/v1/proveedor/", requestOptions)
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setProveedores(JSON.parse(result));
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function cargarProductos() {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://backterra.econtel.com.ec/api/v1/producto/", requestOptions)
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setProductos(JSON.parse(result));
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function cargarBodegas() {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://backterra.econtel.com.ec/api/v1/bodega/", requestOptions)
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setBodegas(JSON.parse(result));
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function cargarMotivos() {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://backterra.econtel.com.ec/api/v1/motivo_movimiento/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setMotivos(JSON.parse(result));
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function getSeries(producto) {
    setCargando(true);

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://backterra.econtel.com.ec/api/v1/seriesby/?bodega=" + bodega.id,
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setSeries(JSON.parse(result));
        setCargando(false);
      })
      .catch((error) => {
        setCargando(false);

        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  async function comprar() {
    setCargando(true);

    let error2 = false;
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );

    let cantidad = 0;
    for (const el of lista.filter(
      (val) => !(val.producto.tipo2 === "COMPUESTO" && val.producto.usa_serie)
    )) {
      let body = JSON.stringify({
        cantidad: el.cantidad,
        tipomovimiento: "INGRESO",
        descripcion: descripcion,
        motivo: motivo.id,
        motivo_movimiento: motivo.id,
        series: el.series ?? [],
        producto: el.producto.id,
        bodega: bodega.id,
        usuario: userInfo.id,
        proveedor: proveedor?.id ?? null,
      });
      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: body,
      };
      //aconsole.log(body);
      await fetch(
        "https://backterra.econtel.com.ec/api/v1/movimiento/",
        requestOptions
      )
        .then(async (response) => {
          if (response.ok) {
            return response.text();
          }
          let mensaje = await response.json();
          throw {
            ...mensaje,
          };
        })
        // eslint-disable-next-line no-loop-func
        .then((result) => {
          //aconsole.log(result);
          cantidad += 1;
        })
        .catch((error) => {
          setCargando(false);

          error2 = true;
          Swal.fire({
            icon: "error",
            text: typeof error === "string" ? error : JSON.stringify(error),
          });
          return;
        });

      if (error2) {
        setCargando(false);

        return;
      }
    }
    for (const el of lista.filter(
      (val) => val.producto.tipo2 === "COMPUESTO" && val.producto.usa_serie
    )) {
      let body = JSON.stringify({
        cantidad: el.cantidad,
        tipomovimiento: "INGRESO",
        descripcion: descripcion,
        motivo: motivo.id,
        motivo_movimiento: motivo.id,
        series: el.series ?? [],
        producto: el.producto.id,
        bodega: bodega.id,
        usuario: userInfo.id,
        proveedor: proveedor?.id ?? null,
        garantia: el.garantia,
      });
      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: body,
      };
      //aconsole.log(body);
      await fetch(
        "https://backterra.econtel.com.ec/api/v1/movimientoCompuesto/",
        requestOptions
      )
        .then(async (response) => {
          if (response.ok) {
            return response.text();
          }
          let mensaje = await response.json();
          throw {
            ...mensaje,
          };
        })
        // eslint-disable-next-line no-loop-func
        .then((result) => {
          //aconsole.log(result);
          cantidad += 1;
        })
        .catch((error) => {
          setCargando(false);

          error2 = true;
          Swal.fire({
            icon: "error",
            text: typeof error === "string" ? error : JSON.stringify(error),
          });
          return;
        });

      if (error2) {
        setCargando(false);

        return;
      }
    }

    if (lista.length === cantidad) {
      limpiar();
      setLista([]);
      setProveedor("");
      setBodega("");
      setDescripcion("");
      setMotivo("");
      setCargando(false);

      Swal.fire({ icon: "success", text: "INGRESO REALIZADO" });
    } else {
      setCargando(false);

      Swal.fire({
        icon: "error",
        text: "Ocurrió un problema con un producto ",
      });
    }
  }

  function limpiar() {
    setNuevaSerie("");
    setCantidad("");
    setProducto("");
    setGarantia("NO");
    setSeleccionado([]);
    setId("");
  }

  function handleOpen(row) {
    setId(row.id);
    setCantidad(row.cantidad);
    setProducto(row.producto);
    setOpen(true);
  }

  function handleOpen2(row) {
    setLista(lista.filter((val) => val.id !== row.id));
  }

  function validar() {
    setCargando(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/api/series_disponibles/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setCargando(false);
        //aconsole.log(JSON.parse(result));
        let seriesD = JSON.parse(result);
        let datos = [];
        let ser = [
          ...seriesD.map((val) => {
            return val.serie;
          }),
        ];
        for (const el of seleccionado) {
          if (ser.includes(el.serie)) {
            datos.push(el.serie);
          }
        }
        if (datos.length > 0) {
          Swal.fire({
            icon: "error",
            text: "Las series: " + datos + " ya se encuentran registradas",
          });
        } else {
          let prod = null;
          let lista2 = [];
          for (const el of lista) {
            if (el.id === id) {
              prod = el;
            } else {
              lista2.push(el);
            }
          }
          setLista([
            ...lista2,
            {
              ...prod,
              garantia: garantia,
              series: [
                ...seleccionado.map((val) => {
                  return val.serie;
                }),
              ],
            },
          ]);
          setOpen2(false);
          limpiar();
        }
      })
      .catch((error) => {
        setCargando(false);
        Swal.fire({
          icon: "error",
          text: "Error al comprobar si las series son únicas",
        });
      });
  }

  const columns = [
    {
      field: "a",
      headerClassName: "super-app-theme--header",
      headerName: "Cod Producto",
      flex: 1,
      valueGetter: (params) => {
        return params.row.producto.codigo;
      },
    },
    {
      field: "b",
      headerClassName: "super-app-theme--header",
      headerName: "Producto",
      flex: 1,
      valueGetter: (params) => {
        return params.row.producto.nombre;
      },
    },
    {
      field: "cantidad",
      headerClassName: "super-app-theme--header",
      headerName: "Cantidad",
      flex: 1,
    },
    {
      field: "tipo2",
      headerClassName: "super-app-theme--header",
      headerName: "Tipo",
      flex: 1,
      valueGetter: (params) => {
        return params.row.producto.tipo2;
      },
    },
    {
      field: "usa_serie",
      headerClassName: "super-app-theme--header",
      headerName: "Usa Serie",
      flex: 1,
      valueGetter: (params) => {
        return params.row.producto.usa_serie ? "SI" : "NO";
      },
    },
    {
      field: "series",
      headerClassName: "super-app-theme--header",
      headerName: "Series",
      flex: 1,
      valueGetter: (params) => {
        return params.row.producto.usa_serie &&
          params.row.producto.tipo2 === "COMPUESTO"
          ? params.row.series.length + " Garantía: " + params.row.garantia
          : params.row.series.length;
      },
    },

    {
      field: "1",
      headerClassName: "super-app-theme--header",
      headerName: "Acciones",
      flex: 1,
      renderCell: (params) => (
        <Stack direction={"row"} spacing={2}>
          {params.row.producto.usa_serie && (
            <IconButton
              onClick={() => {
                setId(params.row.id);
                getSeries(params.row.producto);
                setProducto(params.row.producto);
                setGarantia(params.row.garantia);

                setSeleccionado([
                  ...params.row.series.map((val) => {
                    return { serie: val };
                  }),
                ]);
                if (
                  params.row.producto.tipo2 === "COMPUESTO" &&
                  params.row.producto.usa_serie
                ) {
                  setCantidad(1);
                } else {
                  setCantidad(params.row.cantidad);
                }
                setOpen2(true);
              }}
            >
              <QrCodeIcon color="info" />
            </IconButton>
          )}
          <IconButton onClick={() => handleOpen(params.row)}>
            <EditIcon color="warning" />
          </IconButton>
          <IconButton onClick={() => handleOpen2(params.row)}>
            <DeleteIcon color="error" />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Box py={2}>
        <Paper elevation={2} sx={{ padding: "10px" }}>
          <Typography variant="h5">
            {" "}
            <strong>Nuevo Ingreso</strong>{" "}
          </Typography>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Motivo *</strong>{" "}
                </Typography>
                <Select
                  MenuProps={MenuProps}
                  fullWidth
                  size="small"
                  value={motivo}
                  onChange={(e) => setMotivo(e.target.value)}
                >
                  {motivos
                    ? motivos.map((val) => (
                        <MenuItem key={val.id} value={val}>
                          {val.motivo}
                        </MenuItem>
                      ))
                    : ""}
                </Select>
              </Box>
              <br />
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Bodega *</strong>{" "}
                </Typography>
                <Autocomplete
                  ListboxProps={{ style: { maxHeight: 150 } }}
                  size="small"
                  fullWidth
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disablePortal
                  value={bodega}
                  disabled={lista.length > 0}
                  options={bodegas}
                  getOptionLabel={(option) => option.nombre ?? ""}
                  onChange={(e, t) => {
                    setBodega(t);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Proveedor </strong>{" "}
                </Typography>
                <Autocomplete
                  ListboxProps={{ style: { maxHeight: 150 } }}
                  size="small"
                  fullWidth
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disablePortal
                  value={proveedor}
                  options={proveedores}
                  getOptionLabel={(option) => option.nombre ?? ""}
                  onChange={(e, t) => {
                    setProveedor(t);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <br />
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Descripción *</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  size="small"
                  value={descripcion}
                  onChange={(e) => setDescripcion(e.target.value)}
                />
              </Box>
            </Grid>
          </Grid>
          <br />
          <Button
            color="success"
            variant="contained"
            disabled={bodega === ""}
            onClick={() => setOpen(true)}
          >
            SELECCIONAR PRODUCTO
          </Button>

          <br />
          <br />
          <Paper elevation={2} sx={{ overflowX: "scroll", padding: "10px" }}>
            <Box
              sx={{
                height: 300,
                minWidth: 1000,
                "& .super-app-theme--header": {
                  backgroundColor: "#428bd4",
                  color: "white",
                  fontSize: "16px",
                },
              }}
            >
              <DataGrid
                disableColumnMenu
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
                rows={lista}
                columns={columns}
                getRowId={(row) => row.id}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                slots={{ toolbar: CustomToolbar }}
              />
            </Box>
          </Paper>
          <br />
          {lista.filter(
            (val) =>
              (val.producto.usa_serie &&
                val.producto.tipo2 !== "COMPUESTO" &&
                val.series.length !== val.cantidad) ||
              (val.producto.usa_serie &&
                val.producto.tipo2 === "COMPUESTO" &&
                val.series.length !== 1)
          ).length > 0 && (
            <Typography variant="h5" color="red">
              <strong>
                Hay productos que no tienen las series seleccionadas
              </strong>
            </Typography>
          )}
          <Button
            color="success"
            variant="contained"
            disabled={
              bodega === "" ||
              lista.length === 0 ||
              descripcion === "" ||
              lista.filter(
                (val) =>
                  (val.producto.usa_serie &&
                    val.producto.tipo2 !== "COMPUESTO" &&
                    val.series.length !== val.cantidad) ||
                  (val.producto.usa_serie &&
                    val.producto.tipo2 === "COMPUESTO" &&
                    val.series.length !== 1)
              ).length > 0 ||
              motivo === ""
            }
            onClick={() => comprar()}
          >
            Realizar Ingreso
          </Button>
        </Paper>
      </Box>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          limpiar();
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {" "}
          <strong>{id !== "" ? "Editar " : "Crear "} Ingreso</strong>{" "}
        </DialogTitle>
        <DialogContent sx={{ minHeight: 300 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Producto *</strong>{" "}
                </Typography>
                <Autocomplete
                  ListboxProps={{ style: { maxHeight: 150 } }}
                  size="small"
                  fullWidth
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disablePortal
                  value={producto}
                  disabled={id !== ""}
                  options={productos}
                  getOptionLabel={(option) =>
                    option.codigo ? option.codigo + ": " + option.nombre : ""
                  }
                  onChange={(e, t) => {
                    if (t === null) {
                      setProducto("");
                    } else {
                      setProducto(t);
                    }
                    setCantidad("");
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Cantidad *</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={cantidad}
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={(e) => setCantidad(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Unidad Real: </strong>{" "}
                  {producto?.unidad?.unidad
                    ? producto?.unidad_real + " " + producto?.unidad?.unidad
                    : producto?.unidad_real}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Button
                  color="success"
                  variant="contained"
                  disabled={
                    producto === "" ||
                    cantidad === "" ||
                    (producto?.tipo2 === "COMPUESTO" &&
                      producto?.usa_serie &&
                      cantidad > producto.unidad_real)
                  }
                  onClick={() => {
                    //aconsole.log(producto);
                    if (id !== "") {
                      setLista([
                        ...lista.filter((val) => val.id !== id),
                        {
                          id: id,
                          producto: producto,
                          cantidad: Number(cantidad),
                          garantia: "NO",
                          series: [],
                        },
                      ]);
                    } else {
                      if (
                        producto !== "" &&
                        producto.tipo2 === "COMPUESTO" &&
                        producto.usa_serie
                      ) {
                        setLista([
                          ...lista,
                          {
                            id: numero,
                            producto: producto,
                            garantia: "NO",
                            cantidad: Number(cantidad),
                            series: [],
                          },
                        ]);
                        setNumero(numero + 1);
                      } else {
                        setLista([
                          ...lista.filter(
                            (val) => val.producto.id !== producto.id
                          ),
                          {
                            id: numero,
                            garantia: "NO",
                            producto: producto,
                            cantidad: Number(cantidad),
                            series: [],
                          },
                        ]);
                        setNumero(numero + 1);
                      }
                    }
                    setOpen(false);
                    limpiar();
                  }}
                >
                  Guardar
                </Button>
              </Box>
            </Grid>
            {producto?.tipo2 === "COMPUESTO" &&
              producto?.usa_serie &&
              cantidad > producto.unidad_real && (
                <Grid item xs={12}>
                  <Typography variant="h6" color="red">
                    <strong>
                      En productos compuestos con series no se puede colocar
                      cantidades mayores a su unidad real
                    </strong>
                  </Typography>
                </Grid>
              )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen(false);
              limpiar();
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open2}
        onClose={() => {
          setOpen2(false);
          limpiar();
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {" "}
          <strong>Ingresar Serie</strong>{" "}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Producto: </strong> {producto.codigo}{" "}
                  {" - " + producto.nombre}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Cantidad: </strong>
                  {cantidad}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Garantia *</strong>{" "}
                </Typography>
                <Select
                  fullWidth
                  size="small"
                  value={garantia}
                  onChange={(e) => setGarantia(e.target.value)}
                >
                  <MenuItem key={0} value={"SI"}>
                    {"SI"}
                  </MenuItem>
                  <MenuItem key={1} value={"NO"}>
                    {"NO"}
                  </MenuItem>
                </Select>
              </Box>
              <br />
              <Box px={2}>
                <Stack direction={"row"} spacing={2}>
                  <Button
                    variant="contained"
                    color="info"
                    onClick={handleClick}
                  >
                    Subir Series Nuevas EXCEL
                  </Button>
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleImport}
                    style={{ display: "none" }}
                  />
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleExport}
                  >
                    Descargar Formato EXCEL
                  </Button>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Nueva Serie: </strong>{" "}
                </Typography>
                <Stack direction={"row"} spacing={2}>
                  <TextField
                    fullWidth
                    size="small"
                    value={nuevaSerie}
                    onChange={(e) =>
                      setNuevaSerie(
                        e.target.value
                          ? e.target.value?.toUpperCase()
                          : e.target.value
                      )
                    }
                  />
                  <Button
                    color="success"
                    variant="contained"
                    disabled={nuevaSerie === ""}
                    onClick={() => {
                      if (
                        ![
                          ...seleccionado.map((val) => {
                            return val.serie;
                          }),
                        ].includes(nuevaSerie)
                      ) {
                        if (
                          lista.filter(
                            (val) =>
                              val.producto.id === producto.id &&
                              val.series.includes(nuevaSerie)
                          ).length === 0
                        ) {
                          setSeleccionado(
                            seleccionado.concat([
                              {
                                serie: nuevaSerie,
                              },
                            ])
                          );
                        }
                      }
                      setNuevaSerie("");
                    }}
                  >
                    Agregar
                  </Button>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Paper
                elevation={2}
                sx={{ overflowX: "scroll", padding: "10px" }}
              >
                <Box
                  sx={{
                    height: 300,
                    // minWidth: 1000,
                    "& .super-app-theme--header": {
                      backgroundColor: "#428bd4",
                      color: "white",
                      fontSize: "16px",
                    },
                  }}
                >
                  <DataGrid
                    disableColumnMenu
                    getRowHeight={() => "auto"}
                    getEstimatedRowHeight={() => 200}
                    rows={seleccionado}
                    columns={[
                      {
                        field: "serie",
                        headerClassName: "super-app-theme--header",
                        headerName: "SERIE",
                        flex: 1,
                      },
                      {
                        field: "1",
                        headerClassName: "super-app-theme--header",
                        headerName: "Eliminar",
                        flex: 1,
                        renderCell: (params) => (
                          <IconButton onClick={() => handleOpen3(params.row)}>
                            <DeleteIcon color="error" />
                          </IconButton>
                        ),
                      },
                    ]}
                    getRowId={(row) => row.serie}
                    localeText={
                      esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    slots={{ toolbar: CustomToolbar }}
                  />
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <br />
                <Button
                  color="success"
                  variant="contained"
                  disabled={
                    producto === "" ||
                    cantidad === "" ||
                    id === "" ||
                    seleccionado.length !== cantidad
                  }
                  onClick={() => validar()}
                >
                  Guardar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen2(false);
              limpiar();
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Cargando open2={cargando} />
    </>
  );
};
