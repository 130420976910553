import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { validarRoles } from "../../components/utils/ValidarRoles";

export const ListClientes = ({ info }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton component={Link} onClick={handleClick}>
        <ListItemIcon>
          <AssignmentIndIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{ fontWeight: "bold" }}
          primary="Clientes"
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding dense>
          {validarRoles("LEER CLIENTES", info) && (
            <ListItemButton
              component={Link}
              to={"clientes/admin"}
              sx={{ pl: 8 }}
            >
              <ListItemText primary="Administrar Clientes" />
            </ListItemButton>
          )}

          {validarRoles("CREAR CLIENTES", info) && (
            <ListItemButton
              component={Link}
              to={"clientes/wisphub"}
              sx={{ pl: 8 }}
            >
              <ListItemText primary="Crear Clientes" />
            </ListItemButton>
          )}
        </List>
      </Collapse>
    </>
  );
};
