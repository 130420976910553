import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useState } from "react";
import { MenuAccount } from "./MenuAccount";
import logo from "../../assets/logo.png";
import { Items } from "../../pages/list/ListItems";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export const Nav = (props) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  let theme = createTheme({
    palette: {
      mode: "dark",
    },
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            boxSizing: "border-box",
          },
        },
      },
    },
  });

  return (
    <>
      {props.isAuth && (
        <>
          <Box pb={5} sx={{ flexGrow: 1 }}>
            <AppBar pb={5} variant="outlined" position="absolute" elevation={0}>
              <Toolbar variant="dense">
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 0 }}
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
                <img src={logo} alt="logo" width={150} />
                v1.1.4
                <Typography sx={{ flexGrow: 1 }}></Typography>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 1.5 }}>
                  <MenuAccount />
                </Box>
              </Toolbar>
            </AppBar>
          </Box>
          <Box
            component="nav"
            sx={{ width: { sm: 250 }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <Drawer
              open={mobileOpen}
              onClose={handleDrawerToggle}
              sx={{
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: 250,
                  backgroundColor: "secondary.main",
                  color: "white",
                },
              }}
            >
              <ThemeProvider theme={theme}>
                <Toolbar
                  style={{ justifyContent: "flex-end", padding: "10px" }}
                >
                  <IconButton size="large" onClick={handleDrawerToggle}>
                    <ChevronLeftIcon fontSize="large" sx={{ color: "white" }} />
                  </IconButton>
                </Toolbar>
                <List disablePadding dense>
                  <Items />
                </List>
              </ThemeProvider>
            </Drawer>
          </Box>
          <Box pt={4}></Box>
        </>
      )}
      {props.children}
    </>
  );
};
