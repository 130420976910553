import thunk from 'redux-thunk'
import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import { authReducer } from './reducers/AuthReducer';

const reducer = combineReducers({
    userAuth: authReducer,
})
export const replaceItem = (collection, item) => {
    const index = collection.findIndex((entry) => entry.id === item.id);
    return [...collection.slice(0, index), item, ...collection.slice(index + 1)];
};
const middleware = [thunk]
const store = createStore(reducer,
    composeWithDevTools(applyMiddleware(...middleware)))
export default store