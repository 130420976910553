import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import InventoryIcon from "@mui/icons-material/Inventory";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { validarRoles } from "../../components/utils/ValidarRoles";

export const ListInventario = ({ info }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton component={Link} onClick={handleClick}>
        <ListItemIcon>
          <InventoryIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{ fontWeight: "bold" }}
          primary="Inventario"
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding dense>
          {validarRoles("LEER BODEGA", info) && (
            <ListItemButton
              component={Link}
              to={"inventario/bodegas"}
              sx={{ pl: 8 }}
            >
              <ListItemText primary="Bodegas" />
            </ListItemButton>
          )}

          {validarRoles("LEER PRODUCTO", info) && (
            <ListItemButton
              component={Link}
              to={"inventario/productos"}
              sx={{ pl: 8 }}
            >
              <ListItemText primary="Productos" />
            </ListItemButton>
          )}

          {validarRoles("LEER PROVEEDOR", info) && (
            <ListItemButton
              component={Link}
              to={"inventario/proveedores"}
              sx={{ pl: 8 }}
            >
              <ListItemText primary="Proveedores" />
            </ListItemButton>
          )}

          {validarRoles("LEER STOCK", info) && (
            <ListItemButton
              component={Link}
              to={"inventario/stock"}
              sx={{ pl: 8 }}
            >
              <ListItemText primary="Stock" />
            </ListItemButton>
          )}

          {validarRoles("LEER SERIES", info) && (
            <ListItemButton
              component={Link}
              to={"inventario/series"}
              sx={{ pl: 8 }}
            >
              <ListItemText primary="Series" />
            </ListItemButton>
          )}

          {validarRoles("LEER MOVIMIENTOS", info) && (
            <ListItemButton
              component={Link}
              to={"inventario/movimientos"}
              sx={{ pl: 8 }}
            >
              <ListItemText primary="Historial Movimientos" />
            </ListItemButton>
          )}

           {validarRoles("REALIZAR MOVIMIENTOS", info) && (
            <ListItemButton
              component={Link}
              to={"inventario/movimientos2"}
              sx={{ pl: 8 }}
            >
              <ListItemText primary="Realizar Movimientos" />
            </ListItemButton>
          )}

          {validarRoles("LEER MOTIVOS MOVIMIENTOS", info) && (
            <ListItemButton
              component={Link}
              to={"inventario/motivoMovimiento"}
              sx={{ pl: 8 }}
            >
              <ListItemText primary="Motivo Movimiento" />
            </ListItemButton>
          )}

          {validarRoles("LEER PARAMETROS INVENTARIO", info) && (
            <ListItemButton
              component={Link}
              to={"inventario/parametros"}
              sx={{ pl: 8 }}
            >
              <ListItemText primary="Parámetros" />
            </ListItemButton>
          )}
        </List>
      </Collapse>
    </>
  );
};
