import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { CustomToolbar } from "../../../components/utils/CustomFilter";
import Swal from "sweetalert2";
import moment from "moment";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate } from "react-router-dom";
import { Cargando } from "../../../components/utils/Cargando";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { FiltroFechas } from "../../../components/utils/FiltroFechas";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";

export const Trabajo = () => {
  const [cargando, setCargando] = useState(false);

  const [lista, setLista] = useState(null);
  const navigate = useNavigate();

  const [value, setValue] = useState([
    startOfDay(subDays(new Date(), 6)),
    endOfDay(new Date()),
  ]);

  useEffect(() => {
    cargar();
  }, []);

  function cargar(inicio, fin) {
    setCargando(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    let url = "https://backterra.econtel.com.ec/api/api/hojas";
    if (inicio && fin) {
      url =
        "https://backterra.econtel.com.ec/api/api/hojas?" +
        "inicio=" +
        inicio +
        "&fin=" +
        fin;
    } else {
      url =
        "https://backterra.econtel.com.ec/api/api/hojas?" +
        "inicio=" +
        value[0].toISOString() +
        "&fin=" +
        value[1].toISOString();
    }
    fetch(url, requestOptions)
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setLista(
          JSON.parse(result).sort((a, b) => {
            return b.id - a.id;
          })
        );
        setCargando(false);
      })
      .catch((error) => {
        setCargando(false);

        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  const columns = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "Hoja",
      flex: 1,
      valueGetter: (params) => {
        return "HOJA-" + params.row.id;
      },
    },
    {
      field: "estado",
      headerClassName: "super-app-theme--header",
      headerName: "Estado",
      flex: 1,
    },
    {
      field: "created_at",
      headerClassName: "super-app-theme--header",
      headerName: "Fecha",
      flex: 1,
      valueGetter: (params) => {
        return params.row.created_at
          ? moment(params.row.created_at).format("DD/MMMM/yyyy HH:mm")
          : "";
      },
    },
    {
      field: "id_actividad",
      headerClassName: "super-app-theme--header",
      headerName: "Actividad",
      flex: 1,
      valueGetter: (params) => {
        return params.row.id_actividad
          ? "ACTIVIDAD-" + params.row.id_actividad
          : "";
      },
    },
    {
      field: "solicitud",
      headerClassName: "super-app-theme--header",
      headerName: "Solicitud",
      flex: 1,
      valueGetter: (params) => {
        return params.row.solicitud ? "SOLICITUD-" + params.row.solicitud : "";
      },
    },
    {
      field: "tipo",
      headerClassName: "super-app-theme--header",
      headerName: "Tipo",
      flex: 1,
    },
    {
      field: "cliente",
      headerClassName: "super-app-theme--header",
      headerName: "Cliente",
      flex: 1.5,
      valueGetter: (params) => {
        return params.row.cedula
          ? params.row.nombres_cliente + " - " + params.row.cedula
          : "";
      },
    },
    {
      field: "1",
      headerClassName: "super-app-theme--header",
      headerName: "Ver",
      width: 75,
      renderCell: (params) => (
        <>
          {params.row.id_actividad && (
            <IconButton
              onClick={() =>
                navigate("/tecnico/actividad/" + params.row.id_actividad)
              }
            >
              <RemoveRedEyeIcon color="warning" />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  const _exporter = React.createRef();
  const excelExport = () => {
    setCargando(true);
    if (_exporter.current) {
      _exporter.current.save();
    }
    setCargando(false);
  };

  return (
    <Box px={2} py={2}>
      <Paper elevation={2} sx={{ padding: "10px" }}>
        <Typography variant="h5">
          {" "}
          <strong>Hojas de Trabajo</strong>{" "}
        </Typography>
        <br />
        <FiltroFechas metodo={cargar} value={value} setValue={setValue} />
        <Stack direction={"row"} spacing={2}>
          <Button
            size="small"
            color="success"
            variant="contained"
            disabled={lista?.length === 0}
            onClick={excelExport}
          >
            Exportar Excel
          </Button>
        </Stack>
        <br />
        <Paper elevation={2} sx={{ overflowX: "scroll", padding: "10px" }}>
          <Box
            sx={{
              height: 600,
              minWidth: 1000,
              "& .super-app-theme--header": {
                backgroundColor: "#428bd4",
                color: "white",
                fontSize: "16px",
              },
            }}
          >
            <DataGrid
              disableColumnMenu
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}
              rows={lista ?? []}
              loading={lista === null}
              columns={columns}
              getRowId={(row) => row.id}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              slots={{ toolbar: CustomToolbar }}
            />
          </Box>
        </Paper>
      </Paper>
      <ExcelExport
        data={
          lista
            ? [
                ...lista.map((val) => {
                  return {
                    ...val,
                    Fecha: val.tiempo_fin
                      ? moment(val.tiempo_fin).format("YYYY-MM-DD HH:mm")
                      : "",
                    Estado_Actividad: val.estado_actividad ?? "",
                    Descripción: val.tipo ?? "",
                    Cliente: val.nombres_cliente ?? "",
                    FotoRetiro: val.datos?.FotoEquiposretirados
                      ? val.datos?.FotoEquiposretirados
                      : val.datos
                          ?.FotoEquiposretiradosencasodecambiodealgúnequipo ??
                        "",
                    Grupo: val.cuadrilla ?? "",
                  };
                }),
              ]
            : []
        }
        filterable={true}
        fileName={
          "Hojas de Trabajo " +
          moment(value[0]).format("DD-MM-YYYY") +
          "  " +
          moment(value[1]).format("DD-MM-YYYY") +
          ".xlsx"
        }
        ref={_exporter}
      >
        {["Fecha", "Estado_Actividad", "Descripción"].map((val) => (
          <ExcelExportColumn
            key={val}
            field={val}
            title={val.toUpperCase()}
            width={150}
          />
        ))}
        {["FotoRetiro", "Cliente"].map((val) => (
          <ExcelExportColumn
            key={val}
            field={val}
            title={val.toUpperCase()}
            width={250}
          />
        ))}
        {["Grupo"].map((val) => (
          <ExcelExportColumn
            key={val}
            field={val}
            title={val.toUpperCase()}
            width={150}
          />
        ))}
      </ExcelExport>
      <Cargando open2={cargando} />
    </Box>
  );
};
