import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { CustomToolbar } from "../../components/utils/CustomFilter";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { MenuProps } from "../../components/utils/MenuProps";
import { useSelector } from "react-redux";
import moment from "moment";
import { Cargando } from "../../components/utils/Cargando";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { FiltroFechas } from "../../components/utils/FiltroFechas";

export const AprobarSolicitud = () => {
  const [cargando, setCargando] = useState(false);

  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;
  const [open, setOpen] = useState(false);
  const [descripcion, setDescripcion] = useState("");
  const [id, setId] = useState("");
  const [tipo, setTipo] = useState("");

  const [lista, setLista] = useState(null);

  const [cedula, setCedula] = useState("");
  const [direccion, setDireccion] = useState("");
  const [latitud, setLatitud] = useState("");
  const [longitud, setLongitud] = useState("");
  const [telefono, setTelefono] = useState("");
  const [celular, setCelular] = useState("");
  const [correo, setCorreo] = useState("");
  const [plan, setPlan] = useState("");
  const [horarios, setHorarios] = useState("");
  const [latitudAnterior, setLatitudAnterior] = useState("");
  const [longitudAnterior, setLongitudAnterior] = useState("");
  const [direccionAnterior, setDireccionAnterior] = useState("");
  const [potencia, setPotencia] = useState("");
  const [tecnologia, setTecnologia] = useState("");
  const [nombre, setNombre] = useState("");
  const [observacion, setObservacion] = useState("");

  const [pisos, setPisos] = useState("");
  const [gestion, setGestion] = useState("");
  const [equipos, setEquipos] = useState("");

  const [value, setValue] = useState([
    startOfDay(subDays(new Date(), 6)),
    endOfDay(new Date()),
  ]);

  useEffect(() => {
    cargar();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function limpiar() {
    setDescripcion("");
    setTipo("");
    setId("");

    setCedula("");
    setDireccion("");
    setLatitud("");
    setLongitud("");
    setTelefono("");
    setCelular("");
    setCorreo("");
    setPlan("");
    setHorarios("");
    setLatitudAnterior("");
    setLongitudAnterior("");
    setDireccionAnterior("");
    setPotencia("");
    setTecnologia("");
    setNombre("");
    setObservacion("");
  }
  function handleOpen(row) {
    setTipo(row.tipo);
    setId(row.id);

    if (row.tipo === "INSTALACION") {
      setCedula(row.cedula);
      setDireccion(row.direccion);
      setLatitud(row.latitud);
      setLongitud(row.longitud);
      setTelefono(row.telefono);
      setCelular(row.celular);
      setCorreo(row.correo_electronico);
      setPlan(row.plan_contratar);
      setHorarios(row.horarios_disponibles);
      setNombre(row.nombres_cliente);
      setDireccion(row.direccion);
      setPisos(row.casa_npisos);
    } else if (row.tipo === "CAMBIO DOMICILIO") {
      setCedula(row.cedula);
      setDireccion(row.direccion);
      setLatitud(row.latitud);
      setLongitud(row.longitud);
      setLatitudAnterior(row.latitud_anterior);
      setLongitudAnterior(row.longitud_anterior);
      setTelefono(row.telefono);
      setCelular(row.celular);
      setEquipos(row.cliente_tiene_equipo);
      setHorarios(row.horarios_disponibles);
      setNombre(row.nombres_cliente);
      setDireccionAnterior(row.direccion_anterior);
    } else if (row.tipo === "MIGRACION") {
      setCedula(row.cedula);
      setDireccion(row.direccion);
      setLatitud(row.latitud);
      setLongitud(row.longitud);
      setTelefono(row.telefono);
      setCelular(row.celular);
      setHorarios(row.horarios_disponibles);
      setNombre(row.nombres_cliente);
    } else if (
      row.tipo === "OTRO" ||
      row.tipo === "CAMBIO DE TITULAR" ||
      row.tipo === "RETIRO"
    ) {
      setCedula(row.cedula);
      setDireccion(row.direccion);
      setLatitud(row.latitud);
      setLongitud(row.longitud);
      setTelefono(row.telefono);
      setCelular(row.celular);
      setNombre(row.nombres_cliente);
      setGestion(row.gestion_operador);
    } else {
      setCedula(row.cedula);
      setDireccion(row.direccion);
      setLatitud(row.latitud);
      setLongitud(row.longitud);
      setTelefono(row.telefono);
      setCelular(row.celular);
      setNombre(row.nombres_cliente);
      setTecnologia(row.tecnologia);
      setPotencia(row.senal_potencia);
      setGestion(row.gestion_operador);
    }
    setObservacion(row.observacion_diagnostico);
    setOpen(true);
  }

  function editar() {
    setCargando(true);
    if (id === "") {
      return;
    }
    let body = JSON.stringify({
      estado: "APROBADO",
      usuario_revision: userInfo.id,
      descripcion: "APROBADO",
    });

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/solicitud/" + id + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setOpen(false);
        Swal.fire({ icon: "success", text: "Solicitud actualizada " });
        cargar();
      })
      .catch((error) => {
        setCargando(false);
        setOpen(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function eliminar() {
    setCargando(true);
    if (id === "") {
      return;
    }
    let body = JSON.stringify({
      estado: "RECHAZADO",
      usuario_revision: userInfo.id,
      descripcion: descripcion,
    });

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/solicitud/" + id + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setOpen(false);
        Swal.fire({ icon: "success", text: "Solicitud actualizada " });
        cargar();
      })
      .catch((error) => {
        setCargando(false);
        setOpen(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function cargar(inicio, fin) {
    setCargando(true);

    limpiar();
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    let url =
      "https://backterra.econtel.com.ec/api/api/solicitudes?estado=PENDIENTE";
    if (inicio && fin) {
      url =
        "https://backterra.econtel.com.ec/api/api/solicitudes?estado=PENDIENTE" +
        "&inicio=" +
        inicio +
        "&fin=" +
        fin;
    } else {
      url =
        "https://backterra.econtel.com.ec/api/api/solicitudes?estado=PENDIENTE" +
        "&inicio=" +
        value[0].toISOString() +
        "&fin=" +
        value[1].toISOString();
    }
    fetch(url, requestOptions)
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        // JSON.parse(result).filter((val) => val.estado === "PENDIENTE")

        setLista(
          JSON.parse(result).sort((a, b) => {
            return b.id - a.id;
          })
        );
        setCargando(false);
      })
      .catch((error) => {
        setCargando(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  const columns = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "Solicitud",
      flex: 1,
      valueGetter: (params) => {
        return "SOLICITUD-" + params.row.id;
      },
    },
    {
      field: "cedula",
      headerClassName: "super-app-theme--header",
      headerName: "Cédula",
      flex: 1,
    },
    {
      field: "nombres_cliente",
      headerClassName: "super-app-theme--header",
      headerName: "Cliente",
      flex: 1,
    },
    {
      field: "tipo",
      headerClassName: "super-app-theme--header",
      headerName: "Tipo",
      flex: 1,
    },
    {
      field: "estado",
      headerClassName: "super-app-theme--header",
      headerName: "Estado",
      flex: 1,
    },
    {
      field: "usuario_solicitud",
      headerClassName: "super-app-theme--header",
      headerName: "Creación",
      flex: 1,
      valueGetter: (params) => {
        return params.row.creado_por ?? "";
      },
    },
    {
      field: "fecha",
      headerClassName: "super-app-theme--header",
      headerName: "Fecha",
      flex: 1,
      valueGetter: (params) => {
        return (
          moment(
            new Date(params.row.created_at).getTime() - 5 * 60 * 60 * 1000
          ).format("DD/MMMM/yyyy HH:mm") ?? ""
        );
      },
    },
    {
      field: "1",
      headerClassName: "super-app-theme--header",
      headerName: "Revisar",
      flex: 1,
      renderCell: (params) => (
        <Stack direction={"row"} spacing={2}>
          <IconButton onClick={() => handleOpen(params.row)}>
            <EditIcon color="warning" />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Box px={2} py={2}>
        <Paper elevation={2} sx={{ padding: "10px" }}>
          <Typography variant="h5">
            {" "}
            <strong>Revisar Solicitudes</strong>{" "}
          </Typography>
          <br />
          <FiltroFechas metodo={cargar} value={value} setValue={setValue} />

          <Paper elevation={2} sx={{ overflowX: "scroll", padding: "10px" }}>
            <Box
              sx={{
                height: 600,
                minWidth: 1000,
                "& .super-app-theme--header": {
                  backgroundColor: "#428bd4",
                  color: "white",
                  fontSize: "16px",
                },
              }}
            >
              <DataGrid
                disableColumnMenu
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
                rows={lista ?? []}
                loading={lista === null}
                columns={columns}
                getRowId={(row) => row.id}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                slots={{ toolbar: CustomToolbar }}
              />
            </Box>
          </Paper>
        </Paper>
      </Box>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          limpiar();
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          {" "}
          <strong>{id !== "" ? "Revisar " : "Crear "} Solicitud</strong>{" "}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Identificación Cliente</strong>{" "}
                </Typography>
                <TextField
                  disabled
                  fullWidth
                  size="small"
                  value={cedula}
                  onChange={(e) => {
                    setCedula(e.target.value);
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Tipo Solicitud</strong>{" "}
                </Typography>
                <Select
                  fullWidth
                  disabled
                  size="small"
                  MenuProps={MenuProps}
                  value={tipo}
                  onChange={(e) => {
                    setTipo(e.target.value);
                  }}
                >
                  <MenuItem key={1} value={"INSTALACION"}>
                    {" "}
                    {"INSTALACION"}{" "}
                  </MenuItem>
                  <MenuItem key={2} value={"CAMBIO DOMICILIO"}>
                    {" "}
                    {"CAMBIO DOMICILIO"}{" "}
                  </MenuItem>
                  <MenuItem key={3} value={"VISITA TECNICA"}>
                    {" "}
                    {"VISITA TECNICA"}{" "}
                  </MenuItem>
                  <MenuItem key={4} value={"MIGRACION"}>
                    {" "}
                    {"MIGRACION"}{" "}
                  </MenuItem>
                  <MenuItem key={5} value={"RETIRO"}>
                    {" "}
                    {"RETIRO"}{" "}
                  </MenuItem>
                  <MenuItem key={6} value={"CAMBIO DE TITULAR"}>
                    {" "}
                    {"CAMBIO DE TITULAR"}{" "}
                  </MenuItem>
                  <MenuItem key={7} value={"OTRO"}>
                    {" "}
                    {"OTRO"}{" "}
                  </MenuItem>
                </Select>
              </Box>
            </Grid>

            {tipo === "INSTALACION" && (
              <>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Nombre Completo *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Dirección *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={direccion}
                      onChange={(e) => setDireccion(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Latitud *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={latitud}
                      onChange={(e) => setLatitud(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Longitud *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={longitud}
                      onChange={(e) => setLongitud(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Teléfono *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={telefono}
                      onChange={(e) => setTelefono(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Celular *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={celular}
                      onChange={(e) => setCelular(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Correo *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={correo}
                      onChange={(e) => setCorreo(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Plan *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={plan}
                      onChange={(e) => setPlan(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Horarios disponibles *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      multiline
                      rows={3}
                      value={horarios}
                      onChange={(e) => setHorarios(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Observación</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      multiline
                      rows={3}
                      value={observacion}
                      onChange={(e) => setObservacion(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Casas #Pisos *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={pisos}
                      onChange={(e) => setPisos(e.target.value)}
                    />
                  </Box>
                </Grid>
              </>
            )}
            {tipo === "CAMBIO DOMICILIO" && (
              <>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Nombre Completo *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Cliente tiene Equipos*</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={equipos}
                      onChange={(e) => setEquipos(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Dirección Nueva*</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={direccion}
                      onChange={(e) => setDireccion(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Dirección Anterior*</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={direccionAnterior}
                      onChange={(e) => setDireccionAnterior(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Latitud Nueva*</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={latitud}
                      onChange={(e) => setLatitud(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Longitud Nueva*</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={longitud}
                      onChange={(e) => setLongitud(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Latitud Anterior*</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={latitudAnterior}
                      onChange={(e) => setLatitudAnterior(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Longitud Anterior*</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={longitudAnterior}
                      onChange={(e) => setLongitudAnterior(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Teléfono *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={telefono}
                      onChange={(e) => setTelefono(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Celular *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={celular}
                      onChange={(e) => setCelular(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Horarios disponibles *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      multiline
                      rows={3}
                      value={horarios}
                      onChange={(e) => setHorarios(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Observación</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      multiline
                      rows={3}
                      value={observacion}
                      onChange={(e) => setObservacion(e.target.value)}
                    />
                  </Box>
                </Grid>
              </>
            )}
            {tipo === "MIGRACION" && (
              <>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Nombre Completo *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Dirección *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={direccion}
                      onChange={(e) => setDireccion(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Latitud *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={latitud}
                      onChange={(e) => setLatitud(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Longitud *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={longitud}
                      onChange={(e) => setLongitud(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Teléfono *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={telefono}
                      onChange={(e) => setTelefono(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Celular *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={celular}
                      onChange={(e) => setCelular(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Horarios disponibles *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      multiline
                      rows={3}
                      value={horarios}
                      onChange={(e) => setHorarios(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Observación</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      multiline
                      rows={3}
                      value={observacion}
                      onChange={(e) => setObservacion(e.target.value)}
                    />
                  </Box>
                </Grid>
              </>
            )}
            {tipo === "VISITA TECNICA" && (
              <>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Nombre Completo *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Dirección *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={direccion}
                      onChange={(e) => setDireccion(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Latitud *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={latitud}
                      onChange={(e) => setLatitud(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Longitud *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={longitud}
                      onChange={(e) => setLongitud(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Teléfono *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={telefono}
                      onChange={(e) => setTelefono(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Celular *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={celular}
                      onChange={(e) => setCelular(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Señal o Potencia *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={potencia}
                      onChange={(e) => setPotencia(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Tecnología *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={tecnologia}
                      onChange={(e) => setTecnologia(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Gestión Operador *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      multiline
                      rows={3}
                      value={gestion}
                      onChange={(e) => setGestion(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Observación</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      multiline
                      rows={3}
                      value={observacion}
                      onChange={(e) => setObservacion(e.target.value)}
                    />
                  </Box>
                </Grid>
              </>
            )}
            {tipo === "RETIRO" && (
              <>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Nombre Completo *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Dirección *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      disabled
                      value={direccion}
                      onChange={(e) => setDireccion(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Latitud *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled
                      size="small"
                      value={latitud}
                      onChange={(e) => setLatitud(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Longitud *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={longitud}
                      onChange={(e) => setLongitud(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Teléfono *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled
                      size="small"
                      value={telefono}
                      onChange={(e) => setTelefono(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Celular *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled
                      size="small"
                      value={celular}
                      onChange={(e) => setCelular(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Gestión Operador *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled
                      size="small"
                      multiline
                      rows={3}
                      value={gestion}
                      onChange={(e) => setGestion(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Observación</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled
                      size="small"
                      multiline
                      rows={3}
                      value={observacion}
                      onChange={(e) => setObservacion(e.target.value)}
                    />
                  </Box>
                </Grid>
              </>
            )}
            {tipo === "CAMBIO DE TITULAR" && (
              <>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Nombre Completo *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Dirección *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      disabled
                      value={direccion}
                      onChange={(e) => setDireccion(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Latitud *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled
                      size="small"
                      value={latitud}
                      onChange={(e) => setLatitud(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Longitud *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={longitud}
                      onChange={(e) => setLongitud(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Teléfono *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled
                      size="small"
                      value={telefono}
                      onChange={(e) => setTelefono(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Celular *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled
                      size="small"
                      value={celular}
                      onChange={(e) => setCelular(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Gestión Operador *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled
                      size="small"
                      multiline
                      rows={3}
                      value={gestion}
                      onChange={(e) => setGestion(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Observación</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled
                      size="small"
                      multiline
                      rows={3}
                      value={observacion}
                      onChange={(e) => setObservacion(e.target.value)}
                    />
                  </Box>
                </Grid>
              </>
            )}
            {tipo === "OTRO" && (
              <>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Nombre Completo *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Dirección *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      disabled
                      value={direccion}
                      onChange={(e) => setDireccion(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Latitud *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled
                      size="small"
                      value={latitud}
                      onChange={(e) => setLatitud(e.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Longitud *</strong>{" "}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={longitud}
                      onChange={(e) => setLongitud(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Teléfono *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled
                      size="small"
                      value={telefono}
                      onChange={(e) => setTelefono(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Celular *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled
                      size="small"
                      value={celular}
                      onChange={(e) => setCelular(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Gestión Operador *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled
                      size="small"
                      multiline
                      rows={3}
                      value={gestion}
                      onChange={(e) => setGestion(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Observación</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      disabled
                      size="small"
                      multiline
                      rows={3}
                      value={observacion}
                      onChange={(e) => setObservacion(e.target.value)}
                    />
                  </Box>
                </Grid>
              </>
            )}
            <Grid item xs={12} lg={12}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Motivo Rechazo</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  multiline
                  rows={3}
                  value={descripcion}
                  onChange={(e) => setDescripcion(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <br />
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => editar()}
                >
                  Aprobar
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <br />
                <Button
                  color="error"
                  variant="contained"
                  disabled={descripcion === ""}
                  onClick={() => eliminar()}
                >
                  Rechazar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen(false);
              limpiar();
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Cargando open2={cargando} />
    </>
  );
};
