import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { MenuProps } from "../../../components/utils/MenuProps";
import { useSelector } from "react-redux";
import { Cargando } from "../../../components/utils/Cargando";
import { storage } from "../../../firebaseConfig";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import {
  uploadBytes,
  ref as ref_storage,
  getDownloadURL,
} from "firebase/storage";
import { validarRoles } from "../../../components/utils/ValidarRoles";

export const Materiales = (props) => {
  const [res, setRes] = useState({});
  const [existe, setExiste] = useState(true);

  const [id, setId] = useState(null);

  const [cargando, setCargando] = useState(false);
  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;

  const [open2, setOpen2] = useState(false);

  const [editable, setEditable] = useState(
    validarRoles("EDITAR HOJA", userInfo)
  );

  useEffect(() => {
    cargar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function cambio(texto) {
    return texto.replaceAll(" ", "");
  }

  function crear() {
    setCargando(true);

    if (
      props?.actividad?.solicitud?.tipo === "INSTALACION" ||
      props?.actividad?.solicitud?.tipo === "MIGRACION" ||
      props?.actividad?.solicitud?.tipo === "CAMBIO DOMICILIO"
    ) {
      if (res?.Coordenadascliente) {
        if (res.Coordenadascliente.trim() === "") {
          setCargando(false);
          setOpen2(false);
          Swal.fire({
            icon: "error",
            text: "Error: No has llenado las coordenadas del cliente",
          });
          return;
        }
      } else {
        setCargando(false);
        setOpen2(false);
        Swal.fire({
          icon: "error",
          text: "Error: No has llenado las coordenadas del cliente",
        });
        return;
      }
    }

    if (
      props?.actividad?.solicitud?.tipo === "INSTALACION" ||
      props?.actividad?.solicitud?.tipo === "VISITA TECNICA" ||
      props?.actividad?.solicitud?.tipo === "MIGRACION" || 
      props?.actividad?.solicitud?.tipo === "CAMBIO DOMICILIO"
    ) {
      if (res?.FotoPotenciadelacaja) {
        if (res.FotoPotenciadelacaja.trim() === "") {
          setCargando(false);
          setOpen2(false);
          Swal.fire({
            icon: "error",
            text: "Error: No ha cargado la foto de la potencia de la caja",
          });
          return;
        }
      } else {
        setCargando(false);
        setOpen2(false);
        Swal.fire({
          icon: "error",
          text: "Error: No ha cargado la foto de la potencia de la caja",
        });
        return;
      }
    }

    let body = JSON.stringify({
      estado: "SUBIDO",
      tipo: props?.actividad?.solicitud?.tipo ?? "-",
      usuario_creacion: userInfo.id,
      id_actividad: props?.actividad?.id ?? null,
      datos: {
        ...res,
      },
    });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: id ? "PATCH" : "POST",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/hojatrabajo/" +
        (id ? id + "/" : ""),
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        // crear2()
        //aconsole.log(JSON.parse(result));
        setOpen2(false);
        Swal.fire({ icon: "success", text: "Hoja Subida " });
        cargar();
      })
      .catch((error) => {
        setCargando(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  // function crear2() {
  //   let body = JSON.stringify({
  //     estado: "TERMINADO",
  //     usuario_mod: userInfo.id,
  //     // id_cuadrilla: props?.actividad?.id_cuadrilla,
  //     id_tecnico: userInfo.id,
  //   });
  //   let myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append("ngrok-skip-browser-warning", "asc");
  // myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
  //   let requestOptions = {
  //     method: "PATCH",
  //     headers: myHeaders,
  //     body: body,
  //     redirect: "follow",
  //   };
  //   fetch(
  //     "https://backterra.econtel.com.ec/api/v1/actividades/" +
  //     props?.actividad?.id +
  //     "/",
  //     requestOptions
  //   )
  //     .then(async (response) => {
  //       if (response.ok) {
  //         return response.text();
  //       }
  //       let mensaje = await response.json()
  //       throw {
  //         ...mensaje
  //       };
  //     })
  //     .then((result) => {
  //       setCargando(false)
  //       //aconsole.log(JSON.parse(result));
  //       Swal.fire({ icon: "success", text: "Hoja Subida " });
  //       cargar();
  //     })
  //     .catch((error) => {
  //       setCargando(false)
  //       Swal.fire({ icon: "error", text: "Error: " + (typeof error === "string" ? error : JSON.stringify(error)) });
  //     });
  // }

  function cargar() {
    setCargando(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (props?.actividad?.id) {
      fetch(
        "https://backterra.econtel.com.ec/api/v1/hojatrabajoby/?actividad=" +
          props?.actividad?.id,
        requestOptions
      )
        .then(async (response) => {
          if (response.ok) {
            return response.text();
          }
          let mensaje = await response.json();
          throw {
            ...mensaje,
          };
        })
        .then((result) => {
          //aconsole.log(JSON.parse(result));
          let li = JSON.parse(result);
          if (li.length > 0) {
            setRes(li[0].datos);
            setId(li[0].id);
            setExiste(true);
          } else {
            setExiste(false);
          }
          setCargando(false);
        })
        .catch((error) => {
          setCargando(false);
          setExiste(false);
          Swal.fire({
            icon: "error",
            text:
              "Error: " +
              (typeof error === "string" ? error : JSON.stringify(error)),
          });
        });
    } else {
      setCargando(false);
    }
  }

  async function uploadImage(campo, file) {
    setCargando(true);
    try {
      const storageRef = ref_storage(
        storage,
        `HOJA DE TRABAJO/ACTIVIDAD-${props?.actividad?.id}/${campo}/${file.name}`
      );
      await uploadBytes(storageRef, file).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          let copia = res;
          copia[campo.replaceAll(" ", "")] = downloadURL;
          setRes({
            ...copia,
          });
          setCargando(false);
          Swal.fire({ icon: "success", text: "Imagen Subida" });
        });
      });
    } catch (error) {
      setCargando(false);

      Swal.fire({
        icon: "error",
        text:
          "Error: " +
          (typeof error === "string" ? error : JSON.stringify(error)),
      });
    }
  }

  return (
    <>
      <Typography variant="h5">
        <strong>DATOS DE HOJA</strong>{" "}
      </Typography>
      <br />
      <Grid container spacing={2}>
        {/* <Grid item xs={12}>
          <Button variant="contained" onClick={() => //aconsole.log(res)}>
            ver
          </Button>
        </Grid> */}
        {props?.actividad?.solicitud?.tipo === "INSTALACION" && (
          <>
            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Cédula anverso:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Cédula anverso",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Cédula anverso")] && (
                  <img
                    src={res[cambio("Foto Cédula anverso")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Cédula reverso:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Cédula reverso",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Cédula reverso")] && (
                  <img
                    src={res[cambio("Foto Cédula reverso")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Contrato:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Contrato",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Contrato")] && (
                  <img
                    src={res[cambio("Foto Contrato")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Ubicación de equipos:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Ubicación de equipos",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Ubicación de equipos")] && (
                  <img
                    src={res[cambio("Foto Ubicación de equipos")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Potencia de la caja:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Potencia de la caja",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Potencia de la caja")] && (
                  <img
                    src={res[cambio("Foto Potencia de la caja")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Etiqueta de caja:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Etiqueta de caja",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Etiqueta de caja")] && (
                  <img
                    src={res[cambio("Foto Etiqueta de caja")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Pigtail etiquetado:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Pigtail etiquetado",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Pigtail etiquetado")] && (
                  <img
                    src={res[cambio("Foto Pigtail etiquetado")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Speedtest de las dos bandas:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Speedtest de las dos bandas",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Speedtest de las dos bandas")] && (
                  <img
                    src={res[cambio("Foto Speedtest de las dos bandas")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Coordenadas cliente:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                size="small"
                value={res.Coordenadascliente ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Coordenadascliente: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Coordenadas caja:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                size="small"
                value={res.Coordenadascaja ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Coordenadascaja: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Pago:</strong>{" "}
              </Typography>
              <Select
                size="small"
                disabled={existe && !editable}
                fullWidth
                MenuProps={MenuProps}
                value={res.Pago ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Pago: e.target.value,
                  });
                }}
              >
                <MenuItem key={0} value={"SI"}>
                  {"SI"}
                </MenuItem>
                <MenuItem key={1} value={"NO"}>
                  {"NO"}
                </MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Valor, método de pago y comentario:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                multiline
                rows={2}
                size="small"
                value={res.Valorcomentario ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Valorcomentario: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Foto casa:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Casa",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Casa")] && (
                  <img
                    src={res[cambio("Foto Casa")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Observación:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                multiline
                rows={2}
                size="small"
                value={res.Observación ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Observación: e.target.value,
                  });
                }}
              />
            </Grid>
          </>
        )}
        {props?.actividad?.solicitud?.tipo === "CAMBIO DOMICILIO" && (
          <>
            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Pago:</strong>{" "}
              </Typography>
              <Select
                size="small"
                disabled={existe && !editable}
                fullWidth
                MenuProps={MenuProps}
                value={res.Pago ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Pago: e.target.value,
                  });
                }}
              >
                <MenuItem key={0} value={"SI"}>
                  {"SI"}
                </MenuItem>
                <MenuItem key={1} value={"NO"}>
                  {"NO"}
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Valor, método de pago y comentario:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                multiline
                rows={2}
                size="small"
                value={res.Valorcomentario ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Valorcomentario: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>
                  Equipos retirados en caso de cambio de algún equipo:
                </strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Equipos retirados en caso de cambio de algún equipo",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[
                  cambio(
                    "Foto Equipos retirados en caso de cambio de algún equipo"
                  )
                ] && (
                  <img
                    src={
                      res[
                        cambio(
                          "Foto Equipos retirados en caso de cambio de algún equipo"
                        )
                      ]
                    }
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Equipos nuevos instalados:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Equipos nuevos instalados",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Equipos nuevos instalados")] && (
                  <img
                    src={res[cambio("Foto Equipos nuevos instalados")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>
                  Como queda instalado. que se vean las conexiones:
                </strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Como queda instalado. que se vean las conexiones",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[
                  cambio(
                    "Foto Como queda instalado. que se vean las conexiones"
                  )
                ] && (
                  <img
                    src={
                      res[
                        cambio(
                          "Foto Como queda instalado. que se vean las conexiones"
                        )
                      ]
                    }
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>
                  Reserva de cable al pie de casa de cliente (FIBRA):
                </strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Reserva de cable al pie de casa de cliente (FIBRA)",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[
                  cambio(
                    "Foto Reserva de cable al pie de casa de cliente (FIBRA)"
                  )
                ] && (
                  <img
                    src={
                      res[
                        cambio(
                          "Foto Reserva de cable al pie de casa de cliente (FIBRA)"
                        )
                      ]
                    }
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Trampa de agua en cable UTP (RADIO):</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Trampa de agua en cable UTP (RADIO)",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Trampa de agua en cable UTP (RADIO)")] && (
                  <img
                    src={
                      res[cambio("Foto Trampa de agua en cable UTP (RADIO)")]
                    }
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Speedtest de las dos bandas:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Speedtest de las dos bandas",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Speedtest de las dos bandas")] && (
                  <img
                    src={res[cambio("Foto Speedtest de las dos bandas")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Coordenadas cliente:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                size="small"
                value={res.Coordenadascliente ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Coordenadascliente: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Coordenadas caja:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                size="small"
                value={res.Coordenadascaja ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Coordenadascaja: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Potencia de la caja:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Potencia de la caja",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Potencia de la caja")] && (
                  <img
                    src={res[cambio("Foto Potencia de la caja")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Potencia de cliente:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Potencia de cliente",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Potencia de cliente")] && (
                  <img
                    src={res[cambio("Foto Potencia de cliente")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Foto casa:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Casa",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Casa")] && (
                  <img
                    src={res[cambio("Foto Casa")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Observación:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                multiline
                rows={2}
                size="small"
                value={res.Observación ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Observación: e.target.value,
                  });
                }}
              />
            </Grid>
          </>
        )}
        {props?.actividad?.solicitud?.tipo === "MIGRACION" && (
          <>
            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Equipos retirados:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Equipos retirados",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Equipos retirados")] && (
                  <img
                    src={res[cambio("Foto Equipos retirados")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Equipos nuevos instalados:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Equipos nuevos instalados",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Equipos nuevos instalados")] && (
                  <img
                    src={res[cambio("Foto Equipos nuevos instalados")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>
                  Foto de ubicación de equipos (debe observarse todo lo que se
                  ha usado):
                </strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto de ubicación de equipos (debe observarse todo lo que se ha usado)",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[
                  cambio(
                    "Foto de ubicación de equipos (debe observarse todo lo que se ha usado)"
                  )
                ] && (
                  <img
                    src={res[cambio("id_cuadrilla_id")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>
                  Reserva de cable al pie de casa de cliente:
                </strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Reserva de cable al pie de casa de cliente",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Reserva de cable al pie de casa de cliente")] && (
                  <img
                    src={
                      res[cambio("Reserva de cable al pie de casa de cliente")]
                    }
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Speedtest de las dos bandas:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Speedtest de las dos bandas",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Speedtest de las dos bandas")] && (
                  <img
                    src={res[cambio("Foto Speedtest de las dos bandas")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Etiqueta de caja:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Etiqueta de caja",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Etiqueta de caja")] && (
                  <img
                    src={res[cambio("Foto Etiqueta de caja")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Pigtail etiquetado:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Pigtail etiquetado",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Pigtail etiquetado")] && (
                  <img
                    src={res[cambio("Foto Pigtail etiquetado")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} />
            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Pago:</strong>{" "}
              </Typography>
              <Select
                size="small"
                disabled={existe && !editable}
                fullWidth
                MenuProps={MenuProps}
                value={res.Pago ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Pago: e.target.value,
                  });
                }}
              >
                <MenuItem key={0} value={"SI"}>
                  {"SI"}
                </MenuItem>
                <MenuItem key={1} value={"NO"}>
                  {"NO"}
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Valor, método de pago y comentario:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                multiline
                rows={2}
                size="small"
                value={res.Valorcomentario ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Valorcomentario: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>
                  Verificación en todos los dispositivos del cliente:
                </strong>{" "}
              </Typography>
              <Select
                size="small"
                disabled={existe && !editable}
                fullWidth
                MenuProps={MenuProps}
                value={res.Verificacióndispositivoscliente ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Verificacióndispositivoscliente: e.target.value,
                  });
                }}
              >
                <MenuItem key={0} value={"SI"}>
                  {"SI"}
                </MenuItem>
                <MenuItem key={1} value={"NO"}>
                  {"NO"}
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Gestión:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                multiline
                rows={2}
                size="small"
                value={res.gestionVerificacióndispositivoscliente ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    gestionVerificacióndispositivoscliente: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Coordenadas cliente:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                size="small"
                value={res.Coordenadascliente ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Coordenadascliente: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Coordenadas caja:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                size="small"
                value={res.Coordenadascaja ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Coordenadascaja: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Potencia de cliente:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Potencia de cliente",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Potencia de cliente")] && (
                  <img
                    src={res[cambio("Foto Potencia de cliente")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Potencia de la caja:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Potencia de la caja",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Potencia de la caja")] && (
                  <img
                    src={res[cambio("Foto Potencia de la caja")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Observación:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                multiline
                rows={2}
                size="small"
                value={res.Observación ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Observación: e.target.value,
                  });
                }}
              />
            </Grid>
          </>
        )}
        {props?.actividad?.solicitud?.tipo === "VISITA TECNICA" && (
          <>
            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Problema de cobertura router:</strong>{" "}
              </Typography>
              <Select
                size="small"
                disabled={existe && !editable}
                fullWidth
                MenuProps={MenuProps}
                value={res.Problemacoberturarouter ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Problemacoberturarouter: e.target.value,
                  });
                }}
              >
                <MenuItem key={0} value={"SI"}>
                  {"SI"}
                </MenuItem>
                <MenuItem key={1} value={"NO"}>
                  {"NO"}
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Gestión:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                multiline
                rows={2}
                size="small"
                value={res.gestionProblemacoberturarouter ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    gestionProblemacoberturarouter: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Problema eléctrico:</strong>{" "}
              </Typography>
              <Select
                size="small"
                disabled={existe && !editable}
                fullWidth
                MenuProps={MenuProps}
                value={res.Problemaeléctrico ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Problemaeléctrico: e.target.value,
                  });
                }}
              >
                <MenuItem key={0} value={"SI"}>
                  {"SI"}
                </MenuItem>
                <MenuItem key={1} value={"NO"}>
                  {"NO"}
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Gestión:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                multiline
                rows={2}
                size="small"
                value={res.gestionProblemaeléctrico ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    gestionProblemaeléctrico: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Problema de interferencia:</strong>{" "}
              </Typography>
              <Select
                size="small"
                disabled={existe && !editable}
                fullWidth
                MenuProps={MenuProps}
                value={res.Problemainterferencia ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Problemainterferencia: e.target.value,
                  });
                }}
              >
                <MenuItem key={0} value={"SI"}>
                  {"SI"}
                </MenuItem>
                <MenuItem key={1} value={"NO"}>
                  {"NO"}
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Gestión:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                multiline
                rows={2}
                size="small"
                value={res.gestionProblemainterferencia ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    gestionProblemainterferencia: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Problema en router:</strong>{" "}
              </Typography>
              <Select
                size="small"
                disabled={existe && !editable}
                fullWidth
                MenuProps={MenuProps}
                value={res.Problemarouter ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Problemarouter: e.target.value,
                  });
                }}
              >
                <MenuItem key={0} value={"SI"}>
                  {"SI"}
                </MenuItem>
                <MenuItem key={1} value={"NO"}>
                  {"NO"}
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Gestión:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                multiline
                rows={2}
                size="small"
                value={res.gestionProblemarouter ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    gestionProblemarouter: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Problema en bridge:</strong>{" "}
              </Typography>
              <Select
                size="small"
                disabled={existe && !editable}
                fullWidth
                MenuProps={MenuProps}
                value={res.Problemabridge ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Problemabridge: e.target.value,
                  });
                }}
              >
                <MenuItem key={0} value={"SI"}>
                  {"SI"}
                </MenuItem>
                <MenuItem key={1} value={"NO"}>
                  {"NO"}
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Gestión:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                multiline
                rows={2}
                size="small"
                value={res.gestionProblemabridge ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    gestionProblemabridge: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Problema en roseta:</strong>{" "}
              </Typography>
              <Select
                size="small"
                disabled={existe && !editable}
                fullWidth
                MenuProps={MenuProps}
                value={res.Problemaroseta ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Problemaroseta: e.target.value,
                  });
                }}
              >
                <MenuItem key={0} value={"SI"}>
                  {"SI"}
                </MenuItem>
                <MenuItem key={1} value={"NO"}>
                  {"NO"}
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Gestión:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                multiline
                rows={2}
                size="small"
                value={res.gestionProblemaroseta ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    gestionProblemaroseta: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Cambio de equipo:</strong>{" "}
              </Typography>
              <Select
                size="small"
                disabled={existe && !editable}
                fullWidth
                MenuProps={MenuProps}
                value={res.Cambioequipo ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Cambioequipo: e.target.value,
                  });
                }}
              >
                <MenuItem key={0} value={"SI"}>
                  {"SI"}
                </MenuItem>
                <MenuItem key={1} value={"NO"}>
                  {"NO"}
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Gestión:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                multiline
                rows={2}
                size="small"
                value={res.gestionCambioequipo ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    gestionCambioequipo: e.target.value,
                  });
                }}
              />
            </Grid>
            {res.Cambioequipo === "SI" && (
              <>
                <Grid item xs={12} md={6}>
                  <Typography>
                    {" "}
                    <strong>Equipo(s) utilizado(s):</strong>{" "}
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    disabled={existe && !editable}
                    value={res.Equipoutilizado ?? ""}
                    onChange={(e) => {
                      setRes({
                        ...res,
                        Equipoutilizado: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>
                    {" "}
                    <strong>Equipo(s) retirado(s):</strong>{" "}
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    disabled={existe && !editable}
                    value={res.Equiporetirado ?? ""}
                    onChange={(e) => {
                      setRes({
                        ...res,
                        Equiporetirado: e.target.value,
                      });
                    }}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Daño de fibra:</strong>{" "}
              </Typography>
              <Select
                size="small"
                disabled={existe && !editable}
                fullWidth
                MenuProps={MenuProps}
                value={res.Dañofibra ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Dañofibra: e.target.value,
                  });
                }}
              >
                <MenuItem key={0} value={"SI"}>
                  {"SI"}
                </MenuItem>
                <MenuItem key={1} value={"NO"}>
                  {"NO"}
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Gestión:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                multiline
                rows={2}
                size="small"
                value={res.gestionDañofibra ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    gestionDañofibra: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Férulas limpias:</strong>{" "}
              </Typography>
              <Select
                size="small"
                disabled={existe && !editable}
                fullWidth
                MenuProps={MenuProps}
                value={res.Férulaslimpias ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Férulaslimpias: e.target.value,
                  });
                }}
              >
                <MenuItem key={0} value={"SI"}>
                  {"SI"}
                </MenuItem>
                <MenuItem key={1} value={"NO"}>
                  {"NO"}
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Gestión:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                multiline
                rows={2}
                size="small"
                value={res.gestionFérulaslimpias ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    gestionFérulaslimpias: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>
                  Verificación en todos los dispositivos del cliente:
                </strong>{" "}
              </Typography>
              <Select
                size="small"
                disabled={existe && !editable}
                fullWidth
                MenuProps={MenuProps}
                value={res.Verificacióndispositivoscliente ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Verificacióndispositivoscliente: e.target.value,
                  });
                }}
              >
                <MenuItem key={0} value={"SI"}>
                  {"SI"}
                </MenuItem>
                <MenuItem key={1} value={"NO"}>
                  {"NO"}
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Gestión:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                multiline
                rows={2}
                size="small"
                value={res.gestionVerificacióndispositivoscliente ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    gestionVerificacióndispositivoscliente: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Equipo usado:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        uploadImage(
                          "Foto Equipo usado",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res.FotoEquipousado && (
                  <img src={res.FotoEquipousado} alt={"imagen"} height={110} />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Parte trasera equipos instalados:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Parte trasera equipos instalados",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[
                  "Foto Parte trasera equipos instalados".replaceAll(" ", "")
                ] && (
                  <img
                    src={
                      res[
                        "Foto Parte trasera equipos instalados".replaceAll(
                          " ",
                          ""
                        )
                      ]
                    }
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Foto ubicación de equipos:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto ubicación de equipos",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto ubicación de equipos")] && (
                  <img
                    src={res[cambio("Foto ubicación de equipos")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Speedtest de las dos bandas:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Speedtest de las dos bandas",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Speedtest de las dos bandas")] && (
                  <img
                    src={res[cambio("Foto Speedtest de las dos bandas")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Potencia de la caja:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Potencia de la caja",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Potencia de la caja")] && (
                  <img
                    src={res[cambio("Foto Potencia de la caja")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Coordenadas caja:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                size="small"
                value={res.Coordenadascaja ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Coordenadascaja: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Potencia de cliente:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Potencia de cliente",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Potencia de cliente")] && (
                  <img
                    src={res[cambio("Foto Potencia de cliente")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Coordenadas cliente:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                size="small"
                value={res.Coordenadascliente ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Coordenadascliente: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Pago:</strong>{" "}
              </Typography>
              <Select
                size="small"
                disabled={existe && !editable}
                fullWidth
                MenuProps={MenuProps}
                value={res.Pago ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Pago: e.target.value,
                  });
                }}
              >
                <MenuItem key={0} value={"SI"}>
                  {"SI"}
                </MenuItem>
                <MenuItem key={1} value={"NO"}>
                  {"NO"}
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Valor, método de pago y comentario:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                multiline
                rows={2}
                size="small"
                value={res.Valorcomentario ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Valorcomentario: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Observación:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                multiline
                rows={2}
                size="small"
                value={res.Observación ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Observación: e.target.value,
                  });
                }}
              />
            </Grid>
          </>
        )}
        {props?.actividad?.solicitud?.tipo === "RETIRO" && (
          <>
            <Grid item xs={12}>
              <Typography>
                {" "}
                <strong>Motivo por el cual se retira:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                size="small"
                value={res.Motivoretira ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Motivoretira: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Equipos completos:</strong>{" "}
              </Typography>
              <Select
                size="small"
                disabled={existe && !editable}
                fullWidth
                MenuProps={MenuProps}
                value={res.Equiposcompletos ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Equiposcompletos: e.target.value,
                  });
                }}
              >
                <MenuItem key={0} value={"SI"}>
                  {"SI"}
                </MenuItem>
                <MenuItem key={1} value={"NO"}>
                  {"NO"}
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Equipos retirados:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Equipos retirados",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Equipos retirados")] && (
                  <img
                    src={res[cambio("Foto Equipos retirados")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Gestión:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                multiline
                rows={2}
                size="small"
                value={res.gestionEquiposcompletos ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    gestionEquiposcompletos: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Equipos en estado ok:</strong>{" "}
              </Typography>
              <Select
                size="small"
                disabled={existe && !editable}
                fullWidth
                MenuProps={MenuProps}
                value={res.Equiposok ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Equiposok: e.target.value,
                  });
                }}
              >
                <MenuItem key={0} value={"SI"}>
                  {"SI"}
                </MenuItem>
                <MenuItem key={1} value={"NO"}>
                  {"NO"}
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Gestión:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                multiline
                rows={2}
                size="small"
                value={res.gestionEquiposok ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    gestionEquiposok: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Pago:</strong>{" "}
              </Typography>
              <Select
                size="small"
                disabled={existe && !editable}
                fullWidth
                MenuProps={MenuProps}
                value={res.Pago ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Pago: e.target.value,
                  });
                }}
              >
                <MenuItem key={0} value={"SI"}>
                  {"SI"}
                </MenuItem>
                <MenuItem key={1} value={"NO"}>
                  {"NO"}
                </MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Valor, método de pago y comentario:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                multiline
                rows={2}
                size="small"
                value={res.Valorcomentario ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Valorcomentario: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Observación:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                multiline
                rows={2}
                size="small"
                value={res.Observación ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Observación: e.target.value,
                  });
                }}
              />
            </Grid>
          </>
        )}
        {props?.actividad?.solicitud?.tipo === "CAMBIO DE TITULAR" && (
          <>
            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Cedula anverso nuevo cliente:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Cedula anverso nuevo cliente",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Cedula anverso nuevo cliente")] && (
                  <img
                    src={res[cambio("Foto Cedula anverso nuevo cliente")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Cedula reverso nuevo cliente:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Cedula reverso nuevo cliente",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Cedula reverso nuevo cliente")] && (
                  <img
                    src={res[cambio("Foto Cedula reverso nuevo cliente")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Cedula anverso cliente anterior:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Cedula anverso cliente anterior",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Cedula anverso cliente anterior")] && (
                  <img
                    src={res[cambio("Foto Cedula anverso cliente anterior")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Cedula reverso cliente anterior:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Cedula reverso cliente anterior",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Cedula reverso cliente anterior")] && (
                  <img
                    src={res[cambio("Foto Cedula reverso cliente anterior")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Contrato:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Contrato",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Contrato")] && (
                  <img
                    src={res[cambio("Foto Contrato")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Equipos instalados:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto Equipos instalados",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto Equipos instalados")] && (
                  <img
                    src={res[cambio("Foto Equipos instalados")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Pago de la factura del mes :</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                size="small"
                value={res.Pagomes ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Pagomes: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Valor por cambio de titular:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                size="small"
                value={res.Valortitular ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Valortitular: e.target.value,
                  });
                }}
              />
            </Grid>
          </>
        )}
        {props?.actividad?.solicitud?.tipo === "OTRO" && (
          <>
            <Grid item xs={12}>
              <Typography>
                {" "}
                <strong>Observación:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                multiline
                rows={2}
                size="small"
                value={res.Observación ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Observación: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Foto opcional 1:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto opcional 1",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto opcional 1")] && (
                  <img
                    src={res[cambio("Foto opcional 1")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Foto opcional 2:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto opcional 2",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto opcional 2")] && (
                  <img
                    src={res[cambio("Foto opcional 2")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>
          </>
        )}

        {props?.actividad?.solicitud === null && (
          <>
            <Grid item xs={12}>
              <Typography>
                {" "}
                <strong>Observación:</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                disabled={existe && !editable}
                multiline
                rows={2}
                size="small"
                value={res.Observación ?? ""}
                onChange={(e) => {
                  setRes({
                    ...res,
                    Observación: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Foto opcional 1:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto opcional 1",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto opcional 1")] && (
                  <img
                    src={res[cambio("Foto opcional 1")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography>
                {" "}
                <strong>Foto opcional 2:</strong>{" "}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Stack direction={"column"} spacing={2}>
                  <Button
                    component="label"
                    disabled={existe && !editable}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar archivo
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        //aconsole.log(e.target.files[e.target.files.length - 1]);
                        uploadImage(
                          "Foto opcional 2",
                          e.target.files[e.target.files.length - 1]
                        );
                      }}
                    />
                  </Button>
                </Stack>
                {res[cambio("Foto opcional 2")] && (
                  <img
                    src={res[cambio("Foto opcional 2")]}
                    alt={"imagen"}
                    height={110}
                  />
                )}
              </Stack>
            </Grid>
          </>
        )}
      </Grid>
      {props?.actividad?.id && (
        <>
          <br />
          <Button
            color="success"
            variant="contained"
            onClick={() => {
              setOpen2(true);
            }}
            disabled={existe && !editable}
          >
            {existe && editable ? "Actualizar " : "Subir "} Hoja
          </Button>
        </>
      )}

      <Dialog open={open2}>
        <DialogTitle>
          <Stack
            spacing={2}
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <strong>¿ESTÁ SEGURO DE SUBIR?</strong>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ color: "red" }}>
            <strong>
              Una vez enviada la hoja no podrá registrar los materiales
              recibidos utilizados ni editar nuevamente!!:
            </strong>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="success"
            variant="contained"
            onClick={() => {
              crear();
            }}
          >
            Aceptar
          </Button>
          <Button
            color="info"
            variant="contained"
            onClick={() => {
              setOpen2(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Cargando open2={cargando} />
    </>
  );
};
