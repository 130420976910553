import {
  Box,
  MenuItem,
  Paper,
  Select,
  Button,
  Stack,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { CustomToolbar } from "../../components/utils/CustomFilter";
import Swal from "sweetalert2";
import { Cargando } from "../../components/utils/Cargando";
import { MenuProps } from "../../components/utils/MenuProps";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import moment from "moment";

export const Stock = () => {
  const [cargando, setCargando] = useState(false);

  const [lista, setLista] = useState(null);

  const [filtro, setFiltro] = useState("Todos");

  const [bodegas, setBodegas] = useState([]);
  const [filtro2, setFiltro2] = useState({ id: 0, nombre: "Todos" });

  useEffect(() => {
    cargar();
  }, []);

  const _exporter = React.createRef();
  const excelExport = () => {
    setCargando(true);
    if (_exporter.current) {
      _exporter.current.save();
    }
    setCargando(false);
  };

  function cargar() {
    setCargando(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://backterra.econtel.com.ec/api/v1/stock/", requestOptions)
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setLista(
          JSON.parse(result).filter(
            (val) => val.bodega !== null && val.producto !== null
          )
        );
        setCargando(false);
      })
      .catch((error) => {
        setCargando(false);

        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });

    fetch("https://backterra.econtel.com.ec/api/v1/bodega/", requestOptions)
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setBodegas([{ id: 0, nombre: "Todos" }].concat(JSON.parse(result)));
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  const columns = [
    {
      field: "nombre_bodega",
      headerClassName: "super-app-theme--header",
      headerName: "Bodega",
      flex: 1,
      valueGetter: (params) => {
        return params.row.bodega?.nombre ?? "";
      },
    },
    {
      field: "nombre_producto",
      headerClassName: "super-app-theme--header",
      headerName: "Producto",
      flex: 1,
      valueGetter: (params) => {
        return params.row.producto?.nombre ?? "";
      },
    },
    {
      field: "tipo_producto",
      headerClassName: "super-app-theme--header",
      headerName: "Tipo",
      flex: 1,
      valueGetter: (params) => {
        return params.row.producto?.tipo ?? "";
      },
    },
    {
      field: "cantidad",
      headerClassName: "super-app-theme--header",
      headerName: "Cantidad",
      flex: 1,
    },
  ];

  return (
    <Box px={2} py={2}>
      <Paper elevation={2} sx={{ padding: "10px" }}>
        <Typography variant="h5">
          {" "}
          <strong>Stock</strong>{" "}
        </Typography>
        <br />

        <Stack direction={"row"} spacing={2} pt={2}>
          <Typography>
            {" "}
            <strong>Tipo Inventario:</strong>{" "}
          </Typography>
          <Select
            size="small"
            sx={{ width: 200 }}
            MenuProps={MenuProps}
            value={filtro}
            onChange={(e) => {
              setFiltro(e.target.value);
            }}
          >
            <MenuItem key={0} value={"Todos"}>
              {"Todos"}
            </MenuItem>
            <MenuItem key={1} value={"Herramienta"}>
              {"Herramienta"}
            </MenuItem>
            <MenuItem key={2} value={"Material"}>
              {"Material"}
            </MenuItem>
          </Select>
        </Stack>
        <Stack direction={"row"} spacing={2} pt={2}>
          <Typography>
            {" "}
            <strong>Bodega:</strong>{" "}
          </Typography>
          <Autocomplete
            ListboxProps={{ style: { maxHeight: 250 } }}
            size="small"
            sx={{ width: 250 }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            disablePortal
            value={filtro2 ?? null}
            options={bodegas ?? []}
            getOptionLabel={(option) => option.nombre}
            onChange={(e, t) => {
              setFiltro2(t);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <Button
            size="small"
            color="success"
            variant="contained"
            disabled={lista?.length === 0}
            onClick={excelExport}
          >
            Exportar Excel
          </Button>
        </Stack>
        <br />
        <Paper elevation={2} sx={{ overflowX: "scroll", padding: "10px" }}>
          <Box
            sx={{
              height: 600,
              minWidth: 1000,
              "& .super-app-theme--header": {
                backgroundColor: "#428bd4",
                color: "white",
                fontSize: "16px",
              },
            }}
          >
            <DataGrid
              disableColumnMenu
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}
              rows={
                lista
                  ? lista.filter(
                      (val) =>
                        (filtro !== "Todos"
                          ? val.producto?.tipo === filtro
                          : true) &&
                        (filtro2?.id !== 0
                          ? val.bodega?.id === filtro2?.id
                          : true)
                    )
                  : []
              }
              loading={lista === null}
              columns={columns}
              getRowId={(row) => row.id}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              slots={{ toolbar: CustomToolbar }}
            />
          </Box>
        </Paper>
      </Paper>
      <ExcelExport
        data={
          lista
            ? [
                ...lista
                  .filter(
                    (val) =>
                      val.cantidad !== 0 &&
                      (filtro !== "Todos"
                        ? val.producto?.tipo === filtro
                        : true) &&
                      (filtro2?.id !== 0
                        ? val.bodega?.id === filtro2?.id
                        : true)
                  )
                  .map((val) => {
                    return {
                      ...val,
                      Bodega: val.bodega?.nombre ?? "",
                      Producto: val.producto?.nombre ?? "",
                      Tipo: val.producto?.tipo ?? "",
                      Cantidad: val.cantidad ?? "",
                    };
                  }),
              ]
            : []
        }
        filterable={true}
        fileName={
          "Stock " +
          (filtro === "Todos" ? "" : filtro) +
          " " +
          (filtro2?.id === 0 ? "" : filtro2?.nombre) +
          " " +
          moment().format("DD-MM-YYYY") +
          ".xlsx"
        }
        ref={_exporter}
      >
        {["Bodega", "Producto"].map((val) => (
          <ExcelExportColumn
            key={val}
            field={val}
            title={val.toUpperCase()}
            width={350}
          />
        ))}
        {["Tipo", "Cantidad"].map((val) => (
          <ExcelExportColumn
            key={val}
            field={val}
            title={val.toUpperCase()}
            width={150}
          />
        ))}
      </ExcelExport>
      <Cargando open2={cargando} />
    </Box>
  );
};
