import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Stack,
  Typography,
  Autocomplete,
  Tab,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { CustomToolbar } from "../../../components/utils/CustomFilter";
import { useSelector } from "react-redux";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { useParams } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import moment from "moment";
import { Materiales } from "./Materiales";
import { Cargando } from "../../../components/utils/Cargando";
import { MenuProps } from "../../../components/utils/MenuProps";

export const Hoja = () => {
  const [cargando, setCargando] = useState(false);

  const [numero, setNumero] = useState(2);
  const [garantia, setGarantia] = useState("NO");

  let { actividad } = useParams();
  const [datos, setDatos] = useState({});

  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [nuevaSerie, setNuevaSerie] = useState("");

  const [cantidad, setCantidad] = useState("");
  const [producto, setProducto] = useState("");
  const [bodega, setBodega] = useState("");
  const [id, setId] = useState("");

  const [descripcion, setDescripcion] = useState("");
  const [motivo, setMotivo] = useState("");

  const [lista, setLista] = useState([]);

  const [movimientos, setMovimientos] = useState([]);

  const [seleccionado, setSeleccionado] = useState([]);

  const [bodegas, setBodegas] = useState([]);
  const [productos, setProductos] = useState([]);
  const [motivos, setMotivos] = useState([]);
  const [series, setSeries] = useState([]);

  const [existe, setExiste] = useState(false);

  useEffect(() => {
    cargar();
  }, []);

  function cargar() {
    setCargando(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/hojatrabajoby/?actividad=" +
        actividad,
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        let li = JSON.parse(result);
        if (li.length > 0) {
          setExiste(true);
          cargarBodegas();
        } else {
          setExiste(false);
          cargarBodegas();
          cargarMotivos();
        }
      })
      .catch((error) => {
        setCargando(false);
        setExiste(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function cargarProductos(opcion) {
    setCargando(true);

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      opcion
        ? "https://backterra.econtel.com.ec/api/v1/productosby/?bodega_id=" +
            bodega.id
        : "https://backterra.econtel.com.ec/api/v1/producto/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setProductos(JSON.parse(result));
        setCargando(false);
      })
      .catch((error) => {
        setCargando(false);

        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  const columnsMov = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "Movimiento",
      flex: 1,
      valueGetter: (params) => {
        return "MOV-" + params.row.id;
      },
    },
    {
      field: "bodega",
      headerClassName: "super-app-theme--header",
      headerName: "Cuadrilla",
      flex: 1,
      valueGetter: (params) => {
        return params.row.bodega?.nombre ?? "";
      },
    },
    {
      field: "producto",
      headerClassName: "super-app-theme--header",
      headerName: "Producto",
      flex: 1,
      valueGetter: (params) => {
        return params.row.producto?.nombre ?? "";
      },
    },
    {
      field: "tipomovimiento",
      headerClassName: "super-app-theme--header",
      headerName: "Tipo",
      flex: 1,
    },

    {
      field: "cantidad",
      headerClassName: "super-app-theme--header",
      headerName: "Cantidad",
      flex: 1,
    },
    {
      field: "motivo",
      headerClassName: "super-app-theme--header",
      headerName: "Motivo",
      flex: 1,
      valueGetter: (params) => {
        return params.row.motivo_movimiento?.motivo ?? "";
      },
    },
    {
      field: "series",
      headerClassName: "super-app-theme--header",
      headerName: "Series",
      flex: 1,
      valueGetter: (params) => {
        if (params.row.series) {
          return [
            ...params.row.series.map((val) => {
              return val.serie;
            }),
          ].join(", ");
        } else {
          return "";
        }
      },
    },
    {
      field: "usuario",
      headerClassName: "super-app-theme--header",
      headerName: "Usuario",
      flex: 1,
      valueGetter: (params) => {
        return params.row.usuario?.nombre ?? "";
      },
    },
    {
      field: "fecha",
      headerClassName: "super-app-theme--header",
      headerName: "Fecha",
      flex: 1,
      valueGetter: (params) => {
        return moment(params.row.created_at).format("DD/MMMM/yyyy HH:mm") ?? "";
      },
    },
  ];

  function cargarBodegas() {
    setCargando(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/actividades/" + actividad + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        let res = JSON.parse(result);
        setDatos(res);
        fetch(
          "https://backterra.econtel.com.ec/api/v1/movimiento/?actividad=" +
            res.id,
          requestOptions
        )
          .then(async (response) => {
            if (response.ok) {
              return response.text();
            }
            let mensaje = await response.json();
            throw {
              ...mensaje,
            };
          })
          .then((result) => {
            //aconsole.log(JSON.parse(result));
            setMovimientos(JSON.parse(result));
            setCargando(false);
          })
          .catch((error) => {
            setCargando(false);

            Swal.fire({
              icon: "error",
              text:
                "Error: " +
                (typeof error === "string" ? error : JSON.stringify(error)),
            });
          });
        if (res?.id_cuadrilla === null) {
          setCargando(false);
          return;
        }
        fetch(
          "https://backterra.econtel.com.ec/api/v1/bodega/" +
            res.id_cuadrilla +
            "/",
          requestOptions
        )
          .then(async (response) => {
            if (response.ok) {
              return response.text();
            }
            let mensaje = await response.json();
            throw {
              ...mensaje,
            };
          })
          .then((result) => {
            //aconsole.log(JSON.parse(result));
            setBodegas([JSON.parse(result)]);
          })
          .catch((error) => {
            setCargando(false);
            Swal.fire({
              icon: "error",
              text:
                "Error: " +
                (typeof error === "string" ? error : JSON.stringify(error)),
            });
          });
      })
      .catch((error) => {
        setCargando(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function cargarMotivos() {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://backterra.econtel.com.ec/api/v1/motivo_movimiento/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setMotivos(JSON.parse(result));
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function getSeries(producto) {
    setCargando(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    let link =
      "https://backterra.econtel.com.ec/api/v1/seriesby/?bodega=" + bodega.id;

    if (tab === "1") {
      link =
        "https://backterra.econtel.com.ec/api/v1/seriesby/?bodega=" +
        bodega.id +
        "&producto=" +
        producto.id;
    }
    fetch(link, requestOptions)
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setCargando(false);
        //aconsole.log(JSON.parse(result));
        setSeries(JSON.parse(result).filter((val) => val.estado === "ACTIVO"));
      })
      .catch((error) => {
        setCargando(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  // async function comprar(opcion) {
  //   let error2 = false;
  //   if (opcion) {
  //     for (const el of lista.filter(
  //       (val) => val.producto.tipo2 === "COMPUESTO" && val.producto.usa_serie
  //     )) {
  //       let myHeaders2 = new Headers();
  //       myHeaders2.append("Content-Type", "application/json");
  //       myHeaders2.append("ngrok-skip-browser-warning", "asc");
  //       let requestOptions2 = {
  //         method: "GET",
  //         headers: myHeaders2,
  //       };
  //       await fetch(
  //         "https://backterra.econtel.com.ec/api/v1/seriesby/?producto=" +
  //           el.producto.id +
  //           "&bodega=" +
  //           bodega.id,
  //         requestOptions2
  //       )
  //         .then(async (response) => {
  //           if (response.ok) {
  //             return response.text();
  //           }
  //           let mensaje = await response.json();
  //           throw {
  //             ...mensaje,
  //           };
  //         })
  //         // eslint-disable-next-line no-loop-func
  //         .then((result) => {
  //           let lista2 = JSON.parse(result);
  //           for (const el2 of lista2) {
  //             if (el2.serie === el.series[0]) {
  //               if (el2.stock < el.cantidad) {
  //                 error2 = true;
  //                 Swal.fire({
  //                   icon: "error",
  //                   text:
  //                     "El cantidad de la serie: " +
  //                     el2.serie +
  //                     " debe ser menor o igual a la del stock actual: " +
  //                     el2.stock,
  //                 });
  //                 return;
  //               }
  //             }
  //           }
  //         })
  //         .catch((error) => {
  //           Swal.fire({
  //             icon: "error",
  //             text: "Ocurrió un problema con una serie",
  //           });
  //           return;
  //         });
  //     }
  //   }
  //   if (error2) {
  //     return;
  //   }
  //   let myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append("ngrok-skip-browser-warning", "asc");
  // myHeaders.append(
  //   "Authorization",
  //   "Bearer " + process.env.REACT_APP_TOKEN_API
  // );

  //   let cantidad = 0;
  //   for (const el of lista) {
  //     let body = JSON.stringify({
  //       cantidad: el.cantidad,
  //       tipomovimiento: opcion ? "EGRESO" : "INGRESO",
  //       descripcion: descripcion,
  //       motivo: motivo.id,
  //       motivo_movimiento: motivo.id,
  //       series: el.series ?? [],
  //       producto: el.producto.id,
  //       bodega: bodega.id,
  //       usuario: userInfo.id,
  //       id_actividad: actividad,
  //     });
  //     let requestOptions = {
  //       method: "POST",
  //       headers: myHeaders,
  //       redirect: "follow",
  //       body: body,
  //     };
  //     //aconsole.log(body);
  //     await fetch(
  //       "https://backterra.econtel.com.ec/api/v1/movimiento/",
  //       requestOptions
  //     )
  //       .then(async (response) => {
  //         if (response.ok) {
  //           return response.text();
  //         }
  //         let mensaje = await response.json();
  //         throw {
  //           ...mensaje,
  //         };
  //       })
  //       // eslint-disable-next-line no-loop-func
  //       .then((result) => {
  //         //aconsole.log(result);
  //         cantidad += 1;
  //       })
  //       .catch((error) => {
  //         Swal.fire({
  //           icon: "error",
  //           text:
  //             "Error: " +
  //             (typeof error === "string" ? error : JSON.stringify(error)),
  //         });
  //         return;
  //       });
  //   }
  //   if (lista.length === cantidad) {
  //     limpiar();
  //     setLista([]);
  //     setBodega("");
  //     setDescripcion("");
  //     setMotivo("");
  //     Swal.fire({ icon: "success", text: "INGRESO REALIZADO" });
  //   } else {
  //     Swal.fire({
  //       icon: "error",
  //       text: "Ocurrió un problema con un producto ",
  //     });
  //   }
  // }

  async function ingreso() {
    setCargando(true);
    let error2 = false;
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );

    let cantidad = 0;
    for (const el of lista.filter(
      (val) => !(val.producto.tipo2 === "COMPUESTO" && val.producto.usa_serie)
    )) {
      let body = JSON.stringify({
        cantidad: el.cantidad,
        tipomovimiento: "INGRESO",
        descripcion: descripcion,
        motivo: motivo.id,
        motivo_movimiento: motivo.id,
        series: el.series ?? [],
        producto: el.producto.id,
        bodega: bodega.id,
        usuario: userInfo.id,
        proveedor: null,
        id_actividad: actividad,
      });
      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: body,
      };
      //aconsole.log(body);
      await fetch(
        "https://backterra.econtel.com.ec/api/v1/movimiento/",
        requestOptions
      )
        .then(async (response) => {
          if (response.ok) {
            return response.text();
          }
          let mensaje = await response.json();
          throw {
            ...mensaje,
          };
        })
        // eslint-disable-next-line no-loop-func
        .then((result) => {
          //aconsole.log(result);
          cantidad += 1;
        })
        .catch((error) => {
          setCargando(false);
          error2 = true;
          Swal.fire({
            icon: "error",
            text: typeof error === "string" ? error : JSON.stringify(error),
          });
          return;
        });

      if (error2) {
        setCargando(false);
        return;
      }
    }
    for (const el of lista.filter(
      (val) => val.producto.tipo2 === "COMPUESTO" && val.producto.usa_serie
    )) {
      let body = JSON.stringify({
        cantidad: el.cantidad,
        tipomovimiento: "INGRESO",
        descripcion: descripcion,
        motivo: motivo.id,
        motivo_movimiento: motivo.id,
        series: el.series ?? [],
        producto: el.producto.id,
        bodega: bodega.id,
        usuario: userInfo.id,
        proveedor: null,
        garantia: el.garantia,
        id_actividad: actividad,
      });
      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: body,
      };
      //aconsole.log(body);
      await fetch(
        "https://backterra.econtel.com.ec/api/v1/movimientoCompuesto/",
        requestOptions
      )
        .then(async (response) => {
          if (response.ok) {
            return response.text();
          }
          let mensaje = await response.json();
          throw {
            ...mensaje,
          };
        })
        // eslint-disable-next-line no-loop-func
        .then((result) => {
          //aconsole.log(result);
          cantidad += 1;
        })
        .catch((error) => {
          setCargando(false);
          error2 = true;
          Swal.fire({
            icon: "error",
            text: typeof error === "string" ? error : JSON.stringify(error),
          });
          return;
        });

      if (error2) {
        setCargando(false);
        return;
      }
    }

    if (lista.length === cantidad) {
      limpiar();
      setLista([]);
      setBodega("");
      setDescripcion("");
      setMotivo("");
      cargar();
      setTab("1");
      Swal.fire({ icon: "success", text: "INGRESO REALIZADO" });
    } else {
      setCargando(false);
      Swal.fire({
        icon: "error",
        text: "Ocurrió un problema con un producto ",
      });
    }
  }

  async function egreso() {
    setCargando(true);
    let error2 = false;
    for (const el of lista.filter(
      (val) => val.producto.tipo2 === "COMPUESTO" && val.producto.usa_serie
    )) {
      let myHeaders2 = new Headers();
      myHeaders2.append("Content-Type", "application/json");
      myHeaders2.append("ngrok-skip-browser-warning", "asc");
      myHeaders2.append(
        "Authorization",
        "Bearer " + process.env.REACT_APP_TOKEN_API
      );

      let requestOptions2 = {
        method: "GET",
        headers: myHeaders2,
      };
      await fetch(
        "https://backterra.econtel.com.ec/api/v1/seriesby/?producto=" +
          el.producto.id +
          "&bodega=" +
          bodega.id,
        requestOptions2
      )
        .then(async (response) => {
          if (response.ok) {
            return response.text();
          }
          let mensaje = await response.json();
          throw {
            ...mensaje,
          };
        })
        // eslint-disable-next-line no-loop-func
        .then((result) => {
          let lista2 = JSON.parse(result);
          for (const el2 of lista2) {
            if (el2.serie === el.series[0]) {
              if (el2.stock < el.cantidad) {
                error2 = true;
                setCargando(false);
                Swal.fire({
                  icon: "error",
                  text:
                    "El cantidad de la serie: " +
                    el2.serie +
                    " debe ser menor o igual a la del stock actual: " +
                    el2.stock,
                });
                return;
              }
            }
          }
        })
        .catch((error) => {
          setCargando(false);
          Swal.fire({
            icon: "error",
            text: "Ocurrió un problema con una serie",
          });
          return;
        });
    }
    if (error2) {
      setCargando(false);
      return;
    }

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );

    let cantidad = 0;
    for (const el of lista.filter(
      (val) => !(val.producto.tipo2 === "COMPUESTO" && val.producto.usa_serie)
    )) {
      let body = JSON.stringify({
        cantidad: el.cantidad,
        tipomovimiento: "EGRESO",
        descripcion: descripcion,
        motivo: motivo.id,
        motivo_movimiento: motivo.id,
        producto: el.producto.id,
        bodega: bodega.id,
        series: el.series ?? [],
        usuario: userInfo.id,
        id_actividad: actividad,
      });
      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: body,
      };
      //aconsole.log(body);
      await fetch(
        "https://backterra.econtel.com.ec/api/v1/movimiento/",
        requestOptions
      )
        .then(async (response) => {
          if (response.ok) {
            return response.text();
          }
          let mensaje = await response.json();
          throw {
            ...mensaje,
          };
        })
        .then((result) => {
          //aconsole.log(result);
          cantidad += 1;
        })
        .catch((error) => {
          setCargando(false);
          error2 = true;
          Swal.fire({
            icon: "error",
            text: typeof error === "string" ? error : JSON.stringify(error),
          });
          return;
        });

      if (error2) {
        setCargando(false);
        return;
      }
    }

    for (const el of lista.filter(
      (val) => val.producto.tipo2 === "COMPUESTO" && val.producto.usa_serie
    )) {
      let body = JSON.stringify({
        cantidad: el.cantidad,
        tipomovimiento: "EGRESO",
        descripcion: descripcion,
        motivo: motivo.id,
        motivo_movimiento: motivo.id,
        producto: el.producto.id,
        bodega: bodega.id,
        series: el.series ?? [],
        usuario: userInfo.id,
        id_actividad: actividad,
      });
      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: body,
      };
      //aconsole.log(body);
      await fetch(
        "https://backterra.econtel.com.ec/api/v1/movimientoCompuesto/",
        requestOptions
      )
        .then(async (response) => {
          if (response.ok) {
            return response.text();
          }
          let mensaje = await response.json();
          throw {
            ...mensaje,
          };
        })
        .then((result) => {
          //aconsole.log(result);
          cantidad += 1;
        })
        .catch((error) => {
          setCargando(false);
          error2 = true;
          Swal.fire({
            icon: "error",
            text: typeof error === "string" ? error : JSON.stringify(error),
          });
          return;
        });

      if (error2) {
        setCargando(false);
        return;
      }
    }
    if (lista.length === cantidad) {
      limpiar();
      setLista([]);
      setBodega("");
      setMotivo("");
      setDescripcion("");
      cargar();
      setTab("2");
      Swal.fire({ icon: "success", text: "EGRESO REALIZADO" });
    } else {
      setCargando(false);
      Swal.fire({
        icon: "error",
        text: "Ocurrió un problema con un producto ",
      });
    }
  }

  function limpiar() {
    setCantidad("");
    setProducto("");
    setGarantia("NO");
    setSeleccionado([]);
    setNuevaSerie("");
    setId("");
  }

  function handleOpen(row) {
    setId(row.id);
    setCantidad(row.cantidad);
    setProducto(row.producto);
    setOpen(true);
  }

  function handleOpen2(row) {
    setLista(lista.filter((val) => val.id !== row.id));
  }

  function handleOpen3(row) {
    setSeleccionado(seleccionado.filter((val) => val.serie !== row.serie));
  }

  function validar() {
    setCargando(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/api/series_disponibles/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setCargando(false);
        //aconsole.log(JSON.parse(result));
        let seriesD = JSON.parse(result);
        let datos = [];
        let ser = [
          ...seriesD.map((val) => {
            return val.serie;
          }),
        ];
        for (const el of seleccionado) {
          if (ser.includes(el.serie)) {
            datos.push(el.serie);
          }
        }
        if (datos.length > 0) {
          Swal.fire({
            icon: "error",
            text: "Las series: " + datos + " ya se encuentran registradas",
          });
        } else {
          let prod = null;
          let lista2 = [];
          for (const el of lista) {
            if (el.id === id) {
              prod = el;
            } else {
              lista2.push(el);
            }
          }
          setLista([
            ...lista2,
            {
              ...prod,
              garantia: garantia,
              series: [
                ...seleccionado.map((val) => {
                  return val.serie;
                }),
              ],
            },
          ]);
          setOpen2(false);
          limpiar();
        }
      })
      .catch((error) => {
        setCargando(false);
        Swal.fire({
          icon: "error",
          text: "Error al comprobar si las series son únicas",
        });
      });
  }

  const columns = [
    {
      field: "a",
      headerClassName: "super-app-theme--header",
      headerName: "Código Producto",
      flex: 1,
      valueGetter: (params) => {
        return params.row.producto.codigo;
      },
    },
    {
      field: "b",
      headerClassName: "super-app-theme--header",
      headerName: "Nombre Producto",
      flex: 1,
      valueGetter: (params) => {
        return params.row.producto.nombre;
      },
    },
    {
      field: "cantidad",
      headerClassName: "super-app-theme--header",
      headerName: "Cantidad",
      flex: 1,
    },
    {
      field: "tipo2",
      headerClassName: "super-app-theme--header",
      headerName: "Tipo",
      flex: 1,
      valueGetter: (params) => {
        return params.row.producto.tipo2;
      },
    },
    {
      field: "usa_serie",
      headerClassName: "super-app-theme--header",
      headerName: "Usa Serie",
      flex: 1,
      valueGetter: (params) => {
        return params.row.producto.usa_serie ? "SI" : "NO";
      },
    },
    {
      field: "series",
      headerClassName: "super-app-theme--header",
      headerName: "Series",
      flex: 1,
      valueGetter: (params) => {
        return params.row.producto.usa_serie &&
          params.row.producto.tipo2 === "COMPUESTO"
          ? params.row.series.length + " Garantía: " + params.row.garantia
          : params.row.series.length;
      },
    },

    {
      field: "1",
      headerClassName: "super-app-theme--header",
      headerName: "Acciones",
      flex: 1,
      renderCell: (params) => (
        <Stack direction={"row"} spacing={2}>
          {params.row.producto.usa_serie && (
            <IconButton
              onClick={() => {
                setId(params.row.id);
                getSeries(params.row.producto);
                setProducto(params.row.producto);
                setGarantia(params.row.garantia);

                setSeleccionado([
                  ...params.row.series.map((val) => {
                    return { serie: val };
                  }),
                ]);
                if (
                  params.row.producto.tipo2 === "COMPUESTO" &&
                  params.row.producto.usa_serie
                ) {
                  setCantidad(1);
                } else {
                  setCantidad(params.row.cantidad);
                }
                setOpen2(true);
              }}
            >
              <QrCodeIcon color="info" />
            </IconButton>
          )}
          <IconButton onClick={() => handleOpen(params.row)}>
            <EditIcon color="warning" />
          </IconButton>
          <IconButton onClick={() => handleOpen2(params.row)}>
            <DeleteIcon color="error" />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const [tab, setTab] = useState("0");
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <Box px={2} py={2}>
        <Paper elevation={2} sx={{ padding: "10px" }}>
          <Typography variant="h5">
            {" "}
            <strong>Hoja de Trabajo: </strong> ACTIVIDAD-{datos.id}
          </Typography>
          <br />
          {existe ? (
            <TabContext value={tab}>
              <Box sx={{ borderColor: "divider" }}>
                <TabList onChange={handleChange} variant="scrollable">
                  <Tab
                    label="Materiales Recibidos"
                    sx={{ fontWeight: "bold" }}
                    value={"0"}
                  />
                  <Tab
                    label="Materiales utilizados"
                    sx={{ fontWeight: "bold" }}
                    value={"1"}
                  />
                  <Tab
                    label="Registro Datos"
                    sx={{ fontWeight: "bold" }}
                    value={"2"}
                  />
                </TabList>
              </Box>
              <TabPanel value={"0"}>
                <>
                  <Typography variant="h5">
                    <strong>INGRESO A CUADRILLA</strong>{" "}
                  </Typography>

                  {existe ||
                  (movimientos &&
                    movimientos.filter(
                      (val) => val.tipomovimiento === "INGRESO"
                    ).length > 0) ? (
                    <>
                      <br />
                      <Paper
                        elevation={2}
                        sx={{ overflowX: "scroll", padding: "10px" }}
                      >
                        <Box
                          sx={{
                            height: 400,
                            // minWidth: 1000,
                            "& .super-app-theme--header": {
                              backgroundColor: "#428bd4",
                              color: "white",
                              fontSize: "16px",
                            },
                          }}
                        >
                          <DataGrid
                            disableColumnMenu
                            getRowHeight={() => "auto"}
                            getEstimatedRowHeight={() => 200}
                            rows={movimientos.filter(
                              (val) => val.tipomovimiento === "INGRESO"
                            )}
                            columns={columnsMov}
                            getRowId={(row) => row.id}
                            localeText={
                              esES.components.MuiDataGrid.defaultProps
                                .localeText
                            }
                            slots={{ toolbar: CustomToolbar }}
                          />
                        </Box>
                      </Paper>
                    </>
                  ) : (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                          <Box px={2}>
                            <Typography>
                              {" "}
                              <strong>Motivo *</strong>{" "}
                            </Typography>
                            <Select
                              MenuProps={MenuProps}
                              fullWidth
                              size="small"
                              value={motivo}
                              onChange={(e) => setMotivo(e.target.value)}
                            >
                              {motivos
                                ? motivos.map((val) => (
                                    <MenuItem key={val.id} value={val}>
                                      {val.motivo}
                                    </MenuItem>
                                  ))
                                : ""}
                            </Select>
                          </Box>
                          <br />
                          <Box px={2}>
                            <Typography>
                              {" "}
                              <strong>Bodega *</strong>{" "}
                            </Typography>
                            <Autocomplete
                              size="small"
                              fullWidth
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              disablePortal
                              value={bodega}
                              disabled={lista.length > 0}
                              options={bodegas}
                              getOptionLabel={(option) => option.nombre ?? ""}
                              onChange={(e, t) => {
                                setBodega(t);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <Box px={2}>
                            <Typography>
                              {" "}
                              <strong>Descripción *</strong>{" "}
                            </Typography>
                            <TextField
                              fullWidth
                              multiline
                              rows={3}
                              size="small"
                              value={descripcion}
                              onChange={(e) => setDescripcion(e.target.value)}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      <br />
                      <Button
                        color="success"
                        variant="contained"
                        disabled={bodega === ""}
                        onClick={() => {
                          cargarProductos();
                          setOpen(true);
                        }}
                      >
                        SELECCIONAR PRODUCTO
                      </Button>

                      <br />
                      <br />
                      <Paper
                        elevation={2}
                        sx={{ overflowX: "scroll", padding: "10px" }}
                      >
                        <Box
                          sx={{
                            height: 300,
                            minWidth: 1000,
                            "& .super-app-theme--header": {
                              backgroundColor: "#428bd4",
                              color: "white",
                              fontSize: "16px",
                            },
                          }}
                        >
                          <DataGrid
                            disableColumnMenu
                            getRowHeight={() => "auto"}
                            getEstimatedRowHeight={() => 200}
                            rows={lista}
                            columns={columns}
                            getRowId={(row) => row.id}
                            localeText={
                              esES.components.MuiDataGrid.defaultProps
                                .localeText
                            }
                            slots={{ toolbar: CustomToolbar }}
                          />
                        </Box>
                      </Paper>
                      <br />
                      {lista.filter(
                        (val) =>
                          (val.producto.usa_serie &&
                            val.producto.tipo2 !== "COMPUESTO" &&
                            val.series.length !== val.cantidad) ||
                          (val.producto.usa_serie &&
                            val.producto.tipo2 === "COMPUESTO" &&
                            val.series.length !== 1)
                      ).length > 0 && (
                        <Typography variant="h5" color="red">
                          <strong>
                            Hay productos que no tienen las series seleccionadas
                          </strong>
                        </Typography>
                      )}
                      <Button
                        color="success"
                        variant="contained"
                        disabled={
                          bodega === "" ||
                          lista.length === 0 ||
                          descripcion === "" ||
                          lista.filter(
                            (val) =>
                              (val.producto.usa_serie &&
                                val.producto.tipo2 !== "COMPUESTO" &&
                                val.series.length !== val.cantidad) ||
                              (val.producto.usa_serie &&
                                val.producto.tipo2 === "COMPUESTO" &&
                                val.series.length !== 1)
                          ).length > 0 ||
                          motivo === ""
                        }
                        onClick={() => ingreso()}
                      >
                        Realizar Ingreso
                      </Button>
                    </>
                  )}
                </>
              </TabPanel>
              <TabPanel value={"1"}>
                <>
                  <Typography variant="h5">
                    <strong>EGRESO DE CUADRILLA</strong>{" "}
                  </Typography>

                  {existe ||
                  (movimientos &&
                    movimientos.filter((val) => val.tipomovimiento === "EGRESO")
                      .length > 0) ? (
                    <>
                      <br />
                      <Paper
                        elevation={2}
                        sx={{ overflowX: "scroll", padding: "10px" }}
                      >
                        <Box
                          sx={{
                            height: 400,
                            "& .super-app-theme--header": {
                              backgroundColor: "#428bd4",
                              color: "white",
                              fontSize: "16px",
                            },
                          }}
                        >
                          <DataGrid
                            disableColumnMenu
                            getRowHeight={() => "auto"}
                            getEstimatedRowHeight={() => 200}
                            rows={movimientos.filter(
                              (val) => val.tipomovimiento === "EGRESO"
                            )}
                            columns={columnsMov}
                            getRowId={(row) => row.id}
                            localeText={
                              esES.components.MuiDataGrid.defaultProps
                                .localeText
                            }
                            slots={{ toolbar: CustomToolbar }}
                          />
                        </Box>
                      </Paper>
                    </>
                  ) : (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                          <Box px={2}>
                            <Typography>
                              {" "}
                              <strong>Motivo *</strong>{" "}
                            </Typography>
                            <Select
                              MenuProps={MenuProps}
                              fullWidth
                              size="small"
                              value={motivo}
                              onChange={(e) => setMotivo(e.target.value)}
                            >
                              {motivos
                                ? motivos.map((val) => (
                                    <MenuItem key={val.id} value={val}>
                                      {val.motivo}
                                    </MenuItem>
                                  ))
                                : ""}
                            </Select>
                          </Box>
                          <br />
                          <Box px={2}>
                            <Typography>
                              {" "}
                              <strong>Bodega *</strong>{" "}
                            </Typography>
                            <Autocomplete
                              size="small"
                              fullWidth
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              disablePortal
                              value={bodega}
                              disabled={lista.length > 0}
                              options={bodegas}
                              getOptionLabel={(option) => option.nombre ?? ""}
                              onChange={(e, t) => {
                                setBodega(t);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <Box px={2}>
                            <Typography>
                              {" "}
                              <strong>Descripción *</strong>{" "}
                            </Typography>
                            <TextField
                              fullWidth
                              multiline
                              rows={3}
                              size="small"
                              value={descripcion}
                              onChange={(e) => setDescripcion(e.target.value)}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      <br />
                      <Button
                        color="success"
                        variant="contained"
                        disabled={bodega === ""}
                        onClick={() => {
                          cargarProductos("a");
                          setOpen(true);
                        }}
                      >
                        SELECCIONAR PRODUCTO
                      </Button>

                      <br />
                      <br />
                      <Paper
                        elevation={2}
                        sx={{ overflowX: "scroll", padding: "10px" }}
                      >
                        <Box
                          sx={{
                            height: 300,
                            minWidth: 1000,
                            "& .super-app-theme--header": {
                              backgroundColor: "#428bd4",
                              color: "white",
                              fontSize: "16px",
                            },
                          }}
                        >
                          <DataGrid
                            disableColumnMenu
                            getRowHeight={() => "auto"}
                            getEstimatedRowHeight={() => 200}
                            rows={lista}
                            columns={columns}
                            getRowId={(row) => row.id}
                            localeText={
                              esES.components.MuiDataGrid.defaultProps
                                .localeText
                            }
                            slots={{ toolbar: CustomToolbar }}
                          />
                        </Box>
                      </Paper>
                      <br />
                      {lista.filter(
                        (val) =>
                          (val.producto.usa_serie &&
                            val.producto.tipo2 !== "COMPUESTO" &&
                            val.series.length !== val.cantidad) ||
                          (val.producto.usa_serie &&
                            val.producto.tipo2 === "COMPUESTO" &&
                            val.series.length !== 1)
                      ).length > 0 && (
                        <Typography variant="h5" color="red">
                          <strong>
                            Hay productos que no tienen las series seleccionadas
                          </strong>
                        </Typography>
                      )}
                      <Button
                        color="success"
                        variant="contained"
                        disabled={
                          bodega === "" ||
                          lista.length === 0 ||
                          descripcion === "" ||
                          lista.filter(
                            (val) =>
                              (val.producto.usa_serie &&
                                val.producto.tipo2 !== "COMPUESTO" &&
                                val.series.length !== val.cantidad) ||
                              (val.producto.usa_serie &&
                                val.producto.tipo2 === "COMPUESTO" &&
                                val.series.length !== 1)
                          ).length > 0 ||
                          motivo === ""
                        }
                        onClick={() => egreso()}
                      >
                        Realizar Egreso
                      </Button>
                    </>
                  )}
                </>
              </TabPanel>
              <TabPanel value={"2"}>
                <Materiales actividad={datos} />
              </TabPanel>
            </TabContext>
          ) : (
            <TabContext value={tab}>
              <Box sx={{ borderColor: "divider" }}>
                <TabList variant="scrollable">
                  {tab === "0" && (
                    <Tab
                      label="Materiales Recibidos"
                      sx={{ fontWeight: "bold" }}
                      value={"0"}
                    />
                  )}
                  {tab === "1" && (
                    <Tab
                      label="Materiales utilizados"
                      sx={{ fontWeight: "bold" }}
                      value={"1"}
                    />
                  )}
                  {tab === "2" && (
                    <Tab
                      label="Registro Datos"
                      sx={{ fontWeight: "bold" }}
                      value={"2"}
                    />
                  )}
                </TabList>
              </Box>
              <>
                {tab === "0" && (
                  <TabPanel value={"0"}>
                    <>
                      <>
                        <Typography variant="h5">
                          <strong>INGRESO A CUADRILLA</strong>{" "}
                        </Typography>

                        {existe ||
                        (movimientos &&
                          movimientos.filter(
                            (val) => val.tipomovimiento === "INGRESO"
                          ).length > 0) ? (
                          <>
                            <br />
                            <Paper
                              elevation={2}
                              sx={{ overflowX: "scroll", padding: "10px" }}
                            >
                              <Box
                                sx={{
                                  height: 400,
                                  // minWidth: 1000,
                                  "& .super-app-theme--header": {
                                    backgroundColor: "#428bd4",
                                    color: "white",
                                    fontSize: "16px",
                                  },
                                }}
                              >
                                <DataGrid
                                  disableColumnMenu
                                  getRowHeight={() => "auto"}
                                  getEstimatedRowHeight={() => 200}
                                  rows={movimientos.filter(
                                    (val) => val.tipomovimiento === "INGRESO"
                                  )}
                                  columns={columnsMov}
                                  getRowId={(row) => row.id}
                                  localeText={
                                    esES.components.MuiDataGrid.defaultProps
                                      .localeText
                                  }
                                  slots={{ toolbar: CustomToolbar }}
                                />
                              </Box>
                            </Paper>
                          </>
                        ) : (
                          <>
                            <Grid container spacing={2}>
                              <Grid item xs={12} lg={6}>
                                <Box px={2}>
                                  <Typography>
                                    {" "}
                                    <strong>Motivo *</strong>{" "}
                                  </Typography>
                                  <Select
                                    MenuProps={MenuProps}
                                    fullWidth
                                    size="small"
                                    value={motivo}
                                    onChange={(e) => setMotivo(e.target.value)}
                                  >
                                    {motivos
                                      ? motivos.map((val) => (
                                          <MenuItem key={val.id} value={val}>
                                            {val.motivo}
                                          </MenuItem>
                                        ))
                                      : ""}
                                  </Select>
                                </Box>
                                <br />
                                <Box px={2}>
                                  <Typography>
                                    {" "}
                                    <strong>Bodega *</strong>{" "}
                                  </Typography>
                                  <Autocomplete
                                    size="small"
                                    fullWidth
                                    isOptionEqualToValue={(option, value) =>
                                      option.id === value.id
                                    }
                                    disablePortal
                                    value={bodega}
                                    disabled={lista.length > 0}
                                    options={bodegas}
                                    getOptionLabel={(option) =>
                                      option.nombre ?? ""
                                    }
                                    onChange={(e, t) => {
                                      setBodega(t);
                                    }}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                  />
                                </Box>
                              </Grid>

                              <Grid item xs={12} lg={6}>
                                <Box px={2}>
                                  <Typography>
                                    {" "}
                                    <strong>Descripción *</strong>{" "}
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    multiline
                                    rows={3}
                                    size="small"
                                    value={descripcion}
                                    onChange={(e) =>
                                      setDescripcion(e.target.value)
                                    }
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                            <br />
                            <Button
                              color="success"
                              variant="contained"
                              disabled={bodega === ""}
                              onClick={() => {
                                cargarProductos();
                                setOpen(true);
                              }}
                            >
                              SELECCIONAR PRODUCTO
                            </Button>

                            <br />
                            <br />
                            <Paper
                              elevation={2}
                              sx={{ overflowX: "scroll", padding: "10px" }}
                            >
                              <Box
                                sx={{
                                  height: 300,
                                  minWidth: 1000,
                                  "& .super-app-theme--header": {
                                    backgroundColor: "#428bd4",
                                    color: "white",
                                    fontSize: "16px",
                                  },
                                }}
                              >
                                <DataGrid
                                  disableColumnMenu
                                  getRowHeight={() => "auto"}
                                  getEstimatedRowHeight={() => 200}
                                  rows={lista}
                                  columns={columns}
                                  getRowId={(row) => row.id}
                                  localeText={
                                    esES.components.MuiDataGrid.defaultProps
                                      .localeText
                                  }
                                  slots={{ toolbar: CustomToolbar }}
                                />
                              </Box>
                            </Paper>
                            <br />
                            {lista.filter(
                              (val) =>
                                (val.producto.usa_serie &&
                                  val.producto.tipo2 !== "COMPUESTO" &&
                                  val.series.length !== val.cantidad) ||
                                (val.producto.usa_serie &&
                                  val.producto.tipo2 === "COMPUESTO" &&
                                  val.series.length !== 1)
                            ).length > 0 && (
                              <Typography variant="h5" color="red">
                                <strong>
                                  Hay productos que no tienen las series
                                  seleccionadas
                                </strong>
                              </Typography>
                            )}
                            <Button
                              color="success"
                              variant="contained"
                              disabled={
                                bodega === "" ||
                                lista.length === 0 ||
                                descripcion === "" ||
                                lista.filter(
                                  (val) =>
                                    (val.producto.usa_serie &&
                                      val.producto.tipo2 !== "COMPUESTO" &&
                                      val.series.length !== val.cantidad) ||
                                    (val.producto.usa_serie &&
                                      val.producto.tipo2 === "COMPUESTO" &&
                                      val.series.length !== 1)
                                ).length > 0 ||
                                motivo === ""
                              }
                              onClick={() => ingreso()}
                            >
                              Realizar Ingreso
                            </Button>
                          </>
                        )}
                      </>
                      <br />
                      <br />
                      <Button
                        disabled={
                          !(
                            bodega === "" ||
                            lista.length === 0 ||
                            descripcion === "" ||
                            lista.filter(
                              (val) =>
                                (val.producto.usa_serie &&
                                  val.producto.tipo2 !== "COMPUESTO" &&
                                  val.series.length !== val.cantidad) ||
                                (val.producto.usa_serie &&
                                  val.producto.tipo2 === "COMPUESTO" &&
                                  val.series.length !== 1)
                            ).length > 0 ||
                            motivo === ""
                          )
                        }
                        color="info"
                        variant="contained"
                        onClick={() => setTab("1")}
                      >
                        {movimientos &&
                        movimientos.filter(
                          (val) => val.tipomovimiento === "INGRESO"
                        ).length > 0
                          ? "Avanzar"
                          : "Avanzar sin Ingresar Materiales"}
                      </Button>
                    </>
                  </TabPanel>
                )}
                {tab === "1" && (
                  <TabPanel value={"1"}>
                    <>
                      <>
                        <Typography variant="h5">
                          <strong>EGRESO DE CUADRILLA</strong>{" "}
                        </Typography>

                        {existe ||
                        (movimientos &&
                          movimientos.filter(
                            (val) => val.tipomovimiento === "EGRESO"
                          ).length > 0) ? (
                          <>
                            <br />
                            <Paper
                              elevation={2}
                              sx={{ overflowX: "scroll", padding: "10px" }}
                            >
                              <Box
                                sx={{
                                  height: 400,
                                  "& .super-app-theme--header": {
                                    backgroundColor: "#428bd4",
                                    color: "white",
                                    fontSize: "16px",
                                  },
                                }}
                              >
                                <DataGrid
                                  disableColumnMenu
                                  getRowHeight={() => "auto"}
                                  getEstimatedRowHeight={() => 200}
                                  rows={movimientos.filter(
                                    (val) => val.tipomovimiento === "EGRESO"
                                  )}
                                  columns={columnsMov}
                                  getRowId={(row) => row.id}
                                  localeText={
                                    esES.components.MuiDataGrid.defaultProps
                                      .localeText
                                  }
                                  slots={{ toolbar: CustomToolbar }}
                                />
                              </Box>
                            </Paper>
                          </>
                        ) : (
                          <>
                            <Grid container spacing={2}>
                              <Grid item xs={12} lg={6}>
                                <Box px={2}>
                                  <Typography>
                                    {" "}
                                    <strong>Motivo *</strong>{" "}
                                  </Typography>
                                  <Select
                                    MenuProps={MenuProps}
                                    fullWidth
                                    size="small"
                                    value={motivo}
                                    onChange={(e) => setMotivo(e.target.value)}
                                  >
                                    {motivos
                                      ? motivos.map((val) => (
                                          <MenuItem key={val.id} value={val}>
                                            {val.motivo}
                                          </MenuItem>
                                        ))
                                      : ""}
                                  </Select>
                                </Box>
                                <br />
                                <Box px={2}>
                                  <Typography>
                                    {" "}
                                    <strong>Bodega *</strong>{" "}
                                  </Typography>
                                  <Autocomplete
                                    size="small"
                                    fullWidth
                                    isOptionEqualToValue={(option, value) =>
                                      option.id === value.id
                                    }
                                    disablePortal
                                    value={bodega}
                                    disabled={lista.length > 0}
                                    options={bodegas}
                                    getOptionLabel={(option) =>
                                      option.nombre ?? ""
                                    }
                                    onChange={(e, t) => {
                                      setBodega(t);
                                    }}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                  />
                                </Box>
                              </Grid>

                              <Grid item xs={12} lg={6}>
                                <Box px={2}>
                                  <Typography>
                                    {" "}
                                    <strong>Descripción *</strong>{" "}
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    multiline
                                    rows={3}
                                    size="small"
                                    value={descripcion}
                                    onChange={(e) =>
                                      setDescripcion(e.target.value)
                                    }
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                            <br />
                            <Button
                              color="success"
                              variant="contained"
                              disabled={bodega === ""}
                              onClick={() => {
                                cargarProductos("a");
                                setOpen(true);
                              }}
                            >
                              SELECCIONAR PRODUCTO
                            </Button>

                            <br />
                            <br />
                            <Paper
                              elevation={2}
                              sx={{ overflowX: "scroll", padding: "10px" }}
                            >
                              <Box
                                sx={{
                                  height: 300,
                                  minWidth: 1000,
                                  "& .super-app-theme--header": {
                                    backgroundColor: "#428bd4",
                                    color: "white",
                                    fontSize: "16px",
                                  },
                                }}
                              >
                                <DataGrid
                                  disableColumnMenu
                                  getRowHeight={() => "auto"}
                                  getEstimatedRowHeight={() => 200}
                                  rows={lista}
                                  columns={columns}
                                  getRowId={(row) => row.id}
                                  localeText={
                                    esES.components.MuiDataGrid.defaultProps
                                      .localeText
                                  }
                                  slots={{ toolbar: CustomToolbar }}
                                />
                              </Box>
                            </Paper>
                            <br />
                            {lista.filter(
                              (val) =>
                                (val.producto.usa_serie &&
                                  val.producto.tipo2 !== "COMPUESTO" &&
                                  val.series.length !== val.cantidad) ||
                                (val.producto.usa_serie &&
                                  val.producto.tipo2 === "COMPUESTO" &&
                                  val.series.length !== 1)
                            ).length > 0 && (
                              <Typography variant="h5" color="red">
                                <strong>
                                  Hay productos que no tienen las series
                                  seleccionadas
                                </strong>
                              </Typography>
                            )}
                            <Button
                              color="success"
                              variant="contained"
                              disabled={
                                bodega === "" ||
                                lista.length === 0 ||
                                descripcion === "" ||
                                lista.filter(
                                  (val) =>
                                    (val.producto.usa_serie &&
                                      val.producto.tipo2 !== "COMPUESTO" &&
                                      val.series.length !== val.cantidad) ||
                                    (val.producto.usa_serie &&
                                      val.producto.tipo2 === "COMPUESTO" &&
                                      val.series.length !== 1)
                                ).length > 0 ||
                                motivo === ""
                              }
                              onClick={() => egreso()}
                            >
                              Realizar Egreso
                            </Button>
                          </>
                        )}
                      </>
                      <br />
                      <br />
                      <Button
                        color="error"
                        variant="contained"
                        onClick={() => setTab("0")}
                      >
                        Regresar
                      </Button>
                      <Button
                        disabled={
                          !(
                            bodega === "" ||
                            lista.length === 0 ||
                            descripcion === "" ||
                            lista.filter(
                              (val) =>
                                (val.producto.usa_serie &&
                                  val.producto.tipo2 !== "COMPUESTO" &&
                                  val.series.length !== val.cantidad) ||
                                (val.producto.usa_serie &&
                                  val.producto.tipo2 === "COMPUESTO" &&
                                  val.series.length !== 1)
                            ).length > 0 ||
                            motivo === ""
                          )
                        }
                        color="info"
                        variant="contained"
                        onClick={() => setTab("2")}
                      >
                        {movimientos &&
                        movimientos.filter(
                          (val) => val.tipomovimiento === "EGRESO"
                        ).length > 0
                          ? "Avanzar"
                          : "Avanzar sin Egresar Materiales"}
                      </Button>
                    </>
                  </TabPanel>
                )}
                {tab === "2" && (
                  <TabPanel value={"2"}>
                    <>
                      <Materiales actividad={datos} />
                      <br />
                      <br />
                      <Button
                        color="error"
                        variant="contained"
                        onClick={() => setTab("1")}
                      >
                        Regresar
                      </Button>
                    </>
                  </TabPanel>
                )}
              </>
            </TabContext>
          )}
        </Paper>
      </Box>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          limpiar();
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogContent sx={{ minHeight: 300 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Producto *</strong>{" "}
                </Typography>
                <Autocomplete
                  ListboxProps={{ style: { maxHeight: 150 } }}
                  size="small"
                  fullWidth
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disablePortal
                  value={producto}
                  disabled={id !== ""}
                  options={productos}
                  getOptionLabel={(option) =>
                    option.codigo ? option.codigo + ": " + option.nombre : ""
                  }
                  onChange={(e, t) => {
                    if (t === null) {
                      setProducto("");
                    } else {
                      setProducto(t);
                    }
                    setCantidad("");
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Cantidad *</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={cantidad}
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={(e) => setCantidad(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Unidad Real: </strong>{" "}
                  {producto?.unidad?.unidad
                    ? producto?.unidad_real + " " + producto?.unidad?.unidad
                    : producto?.unidad_real}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <br />
                <Button
                  color="success"
                  variant="contained"
                  disabled={
                    producto === "" ||
                    cantidad === "" ||
                    (producto?.tipo2 === "COMPUESTO" &&
                      producto?.usa_serie &&
                      cantidad > producto.unidad_real)
                  }
                  onClick={() => {
                    //aconsole.log(producto);
                    if (id !== "") {
                      setLista([
                        ...lista.filter((val) => val.id !== id),
                        {
                          id: id,
                          producto: producto,
                          cantidad: Number(cantidad),
                          garantia: "NO",
                          series: [],
                        },
                      ]);
                    } else {
                      if (
                        producto !== "" &&
                        producto.tipo2 === "COMPUESTO" &&
                        producto.usa_serie
                      ) {
                        setLista([
                          ...lista,
                          {
                            id: numero,
                            producto: producto,
                            garantia: "NO",
                            cantidad: Number(cantidad),
                            series: [],
                          },
                        ]);
                        setNumero(numero + 1);
                      } else {
                        setLista([
                          ...lista.filter(
                            (val) => val.producto.id !== producto.id
                          ),
                          {
                            id: numero,
                            garantia: "NO",
                            producto: producto,
                            cantidad: Number(cantidad),
                            series: [],
                          },
                        ]);
                        setNumero(numero + 1);
                      }
                    }
                    setOpen(false);
                    limpiar();
                  }}
                >
                  Guardar
                </Button>
              </Box>
            </Grid>
            {producto?.tipo2 === "COMPUESTO" &&
              producto?.usa_serie &&
              cantidad > producto.unidad_real && (
                <Grid item xs={12}>
                  <Typography variant="h6" color="red">
                    <strong>
                      En productos compuestos con series no se puede colocar
                      cantidades mayores a su unidad real
                    </strong>
                  </Typography>
                </Grid>
              )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen(false);
              limpiar();
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open2 && tab === "0"}
        onClose={() => {
          setOpen2(false);
          limpiar();
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {" "}
          <strong>Ingresar Serie</strong>{" "}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Producto: </strong> {producto.codigo}{" "}
                  {" - " + producto.nombre}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Cantidad: </strong>
                  {cantidad}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Garantia *</strong>{" "}
                </Typography>
                <Select
                  fullWidth
                  size="small"
                  value={garantia}
                  onChange={(e) => setGarantia(e.target.value)}
                >
                  <MenuItem key={0} value={"SI"}>
                    {"SI"}
                  </MenuItem>
                  <MenuItem key={1} value={"NO"}>
                    {"NO"}
                  </MenuItem>
                </Select>
              </Box>
              <br />
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Nueva Serie: </strong>{" "}
                </Typography>
                <Stack direction={"row"} spacing={2}>
                  <TextField
                    fullWidth
                    size="small"
                    value={nuevaSerie}
                    onChange={(e) =>
                      setNuevaSerie(
                        e.target.value
                          ? e.target.value?.toUpperCase()
                          : e.target.value
                      )
                    }
                  />
                  <Button
                    color="success"
                    variant="contained"
                    disabled={nuevaSerie === ""}
                    onClick={() => {
                      if (
                        ![
                          ...seleccionado.map((val) => {
                            return val.serie;
                          }),
                        ].includes(nuevaSerie)
                      ) {
                        if (
                          lista.filter(
                            (val) =>
                              val.producto.id === producto.id &&
                              val.series.includes(nuevaSerie)
                          ).length === 0
                        ) {
                          setSeleccionado(
                            seleccionado.concat([
                              {
                                serie: nuevaSerie,
                              },
                            ])
                          );
                        }
                      }
                      setNuevaSerie("");
                    }}
                  >
                    Agregar
                  </Button>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Paper
                elevation={2}
                sx={{ overflowX: "scroll", padding: "10px" }}
              >
                <Box
                  sx={{
                    height: 300,
                    // minWidth: 1000,
                    "& .super-app-theme--header": {
                      backgroundColor: "#428bd4",
                      color: "white",
                      fontSize: "16px",
                    },
                  }}
                >
                  <DataGrid
                    disableColumnMenu
                    getRowHeight={() => "auto"}
                    getEstimatedRowHeight={() => 200}
                    rows={seleccionado}
                    columns={[
                      {
                        field: "serie",
                        headerClassName: "super-app-theme--header",
                        headerName: "SERIE",
                        flex: 1,
                      },
                      {
                        field: "1",
                        headerClassName: "super-app-theme--header",
                        headerName: "Eliminar",
                        flex: 1,
                        renderCell: (params) => (
                          <IconButton onClick={() => handleOpen3(params.row)}>
                            <DeleteIcon color="error" />
                          </IconButton>
                        ),
                      },
                    ]}
                    getRowId={(row) => row.serie}
                    localeText={
                      esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    slots={{ toolbar: CustomToolbar }}
                  />
                </Box>
              </Paper>
              {/* <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Series: </strong>{" "}
                </Typography>

                <Autocomplete
                  size="small"
                  fullWidth
                  isOptionEqualToValue={(option, value) =>
                    option.serie === value.serie
                  }
                  disablePortal
                  multiple
                  value={seleccionado}
                  options={series}
                  getOptionLabel={(option) => option.serie}
                  onChange={(e, t) => {
                    setSeleccionado(t);
                    //aconsole.log(t);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box> */}
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <br />
                <Button
                  color="success"
                  variant="contained"
                  disabled={
                    producto === "" ||
                    cantidad === "" ||
                    seleccionado.length !== cantidad
                  }
                  onClick={() => validar()}
                >
                  Guardar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen2(false);
              limpiar();
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open2 && tab === "1"}
        onClose={() => {
          setOpen2(false);
          limpiar();
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {" "}
          <strong>Ingresar Serie</strong>{" "}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Producto: </strong> {producto.codigo}{" "}
                  {" - " + producto.nombre}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Cantidad: </strong>
                  {cantidad}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Series: </strong>{" "}
                </Typography>
                <Autocomplete
                  ListboxProps={{ style: { maxHeight: 150 } }}
                  size="small"
                  fullWidth
                  isOptionEqualToValue={(option, value) =>
                    option.serie === value.serie
                  }
                  disablePortal
                  multiple
                  value={seleccionado}
                  options={series}
                  getOptionLabel={(option) => option.serie}
                  onChange={(e, t) => {
                    setSeleccionado(t);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <br />
                <Button
                  color="success"
                  variant="contained"
                  disabled={
                    producto === "" ||
                    cantidad === "" ||
                    seleccionado.length !== cantidad
                  }
                  onClick={() => {
                    let prod = null;
                    let lista2 = [];
                    for (const el of lista) {
                      if (el.id === id) {
                        prod = el;
                      } else {
                        lista2.push(el);
                      }
                    }
                    setLista([
                      ...lista2,
                      {
                        ...prod,
                        series: [
                          ...seleccionado.map((val) => {
                            return val.serie;
                          }),
                        ],
                      },
                    ]);
                    setOpen2(false);
                    limpiar();
                  }}
                >
                  Guardar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen2(false);
              limpiar();
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Cargando open2={cargando} />
    </>
  );
};
