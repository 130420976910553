import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { validarRoles } from "../../components/utils/ValidarRoles";

export const ListRutas = ({ info }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton component={Link} onClick={handleClick}>
        <ListItemIcon>
          <AltRouteIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{ fontWeight: "bold" }}
          primary="Rutas"
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding dense>
          {validarRoles("LEER RUTAS", info) && (
            <ListItemButton sx={{ pl: 8 }} component={Link} to={"rutas/rutas"}>
              <ListItemText primary="Rutas" />
            </ListItemButton>
          )}

          {validarRoles("LEER ACTIVIDADES", info) && (
            <ListItemButton
              sx={{ pl: 8 }}
              component={Link}
              to={"rutas/actividades"}
            >
              <ListItemText primary="Actividades" />
            </ListItemButton>
          )}

          {validarRoles("REAGENDAR ACTIVIDADES", info) && (
            <ListItemButton
              sx={{ pl: 8 }}
              component={Link}
              to={"rutas/reagendar"}
            >
              <ListItemText primary="Reagendar Actividades" />
            </ListItemButton>
          )}

          {validarRoles("LEER METRICAS RUTAS", info) && (
            <ListItemButton
              sx={{ pl: 8 }}
              component={Link}
              to={"rutas/metricas"}
            >
              <ListItemText primary="Métricas Rutas" />
            </ListItemButton>
          )}
        </List>
      </Collapse>
    </>
  );
};
