import { Box, Button, Grid, 
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton, Paper, TextField, Typography } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { CustomToolbar } from "../../../components/utils/CustomFilter";
import moment from "moment";
import { Cargando } from "../../../components/utils/Cargando";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { FiltroFechas } from "../../../components/utils/FiltroFechas";

export const MovimientosSerie = () => {
  const [cargando, setCargando] = useState(false);

  const [lista, setLista] = useState(null);
  const [texto, setTexto] = useState("");
  const [view, setView] = useState(null);
  const [open, setOpen] = useState(false);

  const [value, setValue] = useState([
    startOfDay(subDays(new Date(), 6)),
    endOfDay(new Date()),
  ]);

  function cargar2(inicio, fin){
    cargar(texto,inicio, fin)
  }

  function cargar(serie, inicio, fin) {
    setCargando(true);

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    let url = "https://backterra.econtel.com.ec/api/v1/movimiento?serie=" +serie;
    if (inicio && fin) {
      url = "https://backterra.econtel.com.ec/api/v1/movimiento?serie=" +serie+
      "&inicio=" + inicio + "&fin=" + fin;
    } else {
      url = "https://backterra.econtel.com.ec/api/v1/movimiento?serie=" +serie+
      "&inicio=" + value[0].toISOString() + "&fin=" + value[1].toISOString();
    }

    fetch(
      url,
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setLista(JSON.parse(result).sort((a, b) =>  { return b.id - a.id}));
        setCargando(false);
      })
      .catch((error) => {
        setCargando(false);

        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  const columns = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "Movimiento",
      flex: 1,
      valueGetter: (params) => {
        return "MOV-" + params.row.id;
      },
    },
    {
      field: "bodega",
      headerClassName: "super-app-theme--header",
      headerName: "Bodega",
      flex: 1,
      valueGetter: (params) => {
        return params.row.bodega?.nombre ?? "";
      },
    },
    {
      field: "producto",
      headerClassName: "super-app-theme--header",
      headerName: "Producto",
      flex: 1,
      valueGetter: (params) => {
        return params.row.producto?.nombre ?? "";
      },
    },
    {
      field: "tipomovimiento",
      headerClassName: "super-app-theme--header",
      headerName: "Tipo",
      flex: 1,
    },
    {
      field: "id_actividad",
      headerClassName: "super-app-theme--header",
      headerName: "Actividad",
      flex: 1,
      valueGetter: (params) => {
        return params.row.id_actividad ? "ACTIVIDAD-" + params.row.id_actividad : "";
      },
    },
    {
      field: "proveedor",
      headerClassName: "super-app-theme--header",
      headerName: "Proveedor",
      flex: 1,
      valueGetter: (params) => {
        return params.row.proveedor?.nombre ?? "";
      },
    },
    {
      field: "cantidad",
      headerClassName: "super-app-theme--header",
      headerName: "Cantidad",
      flex: 1,
    },
    {
      field: "motivo",
      headerClassName: "super-app-theme--header",
      headerName: "Motivo",
      flex: 1,
      valueGetter: (params) => {
        return params.row.motivo_movimiento?.motivo ?? "";
      },
    },
    {
      field: "descripcion",
      headerClassName: "super-app-theme--header",
      headerName: "Descripción",
      flex: 1,
    },
    {
      field: "series",
      headerClassName: "super-app-theme--header",
      headerName: "Series",
      flex: 1,
      valueGetter: (params) => {
        if (params.row.series) {
          return [
            ...params.row.series.map((val) => {
              return val.serie;
            }),
          ].join(", ");
        } else {
          return "";
        }
      },
    },
    {
      field: "usuario",
      headerClassName: "super-app-theme--header",
      headerName: "Usuario",
      flex: 1,
      valueGetter: (params) => {
        return params.row.usuario?.nombre ?? "";
      },
    },
    {
      field: "fecha",
      headerClassName: "super-app-theme--header",
      headerName: "Fecha",
      flex: 1,
      valueGetter: (params) => {
        return moment(params.row.created_at).format("DD/MMMM/yyyy HH:mm") ?? "";
      },
    },
    {
      field: "foto",
      headerClassName: "super-app-theme--header",
      headerName: "Foto",
      width: 70,
      renderCell: (params) => (
        <>
          {params.row.foto && (
            <IconButton
              onClick={() => {
                setView(params.row.foto);
                setOpen(true);
              }}
            >
              <RemoveRedEyeIcon color="warning" />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  return (
    <Box py={2}>
      <Paper elevation={2} sx={{ padding: "10px" }}>
        <Typography variant="h5">
          {" "}
          <strong>Historial por Serie</strong>{" "}
        </Typography>
        <br />
        <FiltroFechas metodo={cargar2} value={value} setValue={setValue} />

        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Box px={2}>
              <Typography>
                {" "}
                <strong>Serie</strong>{" "}
              </Typography>
              <TextField
                fullWidth
                size="small"
                value={texto}
                onChange={(e) => setTexto(e.target.value)}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box px={2}>
              <br />
              <Button
                color="success"
                variant="contained"
                disabled={texto === ""}
                onClick={() => cargar(texto)}
              >
                BUSCAR MOVIMIENTOS
              </Button>
            </Box>
          </Grid>
        </Grid>
        <br />
        <Paper elevation={2} sx={{ overflowX: "scroll", padding: "10px" }}>
          <Box
            sx={{
              height: 600,
              minWidth: 1000,
              "& .super-app-theme--header": {
                backgroundColor: "#428bd4",
                color: "white",
                fontSize: "16px",
              },
            }}
          >
            <DataGrid  disableColumnMenu
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}
              rows={lista ?? []}
              loading={lista === null && texto !== ""}
              columns={columns}
              getRowId={(row) => row.id}
              //
              //
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              slots={{ toolbar: CustomToolbar }}
            />
          </Box>
        </Paper>
      </Paper>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setView(null);
        }}
        maxWidth="lg"
      >
        <DialogTitle>
          <strong>Foto Transferencia</strong>
        </DialogTitle>
        <DialogContent>
          <Box px={2}>
            {view && <img src={view} alt={"imagen"} height={200} />}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen(false);
              setView(null);
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Cargando open2={cargando} />
    </Box>
  );
};
