import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  TextField,
  Stack,
  Typography,
  Autocomplete,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { CustomToolbar } from "../../components/utils/CustomFilter";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  deleteDoc,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { useSelector } from "react-redux";
import { validarRoles } from "../../components/utils/ValidarRoles";
import { Cargando } from "../../components/utils/Cargando";

export const AdministrarUsuarios = () => {
  const [cargando, setCargando] = useState(false);

  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;
  const [open, setOpen] = useState(false);
  const [nombre, setNombre] = useState("");
  const [cedula, setCedula] = useState("");
  const [correo, setCorreo] = useState("");
  const [contra, setContra] = useState("");
  const [roles, setRoles] = useState([]);

  const [open3, setOpen3] = useState(false);

  const [seleccionado, setSeleccionado] = useState([]);
  const [id, setId] = useState("");
  const [uid, setUid] = useState("");

  const [lista, setLista] = useState(null);

  const [open2, setOpen2] = useState(false);

  useEffect(() => {
    cargar();
  }, []);

  function limpiar() {
    setUid("");
    setNombre("");
    setCorreo("");
    setCedula("");
    setSeleccionado([]);
    setContra("");
    setId("");
  }

  function handleOpen2(row) {
    setId(row.id);
    setOpen2(true);
    setUid(row.uid);
  }
  function handleOpen(row) {
    setNombre(row.nombre);
    setCorreo(row.correo);
    setSeleccionado(row.roles ?? []);
    setCedula(row.cedula);
    setId(row.id);
    setContra(row.contraseña);
    setOpen(true);
    setUid(row.uid);
  }

  function handleOpen3(row) {
    setId(row.id);
    setCorreo(row.correo);
    setUid(row.uid);
    setContra("");
    setOpen3(true);
  }
  const columns = [
    {
      field: "nombre",
      headerClassName: "super-app-theme--header",
      headerName: "Nombre",
      flex: 1,
    },
    {
      field: "cedula",
      headerClassName: "super-app-theme--header",
      headerName: "Cédula",
      flex: 1,
    },
    {
      field: "correo",
      headerClassName: "super-app-theme--header",
      headerName: "Correo",
      flex: 1,
    },
    {
      field: "roles",
      headerClassName: "super-app-theme--header",
      headerName: "Roles",
      flex: 1,
      valueGetter: (params) => {
        return [
          ...params.row.roles.map((val) => {
            return val.rol + " ";
          }),
        ].join(", ");
      },
    },
    {
      field: "1",
      headerClassName: "super-app-theme--header",
      headerName: "Acciones",
      flex: 1,
      renderCell: (params) => (
        <Stack direction={"row"} spacing={2}>
          {validarRoles("EDITAR USUARIO", userInfo) && (
            <>
              <IconButton onClick={() => handleOpen(params.row)}>
                <EditIcon color="warning" />
              </IconButton>
              <IconButton onClick={() => handleOpen3(params.row)}>
                <VpnKeyIcon color="success" />
              </IconButton>
            </>
          )}
          {validarRoles("ELIMINAR USUARIO", userInfo) && (
            <IconButton onClick={() => handleOpen2(params.row)}>
              <DeleteIcon color="error" />
            </IconButton>
          )}
        </Stack>
      ),
    },
  ];

  function crear() {
    setCargando(true);

    const functions = getFunctions();
    const addUser = httpsCallable(functions, "addUser");
    addUser({ email: correo, password: contra })
      .then((result) => {
        if (result.data.error) {
          setCargando(false);

          Swal.fire({
            icon: "error",
            text: "Error: " + result.data.error.errorInfo,
          });
        } else {
          let uid2 = result.data.user.uid;
          const docRef = doc(db, "usuarios", result.data.user.uid);
          setDoc(docRef, {
            password: contra,
            correo: correo,
            fechaCreacion: serverTimestamp(),
          })
            .then(() => {
              let body = JSON.stringify({
                nombre: nombre,
                cedula: cedula,
                correo: correo,
                contraseña: contra,
                uid: uid2,
                roles: [
                  ...seleccionado.map((val) => {
                    return val.id;
                  }),
                ],
              });
              let myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");
              myHeaders.append("ngrok-skip-browser-warning", "asc");
myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
              let requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: body,
                redirect: "follow",
              };
              fetch(
                "https://backterra.econtel.com.ec/api/v1/usuario/",
                requestOptions
              )
                .then(async (response) => {
                  if (response.ok) {
                    return response.text();
                  }
                  let mensaje = await response.json();
                  throw {
                    ...mensaje,
                  };
                })
                .then((result) => {
                  //aconsole.log(result);
                  setOpen(false);
                  Swal.fire({ icon: "success", text: "Usuario agregado " });
                  cargar();
                })
                .catch((error) => {
                  setCargando(false);

                  setOpen(false);
                  Swal.fire({
                    icon: "error",
                    text:
                      "Error: " +
                      (typeof error === "string"
                        ? error
                        : JSON.stringify(error)),
                  });
                });
            })
            .catch((error) => {
              setCargando(false);

              Swal.fire({
                icon: "error",
                text:
                  "Error: " +
                  (typeof error === "string" ? error : JSON.stringify(error)),
              });
            });
        }
      })
      .catch((error) => {
        setCargando(false);

        //aconsole.log(error);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function editar() {
    setCargando(true);

    if (id === "") {
      crear();
      return;
    }
    let body = JSON.stringify({
      nombre: nombre,
      cedula: cedula,
      contraseña: contra,
      roles: [
        ...seleccionado.map((val) => {
          return val.id;
        }),
      ],
    });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/usuario/" + id + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setOpen(false);
        Swal.fire({ icon: "success", text: "Usuario actualizado " });
        cargar();
      })
      .catch((error) => {
        setCargando(false);

        setOpen(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function eliminar() {
    setCargando(true);

    if (id === "") {
      return;
    }
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    const functions = getFunctions();
    const deleteUser = httpsCallable(functions, "deleteUser");
    deleteUser({ uid: uid })
      .then(async (result) => {
        if (result.data.error) {
          setCargando(false);

          Swal.fire({
            icon: "error",
            text: "Error: " + result.data.error.errorInfo,
          });
        } else {
          await deleteDoc(doc(db, "usuarios", uid));
          fetch(
            "https://backterra.econtel.com.ec/api/v1/usuario/" +
              id +
              "/",
            requestOptions
          )
            .then(async (response) => {
              if (response.ok) {
                return response.text();
              }
              let mensaje = await response.json();
              throw {
                ...mensaje,
              };
            })
            .then((result) => {
              setOpen2(false);
              Swal.fire({ icon: "success", text: "Usuario eliminado " });
              cargar();
            })
            .catch((error) => {
              setOpen2(false);
              setCargando(false);

              Swal.fire({
                icon: "error",
                text:
                  "Error: " +
                  (typeof error === "string" ? error : JSON.stringify(error)),
              });
            });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function credenciales() {
    setCargando(true);

    setOpen3(false);
    let body = JSON.stringify({
      contraseña: contra,
    });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };

    const functions = getFunctions();
    const updateUser = httpsCallable(functions, "updateUser");
    updateUser({ email: correo, password: contra, uid: uid })
      .then((result) => {
        if (result.data.error) {
          setCargando(false);

          Swal.fire({
            icon: "error",
            text: "Error: " + result.data.error.errorInfo,
          });
        } else {
          const docRef = doc(db, "usuarios", uid);
          updateDoc(docRef, {
            fechaModificacion: serverTimestamp(),
            password: contra,
          })
            .then(() => {
              fetch(
                "https://backterra.econtel.com.ec/api/v1/usuario/" +
                  id +
                  "/",
                requestOptions
              )
                .then(async (response) => {
                  if (response.ok) {
                    return response.text();
                  }
                  let mensaje = await response.json();
                  throw {
                    ...mensaje,
                  };
                })
                .then((result) => {
                  setOpen(false);
                  Swal.fire({
                    icon: "success",
                    text: "Credenciales actualizadas ",
                  });
                  cargar();
                })
                .catch((error) => {
                  setCargando(false);

                  setOpen(false);
                  Swal.fire({
                    icon: "error",
                    text:
                      "Error: " +
                      (typeof error === "string"
                        ? error
                        : JSON.stringify(error)),
                  });
                });
            })
            .catch((error) => {
              setCargando(false);

              Swal.fire({
                icon: "error",
                text:
                  "Error: " +
                  (typeof error === "string" ? error : JSON.stringify(error)),
              });
            });
        }
      })
      .catch((error) => {
        setCargando(false);

        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function cargar() {
    limpiar();
    setCargando(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/usuario/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setLista(JSON.parse(result));
        setCargando(false);
      })
      .catch((error) => {
        setCargando(false);

        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
    fetch(
      "https://backterra.econtel.com.ec/api/v1/rol/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setRoles(JSON.parse(result));
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  return (
    <>
      <Box px={2} py={2}>
        <Paper elevation={2} sx={{ padding: "10px" }}>
          <Typography variant="h5">
            {" "}
            <strong>Usuarios</strong>{" "}
          </Typography>
          <br />

          {validarRoles("CREAR USUARIO", userInfo) && (
            <Button
              color="success"
              variant="contained"
              onClick={() => setOpen(true)}
            >
              Agregar
            </Button>
          )}

          <br />
          <br />
          <Paper elevation={2} sx={{ overflowX: "scroll", padding: "10px" }}>
            <Box
              sx={{
                height: 600,
                minWidth: 1000,
                "& .super-app-theme--header": {
                  backgroundColor: "#428bd4",
                  color: "white",
                  fontSize: "16px",
                },
              }}
            >
              <DataGrid  disableColumnMenu
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
                rows={lista ?? []}
                getRowId={(row) => row.id}
                columns={
                  validarRoles("ELIMINAR USUARIO", userInfo) ||
                    validarRoles("EDITAR USUARIO", userInfo)
                    ? columns
                    : columns.filter((val) => val.field !== "1")
                }
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                slots={{ toolbar: CustomToolbar }}
              />
            </Box>
          </Paper>
        </Paper>
      </Box>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          limpiar();
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          {" "}
          <strong>{id !== "" ? "Editar " : "Crear "} Usuario</strong>{" "}
        </DialogTitle>
        <DialogContent sx={{ minHeight: 400 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Nombre *</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Cédula *</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={cedula}
                  onChange={(e) => setCedula(e.target.value)}
                />
              </Box>
            </Grid>
            {id === "" && (
              <>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Correo *</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      value={correo}
                      onChange={(e) => setCorreo(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box px={2}>
                    <Typography>
                      {" "}
                      <strong>Contraseña * (Min. 6 caracteres)</strong>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      value={contra}
                      onChange={(e) => setContra(e.target.value)}
                    />
                  </Box>
                </Grid>
              </>
            )}

            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Roles *</strong>{" "}
                </Typography>

                <Autocomplete
                  ListboxProps={{ style: { maxHeight: 150 } }}
                  size="small"
                  fullWidth
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disablePortal
                  multiple
                  value={seleccionado}
                  options={roles}
                  getOptionLabel={(option) => option.rol}
                  onChange={(e, t) => {
                    setSeleccionado(t);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Grid>
            {/* <Grid item xs={12}>
              <Box
                px={2}
                sx={{
                  height: 100,
                  overflowY: "scroll",
                }}
              >
                {[
                  ...seleccionado.map((val) => {
                    return " " + val.rol;
                  }),
                ].toString()}
              </Box>
            </Grid> */}

            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <br />
                <Button
                  color="success"
                  variant="contained"
                  disabled={
                    nombre.trim().length === 0 ||
                    cedula.trim().length === 0 ||
                    (id === "" &&
                      (contra.trim().length < 6 ||
                        correo.trim().length === 0)) ||
                    roles.length === 0
                  }
                  onClick={() => editar()}
                >
                  Guardar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen(false);
              limpiar();
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open2}>
        <DialogTitle>
          <Stack
            spacing={2}
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            ¿ESTÁ SEGURO DE ELIMINAR?
          </Stack>
        </DialogTitle>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              eliminar();
            }}
          >
            Borrar
          </Button>
          <Button
            color="info"
            variant="contained"
            onClick={() => {
              setOpen2(false);
              limpiar();
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open3}
        onClose={() => {
          setOpen3(false);
          limpiar();
        }}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>
          <strong>Cambiar Contraseña </strong>
          {correo}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box px={1}>
                <Typography>
                  <strong>Contraseña *</strong> (Min: 6 caracteres)
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={contra}
                  onChange={(e) => setContra(e.target.value)}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="success"
            variant="contained"
            disabled={contra.trim().length === 0}
            onClick={() => credenciales()}
          >
            Guardar
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen3(false);
              limpiar();
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Cargando open2={cargando} />
    </>
  );
};
