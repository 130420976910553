import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { CustomToolbar } from "../../../components/utils/CustomFilter";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import { MenuProps } from "../../../components/utils/MenuProps";
import { validarRoles } from "../../../components/utils/ValidarRoles";
import { useSelector } from "react-redux";
import { Cargando } from "../../../components/utils/Cargando";

export const Tecnico = () => {
  const [cargando, setCargando] = useState(false);

  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [id, setId] = useState("");
  const [responsable, setResponsable] = useState("");

  const [usuarios, setUsuarios] = useState([]);

  const [lista, setLista] = useState(null);

  useEffect(() => {
    cargar();
  }, []);

  function limpiar() {
    setResponsable("");
    setId("");
  }

  function handleOpen2(row) {
    setId(row.id);
    setOpen2(true);
  }

  function crear() {
    setCargando(true);
    //aconsole.log(responsable);
    let body = JSON.stringify({
      codigo: responsable.id + "_Tecnico",
      nombre: responsable.nombre,
      personas_encargadas: [responsable.id],
      tipo: "TECNICO",
    });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/bodega/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(result);
        setOpen(false);
        Swal.fire({ icon: "success", text: "Bodega agregada " });
        limpiar();
        cargar();
      })
      .catch((error) => {
        setCargando(false);

        setOpen(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function eliminar() {
    setCargando(true)
    if (id === "") {
      return;
    }
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/bodega/" + id + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setOpen2(false);
        Swal.fire({ icon: "success", text: "Bodega eliminada " });
        limpiar();
        cargar();
      })
      .catch((error) => {
        limpiar();
        setCargando(false);

        setOpen2(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function cargar() {
    setCargando(true)
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://backterra.econtel.com.ec/api/v1/bodega/?tipo=TECNICO",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setLista(JSON.parse(result));
        setCargando(false)

      })
      .catch((error) => {
        setCargando(false)

        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });

    fetch(
      "https://backterra.econtel.com.ec/api/v1/usuario/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setUsuarios(JSON.parse(result));
        //aconsole.log(JSON.parse(result));
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });

  }
  const columns = [
    {
      field: "codigo",
      headerClassName: "super-app-theme--header",
      headerName: "Código",
      flex: 1,
    },
    {
      field: "nombre",
      headerClassName: "super-app-theme--header",
      headerName: "Nombre",
      flex: 1,
    },
    {
      field: "cedula",
      headerClassName: "super-app-theme--header",
      headerName: "Cédula",
      flex: 1,
      valueGetter: (params) => {
        if (usuarios.length !== 0 && params.row.codigo) {
          let res = usuarios.filter(
            (val2) => val2.id + "" === params.row.codigo.split("_")[0] + ""
          )[0];
          return res ? res.cedula : "";
        } else {
          return "";
        }
      },
    },
    {
      field: "1",
      headerClassName: "super-app-theme--header",
      headerName: "Acciones",
      flex: 1,
      renderCell: (params) => (
        <Stack direction={"row"} spacing={2}>
          {validarRoles("ELIMINAR BODEGA", userInfo) && (
            <IconButton onClick={() => handleOpen2(params.row)}>
              <DeleteIcon color="error" />
            </IconButton>
          )}
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Box py={2}>
        <Paper elevation={2} sx={{ padding: "10px" }}>
          <Typography variant="h5">
            {" "}
            <strong>Técnicos</strong>{" "}
          </Typography>
          <br />

          {validarRoles("CREAR BODEGA", userInfo) && (
            <Button
              color="success"
              variant="contained"
              onClick={() => setOpen(true)}
            >
              Agregar
            </Button>
          )}

          <br />
          <br />
          <Paper elevation={2} sx={{ overflowX: "scroll", padding: "10px" }}>
            <Box
              sx={{
                height: 600,
                minWidth: 1000,
                "& .super-app-theme--header": {
                  backgroundColor: "#428bd4",
                  color: "white",
                  fontSize: "16px",
                },
              }}
            >
              <DataGrid  disableColumnMenu
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
                rows={lista ?? []}
                loading={lista === null}
                columns={
                  validarRoles("ELIMINAR BODEGA", userInfo)
                    ? columns
                    : columns.filter((val) => val.field !== "1")
                }
                getRowId={(row) => row.id}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                slots={{ toolbar: CustomToolbar }}
              />
            </Box>
          </Paper>
        </Paper>
      </Box>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          limpiar();
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {" "}
          <strong>{id !== "" ? "Editar " : "Crear "} Técnico</strong>{" "}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  <strong>Usuario *</strong>
                </Typography>
                <Select
                  fullWidth
                  size="small"
                  MenuProps={MenuProps}
                  value={responsable}
                  onChange={(e) => {
                    setResponsable(e.target.value);
                  }}
                >
                  {usuarios
                    ? usuarios.map((val) => (
                        <MenuItem key={val.id} value={val}>
                          {val.nombre}
                        </MenuItem>
                      ))
                    : ""}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <br />
                <Button
                  color="success"
                  variant="contained"
                  disabled={responsable === ""}
                  onClick={() => crear()}
                >
                  Habilitar Bodega Interna
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen(false);
              limpiar();
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open2}>
        <DialogTitle>
          <Stack
            spacing={2}
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            ¿ESTÁ SEGURO DE ELIMINAR?
          </Stack>
        </DialogTitle>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              eliminar();
            }}
          >
            Borrar
          </Button>
          <Button
            color="info"
            variant="contained"
            onClick={() => {
              setOpen2(false);
              limpiar();
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Cargando open2={cargando} />
    </>
  );
};
