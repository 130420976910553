import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  TextField,
  Stack,
  Typography,
  Autocomplete,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { CustomToolbar } from "../../components/utils/CustomFilter";
import { useSelector } from "react-redux";
import { validarRoles } from "../../components/utils/ValidarRoles";
import { Cargando } from "../../components/utils/Cargando";
import moment from "moment";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate } from "react-router-dom";

export const Vehiculo = () => {
  const [cargando, setCargando] = useState(false);
  const navigate = useNavigate();

  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;
  const [open, setOpen] = useState(false);

  const [descripcion, setDescripcion] = useState("");
  const [placa, setPlaca] = useState("");
  const [modelo, setModelo] = useState("");
  const [nombre, setNombre] = useState("");
  const [km, setKm] = useState("");
  const [cuadrilla, setCuadrilla] = useState(null);

  const [cuadrillas, setCuadrillas] = useState([]);

  const [id, setId] = useState("");

  const [lista, setLista] = useState(null);

  const [open2, setOpen2] = useState(false);

  useEffect(() => {
    cargar();
  }, []);

  function limpiar() {
    setId("");
    setModelo("");
    setNombre("");
    setCuadrilla(null);
    setPlaca("");
    setDescripcion("");
    setKm("");
  }

  const columns = [
    {
      field: "Ver",
      headerClassName: "super-app-theme--header",
      headerName: "",
      width: 50,
      renderCell: (params) => (
        <IconButton
          onClick={() => navigate("/vehiculo/vehiculo/" + params.row.id)}
        >
          <RemoveRedEyeIcon color="warning" />
        </IconButton>
      ),
    },
    {
      field: "nombre",
      headerClassName: "super-app-theme--header",
      headerName: "Nombre",
      flex: 1,
    },
    {
      field: "cuadrilla",
      headerClassName: "super-app-theme--header",
      headerName: "Cuadrilla",
      flex: 1,
      valueGetter: (params) => {
        return params.row.cuadrilla?.nombre ?? "";
      },
    },
    {
      field: "km",
      headerClassName: "super-app-theme--header",
      headerName: "Km",
      flex: 0.5,
    },
    {
      field: "usuario",
      headerClassName: "super-app-theme--header",
      headerName: "Modificado Por",
      flex: 1,
      valueGetter: (params) => {
        return params.row.usuario?.nombre ?? "";
      },
    },
    {
      field: "updated_at",
      headerClassName: "super-app-theme--header",
      headerName: "F. Modificación",
      flex: 1,
      valueGetter: (params) => {
        return params.row.updated_at
          ? moment(params.row.updated_at).format("DD/MMMM/yyyy HH:mm")
          : "";
      },
    },
  ];

  function crear() {
    setCargando(true);

    let body = JSON.stringify({
      modelo: modelo,
      nombre: nombre,
      id_cuadrilla: cuadrilla?.id ?? null,
      placa: placa,
      descripcion: descripcion,
      km: km,
      id_usuario: userInfo.id,
    });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch("https://backterra.econtel.com.ec/api/v1/vehiculos/", requestOptions)
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(result);
        setOpen(false);
        Swal.fire({ icon: "success", text: "Vehículo agregado " });
        cargar();
      })
      .catch((error) => {
        setOpen(false);
        setCargando(false);

        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function editar() {
    setCargando(true);

    if (id === "") {
      crear();
      return;
    }
    let body = JSON.stringify({
      modelo: modelo,
      nombre: nombre,
      id_cuadrilla: cuadrilla?.id ?? null,
      placa: placa,
      descripcion: descripcion,
      km: km,
      id_usuario: userInfo.id,
    });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/vehiculos/" + id + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setOpen(false);
        Swal.fire({ icon: "success", text: "Vehículo actualizado " });
        cargar();
      })
      .catch((error) => {
        setOpen(false);
        setCargando(false);

        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function eliminar() {
    setCargando(true);

    if (id === "") {
      return;
    }
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/vehiculos/" + id + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setOpen2(false);
        Swal.fire({ icon: "success", text: "Vehículo eliminado " });
        cargar();
      })
      .catch((error) => {
        setOpen2(false);
        setCargando(false);

        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function cargar() {
    limpiar();
    setCargando(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch("https://backterra.econtel.com.ec/api/v1/vehiculos/", requestOptions)
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        if (
          validarRoles("LEER VEHICULOS PARTICULARES", userInfo) &&
          validarRoles("LEER VEHICULOS CUADRILLA", userInfo)
        ) {
          setLista(JSON.parse(result));
        } else if (validarRoles("LEER VEHICULOS PARTICULARES", userInfo)) {
          setLista(JSON.parse(result).filter((val)=> val.cuadrilla === null));
        } else if (validarRoles("LEER VEHICULOS CUADRILLA", userInfo)) {
          setLista(JSON.parse(result).filter((val)=> val.cuadrilla !== null));
        }
        setCargando(false);
      })
      .catch((error) => {
        setCargando(false);

        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
    fetch(
      "https://backterra.econtel.com.ec/api/v1/bodega/?tipo=CUADRILLA",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setCuadrillas(JSON.parse(result));
        
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  return (
    <>
      <Box px={2} py={2}>
        <Paper elevation={2} sx={{ padding: "10px" }}>
          <Typography variant="h5">
            {" "}
            <strong>Vehículo</strong>{" "}
          </Typography>
          <br />

          {validarRoles("CREAR VEHICULOS", userInfo) && (
            <Button
              color="success"
              variant="contained"
              onClick={() => setOpen(true)}
            >
              Agregar
            </Button>
          )}

          <br />
          <br />
          <Paper elevation={2} sx={{ overflowX: "scroll", padding: "10px" }}>
            <Box
              sx={{
                height: 600,
                minWidth: 1000,
                "& .super-app-theme--header": {
                  backgroundColor: "#428bd4",
                  color: "white",
                  fontSize: "16px",
                },
              }}
            >
              <DataGrid
                disableColumnMenu
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
                rows={lista ?? []}
                loading={lista === null}
                columns={columns}
                getRowId={(row) => row.id}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                slots={{ toolbar: CustomToolbar }}
              />
            </Box>
          </Paper>
        </Paper>
      </Box>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          limpiar();
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {" "}
          <strong>{id !== "" ? "Editar " : "Crear "} Vehículo</strong>{" "}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Nombre *</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Cuadrilla</strong>{" "}
                </Typography>
                <Autocomplete
                  size="small"
                  fullWidth
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disablePortal
                  value={cuadrilla}
                  options={cuadrillas ?? []}
                  getOptionLabel={(option) => option.nombre}
                  onChange={(e, t) => {
                    //aconsole.log(t);
                    setCuadrilla(t);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Km *</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  value={km}
                  onChange={(e) => {
                    setKm(e.target.value);
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Placa</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={placa}
                  onChange={(e) => {
                    setPlaca(e.target.value);
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Modelo</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={modelo}
                  onChange={(e) => {
                    setModelo(e.target.value);
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Descripción</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  multiline
                  rows={2}
                  value={descripcion}
                  onChange={(e) => {
                    setDescripcion(e.target.value);
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <br />
                <Button
                  color="success"
                  variant="contained"
                  disabled={
                    nombre?.trim().length === 0 || km?.trim().length === 0
                  }
                  onClick={() => editar()}
                >
                  Guardar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen(false);
              limpiar();
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open2}>
        <DialogTitle>
          <Stack
            spacing={2}
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            ¿ESTÁ SEGURO DE ELIMINAR?
          </Stack>
        </DialogTitle>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              eliminar();
            }}
          >
            Borrar
          </Button>
          <Button
            color="info"
            variant="contained"
            onClick={() => {
              setOpen2(false);
              limpiar();
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Cargando open2={cargando} />
    </>
  );
};
