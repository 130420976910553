import { Box, Button, Paper, Tab, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { PromInstalcion } from "./metricas/PromInstalacion";
import { PromVisita } from "./metricas/PromVisita";
import { InstaRel } from "./metricas/InstaRel";
import { VisitaRel } from "./metricas/VisitaRel";
import { MigraRel } from "./metricas/MigraRel";
import { Jornada } from "./metricas/Jornada";
import { Cargando } from "../../components/utils/Cargando";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import moment from "moment";

export const Metricas = () => {
  const [cargando, setCargando] = useState(false);

  const [lista, setLista] = useState([]);

  const [cuadrillas, setCuadrillas] = useState([]);
  const [tab, setTab] = useState("0");
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    cargar();
    cargarCuadrilla();
    cargarData();
  }, []);

  function cargarData() {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://backterra.econtel.com.ec/api/api/desgloce/", requestOptions)
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setData(JSON.parse(result));
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function cargar() {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://backterra.econtel.com.ec/api/v1/usuario/", requestOptions)
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setLista(JSON.parse(result));
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function cargarCuadrilla() {
    setCargando(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://backterra.econtel.com.ec/api/v1/bodega/?tipo=CUADRILLA",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        setCargando(false);
        setCuadrillas(JSON.parse(result));
      })
      .catch((error) => {
        setCargando(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  const _exporter = React.createRef();
  const excelExport = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  return (
    <Box px={2} py={2}>
      <Paper elevation={2} sx={{ padding: "10px" }}>
        <Typography variant="h5">
          {" "}
          <strong>Métricas de Rutas</strong>{" "}
        </Typography>

        <br />
        <TabContext value={tab}>
          <Box sx={{ borderColor: "divider" }}>
            <TabList onChange={handleChange} variant="scrollable">
              <Tab
                label="Promedio Instalacion"
                sx={{ fontWeight: "bold" }}
                value={"0"}
              />
              <Tab
                label="Promedio Visita Técnica"
                sx={{ fontWeight: "bold" }}
                value={"1"}
              />
              <Tab
                label="Instalaciones Realizadas"
                sx={{ fontWeight: "bold" }}
                value={"2"}
              />
              <Tab
                label="Visitas Técnicas Realizadas"
                sx={{ fontWeight: "bold" }}
                value={"3"}
              />
              <Tab
                label="Migraciones Realizadas"
                sx={{ fontWeight: "bold" }}
                value={"4"}
              />
              <Tab
                label="Tiempos Jornada"
                sx={{ fontWeight: "bold" }}
                value={"5"}
              />
              <Tab
                label="Reporte Completo"
                sx={{ fontWeight: "bold" }}
                value={"6"}
              />
            </TabList>
          </Box>
          <TabPanel value={"0"}>
            <PromInstalcion cuadrillas={cuadrillas} tecnicos={lista} />
          </TabPanel>
          <TabPanel value={"1"}>
            <PromVisita cuadrillas={cuadrillas} tecnicos={lista} />
          </TabPanel>
          <TabPanel value={"2"}>
            <InstaRel cuadrillas={cuadrillas} tecnicos={lista} />
          </TabPanel>
          <TabPanel value={"3"}>
            <VisitaRel cuadrillas={cuadrillas} tecnicos={lista} />
          </TabPanel>
          <TabPanel value={"4"}>
            <MigraRel cuadrillas={cuadrillas} tecnicos={lista} />
          </TabPanel>
          <TabPanel value={"5"}>
            <Jornada cuadrillas={cuadrillas} />
          </TabPanel>
          <TabPanel value={"6"}>
            <Button
              color="success"
              variant="contained"
              disabled={data.length === 0}
              onClick={excelExport}
            >
              Exportar Excel
            </Button>

            <ExcelExport
              data={
                data
                  ? [
                      ...data.map((val) => {
                        return {
                          ...val,
                          ruta: val.ruta ? "RUTA-" + val.ruta : "",
                          actividad: val.actividad
                            ? "ACTIVIDAD-" + val.actividad
                            : null,
                          solicitud: val.solicitud
                            ? "SOLICITUD-" + val.solicitud
                            : null,
                          inicio_actividad: val.inicio_actividad
                            ? moment(
                                new Date(val.inicio_actividad).getTime() -
                                  5 * 60 * 60 * 1000
                              ).format("YYYY-MM-DD HH:mm")
                            : null,
                          fin_actividad: val.fin_actividad
                            ? moment(
                                new Date(val.fin_actividad).getTime() -
                                  5 * 60 * 60 * 1000
                              ).format("YYYY-MM-DD HH:mm")
                            : null,
                          inicio_ruta: val.inicio_ruta
                            ? moment(
                                new Date(val.inicio_ruta).getTime() -
                                  5 * 60 * 60 * 1000
                              ).format("YYYY-MM-DD HH:mm")
                            : null,
                          fin_ruta: val.fin_ruta
                            ? moment(
                                new Date(val.fin_ruta).getTime() -
                                  5 * 60 * 60 * 1000
                              ).format("YYYY-MM-DD HH:mm")
                            : null,
                        };
                      }),
                    ]
                  : []
              }
              // collapsible={true}
              filterable={true}
              fileName="Actividades.xlsx"
              ref={_exporter}
            >
              {[
                "actividad",
                "inicio_actividad",
                "fin_actividad",
                "estado_actividad",
                "km",
                "motivo_cancelado",
                "solicitud",
                "tipo",
                "cedula",
                "nombres_cliente",
                "cuadrilla",
                "ruta",
                "inicio_ruta",
                "fin_ruta",
                "estado_ruta",
              ].map((val) => (
                <ExcelExportColumn
                  key={val}
                  field={val}
                  title={val.toUpperCase()}
                  width={200}
                />
              ))}
            </ExcelExport>
          </TabPanel>
        </TabContext>
      </Paper>
      <Cargando open2={cargando} />
    </Box>
  );
};
