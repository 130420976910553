import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Stack,
  Typography,
  Autocomplete,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import React, { useState, useEffect, useRef } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { CustomToolbar } from "../../../components/utils/CustomFilter";
import { useSelector } from "react-redux";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { read, utils, writeFile } from "xlsx";
import { Cargando } from "../../../components/utils/Cargando";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  getDownloadURL,
  uploadBytes,
  ref as ref_storage,
} from "firebase/storage";
import { storage } from "../../../firebaseConfig";
import moment from "moment";
import { MenuProps } from "../../../components/utils/MenuProps";

export const Transferencias = () => {
  const [cargando, setCargando] = useState(false);

  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [cantidad, setCantidad] = useState("");
  const [producto, setProducto] = useState("");
  const [bodega, setBodega] = useState("");
  const [bodegaD, setBodegaD] = useState("");
  const [id, setId] = useState("");

  const [imagen, setImagen] = useState(null);
  const [view, setView] = useState(null);

  const [motivo, setMotivo] = useState("");
  const [descripcion, setDescripcion] = useState("");

  const [lista, setLista] = useState([]);

  const [seleccionado, setSeleccionado] = useState([]);
  const [numero, setNumero] = useState(2);

  const [bodegas, setBodegas] = useState([]);
  const [productos, setProductos] = useState([]);
  const [motivos, setMotivos] = useState([]);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    cargarBodegas();
    cargarMotivos();
  }, []);

  function cargarMotivos() {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://backterra.econtel.com.ec/api/v1/motivo_movimiento/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setMotivos(JSON.parse(result));
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function getSeries(producto) {
    setCargando(true);

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://backterra.econtel.com.ec/api/v1/seriesby/?bodega=" +
        bodega.id +
        "&producto=" +
        producto.id,
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setSeries(JSON.parse(result).filter((val) => val.estado === "ACTIVO"));
        setCargando(false);
      })
      .catch((error) => {
        setCargando(false);

        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function cargarProductos() {
    setCargando(true);

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://backterra.econtel.com.ec/api/v1/productosby/?bodega_id=" +
        bodega.id,
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setProductos(JSON.parse(result));
        setCargando(false);
      })
      .catch((error) => {
        setCargando(false);

        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function cargarBodegas() {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://backterra.econtel.com.ec/api/v1/bodega/", requestOptions)
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setBodegas(JSON.parse(result));
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  // async function enviar(b, t) {
  //   let myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append("ngrok-skip-browser-warning", "asc");
  // myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);

  //   for (const el of lista) {
  //     let body = JSON.stringify({
  //       cantidad: el.cantidad,
  //       tipomovimiento: t,
  //       descripcion: descripcion,
  //       motivo: motivo.id,
  //       motivo_movimiento: motivo.id,
  //       series: el.series ?? [],
  //       producto: el.producto.id,
  //       bodega: b,
  //       usuario: userInfo.id,
  //     });

  //     let requestOptions = {
  //       method: "POST",
  //       headers: myHeaders,
  //       redirect: "follow",
  //       body: body,
  //     };

  //     //aconsole.log(body);
  //     await fetch(
  //       "https://backterra.econtel.com.ec/api/v1/movimiento/",
  //       requestOptions
  //     )
  //       .then(async (response) => {
  //         if (response.ok) {
  //           return response.text();
  //         }
  //         let mensaje = await response.json();
  //         throw {
  //           ...mensaje,
  //         };
  //       })
  //       .then((result) => {
  //         //aconsole.log(result);
  //       })
  //       .catch((error) => {
  //         Swal.fire({
  //           icon: "error",
  //           text:
  //             "Error: " +
  //             (typeof error === "string" ? error : JSON.stringify(error)),
  //         });
  //         return;
  //       });
  //   }
  // }

  async function uploadImage() {
    setCargando(true);
    if (imagen) {
      try {
        const storageRef = ref_storage(
          storage,
          `${userInfo.id}/Foto Transferencia:  ${moment().format(
            "DD/MMMM/yyyy HH:mm:ss "
          )} ${imagen.name}`
        );
        await uploadBytes(storageRef, imagen).then((snapshot) => {
          getDownloadURL(snapshot.ref).then((downloadURL) => {
            comprar(downloadURL);
          });
        });
      } catch (error) {
        setCargando(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      }
      setImagen(null);
      setView(null);
    } else {
      comprar();
    }
  }

  async function comprar(link) {
    setCargando(true);

    let error2 = false;
    let cantidad = 0;

    for (const el of lista.filter(
      (val) => val.producto.tipo2 === "COMPUESTO" && val.producto.usa_serie
    )) {
      let myHeaders2 = new Headers();
      myHeaders2.append("Content-Type", "application/json");
      myHeaders2.append("ngrok-skip-browser-warning", "asc");
      myHeaders2.append(
        "Authorization",
        "Bearer " + process.env.REACT_APP_TOKEN_API
      );

      let requestOptions2 = {
        method: "GET",
        headers: myHeaders2,
      };
      await fetch(
        "https://backterra.econtel.com.ec/api/v1/seriesby/?producto=" +
          el.producto.id +
          "&bodega=" +
          bodega.id,
        requestOptions2
      )
        .then(async (response) => {
          if (response.ok) {
            return response.text();
          }
          let mensaje = await response.json();
          throw {
            ...mensaje,
          };
        })
        // eslint-disable-next-line no-loop-func
        .then((result) => {
          let lista2 = JSON.parse(result);
          for (const el2 of lista2) {
            if (el2.serie === el.series[0]) {
              if (el2.stock !== el.cantidad) {
                setCargando(false);

                error2 = true;
                Swal.fire({
                  icon: "error",
                  text:
                    "El cantidad de la serie: " +
                    el2.serie +
                    " debe ser igual a la del stock actual: " +
                    el2.stock,
                });
                return;
              }
            }
          }
        })
        .catch((error) => {
          setCargando(false);

          Swal.fire({
            icon: "error",
            text: "Ocurrió un problema con una serie",
          });
          return;
        });
    }
    if (error2) {
      setCargando(false);

      return;
    }

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );

    for (const el of lista.filter(
      (val) => !(val.producto.tipo2 === "COMPUESTO" && val.producto.usa_serie)
    )) {
      let body = JSON.stringify({
        cantidad: el.cantidad,
        tipomovimiento: "EGRESO",
        descripcion: descripcion,
        motivo: motivo.id,
        motivo_movimiento: motivo.id,
        foto: link ?? null,
        series: el.series ?? [],
        producto: el.producto.id,
        bodega: bodega.id,
        usuario: userInfo.id,
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: body,
      };

      //aconsole.log(body);
      await fetch(
        "https://backterra.econtel.com.ec/api/v1/movimiento/",
        requestOptions
      )
        .then(async (response) => {
          if (response.ok) {
            return response.text();
          }
          let mensaje = await response.json();
          throw {
            ...mensaje,
          };
        })
        .then((result) => {
          //aconsole.log(result);
          cantidad += 1;
        })
        .catch((error) => {
          setCargando(false);

          error2 = true;
          Swal.fire({
            icon: "error",
            text: typeof error === "string" ? error : JSON.stringify(error),
          });
          return;
        });

      if (error2) {
        setCargando(false);

        return;
      }

      body = JSON.stringify({
        cantidad: el.cantidad,
        tipomovimiento: "INGRESO",
        descripcion: descripcion,
        motivo: motivo.id,
        motivo_movimiento: motivo.id,
        foto: link ?? null,
        series: el.series ?? [],
        producto: el.producto.id,
        bodega: bodegaD.id,
        usuario: userInfo.id,
      });

      requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: body,
      };

      //aconsole.log(body);
      await fetch(
        "https://backterra.econtel.com.ec/api/v1/movimiento/",
        requestOptions
      )
        .then(async (response) => {
          if (response.ok) {
            return response.text();
          }
          let mensaje = await response.json();
          throw {
            ...mensaje,
          };
        })
        .then((result) => {
          //aconsole.log(result);
          cantidad += 1;
        })
        .catch((error) => {
          setCargando(false);

          error2 = true;
          Swal.fire({
            icon: "error",
            text: typeof error === "string" ? error : JSON.stringify(error),
          });
          return;
        });

      if (error2) {
        setCargando(false);

        return;
      }
    }

    for (const el of lista.filter(
      (val) => val.producto.tipo2 === "COMPUESTO" && val.producto.usa_serie
    )) {
      let body = JSON.stringify({
        cantidad: el.cantidad,
        tipomovimiento: "EGRESO",
        descripcion: descripcion,
        motivo: motivo.id,
        motivo_movimiento: motivo.id,
        foto: link ?? null,
        series: el.series ?? [],
        producto: el.producto.id,
        bodega: bodega.id,
        usuario: userInfo.id,
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: body,
      };

      //aconsole.log(body);
      await fetch(
        "https://backterra.econtel.com.ec/api/v1/movimientoCompuesto/",
        requestOptions
      )
        .then(async (response) => {
          if (response.ok) {
            return response.text();
          }
          let mensaje = await response.json();
          throw {
            ...mensaje,
          };
        })
        .then((result) => {
          //aconsole.log(result);
          cantidad += 1;
        })
        .catch((error) => {
          setCargando(false);

          error2 = true;
          Swal.fire({
            icon: "error",
            text: typeof error === "string" ? error : JSON.stringify(error),
          });
          return;
        });

      if (error2) {
        setCargando(false);

        return;
      }

      body = JSON.stringify({
        cantidad: el.cantidad,
        tipomovimiento: "INGRESO",
        descripcion: descripcion,
        motivo: motivo.id,
        motivo_movimiento: motivo.id,
        foto: link ?? null,
        series: el.series ?? [],
        producto: el.producto.id,
        bodega: bodegaD.id,
        usuario: userInfo.id,
      });

      requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: body,
      };

      //aconsole.log(body);
      await fetch(
        "https://backterra.econtel.com.ec/api/v1/movimientoCompuesto/",
        requestOptions
      )
        .then(async (response) => {
          if (response.ok) {
            return response.text();
          }
          let mensaje = await response.json();
          throw {
            ...mensaje,
          };
        })
        .then((result) => {
          //aconsole.log(result);
          cantidad += 1;
        })
        .catch((error) => {
          setCargando(false);

          error2 = true;
          Swal.fire({
            icon: "error",
            text: typeof error === "string" ? error : JSON.stringify(error),
          });
          return;
        });

      if (error2) {
        setCargando(false);

        return;
      }
    }

    // await enviar(bodega.id, "EGRESO");
    // await enviar(bodegaD.id, "INGRESO");

    if (lista.length * 2 === cantidad) {
      limpiar();
      setLista([]);
      setBodega("");
      setBodegaD("");
      setMotivo("");
      setDescripcion("");
      setCargando(false);

      Swal.fire({ icon: "success", text: "TRANSFERENCIA REALIZADA" });
    } else {
      setCargando(false);

      Swal.fire({
        icon: "error",
        text: "Ocurrió un problema con un movimiento, revise el historial ",
      });
    }
  }

  function limpiar() {
    setCantidad("");
    setProducto("");
    setSeleccionado([]);
    setId("");
    setImagen(null);
    setView(null);
  }

  const changeimagen = (e) => {
    const file = e.target.files[e.target.files.length - 1];
    setImagen(file);
    setView(URL.createObjectURL(file));
  };

  function handleOpen(row) {
    setId(row.id);
    setCantidad(row.cantidad);
    setProducto(row.producto);
    setOpen(true);
  }

  function handleOpen2(row) {
    setLista(lista.filter((val) => val.id !== row.id));
  }

  const columns = [
    {
      field: "a",
      headerClassName: "super-app-theme--header",
      headerName: "Cod Producto",
      flex: 1,
      valueGetter: (params) => {
        return params.row.producto.codigo;
      },
    },
    {
      field: "b",
      headerClassName: "super-app-theme--header",
      headerName: "Producto",
      flex: 1,
      valueGetter: (params) => {
        return params.row.producto.nombre;
      },
    },
    {
      field: "cantidad",
      headerClassName: "super-app-theme--header",
      headerName: "Cantidad",
      flex: 1,
    },
    {
      field: "tipo2",
      headerClassName: "super-app-theme--header",
      headerName: "Tipo",
      flex: 1,
      valueGetter: (params) => {
        return params.row.producto.tipo2;
      },
    },
    {
      field: "usa_serie",
      headerClassName: "super-app-theme--header",
      headerName: "Usa Serie",
      flex: 1,
      valueGetter: (params) => {
        return params.row.producto.usa_serie ? "SI" : "NO";
      },
    },
    {
      field: "series",
      headerClassName: "super-app-theme--header",
      headerName: "Series Seleccionadas",
      flex: 1,
      valueGetter: (params) => {
        return params.row.series.length;
      },
    },

    {
      field: "1",
      headerClassName: "super-app-theme--header",
      headerName: "Acciones",
      flex: 1,
      renderCell: (params) => (
        <Stack direction={"row"} spacing={2}>
          {params.row.producto.usa_serie && (
            <IconButton
              onClick={() => {
                setId(params.row.id);
                getSeries(params.row.producto);
                setProducto(params.row.producto);
                setSeleccionado([
                  ...params.row.series.map((val) => {
                    return { serie: val };
                  }),
                ]);
                if (
                  params.row.producto.tipo2 === "COMPUESTO" &&
                  params.row.producto.usa_serie
                ) {
                  setCantidad(1);
                } else {
                  setCantidad(params.row.cantidad);
                }
                setOpen2(true);
              }}
            >
              <QrCodeIcon color="info" />
            </IconButton>
          )}
          <IconButton onClick={() => handleOpen(params.row)}>
            <EditIcon color="warning" />
          </IconButton>
          <IconButton onClick={() => handleOpen2(params.row)}>
            <DeleteIcon color="error" />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const handleImport = ($event) => {
    const files = $event.target.files;
    //aconsole.log(files.length);
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          let datos = [];
          let ser = [
            ...series.map((val) => {
              return val.serie;
            }),
          ];
          for (const el of rows) {
            if (ser.includes(el.SERIE)) {
              datos.push(el.SERIE);
            }
          }
          datos = Array.from(new Set(datos));
          setSeleccionado([
            ...datos.map((val) => {
              return { serie: val };
            }),
          ]);
          Swal.fire({
            icon: "success",
            text: "CONCIDENCIA DE SERIES: " + datos.length,
          });
        }
        event.target.value = null;
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleExport = () => {
    let data = [
      { SERIE: "EjemploSerie" },
      { SERIE: "EjemploSerie2" },
      { SERIE: "EjemploSerie3" },
    ];
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    let lista = [];
    for (const el of Object.keys(data)) {
      lista.push({ wch: 20 });
    }
    ws["!cols"] = lista;
    utils.sheet_add_json(ws, data, { origin: "A1" });
    utils.book_append_sheet(wb, ws, "Hoja");
    writeFile(wb, "Formato.xlsx");
  };

  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      <Box py={2}>
        <Paper elevation={2} sx={{ padding: "10px" }}>
          <Typography variant="h5">
            {" "}
            <strong>Nuevo Transferencia</strong>{" "}
          </Typography>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <Box px={2}>
                <Typography>
                  <strong>Motivo *</strong>{" "}
                </Typography>
                <Select
                  MenuProps={MenuProps}
                  fullWidth
                  size="small"
                  value={motivo}
                  onChange={(e) => setMotivo(e.target.value)}
                >
                  {motivos
                    ? motivos.map((val) => (
                        <MenuItem key={val.id} value={val}>
                          {val.motivo}
                        </MenuItem>
                      ))
                    : ""}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Bodega Origen *</strong>{" "}
                </Typography>
                <Select
                  fullWidth
                  size="small"
                  value={bodega}
                  disabled={lista.length > 0}
                  onChange={(e) => setBodega(e.target.value)}
                >
                  {bodegas
                    ? bodegas.map((val) => (
                        <MenuItem key={val.id} value={val}>
                          {val.nombre}
                        </MenuItem>
                      ))
                    : ""}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Bodega Destino *</strong>{" "}
                </Typography>
                <Select
                  fullWidth
                  size="small"
                  value={bodegaD}
                  disabled={lista.length > 0}
                  onChange={(e) => setBodegaD(e.target.value)}
                >
                  {bodegas
                    ? bodegas.map((val) => (
                        <MenuItem key={val.id} value={val}>
                          {val.nombre}
                        </MenuItem>
                      ))
                    : ""}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Descripción *</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  size="small"
                  value={descripcion}
                  onChange={(e) => setDescripcion(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Foto de Transferencia</strong>{" "}
                </Typography>
                <Stack direction={"row"} spacing={2}>
                  <Stack direction={"column"} spacing={2}>
                    {id === "" && (
                      <Button
                        component="label"
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                      >
                        Cargar Foto
                        <input hidden type="file" onChange={changeimagen} />
                      </Button>
                    )}
                  </Stack>
                  {view && (
                    <img
                      src={view}
                      alt={imagen?.name ?? "imagen"}
                      height={110}
                    />
                  )}
                </Stack>
              </Box>
            </Grid>
          </Grid>
          <br />
          <Button
            color="success"
            variant="contained"
            disabled={bodegaD === "" || bodega === ""}
            onClick={() => {
              cargarProductos();
              setOpen(true);
            }}
          >
            SELECCIONAR PRODUCTO
          </Button>

          <br />
          <br />
          <Paper elevation={2} sx={{ overflowX: "scroll", padding: "10px" }}>
            <Box
              sx={{
                height: 300,
                minWidth: 1000,
                "& .super-app-theme--header": {
                  backgroundColor: "#428bd4",
                  color: "white",
                  fontSize: "16px",
                },
              }}
            >
              <DataGrid
                disableColumnMenu
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
                rows={lista}
                columns={columns}
                getRowId={(row) => row.id}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                slots={{ toolbar: CustomToolbar }}
              />
            </Box>
          </Paper>
          <br />
          {lista.filter(
            (val) =>
              (val.producto.usa_serie &&
                val.producto.tipo2 !== "COMPUESTO" &&
                val.series.length !== val.cantidad) ||
              (val.producto.usa_serie &&
                val.producto.tipo2 === "COMPUESTO" &&
                val.series.length !== 1)
          ).length > 0 && (
            <Typography variant="h5" color="red">
              <strong>
                Hay productos que no tienen las series seleccionadas
              </strong>
            </Typography>
          )}
          <Button
            color="success"
            variant="contained"
            disabled={
              bodega === "" ||
              bodegaD === "" ||
              bodega === bodegaD ||
              lista.length === 0 ||
              descripcion === "" ||
              lista.filter(
                (val) =>
                  (val.producto.usa_serie &&
                    val.producto.tipo2 !== "COMPUESTO" &&
                    val.series.length !== val.cantidad) ||
                  (val.producto.usa_serie &&
                    val.producto.tipo2 === "COMPUESTO" &&
                    val.series.length !== 1)
              ).length > 0 ||
              motivo === ""
            }
            onClick={() => uploadImage()}
          >
            Realizar Transferencia
          </Button>
        </Paper>
      </Box>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          limpiar();
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {" "}
          <strong>{id !== "" ? "Editar " : "Crear "} Transferencia</strong>{" "}
        </DialogTitle>
        <DialogContent sx={{ minHeight: 300 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Producto *</strong>{" "}
                </Typography>
                <Autocomplete
                  ListboxProps={{ style: { maxHeight: 150 } }}
                  size="small"
                  fullWidth
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disablePortal
                  value={producto}
                  disabled={id !== ""}
                  options={productos}
                  getOptionLabel={(option) =>
                    option.codigo ? option.codigo + ": " + option.nombre : ""
                  }
                  onChange={(e, t) => {
                    if (t === null) {
                      setProducto("");
                    } else {
                      setProducto(t);
                    }
                    setCantidad("");
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Cantidad *</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={cantidad}
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={(e) => setCantidad(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Unidad Real: </strong>{" "}
                  {producto?.unidad?.unidad
                    ? producto?.unidad_real + " " + producto?.unidad?.unidad
                    : producto?.unidad_real}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <br />
                <Button
                  color="success"
                  variant="contained"
                  disabled={
                    producto === "" ||
                    cantidad === "" ||
                    (producto?.tipo2 === "COMPUESTO" &&
                      producto?.usa_serie &&
                      cantidad > producto.unidad_real)
                  }
                  onClick={() => {
                    //aconsole.log(producto);
                    if (id !== "") {
                      setLista([
                        ...lista.filter((val) => val.id !== id),
                        {
                          id: id,
                          producto: producto,
                          cantidad: Number(cantidad),
                          series: [],
                        },
                      ]);
                    } else {
                      if (
                        producto !== "" &&
                        producto.tipo2 === "COMPUESTO" &&
                        producto.usa_serie
                      ) {
                        setLista([
                          ...lista,
                          {
                            id: numero,
                            producto: producto,
                            cantidad: Number(cantidad),
                            series: [],
                          },
                        ]);
                        setNumero(numero + 1);
                      } else {
                        setLista([
                          ...lista.filter(
                            (val) => val.producto.id !== producto.id
                          ),
                          {
                            id: numero,
                            producto: producto,
                            cantidad: Number(cantidad),
                            series: [],
                          },
                        ]);
                        setNumero(numero + 1);
                      }
                    }
                    setOpen(false);
                    limpiar();
                  }}
                >
                  Guardar
                </Button>
              </Box>
            </Grid>
            {producto?.tipo2 === "COMPUESTO" &&
              producto?.usa_serie &&
              cantidad > producto.unidad_real && (
                <Grid item xs={12}>
                  <Typography variant="h6" color="red">
                    <strong>
                      En productos compuestos con series no se puede colocar
                      cantidades mayores a su unidad real
                    </strong>
                  </Typography>
                </Grid>
              )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen(false);
              limpiar();
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open2}
        onClose={() => {
          setOpen2(false);
          limpiar();
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {" "}
          <strong>Ingresar Serie</strong>{" "}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Producto: </strong> {producto.codigo}{" "}
                  {" - " + producto.nombre}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Cantidad: </strong>
                  {cantidad}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box px={2}>
                <Stack direction={"row"} spacing={2}>
                  <Button
                    variant="contained"
                    color="info"
                    onClick={handleClick}
                  >
                    Subir Excel
                  </Button>
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleImport}
                    style={{ display: "none" }}
                  />
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleExport}
                  >
                    Descargar Formato
                  </Button>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Series: </strong>{" "}
                </Typography>
                <Autocomplete
                  ListboxProps={{ style: { maxHeight: 150 } }}
                  size="small"
                  fullWidth
                  isOptionEqualToValue={(option, value) =>
                    option.serie === value.serie
                  }
                  disablePortal
                  multiple
                  value={seleccionado}
                  options={series}
                  getOptionLabel={(option) => option.serie}
                  onChange={(e, t) => {
                    setSeleccionado(t);
                    //aconsole.log(t);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <br />
                <Button
                  color="success"
                  variant="contained"
                  disabled={
                    producto === "" ||
                    cantidad === "" ||
                    seleccionado.length !== cantidad
                  }
                  onClick={() => {
                    let prod = null;
                    let lista2 = [];
                    for (const el of lista) {
                      if (el.id === id) {
                        prod = el;
                      } else {
                        lista2.push(el);
                      }
                    }
                    setLista([
                      ...lista2,
                      {
                        ...prod,
                        series: [
                          ...seleccionado.map((val) => {
                            return val.serie;
                          }),
                        ],
                      },
                    ]);
                    setOpen2(false);
                    limpiar();
                  }}
                >
                  Guardar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen2(false);
              limpiar();
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Cargando open2={cargando} />
    </>
  );
};
