import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { validarRoles } from "../../components/utils/ValidarRoles";

export const ListContabilidad = ({ info }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton component={Link} onClick={handleClick}>
        <ListItemIcon>
          <PointOfSaleIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{ fontWeight: "bold" }}
          primary="Contabilidad"
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding dense>
          {validarRoles("LEER GASTOS", info) && (
            <ListItemButton
              component={Link}
              to={"contabilidad/gasto"}
              sx={{ pl: 8 }}
            >
              <ListItemText primary="Gastos" />
            </ListItemButton>
          )}
          {validarRoles("LEER MOTIVOS GASTOS", info) && (
            <ListItemButton
              component={Link}
              to={"inventario/motivoGasto"}
              sx={{ pl: 8 }}
            >
              <ListItemText primary="Motivo Gasto" />
            </ListItemButton>
          )}

          {validarRoles("LEER RECAUDACIONES", info) && (
            <ListItemButton
              component={Link}
              to={"contabilidad/recepcion"}
              sx={{ pl: 8 }}
            >
              <ListItemText primary="Recaudación Dinero" />
            </ListItemButton>
          )}
        </List>
      </Collapse>
    </>
  );
};
