import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Tab,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { DataGrid, esES } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { CustomToolbar } from "../../components/utils/CustomFilter";
import moment from "moment";
import { useSelector } from "react-redux";
import { Cargando } from "../../components/utils/Cargando";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { FiltroFechas } from "../../components/utils/FiltroFechas";

export const Wisphub = () => {
  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;
  const [cargando, setCargando] = useState(false);
  const [open, setOpen] = useState(false);

  const [lista, setLista] = useState(null);
  const [lista2, setLista2] = useState(null);

  const [zonas, setZonas] = useState([]);
  const [planes, setPlanes] = useState([]);

  const [hoja, setHoja] = useState(null);

  const [zona, setZona] = useState("");
  const [plan, setPlan] = useState("");
  const [user, setUser] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [ip, setIp] = useState("");

  const [value, setValue] = useState([
    startOfDay(subDays(new Date(), 6)),
    endOfDay(new Date()),
  ]);

  useEffect(() => {
    cargar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function limpiar() {
    setIp("");
    setUser("");
    setApellidos("");
    setPlan("");
    setZona("");
    setHoja(null);
  }

  const columns = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "Hoja",
      flex: 1,
      valueGetter: (params) => {
        return "HOJA-" + params.row.id;
      },
    },
    {
      field: "estado",
      headerClassName: "super-app-theme--header",
      headerName: "Estado",
      flex: 1,
    },
    {
      field: "tipo",
      headerClassName: "super-app-theme--header",
      headerName: "Tipo",
      flex: 1,
    },
    {
      field: "created_at",
      headerClassName: "super-app-theme--header",
      headerName: "Fecha",
      flex: 1,
      valueGetter: (params) => {
        return params.row.created_at
          ? moment(params.row.created_at).format("DD/MMMM/yyyy HH:mm")
          : "";
      },
    },
    {
      field: "id_actividad",
      headerClassName: "super-app-theme--header",
      headerName: "Actividad",
      flex: 1,
      valueGetter: (params) => {
        return params.row.id_actividad
          ? "ACTIVIDAD-" + params.row.id_actividad
          : "";
      },
    },
  ];

  const columns2 = [
    {
      field: "usuariorb",
      headerClassName: "super-app-theme--header",
      headerName: "Usuario Rb",
      flex: 1,
    },
    {
      field: "ip",
      headerClassName: "super-app-theme--header",
      headerName: "Ip",
      flex: 1,
    },
    {
      field: "idzona",
      headerClassName: "super-app-theme--header",
      headerName: "Id Zona",
      flex: 0.5,
    },
    {
      field: "zona",
      headerClassName: "super-app-theme--header",
      headerName: "Zona",
      flex: 1,
    },
    {
      field: "id_plan",
      headerClassName: "super-app-theme--header",
      headerName: "Id Plan",
      flex: 0.5,
    },
    {
      field: "plan",
      headerClassName: "super-app-theme--header",
      headerName: "Plan",
      flex: 1,
    },
    {
      field: "created_at",
      headerClassName: "super-app-theme--header",
      headerName: "Fecha",
      flex: 1,
      valueGetter: (params) => {
        return params.row.created_at
          ? moment(params.row.created_at).format("DD/MMMM/yyyy HH:mm")
          : "";
      },
    },
    {
      field: "usuario_creacion",
      headerClassName: "super-app-theme--header",
      headerName: "Usuario",
      flex: 1,
      valueGetter: (params) => {
        return params.row.usuario_creacion?.nombre ?? "";
      },
    },
    {
      field: "hojatrabajo",
      headerClassName: "super-app-theme--header",
      headerName: "Hoja Trabajo",
      flex: 1,
      valueGetter: (params) => {
        return params.row.hojatrabajo ? "HOJA-" + params.row.hojatrabajo : "";
      },
    },
  ];

  async function crear() {
    setCargando(true);
    let body = JSON.stringify({
      hojatrabajo: hoja.id,
      idzona: zona.id,
      zona: zona.nombre,
      id_plan: plan.id,
      plan: plan.nombre,
      usuariorb: user.trim() + " " + apellidos.trim(),
      ip: ip,
      usuario_creacion: userInfo.id,
    });

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/userwisp/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then(async (result) => {
        //aconsole.log(result);
        setOpen(false);
        Swal.fire({ icon: "success", text: "Usuario agregado " });
        cargar();
      })
      .catch((error) => {
        setOpen(false);
        setCargando(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  async function crear3() {
    setCargando(true);
    let body = JSON.stringify({
      zona: zona.id,
      plan_internet: plan.id,
      usuario_rb: user.trim() + " " + apellidos.trim(),
      nombre: user.trim(),
      apellidos: apellidos.trim(),
      ip: ip,
    });

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/api/clientes/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then(async (result) => {
        //aconsole.log(result);
        let res = JSON.parse(result);
        if (res?.task_id) {
          // await crear2(hoja.id);
          await crear();
        } else {
          setOpen(false);
          setCargando(false);
          Swal.fire({
            icon: "error",
            text:
              "Error: Error al crear el usuario en wisphub: " +
              JSON.stringify(res),
          });
        }
      })
      .catch((error) => {
        setCargando(false);
        setOpen(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  // async function crear2(id2) {
  //   let body = JSON.stringify({
  //     estado: "USUARIO CREADO",
  //     usuario_modificacion: userInfo.id,
  //   });

  //   let myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append("ngrok-skip-browser-warning", "asc");
  // myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
  //   let requestOptions = {
  //     method: "PATCH",
  //     headers: myHeaders,
  //     body: body,
  //     redirect: "follow",
  //   };
  //   fetch(
  //     "https://backterra.econtel.com.ec/api/v1/hojatrabajo/" + id2 + "/",
  //     requestOptions
  //   )
  //     .then(async (response) => {
  //       if (response.ok) {
  //         return response.text();
  //       }
  //       let mensaje = await response.json()
  //       throw {
  //         ...mensaje
  //       };
  //     })
  //     .then((result) => {
  //       //aconsole.log(result);
  //     })
  //     .catch((error) => {
  //       setOpen(false);
  //       setCargando(false);
  //       Swal.fire({ icon: "error", text: "Error: " + (typeof error === "string" ? error : JSON.stringify(error)) });
  //     });
  // }

  function editar() {
    crear3();
  }

  function cargar(inicio, fin) {
    setCargando(true);
    limpiar();
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    let url2 = "https://backterra.econtel.com.ec/api/v1/hojatrabajoby/?estado=SUBIDO&tipo=INSTALACION";
    if (inicio && fin) {
      url2 =
        "https://backterra.econtel.com.ec/api/v1/hojatrabajoby/?estado=SUBIDO&tipo=INSTALACION&inicio=" +
        inicio +
        "&fin=" +
        fin;
    } else {
      url2 =
        "https://backterra.econtel.com.ec/api/v1/hojatrabajoby/?estado=SUBIDO&tipo=INSTALACION&inicio=" +
        value[0].toISOString() +
        "&fin=" +
        value[1].toISOString();
    }
    fetch(
      url2,
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setLista(
          JSON.parse(result)
        );
        setCargando(false);
      })
      .catch((error) => {
        setCargando(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });

    let url = "https://backterra.econtel.com.ec/api/v1/userwisp/";
    if (inicio && fin) {
      url =
        "https://backterra.econtel.com.ec/api/v1/userwisp/?inicio=" +
        inicio +
        "&fin=" +
        fin;
    } else {
      url =
        "https://backterra.econtel.com.ec/api/v1/userwisp/?inicio=" +
        value[0].toISOString() +
        "&fin=" +
        value[1].toISOString();
    }
    fetch(
      url,
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setLista2(
          JSON.parse(result).sort((a, b) => {
            return b.id - a.id;
          })
        );
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });

    fetch(
      "https://backterra.econtel.com.ec/api/api/planes/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setPlanes(
          JSON.parse(result).sort((a, b) => {
            return a.nombre - b.nombre;
          })
        );
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });

    fetch(
      "https://backterra.econtel.com.ec/api/api/zonas/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setZonas(
          JSON.parse(result).sort((a, b) => {
            return a.nombre - b.nombre;
          })
        );
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  const [tab, setTab] = useState("0");
  const handleChange = (event, newValue) => {
    setTab(newValue);
    cargar();
  };

  return (
    <>
      <Box px={2} py={2}>
        <Paper elevation={2} sx={{ padding: "10px" }}>
          <TabContext value={tab}>
            <Box sx={{ borderColor: "divider" }}>
              <TabList onChange={handleChange} variant="scrollable">
                <Tab label="Crear" sx={{ fontWeight: "bold" }} value={"0"} />
                <Tab label="Ver" sx={{ fontWeight: "bold" }} value={"1"} />
              </TabList>
            </Box>

            <TabPanel value={"0"}>
              <Typography variant="h5">
                {" "}
                <strong>Clientes Por Crear - Instalaciones</strong>{" "}
              </Typography>
              <br />
              <FiltroFechas metodo={cargar} value={value} setValue={setValue} />

              <Button
                color="success"
                variant="contained"
                onClick={() => setOpen(true)}
              >
                Agregar
              </Button>

              <br />
              <Paper
                elevation={2}
                sx={{ overflowX: "scroll", padding: "10px" }}
              >
                <Box
                  sx={{
                    height: 600,
                    minWidth: 1000,
                    "& .super-app-theme--header": {
                      backgroundColor: "#428bd4",
                      color: "white",
                      fontSize: "16px",
                    },
                  }}
                >
                  <DataGrid
                    disableColumnMenu
                    getRowHeight={() => "auto"}
                    getEstimatedRowHeight={() => 200}
                    rows={lista ?? []}
                    loading={lista === null}
                    columns={columns}
                    getRowId={(row) => row.id}
                    localeText={
                      esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    slots={{ toolbar: CustomToolbar }}
                  />
                </Box>
              </Paper>
            </TabPanel>
            <TabPanel value={"1"}>
              <Typography variant="h5">
                {" "}
                <strong>Clientes Creados - Instalaciones</strong>{" "}
              </Typography>
              <br />
              <FiltroFechas metodo={cargar} value={value} setValue={setValue} />

              <Paper
                elevation={2}
                sx={{ overflowX: "scroll", padding: "10px" }}
              >
                <Box
                  sx={{
                    height: 600,
                    minWidth: 1000,
                    "& .super-app-theme--header": {
                      backgroundColor: "#428bd4",
                      color: "white",
                      fontSize: "16px",
                    },
                  }}
                >
                  <DataGrid
                    disableColumnMenu
                    getRowHeight={() => "auto"}
                    getEstimatedRowHeight={() => 200}
                    rows={lista2 ?? []}
                    loading={lista2 === null}
                    columns={columns2}
                    getRowId={(row) => row.id}
                    localeText={
                      esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    slots={{ toolbar: CustomToolbar }}
                  />
                </Box>
              </Paper>
            </TabPanel>
          </TabContext>
        </Paper>
      </Box>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          limpiar();
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {" "}
          <strong>Crear Cliente</strong>{" "}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Hoja de Trabajo</strong>{" "}
                </Typography>
                <Autocomplete
                  ListboxProps={{ style: { maxHeight: 150 } }}
                  size="small"
                  fullWidth
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disablePortal
                  value={hoja}
                  options={lista ?? []}
                  getOptionLabel={(option) =>
                    option.id ? "HOJA-" + option.id : ""
                  }
                  onChange={(e, t) => {
                    setHoja(t);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Plan *</strong>{" "}
                </Typography>
                <Autocomplete
                  ListboxProps={{ style: { maxHeight: 150 } }}
                  size="small"
                  fullWidth
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disablePortal
                  value={plan}
                  options={planes}
                  getOptionLabel={(option) => option.nombre ?? ""}
                  onChange={(e, t) => {
                    setPlan(t);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Zona *</strong>{" "}
                </Typography>
                <Autocomplete
                  ListboxProps={{ style: { maxHeight: 150 } }}
                  size="small"
                  fullWidth
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disablePortal
                  value={zona}
                  options={zonas}
                  getOptionLabel={(option) => option.nombre ?? ""}
                  onChange={(e, t) => {
                    setZona(t);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>IP *</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={ip}
                  onChange={(e) => setIp(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Nombres *</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={user}
                  onChange={(e) => setUser(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Apellidos *</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={apellidos}
                  onChange={(e) => setApellidos(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box px={2}>
                <Typography>
                  {" "}
                  <strong>Usuario</strong>{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  disabled
                  value={user.trim() + " " + apellidos.trim()}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box px={2}>
                <br />
                <Button
                  color="success"
                  variant="contained"
                  disabled={
                    hoja === null ||
                    ip === "" ||
                    plan === "" ||
                    zona === "" ||
                    apellidos.trim() === "" ||
                    user.trim() === ""
                  }
                  onClick={() => editar()}
                >
                  Guardar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen(false);
              limpiar();
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Cargando open2={cargando} />
    </>
  );
};
