import { Box, MenuItem, Paper, Select, Typography } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { CustomToolbar } from "../../../components/utils/CustomFilter";
import Swal from "sweetalert2";
import { MenuProps } from "../../../components/utils/MenuProps";

export const Jornada = (props) => {
  const [lista, setLista] = useState(null);
  const [tipo, setTipo] = useState("Tiempo_recorrido_dia");

  useEffect(() => {
    cargar(tipo);
  }, []);

  function calculo(tiempo) {
    let horas = tiempo / 60;
    horas = parseInt(horas);
    let minutos = tiempo - horas * 60;
    if (minutos <= 9) {
      return horas + "H " + "0" + minutos + " minutos";
    }
    return horas + "H " + minutos + " minutos";
  }

  function cargar(tipo2) {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://backterra.econtel.com.ec/api/api/tiempos_cuadrilla/?estadistico=" +
        tipo2,
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setLista(JSON.parse(result));
      })
      .catch((error) => {
        Swal.fire({ icon: "error", text: "Error: " + (typeof error === "string" ? error : JSON.stringify(error)) });
      });
  }

  const columns = [
    {
      field: "cuadrilla",
      headerClassName: "super-app-theme--header",
      headerName: "Cuadrilla",
      flex: 1,
      valueGetter: (params) => {
        let texto = "";
        if (props.cuadrillas) {
          let lis = props.cuadrillas.filter(
            (val) => val.id === params.row.id_cuadrilla
          );
          if (lis.length > 0) {
            texto = lis[0].nombre;
          }
        }
        return texto === "" ? params.row.id_cuadrilla : texto;
      },
    },
    {
      field: "fecha",
      headerClassName: "super-app-theme--header",
      headerName: "Fecha",
      flex: 1,
      valueGetter: (params) => {
        let texto = params.row.fecha
        if(params.row.fecha){
          texto = params.row.fecha.split("T")[0] ?? params.row.fecha
        }
        return  texto;
      },
    },
    {
      field: "tiempo",
      headerClassName: "super-app-theme--header",
      headerName: "Tiempo",
      flex: 1,
      valueGetter: (params) => {
        return calculo(params.row.tiempo);
      },
    },
  ];

  const columns2 = [
    {
      field: "cuadrilla",
      headerClassName: "super-app-theme--header",
      headerName: "Cuadrilla",
      flex: 1,
      valueGetter: (params) => {
        let texto = "";
        if (props.cuadrillas) {
          let lis = props.cuadrillas.filter(
            (val) => val.id === params.row.id_cuadrilla
          );
          if (lis.length > 0) {
            texto = lis[0].nombre;
          }
        }
        return texto === "" ? params.row.id_cuadrilla : texto;
      },
    },
    {
      field: "fecha_mes",
      headerClassName: "super-app-theme--header",
      headerName: "Mes",
      flex: 1,
    },
    {
      field: "tiempo",
      headerClassName: "super-app-theme--header",
      headerName: "Tiempo",
      flex: 1,
      valueGetter: (params) => {
        return calculo(params.row.tiempo);
      },
    },
  ];

  const columns3 = [
    {
      field: "cuadrilla",
      headerClassName: "super-app-theme--header",
      headerName: "Cuadrilla",
      flex: 1,
      valueGetter: (params) => {
        let texto = "";
        if (props.cuadrillas) {
          let lis = props.cuadrillas.filter(
            (val) => val.id === params.row.id_cuadrilla
          );
          if (lis.length > 0) {
            texto = lis[0].nombre;
          }
        }
        return texto === "" ? params.row.id_cuadrilla : texto;
      },
    },
    {
      field: "tiempo",
      headerClassName: "super-app-theme--header",
      headerName: "Tiempo",
      flex: 1,
      valueGetter: (params) => {
        return calculo(params.row.tiempo);
      },
    },
  ];

  return (
    <>
      <Typography>
        {" "}
        <strong>Filtrar Por:</strong>{" "}
      </Typography>
      <Select
        size="small"
        MenuProps={MenuProps}
        value={tipo}
        onChange={(e) => {
          setTipo(e.target.value);
          cargar(e.target.value);
        }}
      >
        <MenuItem key={1} value={"Tiempo_recorrido_dia"}>
          {" "}
          {"Tiempo Recorrido al día"}{" "}
        </MenuItem>
        <MenuItem key={2} value={"Tiempo_recorrido_mes"}>
          {" "}
          {"Tiempo Recorrido al mes"}{" "}
        </MenuItem>
        <MenuItem key={3} value={"tiempo_promedio_dia"}>
          {" "}
          {"Tiempo Promedio al día"}{" "}
        </MenuItem>
      </Select>
      <Paper elevation={2} sx={{ overflowX: "scroll", padding: "10px" }}>
        <Box
          sx={{
            height: 400,
            minWidth: 400,
            "& .super-app-theme--header": {
              backgroundColor: "#428bd4",
              color: "white",
              fontSize: "16px",
            },
          }}
        >
          <DataGrid  disableColumnMenu
            rows={lista ?? []}
            loading={lista === null}
            columns={
              tipo === "Tiempo_recorrido_dia"
                ? columns
                : tipo === "Tiempo_recorrido_mes"
                ? columns2
                : columns3
            }
            getRowId={(row) => row.id_tecnico + "-" + row.id_cuadrilla}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            slots={{ toolbar: CustomToolbar }}
          />
        </Box>
      </Paper>
    </>
  );
};
