import React, { useState, useEffect } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { PublicRoute, PublicRoute2 } from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import { useDispatch } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { CargandoPage } from "../components/utils/Cargando";
import { auth, db } from "../firebaseConfig";
import { Nav } from "../components/Navigation/Nav";
import { Home } from "../pages/home/Home";
import { Login } from "../pages/login/Login";
import { BodegaPage } from "../pages/inventario/bodega/BodegaPage";
import { Producto } from "../pages/inventario/Producto";
import { Proveedor } from "../pages/inventario/Proveedor";
import { Stock } from "../pages/inventario/Stock";
import { MovimientosPage } from "../pages/inventario/movimientos/MovimientosPage";
import { ParametroInventario } from "../pages/inventario/parametros/ParametrosInventario";
import { Rutas } from "../pages/rutas/Rutas";
import { Actividades } from "../pages/rutas/Actividades";
import Unauthorized from "../components/Navigation/Unauthorized";
import Swal from "sweetalert2";
import { doc, getDoc } from "firebase/firestore";
import { Clientes } from "../pages/clientes/Clientes";
import { Solicitudes } from "../pages/agenda/Solicitudes";
import { TipoMovimiento } from "../pages/inventario/movimientos/TipoMovimiento";
import { AprobarSolicitud } from "../pages/agenda/AprobarSolicitud";
import { StockTecnico } from "../pages/inventario/tecnicos/StockTecnico";
import { StockCuadrilla } from "../pages/inventario/tecnicos/StockCuadrilla";
import { Series } from "../pages/inventario/Series";
import { CuadrillaRuta } from "../pages/inventario/tecnicos/CuadrillaRuta";
import { Recepcion } from "../pages/contabilidad/Recepcion";
import { Gasto } from "../pages/contabilidad/Gasto";
import { Hoja } from "../pages/inventario/tecnicos/Hoja";
import { Reagendar } from "../pages/rutas/Reagendar";
import { Wisphub } from "../pages/clientes/Wisphub";
import { Trabajo } from "../pages/inventario/registro/Trabajo";
import { AdministrarUsuarios } from "../pages/usuarios/AdministrarUsuarios";
import { Roles } from "../pages/usuarios/Roles";
import { CuadrillaRuta2 } from "../pages/inventario/tecnicos/CuadrillaRuta2";
import { Dialog, DialogTitle, Stack } from "@mui/material";
import { Metricas } from "../pages/rutas/Metricas";
import { TipoGasto } from "../pages/contabilidad/TipoGasto";
import { GenerarMov } from "../pages/inventario/movimientos/GenerarMov";
import { TipoRevision } from "../pages/vehiculos/TipoRevision";
import { Vehiculo } from "../pages/vehiculos/Vehiculo";
import { Revision } from "../pages/vehiculos/Revision";
import { VehiculoDetalle } from "../pages/vehiculos/VehiculoDetalle";

export const AppRouters = () => {
  const [checking, setChecking] = useState(true);
  const [isAuth, setIsAuth] = useState(false);
  const [ver, setVer] = useState(true);

  const dispatch = useDispatch();
  const mode = "light";

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (userAuth) => {
      //aconsole.log("cambio");
      setChecking(true);
      version();
      if (userAuth) {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("ngrok-skip-browser-warning", "asc");
        myHeaders.append(
          "Authorization",
          "Bearer " + process.env.REACT_APP_TOKEN_API
        );
        const docRef = doc(db, "usuarios", userAuth.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          let requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
            body: JSON.stringify({
              correo: userAuth.email,
              contraseña: docSnap.data().password,
            }),
          };
          fetch(
            "https://backterra.econtel.com.ec/api/v1/usuario/authenticate/",
            requestOptions
          )
            .then(async (response) => {
              if (response.ok) {
                return response.text();
              }
              let mensaje = await response.json();
              throw {
                ...mensaje,
              };
            })
            .then((result) => {
              let resultado = JSON.parse(result);
              let lista = [];
              if (resultado.id && resultado.roles) {
                for (const val of resultado.roles) {
                  lista = lista.concat([
                    ...val.permisos.map((val2) => {
                      return val2.nombre;
                    }),
                  ]);
                }
                lista = Array.from(new Set(lista));

                dispatch({
                  type: "AUTH_ADD_ITEMS",
                  payload: {
                    ...resultado,
                    roles2: lista,
                  },
                });
                setChecking(false);
                setIsAuth(true);
              } else {
                dispatch({
                  type: "GET_CLIENT_FAIL",
                  payload: "Cliente no encontrado",
                });
                //aconsole.log("No esta logeado");
                setChecking(false);
              }
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                text:
                  "Error: " +
                  (typeof error === "string" ? error : JSON.stringify(error)),
              });
            });
        } else {
          dispatch({
            type: "GET_CLIENT_FAIL",
            payload: "Cliente no encontrado",
          });
          //aconsole.log("No existe el usuario");
          setChecking(false);
        }
      } else {
        //aconsole.log("No esta logeado");
        setChecking(false);
        setIsAuth(false);
      }
    });
    return () => {
      //aconsole.log("cerrar");
      unsubscribe();
    };
  }, [dispatch]);

  async function version() {
    while (true) {
      cargar();
      await new Promise((r) => setTimeout(r, 300000));
    }
  }

  function cargar() {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://backterra.econtel.com.ec/api/v1/version/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        let res = JSON.parse(result);
        //aconsole.log(res);
        if (res[0]) {
          if (res[0].version === "1.1.4") {
            setVer(true);
          } else {
            setVer(false);
          }
        } else {
          setVer(false);
        }
      })
      .catch((error) => {
        setVer(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          background: {
            default: "#f0f2f5",
          },
          primary: {
            main: "#808080",
          },
          secondary: {
            main: "#428bd4",
          },
          mode,
        },
      }),
    [mode]
  );
  if (checking) {
    return <CargandoPage />;
  }
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Nav isAuth={isAuth}>
          <Routes>
            <Route element={<PublicRoute />}>
              <Route path="/" element={<Home />} />
            </Route>
            <Route element={<PublicRoute2 />}>
              <Route path="/login" element={<Login />} />
            </Route>

            <Route
              element={
                <PrivateRoute
                  isAuth={isAuth}
                  allowedRoles={["LEER STOCK TECNICO"]}
                />
              }
            >
              <Route path="tecnico/tecnico" element={<StockTecnico />} />
            </Route>
            <Route
              element={
                <PrivateRoute
                  isAuth={isAuth}
                  allowedRoles={["LEER STOCK CUADRILLA"]}
                />
              }
            >
              <Route path="tecnico/cuadrilla" element={<StockCuadrilla />} />
            </Route>
            <Route
              element={
                <PrivateRoute
                  isAuth={isAuth}
                  allowedRoles={["VER RUTAS ASIGNADAS"]}
                />
              }
            >
              <Route path="tecnico/rutas" element={<CuadrillaRuta />} />
              <Route path="tecnico/rutas/:ruta" element={<CuadrillaRuta2 />} />
              <Route path="tecnico/actividad/:actividad" element={<Hoja />} />
            </Route>

            <Route
              element={
                <PrivateRoute isAuth={isAuth} allowedRoles={["LEER BODEGA"]} />
              }
            >
              <Route path="inventario/bodegas" element={<BodegaPage />} />
            </Route>
            <Route
              element={
                <PrivateRoute
                  isAuth={isAuth}
                  allowedRoles={["LEER PRODUCTO"]}
                />
              }
            >
              <Route path="inventario/productos" element={<Producto />} />
            </Route>
            <Route
              element={
                <PrivateRoute
                  isAuth={isAuth}
                  allowedRoles={["LEER PROVEEDOR"]}
                />
              }
            >
              <Route path="inventario/proveedores" element={<Proveedor />} />
            </Route>
            <Route
              element={
                <PrivateRoute isAuth={isAuth} allowedRoles={["LEER STOCK"]} />
              }
            >
              <Route path="inventario/stock" element={<Stock />} />
            </Route>
            <Route
              element={
                <PrivateRoute isAuth={isAuth} allowedRoles={["LEER SERIES"]} />
              }
            >
              <Route path="inventario/series" element={<Series />} />
            </Route>
            <Route
              element={
                <PrivateRoute
                  isAuth={isAuth}
                  allowedRoles={["LEER MOVIMIENTOS"]}
                />
              }
            >
              <Route
                path="inventario/movimientos"
                element={<MovimientosPage />}
              />
            </Route>
            <Route
              element={
                <PrivateRoute
                  isAuth={isAuth}
                  allowedRoles={["REALIZAR MOVIMIENTOS"]}
                />
              }
            >
              <Route path="inventario/movimientos2" element={<GenerarMov />} />
            </Route>
            <Route
              element={
                <PrivateRoute
                  isAuth={isAuth}
                  allowedRoles={["LEER MOTIVOS MOVIMIENTOS"]}
                />
              }
            >
              <Route
                path="inventario/motivoMovimiento"
                element={<TipoMovimiento />}
              />
            </Route>
            <Route
              element={
                <PrivateRoute
                  isAuth={isAuth}
                  allowedRoles={["LEER PARAMETROS INVENTARIO"]}
                />
              }
            >
              <Route
                path="inventario/parametros"
                element={<ParametroInventario />}
              />
            </Route>

            <Route
              element={
                <PrivateRoute isAuth={isAuth} allowedRoles={["LEER USUARIO"]} />
              }
            >
              <Route path="usuarios/admin" element={<AdministrarUsuarios />} />
            </Route>
            <Route
              element={
                <PrivateRoute isAuth={isAuth} allowedRoles={["LEER ROLES"]} />
              }
            >
              <Route path="usuarios/roles" element={<Roles />} />
            </Route>

            <Route
              element={
                <PrivateRoute isAuth={isAuth} allowedRoles={["LEER RUTAS"]} />
              }
            >
              <Route path="rutas/rutas" element={<Rutas />} />
            </Route>

            <Route
              element={
                <PrivateRoute
                  isAuth={isAuth}
                  allowedRoles={["LEER METRICAS RUTAS"]}
                />
              }
            >
              <Route path="rutas/metricas" element={<Metricas />} />
            </Route>
            <Route
              element={
                <PrivateRoute
                  isAuth={isAuth}
                  allowedRoles={["LEER ACTIVIDADES"]}
                />
              }
            >
              <Route path="rutas/actividades" element={<Actividades />} />
            </Route>
            <Route
              element={
                <PrivateRoute
                  isAuth={isAuth}
                  allowedRoles={["REAGENDAR ACTIVIDADES"]}
                />
              }
            >
              <Route path="rutas/reagendar" element={<Reagendar />} />
            </Route>

            <Route
              element={
                <PrivateRoute
                  isAuth={isAuth}
                  allowedRoles={["LEER SOLICITUDES"]}
                />
              }
            >
              <Route path="agenda/solicitudes" element={<Solicitudes />} />
            </Route>
            <Route
              element={
                <PrivateRoute
                  isAuth={isAuth}
                  allowedRoles={["APROBAR SOLICITUDES"]}
                />
              }
            >
              <Route path="agenda/aprobar" element={<AprobarSolicitud />} />
            </Route>

            <Route
              element={
                <PrivateRoute isAuth={isAuth} allowedRoles={["LEER HOJAS"]} />
              }
            >
              <Route path="registros/hojas" element={<Trabajo />} />
              <Route path="tecnico/actividad/:actividad" element={<Hoja />} />
            </Route>

            <Route
              element={
                <PrivateRoute
                  isAuth={isAuth}
                  allowedRoles={["LEER CLIENTES"]}
                />
              }
            >
              <Route path="clientes/admin" element={<Clientes />} />
            </Route>
            <Route
              element={
                <PrivateRoute
                  isAuth={isAuth}
                  allowedRoles={["CREAR CLIENTES"]}
                />
              }
            >
              <Route path="clientes/wisphub" element={<Wisphub />} />
            </Route>

            <Route
              element={
                <PrivateRoute isAuth={isAuth} allowedRoles={["LEER GASTOS"]} />
              }
            >
              <Route path="contabilidad/gasto" element={<Gasto />} />
            </Route>
            <Route
              element={
                <PrivateRoute
                  isAuth={isAuth}
                  allowedRoles={["LEER MOTIVOS GASTOS"]}
                />
              }
            >
              <Route path="inventario/motivoGasto" element={<TipoGasto />} />
            </Route>
            <Route
              element={
                <PrivateRoute
                  isAuth={isAuth}
                  allowedRoles={["LEER RECAUDACIONES"]}
                />
              }
            >
              <Route path="contabilidad/recepcion" element={<Recepcion />} />
            </Route>

            <Route
              element={
                <PrivateRoute
                  isAuth={isAuth}
                  allowedRoles={["LEER TIPO REVISION"]}
                />
              }
            >
              <Route path="vehiculo/tipo" element={<TipoRevision />} />
            </Route>

            <Route
              element={
                <PrivateRoute
                  isAuth={isAuth}
                  allowedRoles={["LEER VEHICULOS CUADRILLA", "LEER VEHICULOS PARTICULARES"]}
                />
              }
            >
              <Route path="vehiculo/vehiculo" element={<Vehiculo />} />
              <Route path="vehiculo/vehiculo/:id" element={<VehiculoDetalle />} />
            </Route>

            <Route
              element={
                <PrivateRoute
                  isAuth={isAuth}
                  allowedRoles={["LEER REVISIONES"]}
                />
              }
            >
              <Route path="vehiculo/revision" element={<Revision />} />
            </Route>

            <Route
              element={
                <PrivateRoute isAuth={isAuth} allowedRoles={["ADMIN"]} />
              }
            ></Route>

            <Route path="unauthorized" element={<Unauthorized />} />
            <Route
              path="*"
              element={<Navigate to={isAuth ? "/" : "/login"} />}
            />
          </Routes>
        </Nav>
      </ThemeProvider>
      <Dialog open={!ver}>
        <DialogTitle>
          <Stack
            spacing={2}
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            La versión del sistema no es actual, refresque la ventana para
            actualizar o no hay conexión con el servidor
          </Stack>
        </DialogTitle>
      </Dialog>
    </BrowserRouter>
  );
};
