import React, { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Typography } from "@mui/material";
import { Marcas } from "./Marcas";
import { Modelos } from "./Modelos";
import { Unidad } from "./Unidad";

export const ParametroInventario = () => {
  const [tab, setTab] = useState("0");
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box px={2}>
      <Typography variant="h5">
        {" "}
        <strong>Parámetros Inventario</strong>{" "}
      </Typography>
      <TabContext value={tab}>
        <Box sx={{ borderColor: "divider" }}>
          <TabList onChange={handleChange} variant="scrollable">
            <Tab label="MARCAS" sx={{ fontWeight: "bold" }} value={"0"} />
            <Tab label="MODELOS" sx={{ fontWeight: "bold" }} value={"1"} />
            <Tab label="UNIDADES" sx={{ fontWeight: "bold" }} value={"2"} />
          </TabList>
        </Box>

        <TabPanel value={"0"}>
          <Marcas />
        </TabPanel>
        <TabPanel value={"1"}>
          <Modelos />
        </TabPanel>
        <TabPanel value={"2"}>
          <Unidad />
        </TabPanel>
      </TabContext>
    </Box>
  );
};
