/* eslint-disable no-loop-func */
import { Box, IconButton, Paper, Stack, Typography } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { CustomToolbar } from "../../../components/utils/CustomFilter";
import { useSelector } from "react-redux";
import moment from "moment";
import { Cargando } from "../../../components/utils/Cargando";
import { useNavigate } from "react-router-dom";

export const CuadrillaRuta = () => {
  const navigate = useNavigate();
  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;
  const [cargando, setCargando] = useState(false);

  const [lista, setLista] = useState(null);

  useEffect(() => {
    cargar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      field: "1",
      headerClassName: "super-app-theme--header",
      headerName: "Acciones",
      width: 60,
      renderCell: (params) => (
        <Stack direction={"row"} spacing={2}>
          <IconButton
            onClick={() => {
              navigate("/tecnico/rutas/" + params.row.id);
              // handleOpen(params.row)
            }}
            disabled={params.row.actividades.length === 0}
          >
            <EditIcon
              color={params.row.actividades.length === 0 ? "gray" : "warning"}
            />
          </IconButton>
        </Stack>
      ),
    },
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "Ruta",
      flex: 1,
      valueGetter: (params) => {
        return "RUTA-" + params.row.id;
      },
    },
    {
      field: "cuadrilla",
      headerClassName: "super-app-theme--header",
      headerName: "Cuadrilla",
      flex: 1,
      valueGetter: (params) => {
        return params.row.cuadrilla?.nombre ?? "";
      },
    },
    {
      field: "estado",
      headerClassName: "super-app-theme--header",
      headerName: "Estado",
      flex: 1,
    },
    {
      field: "tiempo_inicio",
      headerClassName: "super-app-theme--header",
      headerName: "Tiempo Inicio",
      flex: 1,
      valueGetter: (params) => {
        return params.row.tiempo_inicio
          ? moment(params.row.tiempo_inicio).format("DD/MMMM/yyyy HH:mm")
          : "";
      },
    },
    {
      field: "tiempo_fin",
      headerClassName: "super-app-theme--header",
      headerName: "Tiempo Fin",
      flex: 1,
      valueGetter: (params) => {
        return params.row.tiempo_fin
          ? moment(params.row.tiempo_fin).format("DD/MMMM/yyyy HH:mm")
          : "";
      },
    },
    {
      field: "actividades",
      headerClassName: "super-app-theme--header",
      headerName: "Actividades",
      flex: 1,
      valueGetter: (params) => {
        if (params.row.actividades) {
          return [
            ...params.row.actividades.map((val) => {
              return "ACTIVIDAD-" + val.id;
            }),
          ].join(", ");
        } else {
          return "";
        }
      },
    },
    {
      field: "created_at",
      headerClassName: "super-app-theme--header",
      headerName: "Creación",
      flex: 1,
      valueGetter: (params) => {
        return params.row.created_at
          ? moment(params.row.created_at).format("DD/MMMM/yyyy HH:mm")
          : "";
      },
    },
  ];

  function cargar() {
    setCargando(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/bodega/?encargado_id=" +
        userInfo.id,
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then(async (result) => {
        let tecnicos = JSON.parse(result).filter(
          (val) => val.tipo === "CUADRILLA"
        );
        tecnicos = [
          ...tecnicos.map((val) => {
            return val.id;
          }),
        ];
        let lista2 = [];
        for (const el of tecnicos) {
          await fetch(
            //&estado=EJECUTANDO
            "https://backterra.econtel.com.ec/api/v1/rutasby/?estado2=TERMINADO&cuadrilla=" + el,
            requestOptions
          )
            .then(async (response) => {
              if (response.ok) {
                return response.text();
              }
              let mensaje = await response.json();
              throw {
                ...mensaje,
              };
            })
            .then((result) => {
              let l = JSON.parse(result);
              if (l.length > 0) {
                lista2 = lista2.concat(l);
              }
            })
            .catch((error) => {
              setCargando(false);
              Swal.fire({
                icon: "error",
                text:
                  "Error: " +
                  (typeof error === "string" ? error : JSON.stringify(error)),
              });
            });
        }
        //aconsole.log(lista2);
        // lista2 = lista2.filter((val)=> val.estado !== "TERMINADO")
        setLista(
          lista2.sort((a, b) => {
            return b.id - a.id;
          })
        );
        setCargando(false);
      })
      .catch((error) => {
        setCargando(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  return (
    <>
      <Box px={2} py={2}>
        <Paper elevation={2} sx={{ padding: "10px" }}>
          <Typography variant="h5">
            {" "}
            <strong>Rutas y Actividades Asignadas</strong>{" "}
          </Typography>

          <br />
          <br />
          <Paper elevation={2} sx={{ overflowX: "scroll", padding: "10px" }}>
            <Box
              sx={{
                height: 600,
                minWidth: 1000,
                "& .super-app-theme--header": {
                  backgroundColor: "#428bd4",
                  color: "white",
                  fontSize: "16px",
                },
              }}
            >
              <DataGrid
                disableColumnMenu
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
                rows={lista ?? []}
                loading={lista === null}
                columns={columns}
                getRowId={(row) => row.id}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                slots={{ toolbar: CustomToolbar }}
              />
            </Box>
          </Paper>
        </Paper>
      </Box>

      <Cargando open2={cargando} />
    </>
  );
};
