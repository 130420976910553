import esES from "rsuite/locales/es_ES";
import React from "react";
import { CustomProvider, DateRangePicker } from "rsuite";
import {
  endOfDay,
  startOfDay,
  startOfMonth,
  startOfYear,
  subDays,
  subYears,
} from "date-fns";
import { Box } from "@mui/material";

export const FiltroFechas = ({metodo ,value, setValue}) => {
  return (
    <Box py={1}>
      <CustomProvider locale={esES}>
        <strong>Filtro:</strong>
        <DateRangePicker
          ranges={[
            {
              label: "Hoy",
              value: [startOfDay(new Date()), endOfDay(new Date())],
              placement: 'left'
            },
            {
              label: "Última semana",
              value: [
                startOfDay(subDays(new Date(), 6)),
                endOfDay(new Date().setHours(23)),
              ],
              placement: 'left'
            },
            {
              label: "Mes actual",
              value: [
                startOfDay(startOfMonth(new Date())),
                endOfDay(new Date().setHours(23)),
              ],
              placement: 'left'
            },
            {
              label: "Año actual",
              value: [
                startOfDay(startOfYear(new Date())),
                endOfDay(new Date().setHours(23)),
              ],
              placement: 'left'
            },
            {
              label: "Todo",
              value: [
                startOfDay(subYears(startOfYear(new Date()), 10)),
                endOfDay(new Date().setHours(23)),
              ],
              placement: 'left'
            },
          ]}
          value={value ?? new Date()}
          onChange={(e)=>{
            let fechas = [startOfDay(e[0]), endOfDay(e[1])]
            if(setValue){
                setValue(fechas)            
            }
            if(metodo){
                metodo(fechas[0].toISOString(),fechas[1].toISOString())
            }
          }}
          cleanable={false}
          showOneCalendar
          format="dd MMMM yyyy HH:mm"
        />
      </CustomProvider>
    </Box>
  );
};
