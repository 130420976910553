import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from '@mui/icons-material/Home';
import { Link, useNavigate } from "react-router-dom";
import { ListInventario } from "./ListInventario";
import { ListRutas } from "./ListRutas";
import { ListInstalaciones } from "./ListInstalaciones";
import { ListAgenda } from "./ListAgenda";
import { ListClientes } from "./ListClientes";
import { ListUsuarios } from "./ListUsuarios";
import { ListTecnico } from "./ListTecnico";
import { ListContabilidad } from "./ListContabilidad";
import { useSelector } from "react-redux";
import { ListVehiculo } from "./ListVehiculo";


export const Items = () => {
  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;

  return (
    <React.Fragment >
      <ListItemButton component={Link} to={"home"} >
        <ListItemIcon>
          <HomeIcon  />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ fontWeight: 'bold' }} primary="Home" />
      </ListItemButton>
      <ListTecnico  info={userInfo}/>
      <ListInventario info={userInfo}/>
      <ListUsuarios info={userInfo}/>
      <ListRutas info={userInfo}/>
      <ListAgenda info={userInfo} />
      <ListInstalaciones info={userInfo}/>
      <ListClientes info={userInfo}/>
      <ListContabilidad info={userInfo}/>
      <ListVehiculo info={userInfo}/>

    </React.Fragment>
  );
};
