/* eslint-disable no-loop-func */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  Divider,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import moment from "moment";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Cargando } from "../../../components/utils/Cargando";
import { useNavigate, useParams } from "react-router-dom";

export const CuadrillaRuta2 = () => {
  let { ruta } = useParams();
  const navigate = useNavigate();
  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;
  const [cargando, setCargando] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const [km, setKm] = useState("");
  const [motivo, setMotivo] = useState("");

  const [solicitud, setSolicitud] = useState(null);

  const [actividad, setActividad] = useState(0);

  const [objeto, setObjeto] = useState({});

  useEffect(() => {
    cargar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function limpiar() {
    setKm("");
    setMotivo("");
    // setActividad(0);
    setObjeto({});
    setSolicitud(null);
    setOpen2(false);
    setOpen3(false);
  }
  // const link =
  //   ;

  const Mapa = () => {
    return (
      <iframe
        width="100%"
        height="250"
        title="Punto de Referencia"
        src={
          "https://www.google.com/maps/embed/v1/search?key=" +
          process.env.REACT_APP_API_KEY +
          "&q=" +
          solicitud.latitud +
          "," +
          solicitud.longitud
        }
      >
         
      </iframe>
    );
  };

  function handleOpen(row) {
    setObjeto(row);
    //aconsole.log(row);
    // setActividad(0);
    if (row.actividades[0]?.solicitud_id) {
      getSolicitud(row.actividades[actividad]?.solicitud_id);
    }
    setCargando(false);
  }

  function iniciar(id2) {
    setCargando(true);
    let body = JSON.stringify({
      tiempo_inicio: moment().format("YYYY-MM-DD HH:mm"),
      estado: "EN CURSO",
    });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/rutas/" + id2 + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        cargar();
        Swal.fire({ icon: "success", text: "Ruta Iniciada" });
      })
      .catch((error) => {
        setCargando(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function terminar(id2) {
    setCargando(true);
    let body = JSON.stringify({
      tiempo_fin: moment().format("YYYY-MM-DD HH:mm"),
      estado: "TERMINADO",
    });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/rutas/" + id2 + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        cargar();
        Swal.fire({ icon: "success", text: "Ruta Terminada" });
      })
      .catch((error) => {
        setCargando(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function iniciar2(id2) {
    setCargando(true);
    let body = JSON.stringify({
      tiempo_inicio: moment().format("YYYY-MM-DD HH:mm"),
      estado: "EN CURSO",
      usuario_mod: userInfo.id,
      id_tecnico: userInfo.id,
      id_cuadrilla: objeto?.cuadrilla?.id,
      km: km,
    });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/actividades/" +
        id2 +
        "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        cargar();
        Swal.fire({ icon: "success", text: "Actividad Iniciada" });
      })
      .catch((error) => {
        setCargando(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function cancelar(id2) {
    setCargando(true);
    let body = JSON.stringify({
      estado: "CANCELADO",
      motivo_cancelado: motivo,
      id_tecnico: userInfo.id,
      id_cuadrilla: objeto?.cuadrilla?.id,
      usuario_mod: userInfo.id,
    });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/actividades/" +
        id2 +
        "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        cargar();
        Swal.fire({ icon: "success", text: "Actividad Cancelada" });
      })
      .catch((error) => {
        setCargando(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function terminar2(id2) {
    setCargando(true);
    let body = JSON.stringify({
      tiempo_fin: moment().format("YYYY-MM-DD HH:mm"),
      estado: "SUBIR HOJA",
      id_tecnico: userInfo.id,
      id_cuadrilla: objeto?.cuadrilla?.id,
      usuario_mod: userInfo.id,
    });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/actividades/" +
        id2 +
        "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        cargar();
        Swal.fire({ icon: "success", text: "Actividad Terminada" });
      })
      .catch((error) => {
        setCargando(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function getSolicitud(sol) {
    setCargando(true);
    if (sol === null) {
      setSolicitud(null);
      setCargando(false);
      return;
    }
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    let link =
      "https://backterra.econtel.com.ec/api/v1/solicitud/" +
      sol +
      "/";
    fetch(link, requestOptions)
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setSolicitud(JSON.parse(result));
        setCargando(false);
      })
      .catch((error) => {
        setSolicitud(null);
        setCargando(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  function cargar() {
    setCargando(true);
    limpiar();
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
    myHeaders.append(
      "Authorization",
      "Bearer " + process.env.REACT_APP_TOKEN_API
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://backterra.econtel.com.ec/api/v1/rutas/" + ruta + "/",
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then(async (result) => {
        let res = JSON.parse(result);
        //aconsole.log(res);
        handleOpen(res);
      })
      .catch((error) => {
        setCargando(false);
        Swal.fire({
          icon: "error",
          text:
            "Error: " +
            (typeof error === "string" ? error : JSON.stringify(error)),
        });
      });
  }

  return (
    <>
      <Box px={2} py={2}>
        <Paper elevation={2} sx={{ padding: "10px" }}>
          <Typography variant="h5">
            {" "}
            <strong>{ruta !== "" ? "RUTA-" + ruta : ""} </strong>
          </Typography>
          <br />
          <Paper elevation={2} sx={{ overflowX: "scroll", padding: "10px" }}>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={3}>
                <Stack direction={"column"} spacing={2} px={2}>
                  <a>
                    <strong>Tiempo Inicio: </strong>
                    {objeto.tiempo_inicio &&
                      moment(objeto.tiempo_inicio).format("DD/MMMM/yyyy HH:mm")}
                  </a>
                  <Button
                    color="info"
                    variant="contained"
                    disabled={objeto.tiempo_inicio !== null}
                    onClick={() => iniciar(objeto.id)}
                  >
                    Iniciar Ruta
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={6} lg={3}>
                <Stack direction={"column"} spacing={2} px={2}>
                  <a>
                    <strong>Tiempo Fin: </strong>
                    {objeto.tiempo_fin &&
                      moment(objeto.tiempo_fin).format("DD/MMMM/yyyy HH:mm")}
                  </a>
                  <Button
                    color="error"
                    disabled={
                      objeto.tiempo_fin !== null ||
                      objeto.tiempo_inicio === null ||
                      objeto.actividades.filter(
                        (val) =>
                          val.estado === "INGRESADO" ||
                          val.estado === "SUBIR HOJA" ||
                          val.estado === "EN CURSO"
                      ).length > 0
                    }
                    variant="contained"
                    onClick={() => terminar(objeto.id)}
                  >
                    Terminar Ruta
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12} lg={3} />
              <Grid item xs={12} lg={3}>
                <Stack direction={"row"} spacing={2} px={2}>
                  <IconButton
                    color="info"
                    disabled={
                      objeto?.actividades?.length === 0 || actividad - 1 < 0
                    }
                    onClick={() => {
                      getSolicitud(
                        objeto.actividades[actividad - 1]?.solicitud_id
                      );
                      setActividad(actividad - 1);
                    }}
                  >
                    <KeyboardArrowLeftIcon fontSize="large" />
                  </IconButton>
                  <IconButton
                    color="info"
                    disabled={
                      objeto?.actividades?.length === 0 ||
                      actividad + 1 >= objeto?.actividades?.length
                    }
                    onClick={() => {
                      getSolicitud(
                        objeto.actividades[actividad + 1]?.solicitud_id
                      );
                      setActividad(actividad + 1);
                    }}
                  >
                    <KeyboardArrowRightIcon fontSize="large" />
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>
            <br />
            {objeto?.actividades?.length > 0 && (
              <Paper
                // key={objeto.actividades[actividad]?.id}
                sx={{ margin: 2 }}
                elevation={4}
              >
                <Box px={2} py={2}>
                  <Typography align="center" variant="h6">
                    {" "}
                    <strong>
                      ACTIVIDAD-{objeto.actividades[actividad]?.id}
                    </strong>
                  </Typography>
                  <Typography align="center">
                    <strong>Estado:</strong>{" "}
                    {objeto.actividades[actividad]?.estado}
                  </Typography>
                  <br />
                  <Divider />
                  <br />
                  <Grid container spacing={2}>
                    {" "}
                    <Grid
                      item
                      xs={12}
                      md={4}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Stack direction={"column"} spacing={2}>
                        <Button
                          color="info"
                          variant="contained"
                          disabled={
                            objeto.actividades[actividad]?.estado !==
                              "INGRESADO" ||
                            objeto.tiempo_fin !== null ||
                            objeto.tiempo_inicio === null
                          }
                          onClick={() => setOpen2(true)}
                        >
                          Iniciar Actividad
                        </Button>

                        <Button
                          color="warning"
                          variant="contained"
                          disabled={
                            objeto.actividades[actividad]?.estado ===
                              "CANCELADO" ||
                            objeto.actividades[actividad]?.estado ===
                              "SUBIR HOJA" ||
                            objeto.actividades[actividad]?.estado ===
                              "TERMINADO" ||
                            objeto.tiempo_fin !== null ||
                            objeto.tiempo_inicio === null
                          }
                          onClick={() =>
                            // cancelar(objeto.actividades[actividad]?.id)
                            setOpen3(true)
                          }
                        >
                          Cancelar Actividad
                        </Button>
                        <Button
                          color="error"
                          disabled={
                            objeto.actividades[actividad]?.estado ===
                              "CANCELADO" ||
                            objeto.actividades[actividad]?.estado ===
                              "INGRESADO" ||
                            objeto.actividades[actividad]?.estado ===
                              "SUBIR HOJA" ||
                            objeto.actividades[actividad]?.estado ===
                              "TERMINADO" ||
                            objeto.tiempo_fin !== null ||
                            objeto.tiempo_inicio === null
                          }
                          variant="contained"
                          onClick={() =>
                            terminar2(objeto.actividades[actividad]?.id)
                          }
                        >
                          Terminar Actividad
                        </Button>
                        <Button
                          color="info"
                          disabled={
                            objeto.actividades[actividad]?.estado !==
                            "SUBIR HOJA"
                          }
                          variant="contained"
                          onClick={() =>
                            navigate(
                              "/tecnico/actividad/" +
                                objeto.actividades[actividad]?.id
                            )
                          }
                        >
                          Subir hoja de trabajo
                        </Button>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={8}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {solicitud?.latitud && solicitud?.longitud && <Mapa />}
                    </Grid>
                  </Grid>
                  <br />
                  <Divider />
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <Box px={2}>
                        <Typography>
                          <strong>Fecha: </strong>
                          {moment(objeto.actividades[actividad]?.fecha).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Box px={2}>
                        <Typography>
                          {" "}
                          <strong>Descripción: </strong>
                          {objeto.actividades[actividad]?.descripcion}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <br />
                  <Divider />
                  <br />
                  {solicitud && (
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6}>
                        <Box px={2}>
                          <Typography>
                            {" "}
                            <strong>Identificación Cliente</strong>{" "}
                          </Typography>
                          <Typography>{solicitud.cedula}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Box px={2}>
                          <Typography>
                            {" "}
                            <strong>Tipo Solicitud</strong>{" "}
                          </Typography>
                          <Typography>{solicitud.tipo}</Typography>
                        </Box>
                      </Grid>
                      {solicitud.tipo === "INSTALACION" && (
                        <>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Nombre Completo</strong>{" "}
                              </Typography>
                              <Typography>
                                {solicitud.nombres_cliente}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Dirección</strong>{" "}
                              </Typography>
                              <Typography>{solicitud.direccion}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Latitud</strong>{" "}
                              </Typography>
                              <Typography>{solicitud.latitud}</Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Longitud</strong>{" "}
                              </Typography>
                              <Typography>{solicitud.longitud}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Teléfono</strong>{" "}
                              </Typography>
                              <Typography>{solicitud.telefono}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Celular</strong>{" "}
                              </Typography>
                              <Typography>{solicitud.celular}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Correo</strong>{" "}
                              </Typography>
                              <Typography>
                                {solicitud.correo_electronico}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Plan</strong>{" "}
                              </Typography>
                              <Typography>
                                {solicitud.plan_contratar}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Horarios disponibles</strong>{" "}
                              </Typography>
                              <Typography>
                                {solicitud.horarios_disponibles}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Observación</strong>{" "}
                              </Typography>
                              <Typography>
                                {solicitud.observacion_diagnostico}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Casas #Pisos</strong>{" "}
                              </Typography>
                              <Typography>{solicitud.casa_npisos}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              {solicitud.foto_casa && (
                                <img
                                  src={solicitud.foto_casa}
                                  alt={"imagen"}
                                  height={110}
                                />
                              )}
                            </Box>
                          </Grid>
                        </>
                      )}
                      {solicitud.tipo === "CAMBIO DOMICILIO" && (
                        <>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Nombre Completo</strong>{" "}
                              </Typography>
                              <Typography>
                                {solicitud.nombres_cliente}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Cliente tiene Equipos*</strong>{" "}
                              </Typography>
                              <Typography>
                                {solicitud.cliente_tiene_equipo}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Dirección Nueva*</strong>{" "}
                              </Typography>
                              <Typography>{solicitud.direccion}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Dirección Anterior*</strong>{" "}
                              </Typography>
                              <Typography>
                                {solicitud.direccion_anterior}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Latitud Nueva*</strong>{" "}
                              </Typography>
                              <Typography>{solicitud.latitud}</Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Longitud Nueva*</strong>{" "}
                              </Typography>
                              <Typography>{solicitud.longitud}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Latitud Anterior*</strong>{" "}
                              </Typography>
                              <Typography>
                                {solicitud.latitud_anterior}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Longitud Anterior*</strong>{" "}
                              </Typography>
                              <Typography>
                                {solicitud.longitud_anterior}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Teléfono</strong>{" "}
                              </Typography>
                              <Typography>{solicitud.telefono}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Celular</strong>{" "}
                              </Typography>
                              <Typography>{solicitud.celular}</Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Horarios disponibles</strong>{" "}
                              </Typography>
                              <Typography>
                                {solicitud.horarios_disponibles}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Observación</strong>{" "}
                              </Typography>
                              <Typography>
                                {solicitud.observacion_diagnostico}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              {solicitud.foto_casa && (
                                <img
                                  src={solicitud.foto_casa}
                                  alt={"imagen"}
                                  height={110}
                                />
                              )}
                            </Box>
                          </Grid>
                        </>
                      )}
                      {solicitud.tipo === "MIGRACION" && (
                        <>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Nombre Completo</strong>{" "}
                              </Typography>
                              <Typography>
                                {solicitud.nombres_cliente}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Dirección</strong>{" "}
                              </Typography>
                              <Typography>{solicitud.direccion}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Latitud</strong>{" "}
                              </Typography>
                              <Typography>{solicitud.latitud}</Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Longitud</strong>{" "}
                              </Typography>
                              <Typography>{solicitud.longitud}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Teléfono</strong>{" "}
                              </Typography>
                              <Typography>{solicitud.telefono}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Celular</strong>{" "}
                              </Typography>
                              <Typography>{solicitud.celular}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Horarios disponibles</strong>{" "}
                              </Typography>
                              <Typography>
                                {solicitud.horarios_disponibles}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Observación</strong>{" "}
                              </Typography>
                              <Typography>
                                {solicitud.observacion_diagnostico}
                              </Typography>
                            </Box>
                          </Grid>
                        </>
                      )}
                      {solicitud.tipo === "VISITA TECNICA" && (
                        <>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Nombre Completo</strong>{" "}
                              </Typography>
                              <Typography>
                                {solicitud.nombres_cliente}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Dirección</strong>{" "}
                              </Typography>
                              <Typography>{solicitud.direccion}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Latitud</strong>{" "}
                              </Typography>
                              <Typography>{solicitud.latitud}</Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Longitud</strong>{" "}
                              </Typography>
                              <Typography>{solicitud.longitud}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Teléfono</strong>{" "}
                              </Typography>
                              <Typography>{solicitud.telefono}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Celular</strong>{" "}
                              </Typography>
                              <Typography>{solicitud.celular}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Señal o Potencia</strong>{" "}
                              </Typography>
                              <Typography>
                                {solicitud.senal_potencia}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Tecnología</strong>{" "}
                              </Typography>
                              <Typography>{solicitud.tecnologia}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Gestión Operador</strong>{" "}
                              </Typography>
                              <Typography>
                                {solicitud.gestion_operador}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Observación</strong>{" "}
                              </Typography>
                              <Typography>
                                {solicitud.observacion_diagnostico}
                              </Typography>
                            </Box>
                          </Grid>
                        </>
                      )}
                      {(solicitud.tipo === "OTRO" ||
                        solicitud.tipo === "CAMBIO DE TITULAR" ||
                        solicitud.tipo === "RETIRO") && (
                        <>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Nombre Completo</strong>{" "}
                              </Typography>
                              <Typography>
                                {solicitud.nombres_cliente}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Dirección</strong>{" "}
                              </Typography>
                              <Typography>{solicitud.direccion}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Latitud</strong>{" "}
                              </Typography>
                              <Typography>{solicitud.latitud}</Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Longitud</strong>{" "}
                              </Typography>
                              <Typography>{solicitud.longitud}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Teléfono</strong>{" "}
                              </Typography>
                              <Typography>{solicitud.telefono}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Celular</strong>{" "}
                              </Typography>
                              <Typography>{solicitud.celular}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Gestión Operador</strong>{" "}
                              </Typography>
                              <Typography>
                                {solicitud.gestion_operador}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Box px={2}>
                              <Typography>
                                {" "}
                                <strong>Observación</strong>{" "}
                              </Typography>
                              <Typography>
                                {solicitud.observacion_diagnostico}
                              </Typography>
                            </Box>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  )}
                </Box>
              </Paper>
            )}
          </Paper>
        </Paper>
      </Box>

      <Dialog
        open={open2}
        maxWidth="md"
        onClose={() => {
          setOpen2(false);
          setKm("");
        }}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box px={2}>
                <Typography>
                  <strong>INGRESE EL KILOMETRAJE ACTUAL DE LA CUADRILLA</strong>
                </Typography>
                <br />
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  value={km}
                  onChange={(e) => {
                    setKm(e.target.value);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="success"
            variant="contained"
            disabled={km === ""}
            onClick={() => {
              iniciar2(objeto.actividades[actividad]?.id);
            }}
          >
            Iniciar
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen2(false);
              setKm("");
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open3}
        maxWidth="md"
        fullWidth
        onClose={() => {
          setOpen3(false);
          setMotivo("");
        }}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box px={2}>
                <Typography>
                  <strong>INGRESE EL MOTIVO DE LA CANCELACIÓN</strong>
                </Typography>
                <br />
                <TextField
                  fullWidth
                  size="small"
                  multiline
                  rows={2}
                  value={motivo}
                  onChange={(e) => {
                    setMotivo(e.target.value);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="success"
            variant="contained"
            disabled={motivo === ""}
            onClick={() => {
              cancelar(objeto.actividades[actividad]?.id);
            }}
          >
            Confirmar
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setOpen3(false);
              setMotivo("");
            }}
          >
            Regresar
          </Button>
        </DialogActions>
      </Dialog>
      <Cargando open2={cargando} />
    </>
  );
};
