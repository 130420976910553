// import { BrowserRouter, Route, Routes} from "react-router-dom";
// import "./App.css";

// import "react-datepicker/dist/react-datepicker.css";


// import { Home } from "./pages/home/Home";
// import ProtectedRoute from "./components/utils/ProtectedRoute";
// import { Login } from "./pages/login/Login";
// import { Ejemplo } from "./pages/home/Nav";
// import { Box } from "@mui/material";
// import { Bodega } from "./pages/inventario/bodega/Bodega";
// import { Producto } from "./pages/inventario/Producto";
// import { Proveedor } from "./pages/inventario/Proveedor";
// import { Stock } from "./pages/inventario/Stock";
// import { ListaCompras } from "./pages/inventario/ListaCompras";
// import { ParametroInventario } from "./pages/inventario/parametros/ParametrosInventario";
// import { UsuariosPage } from "./pages/usuarios/UsuariosPage";
// import { BodegaPage } from "./pages/inventario/bodega/BodegaPage";
// import { MovimientosPage } from "./pages/inventario/movimientos/MovimientosPage";
// import { Actividades } from "./pages/rutas/Actividades";
// import { Rutas } from "./pages/rutas/Rutas";


// const App =() => {
//   return (
//     <BrowserRouter>
//       <Box sx={{ display: "flex" }}>
//         <Ejemplo />
//         <Box
//           component="main"
//           sx={{
//             backgroundColor: (theme) =>
//               theme.palette.mode === "light"
//                 ? theme.palette.grey[100]
//                 : theme.palette.grey[900],
//             flexGrow: 1,
//             height: "100vh",
//             overflow: "auto",
//           }}
//         >
//           <Routes>
//             <Route element={<ProtectedRoute redirectPath="/login" />}>
//               <Route path="" element={<Home />} />
//               <Route path="home" element={<Home />} />
//               <Route path="inventario/bodegas" element={<BodegaPage />} />
//               <Route path="inventario/productos" element={<Producto />} />
//               <Route path="inventario/proveedores" element={<Proveedor />} />
//               <Route path="inventario/stock" element={<Stock />} />
//               <Route path="inventario/movimientos" element={<MovimientosPage />} />
//               <Route path="inventario/parametros" element={<ParametroInventario />} />
//               <Route path="inventario/compras" element={<ListaCompras />} />
              
//               <Route path="usuarios/admin" element={<UsuariosPage />} />


//               <Route path="rutas/rutas" element={<Rutas />} />
//               <Route path="rutas/actividades" element={<Actividades />} />
//             </Route>
//             <Route path="login" element={<Login />} />
//             <Route path="*" element={<Login />} />
//           </Routes>
//         </Box>
//       </Box>
//     </BrowserRouter>
//   );
// }

// export default App;

import '@progress/kendo-theme-bootstrap/dist/all.css';


import './index.css';
import { AppRouters } from './routers/AppRoutes';

function App() {
  return (
    <AppRouters />
  );
}
export default App;
