import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import TodayIcon from "@mui/icons-material/Today";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { validarRoles } from "../../components/utils/ValidarRoles";

export const ListAgenda = ({ info }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton component={Link} onClick={handleClick}>
        <ListItemIcon>
          <TodayIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{ fontWeight: "bold" }}
          primary="Agenda"
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding dense>
          {validarRoles("APROBAR SOLICITUDES", info) && (
            <ListItemButton
              component={Link}
              to={"agenda/aprobar"}
              sx={{ pl: 8 }}
            >
              <ListItemText primary="Aprobar Solicitudes" />
            </ListItemButton>
          )}

          {validarRoles("LEER SOLICITUDES", info) && (
            <ListItemButton
              component={Link}
              to={"agenda/solicitudes"}
              sx={{ pl: 8 }}
            >
              <ListItemText primary="Solicitudes" />
            </ListItemButton>
          )}
        </List>
      </Collapse>
    </>
  );
};
