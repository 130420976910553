import { Box, MenuItem, Paper, Select, Typography } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { CustomToolbar } from "../../../components/utils/CustomFilter";
import Swal from "sweetalert2";
import { MenuProps } from "../../../components/utils/MenuProps";

export const VisitaRel = (props) => {
  const [lista, setLista] = useState(null);
  const [tipo, setTipo] = useState("cuadrilla");

  useEffect(() => {
    cargar(tipo);
  }, []);

  function cargar(tipo2) {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ngrok-skip-browser-warning", "asc");
myHeaders.append("Authorization", "Bearer "+process.env.REACT_APP_TOKEN_API);
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://backterra.econtel.com.ec/api/api/visitas_realizadas/?tipo=" +
        tipo2,
      requestOptions
    )
      .then(async (response) => {
        if (response.ok) {
          return response.text();
        }
        let mensaje = await response.json();
        throw {
          ...mensaje,
        };
      })
      .then((result) => {
        //aconsole.log(JSON.parse(result));
        setLista(JSON.parse(result));
      })
      .catch((error) => {
        Swal.fire({ icon: "error", text: "Error: " + (typeof error === "string" ? error : JSON.stringify(error)) });
      });
  }

  const columns = [
    {
      field: "cuadrilla",
      headerClassName: "super-app-theme--header",
      headerName: "Cuadrilla",
      flex: 1,
      valueGetter: (params) => {
        let texto = "";
        if (props.cuadrillas) {
          let lis = props.cuadrillas.filter(
            (val) => val.id === params.row.id_cuadrilla
          );
          if (lis.length > 0) {
            texto = lis[0].nombre;
          }
        }
        return texto === "" ? params.row.id_cuadrilla : texto;
      },
    },
    {
      field: "tipo",
      headerClassName: "super-app-theme--header",
      headerName: "Tipo",
      flex: 1,
    },
    {
      field: "total",
      headerClassName: "super-app-theme--header",
      headerName: "Total",
      flex: 1,
    },
  ];

  const columns2 = [
    {
      field: "tecnico",
      headerClassName: "super-app-theme--header",
      headerName: "Técnico",
      flex: 1,
      valueGetter: (params) => {
        let texto = "";
        if (props.tecnicos) {
          let lis = props.tecnicos.filter(
            (val) => val.id === params.row.id_tecnico
          );
          if (lis.length > 0) {
            texto = lis[0].nombre;
          }
        }
        return texto === "" ? params.row.id_tecnico : texto;
      },
    },
    {
      field: "tipo",
      headerClassName: "super-app-theme--header",
      headerName: "Tipo",
      flex: 1,
    },
    {
      field: "total",
      headerClassName: "super-app-theme--header",
      headerName: "Total",
      flex: 1,
    },
  ];

  return (
    <>
      <Typography>
        {" "}
        <strong>Filtrar Por:</strong>{" "}
      </Typography>
      <Select
        size="small"
        MenuProps={MenuProps}
        value={tipo}
        onChange={(e) => {
          setTipo(e.target.value);
          cargar(e.target.value);
        }}
      >
        <MenuItem key={1} value={"cuadrilla"}>
          {" "}
          {"CUADRILLA"}{" "}
        </MenuItem>
        <MenuItem key={2} value={"tecnico"}>
          {" "}
          {"TECNICO"}{" "}
        </MenuItem>
      </Select>
      <Paper elevation={2} sx={{ overflowX: "scroll", padding: "10px" }}>
        <Box
          sx={{
            height: 400,
            minWidth: 400,
            "& .super-app-theme--header": {
              backgroundColor: "#428bd4",
              color: "white",
              fontSize: "16px",
            },
          }}
        >
          <DataGrid  disableColumnMenu
            rows={lista ?? []}
            loading={lista === null}
            columns={tipo === "cuadrilla" ? columns : columns2}
            getRowId={(row) => row.id_tecnico + "-" + row.id_cuadrilla}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            slots={{ toolbar: CustomToolbar }}
          />
        </Box>
      </Paper>
    </>
  );
};
